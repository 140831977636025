$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.game-frame {
  position: relative;
  flex-grow: 1;

  iframe {
    @include absolute-cover;
    border: 0;
  }
}
