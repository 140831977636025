$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;


.date-picker-wrapper {
    position: absolute;
    z-index: 4;
    border-radius: 10px;
    background: $cream;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15),
    0 3px 4px rgba(0, 0, 0, 0.15);

    .month-wrapper {
        position: relative;
        overflow: hidden;
        width: 640px !important;

        table {
            float: left;
            width: 270px;
        }

        tbody {
            //border-bottom: 30px solid transparent;
            border-bottom: 10px solid transparent;
        }

        tbody td {
            cursor: pointer;
        }

        .day {
            height: 30px;
            padding: 9px 0;
            font-size: 12px;
            line-height: 12px;
            text-align: center;
            color: $darkDarken;
            border-radius: 15px;
        }

        // current day
        .day.real-today:not(.checked),
        .day.real-today:not(.hovering) {
            //background-color: #e5e5e5;
            background-color: #fbce7e;
        }

        .day.checked,
        .day.toMonth.hovering {
            background-color: #fbce7e;
        }

        .first-date-selected,
        .last-date-selected {
            font-weight: 700;
            color: #fff;
            //background-color: $darkDarken !important;
            background-color: #fbce7e;
        }
    }

    &.single-date.single-month .month-wrapper {
        width: 320px !important;
        //width: 350px;
    }

    .caption {
        background-color: $cream;
    }

    .month-name {
        font-size: 18px;
        font-weight: 700;
        line-height: 18px;
        text-transform: capitalize;
        color: $violetOld;

        height: 64px;
    }

    .prev,
    .next {
        float: left;
        //height: 64px;
        height: 40px;
        //width: 40px;
        width: 24px;
        font-size: 0;
        cursor: pointer;

        background-position: center;
        //background-size: 20px 20px;
        background-size: 11px 9px;
        background-repeat: no-repeat;
        background-image: url("/img/arr_b-dark.svg");
        background-color: $cream;

        //transform: rotate( 270deg );
        margin-top: 11px;
    }

    .prev {
        //transform: rotate(90deg);
        transform: translate(30px) rotate(90deg);
    }
    .next {
        transform: translate(-30px) rotate( 270deg );
    }

    .week-name {
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: .05em;
        text-transform: uppercase;
        color: $dark;

        th {
            height: 35px;
            width: 38px;
        }
    }

    .date-range-length-tip {
        display: none;
        position: absolute;

        font-size: 12px;
        line-height: 16px;
        color: #fff;

        margin-top: -4px;
        margin-left: -8px;
        padding: 0 6px;
        
        background-color: $darkDarken;
        border-radius: 2px;

        &::after {
            content: '';
        
            position: absolute;
            left: 50%;
            bottom: -4px;
        
            margin-left: -4px;
        
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid $darkDarken;
        }
    }






}

@include s {
    .date-picker-wrapper {
        //top: 44px !important;
    }
}


.date-picker-wrapper .select-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}


.date-picker-wrapper .month-element {
    display: inline-block;
    vertical-align: middle
}

.date-picker-wrapper .select-wrapper select {
    position: absolute;
    margin: 0;
    padding: 0;
    left: 0;
    top: -1px;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    text-transform: inherit;
    color: inherit;
    cursor: pointer;
    appearance: none;
    background: 0 0;
    border: 0;
    outline: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
    filter: alpha(opacity=1);
    opacity: .01
}

.date-picker-wrapper .month-wrapper table div.day.lastMonth,
.date-picker-wrapper .month-wrapper table div.day.nextMonth {
    color: #999;
    cursor: default
}


.date-picker-wrapper .month-wrapper table .day.has-tooltip {
    cursor: help !important
}

.date-picker-wrapper .month-wrapper table .day.has-tooltip .tooltip {
    white-space: nowrap
}

.date-picker-wrapper .time label {
    white-space: nowrap
}

.date-picker-wrapper .month-wrapper table .day.lastMonth,
.date-picker-wrapper .month-wrapper table .day.nextMonth {
    display: none
}


.date-picker-wrapper .selected-days {
    display: none
}



.date-picker-wrapper .time {
    position: relative
}

.date-picker-wrapper.single-month .time {
    display: block
}


.date-picker-wrapper .hide {
    display: none
}

// renderTime()
// showTime

