$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.popup {
  position: fixed;
  background-color: rgba(62,62,62, .8);
  min-height: 100%;
  height: 100%;
  max-width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  z-index: 1000;
  overflow-y: scroll;
  &.is-hidden {
    visibility: hidden;
  }
  &__content {
    padding: 30px 30px 40px;
    position: relative;
    margin: 100px auto 0;
    border-radius: 10px;
    background-color: #ffd180;
    max-width: 350px;
    margin-bottom: 20px;
  }
  &__close {
    position: absolute;
    right: -30px;
    top: -30px;
    transition: all 200ms;
    z-index: 100;
    background-color: transparent;
    border: none;
    svg {
      width: 28px;
      height: 28px;
      fill: #fff;
    }
      &:hover{
        -ms-transform: rotate(-360deg);
        transform: rotate(-360deg);
      }
  }

  &__logo {
    width: 135px;
    height: 54px;
    margin: 0 auto;

    & svg {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    color: #3e3e3e;
    margin-bottom: 20px;
  }
  &__text {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.76;
    color: #3e3e3e;
  }

  @include xxs {
    background: #3e3e3e;
    &__content {
      border-radius: 0px;
      max-width: 320px;
      margin-top: 69px;
      padding: 30px 15px 40px;
    }
    &__close {
      top: -49px;
      right: 20px;
    }
  }
}
.popup-cashier {
  max-width: 410px;

  .company {
    text-align: center;
    color: #3f3f3f;
    font-size: 10px;
  }

  @include xxs {
    &__content {
      max-width: 410px;
    }
  }
}
/*===================================
----------- POPUP RULE (INFO POPUP)---------------
======================================*/
.popup-rule {
  background-color: rgb(249, 251, 231);
  margin: 104px auto 0;
  max-width: 635px;
  max-height: 835px;
  width: 100%;
  height: calc(100% - 190px);
  min-height: 320px;
  &__content {
    background-color: transparent;
    max-width: 100%;
    font-size: 14px;
    line-height: 1.76;
    color: rgb(63, 63, 63);
    overflow: hidden;
    & p {
      margin: 0px 0px 15px 0px;
      &:last-child {
        margin: 0px;
      }
    }
    & table {
      width: auto !important;
      max-width: 100%;
    }
  }
  &__wrap {
    height: calc(100% - 110px);
    overflow: hidden;

    &.is-flex {
      display: flex;
      flex-direction: column;
    }
  }
  &__block {
    height: 100%;
    overflow-y: auto;
    padding-right: 25px;
  }
  &__close-btn-wrap {
    text-align: center;
    margin-top: 20px;
  }
  @include xs {
    max-width: 100%;
    border-radius: 0;
    &__close {
      top: -49px;
      right: 20px;
    }
    &__close-btn-wrap {
      margin-top: 20px;
    }
    &__block {
      padding: 0px;
      margin-right: -37px;
      padding-right: 37px;
    }
  }
  @include xxs() {
    margin: 68px auto 0px;
    height: calc(100% - 70px);
  }
}
