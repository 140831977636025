$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.header-l-promo{
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0 185px;
    &__icon{
        width: 204px;
    }
    &__content{
        position: relative;
        width:400px;
        max-width:400px;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    &__text{
        font-size: 26px;
        line-height: 37px;
        letter-spacing: 1px;
        color: #3f3f3f;
        text-transform: uppercase;
        font-weight:bold;
        margin:0 0 12px 0;
        overflow: hidden;
        &.is-violet{
            color: #9746a5;
            text-shadow: 0 3px 0 #ffffff;
        }
        &.is-medium{
            font-size:26px;
            letter-spacing: 0.08em;
        }
        &.is-big{
            font-size:48px;
            font-weight: 900;
        }
    }
    &__item{
        font-size: 14px;
        color: #646464;
        text-align:left;
        line-height:24px;
    }

    @include xxl(){
        padding: 0 38px;
    }

    @include s(){
        justify-content: center;
        align-items: center;
    }

    @include xs(){
        padding: 0;
        &__icon{
            display: block;
            margin: 0 auto;
            width: 84px;
            height: 38px;
        }
        &__item{
            line-height: 19px;
        }
        &__content{
            width: 100%;
            max-width: 100%;
            display: inline-block;
        }
        &__text{
            font-size:16px;
            &.is-big{
                font-size:44px;
            }
            &.is-medium{
                font-size:18px;
            }
        }
    }
}