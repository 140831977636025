$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.header-l{
    display:block;
    background: url( $cdn + "/landings/yoyo/background.jpg") no-repeat;
    background-position-x: left;
    margin-bottom:28px;
    min-height:800px;
    &__bonus-btn{
        margin-top: 57px;
        @include s{
            margin-top: 35px;
        }
    }

    &__arrow-icon{
        position: relative;
        width:14px;
        height:14px;
        margin:12px 0;
        top:-1px;
        animation: arrow 1s infinite linear;
        & svg{
            max-width:100%;
            max-height:100%;
        }
    }
    &__arrow-text{
        font-size: 14px;
        color: #646464;
    }
    @include xxl(){
        background-position: 55% 50%;
    }
    @include l(){
        background-position: 66% 50%;
    }
    @include m(){
        background-position: 72% 50%;
    }
    @include s(){
        background: url( $cdn + "/landings/yoyo/banner_mobile.jpg") no-repeat;
        background-position: center top;
        margin-bottom:20px;
        min-height:auto;
        &__arrow-icon{
            animation:none;
            top:5px;
        }

    }
    @include xs(){
        background: linear-gradient(180deg, rgba(249, 251, 231, 0) 60%, #F9FBE7 100%), url( $cdn + "/landings/yoyo/banner_mobile.jpg");
        background-position: 0 -324px, center 0;
        background-size: 100% 570px, 720px auto;
        background-repeat: no-repeat;
        &__mobile-text{
            margin-right: 5px;
        }
    }
}

@keyframes arrow {
    50% {
        top: 5px;
    }
}
