$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.game-seo {
  position: relative;
  overflow: hidden;
  height: 157px;
  padding: 32px 32px 40px;

  &::before {
    content: '';
    display: block;
    @include absolute-cover;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 64.5%);
    pointer-events: none;
    z-index: 1;
  }

  &__content {
    margin: 0;
    padding-bottom: 30px;
    @include text(12px, 140%, 400, rgba(#fff, 0.8));

    h1,
    h2 {
      @include text(14px, 140%, 500, #fff);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    div,
    table {
      margin: 0 0 12px;
    }

    & > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__btn {
    position: absolute !important;
    bottom: 30px;
    z-index: 2;
  }

  &.is-active {
    height: auto;

    &::before {
      opacity: 0;
    }
  }
}
