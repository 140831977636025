$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.promotions {
    height: 100%;
    padding: 23px 0 50px;
    line-height: 1.2;
    background-image: url("/img/img-promo-left_element.svg"), url("/img/img-promo-right_element.svg");
    background-repeat: no-repeat;
    background-position: top left, top right;
    @include m {
      background-position:  top left -250px, top right -250px;
    }
}
