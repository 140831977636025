$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.linda-vip-item{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:19%;
  border-radius: 10px;
  background: rgba(53, 210, 144, 0.1);
  padding:32px 25px;
  box-sizing: border-box;
  margin-right:15px;
  &:last-child{
    margin-right:0;
  }
  &_status-2{
    background:rgba(255, 209, 128, 0.2)
  }
  &_status-3{
    background:rgba(170, 105, 172, 0.1)
  }
  &_status-4{
    background:rgba(255, 168, 0, 0.15)
  }
  &_status-5{
    background:rgba(234, 70, 66, 0.1);
  }
  &__title-wrap{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom:14px;
  }
  &__title{
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    color: #3f3f3f;
    margin-bottom:25px;
    text-align: center;
  }
  &__icon{
    line-height: 0;
    & svg{
      width:100%;
      height:100%;
      max-width:160px;
      max-height:126px;
    }
  }
  &__info{
    width:100%;
    display:flex;
    flex-direction: column;
    padding:8px 0;
    border-bottom:1px solid #c5d3bf;
    min-height:50px;
    align-items: center;
    &:last-child{
      border-bottom:none;
      margin-bottom:0;
      padding-bottom:0;
    }
  }
  &__key{
    text-align: left;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.2;
    color: #3e3e3e;
    margin-bottom: 5px;

  }
  &__value{
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    color: #3e3e3e;
    text-align: right;
    @include text-dots()
  }
  @include l(){
    padding:32px 15px;
    &__icon{
      & svg{
        max-width:128px;
        max-height:101px;
      }
    }
  }
  @include m(){
    max-width:316px;
    margin:0 18px 18px 0;
    padding:32px 25px;
    flex: 1 1 0;
    min-width: 300px;
    &__title{
      margin-bottom:22px;
    }
    &__title-wrap{
      margin-bottom:4px;
    }
    &_status-3{
      margin-right:0;
    }
  }
  @include s(){
    max-width:232px;
    margin:0 16px 16px 0;
    padding:30px 15px;
    min-width: 220px;
    &_status-3{
      margin-right:0;
    }
  }
  @include xs(){
    max-width: 242px;
    min-width:233px;
    padding: 20px 20px 25px;
    &_status-2{
      margin-right:0;
    }
    &_status-3{
      margin-right:16px;
    }
    &_status-4{
      margin-right:0;
    }
    &__icon{
      & svg{
        max-width:84px;
        max-height:67px;
      }
    }
    &__title{
      margin-bottom: 0px;
    }
    &__title-wrap{
      margin-bottom: 5px;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
    }
  }
  @include xxs(){
    max-width: 290px;
    margin-right:0;
    padding-top:20px;
    display: block;
    width: 100%;
    &__title{
      width: 130px;
      text-align: left;
      margin-bottom:0;
    }
    &__icon{
      & svg{
        max-width:84px;
        max-height:67px;
      }
    }
    &__key{
      font-size:12px;
    }
    &__value{
      font-size:14px;
    }
  }
}