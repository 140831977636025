$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.bankid-banner-promo {
  text-align: center;
  &__title {
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.6);
  }

  &__description {
    margin-top: 4px;
    font-weight: 900;
    font-size: 32px;
    line-height: 36px;
    color: #FFFFFF;
    @include text-lines-clip(3);
  }
  &__btns{
    margin-top: 16px;
    height: 48px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
