$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.w-cancel {
  &__title {
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(65, 48, 52, 0.8);
    text-align: center;
    margin-bottom: 20px;
  }
}

.w-cancel-table {

  &__thead, &__tr {
    padding-left: 50px;
  }

  &__th, &__tr-subrow {
    width: 20%;
    flex-shrink: 0;
  }
}

.w-info {
  background: #FFFFFF;
  border: 2px solid #FFA800;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 16px 24px;
  margin: 16px auto 0;
  width: fit-content;

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #3E3E3E;
    margin: 0;
  }
}

.w-bonus {
  background: #FFFFFF;
  border: 2px solid #FFA800;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 16px 24px;
  margin: 16px auto 0;
  width: fit-content;
  text-align: center;

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #3E3E3E;
    margin: 0 2px;

    &.is-red {
      color: #FF5300;
    }

    &:last-child {
      display: block;
    }
  }
}

.w-cont {
  background: #00BE6E;
  border-radius: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  text-align: center;
  padding: 10px 64px;
  width: fit-content;
  margin: 16px auto 0;
  cursor: pointer;
}

.w-cancel-bonus {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-decoration-line: underline;
  color: #AA69AC;
  margin: 20px auto 0;
  background-color: transparent;
  border: none;
  display: block;
  cursor: pointer;
}

.w-atten {
  background: #FFFFFF;
  border: 2px solid #FFA800;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 16px 24px;
  margin: 16px auto 0;
  width: fit-content;
  text-align: center;

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #3E3E3E;
    margin: 0 2px;
  }
}

.w-dep {
  min-width: 250px;
  margin: 16px auto 0;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}