$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.missed-data {
    display: block;
    background-image: url($cdn + "/yoyo/bg1920.jpg");
    min-height: 100%;
    background-size: cover;
    padding-bottom: 30px;
        .header__logo {
            display: block;
            height: 49px;
            width: 120px;
        }
    &__header {
        padding-top: 30px;
        margin-bottom: 72px;
    }
    &__section {
        width: 640px;
        margin: 0 auto;
        padding: 30px 12px 0 34px;
        background: #F9FBE7;
        border-radius: 12px;
    }
    &__content {

        .has-terms {
            padding: 20px 0 16px;
        }
    }
    &__wrapper {
        --scroll-width: 6px;

        height: 417px;
        padding-right: calc(24px - var(--scroll-width));
        overflow-x: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: var(--scroll-width);
        }
        &::-webkit-scrollbar-track {
            background-color: #fff;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #FBCE7E;
        }

         & h1:not(.missed-data__title) {
             display: none;
         }

        & .info-page__container {
            padding: 0;
        }

        &.is-small {
            height: 251px;
        }


    }

    &__title {
        margin-bottom: 16px;
        margin-top: 0;
        font-size: 24px;
        line-height: 28px;
        font-weight: 500;
        color: #686B6C;
        text-align: center;
    }

    &__form-wrapper {
        position: relative;
        z-index: 100;
        margin: 0 -12px 0 -34px;

        .has-terms & {
            box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.15);
        }
    }

    &__form {
        padding: 9px 40px 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__check {
        margin-bottom: 15px;
        margin-top: 15px;

        .g-formbox-item, .g-check-label, .g-check-text {
            width: auto;
        }
        .g-check + .g-check-label {
            justify-content: center;
            flex-direction: column;
        }
        .g-check-label__wrap {
            display: flex;
            align-items: center;
        }

        .g-form-error_checkbox {
            & .g-form-error__text .g-required {
                text-align: center;
            }
        }
    }

    .missed-terms {
        .info-page__wrap {
            padding-top: 0;
            padding-bottom: 0;
        }
        & h1, 
        & h2, 
        & h3, 
        & p {
            margin: 0 0 15px 0;
            font-size: 12px;
            line-height: 16px;

            & span {
                font-weight: bold;
            }
        }
    }
    &__btn {
        width: 151px;
        height: 40px;
        background-color: #35D290;
        color: #fff;
        border-radius: 80px;
        border: none;
        font-size: 16px;
        line-height: 18px;
        font-weight: 600;
        text-transform: capitalize;
        cursor: pointer;
        margin: 0 5px;
        &:disabled {
            background-color: #A4A4A4;
            color: rgba(255, 255, 255, 0.4);
            cursor: not-allowed;
            &:hover {
                background-color: #A4A4A4;
                color: rgba(255, 255, 255, 0.4);
            }
        }
        &:hover {
            background-color: #2EB47B;
            color: #fff;
        }
    }
    &__cancel {
        margin: 0 5px;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        border: none;
        background: transparent;
        cursor: pointer;
        width: 151px;
        &:hover {
            text-decoration-line: underline;
        }
    }  
    .has-margin-top-16 {
        margin-top: 16px;
    }
    
    &__limit-btn {
        background: none;
        border:none; 
        margin-top: 20px;
        cursor: pointer;
    }
}