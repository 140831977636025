$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.se-self-exclusion {
  &__title {
    margin-bottom: 20px;
  }
  &__sub-title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    text-align: left;
  }
  &__text {
    margin-bottom: 20px;
  }
  @include xs {
    &__modal-box {
      width: 320px;
      padding: 30px 15px 40px;
    }
  }
}