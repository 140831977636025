$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.mail-change {
  text-align: left;
  &__formbox-item {
    margin-bottom: 20px;
    margin-top: 0;
  }
  &__btn {
    width: 100%;
  }
}