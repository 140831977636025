$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.self-control {

  &__section {
    padding: 24px 28px;
    border-radius: 10px;
    border: 1px solid #ffa800;
  }

  //todo delete after global refactoring
  p,
  h2 {
    margin: 0;
  }

  &__title {
    @include text(20px, 24px, 700, #3F3F3F);
    padding-bottom: 20px;
  }

  &__text {
    @include text(16px, 22px, 400, #3F3F3F);
  }

  &__list {
    padding: 0;
    counter-reset:item;
    list-style-type:none;
    text-align: left;
    margin: auto;
  }
  &__list-item {
    margin:0;
    padding-left: 24px;
    padding-bottom: 12px;
    counter-increment: item;
    position: relative;
    @include text(16px, 22px, 400, #3F3F3F);

    &:last-of-type {
      padding-bottom: 0;
    }
    &:before {
      content: counter(item) ".";
      width: 20px;
      padding-right: 4px;
      font-weight: bold;
      position: absolute;
      left: 0;
    }
  }
  &__wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    column-gap: 20px;
    margin-top: 20px;
  }
}
