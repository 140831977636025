$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-deposit-limit {

  &__interval-wrap {
    display: flex;
    margin-top: 20px;
    align-items: flex-start;

    //todo перевірити,переробити
    min-height: 320px;

  }
  &__interval-item {
    padding: 20px 20px 30px;
    margin-right: 20px;
    max-width: 314px;
    min-height: 248px;
    border-radius: 10px;
    background-color: #faeab9;
    overflow: hidden;
    text-align: center;
    flex-grow: 1;
    &:last-child {
      margin-right: 0;
    }
  }
  &__interval-title {
    font-size: 14px;
    color: #3f3f3f;
    margin-bottom: 5px;
  }
  &__interval-big-title {
    font-size: 24px;
    font-weight: 900;
    color: #66554f;
    margin-bottom: 20px;
  }
  &__amount-text {
    font-size: 14px;
    font-weight: 500;
    color: #3f3f3f;
    margin-bottom: 10px;
    text-align: left;
    padding-left: 25px;
  }
  &__input-wrap {
    margin-bottom: 20px;
    text-align: left;
  }
  &__next-limit {
    font-size: 12px;
    color: #3f3f3f;
    margin-top: 5px;
    text-align: center;
    line-height: 1.2;
    margin-bottom: -10px;
  }
  &__text-b {
    font-weight: 600;
  }
  &__btn-s {
    //width: 100%;
    margin-bottom: 15px;
  }
  @include xs {
    &__interval-wrap {
      flex-wrap: wrap;
      justify-content: center;
    }
    &__interval-item {
      max-width: 247px;
      margin-right: 15px;
      margin-bottom: 15px;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
  @include xxs {
    &__interval-item {
      margin-right: 0;
      max-width: 290px;
    }
  }
}


.show-on-editMode {
  display: none;
}

.set-editmode {
  .show-on-editMode {
    display: block;

    &.is-flex {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
  .hide-on-editMode {
    display: none;
  }
}

.input-block {
  background-color: #dcdbca;
  border-color: transparent;
  line-height: 38px;
}