$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.game-box {

  &__wrap {
    display: flex;
    flex-wrap: wrap;
  }
  &__show-more-wrap {
    text-align: center;
  }
  &__show-more-btn {
    max-width: 100%;
    margin: 20px auto 0;
  }
  &__nogame {
    margin: 0 auto;
  }
  &__nogame-h2 {
    margin: 20px 0;
    color: rgba(65, 48, 52, 0.8);
  }
  @include l {

    &__show-more-btn {
      margin: 25px auto 0;
    }
  }

  @include s {

    &__show-more-btn {
      margin: 20px auto 0;
    }
  }

  @include xs {
    &__show-more-btn {
      margin: 15px auto 0;
    }
  }
}

.game-box-tournament {
  @include l {
    margin: 0 -8px;
  }
  @include xs {
    margin: 0 -5px;
  }
}
