$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.search-grid {
  grid-gap: 12px 6px;
  grid-template-columns: repeat(4, 104px);
  display: grid;

  @include bp-1440 {
    grid-template-columns: repeat(6, 170px);
    grid-gap: 16px 8px;
    margin-top: 12px;
  }
}
