$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
#air-datepicker-global-container {
  .air-datepicker {
    --adp-z-index: 1000;
    --adp-border-radius: 10px;
    --adp-width: 270px;
    --adp-padding: 8px;
    --adp-overlay-background-color: #f9fbe7;
    --adp-background-color: #f9fbe7;
    --adp-background-color-hover: #f9fbe7;
    --adp-cell-background-color-in-range: #fbce7e50;
    --adp-color: #404040;
    --adp-color-secondary: #ab69ad;
    --adp-nav-arrow-color: #404040;
    --adp-day-name-color: #404040;
    --adp-day-cell-height: 30px;
    --adp-cell-border-radius: 0;
    --adp-cell-background-color-hover: none;
    --adp-border-color: transparent;
    --adp-border-color-inner: transparent;
    background: #f9fbe7!important;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15),
    0 3px 4px rgba(0, 0, 0, 0.15)!important;
  }
  .air-datepicker-nav--title {
    text-transform: capitalize!important;
    color: #ab69ad;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
  }
  .air-datepicker-cell.-focus- {
    color: #3e3e3e;
    background-color: #fbce7e;
    border-radius: 15px;
    padding: 9px 0;
  }
  .air-datepicker-cell.-current- {
    color: #3e3e3e;
    background-color: #fbce7e;
    border-radius: 15px;
    padding: 9px 0;
  }
  .air-datepicker-cell.-selected-, .air-datepicker-cell.-selected-.-current- {
    color: #fff!important;
    background: #fbce7e!important;
    border-radius: 15px;
  }
  .air-datepicker-body--day-name {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
  }
  .air-datepicker-cell.-day-.-other-month- {
    visibility: hidden;
    pointer-events: none;
  }
  .air-datepicker-nav--action:hover {
    background: none;
  }
  .air-datepicker-nav--action path {
    stroke: #404040;
  }
  .air-datepicker--pointer {
    display: none;
  }
  .air-datepicker-cell.-range-from- {
    border-radius: 15px 0 0 15px;
  }
  .air-datepicker-cell.-range-to- {
    border-radius: 0 15px 15px 0;
  }
}