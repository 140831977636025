$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.seo-game {
    padding: 25px 35px;
    background-color: #FDFFEE;
}

.seo {
    &-container-v2 {
        background: transparent;
        padding: 0;

        & section {
            border-radius: 10px;
            border: 1px solid #FFC200;

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        & h1, h2 {
            margin: 0;
            padding: 12px 60px;
            text-align: center;
            position: relative;
            cursor: pointer;
            color: #AA69AC;
            font-size: 32px;
            line-height: 1.4em;
            font-weight: 700;
            min-height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            transition: background-color .3s ease;
            border-radius: 10px 10px 0 0;

            &:after {
                position: absolute;
                content: '';
                width: 19px;
                height: 12px;
                right: 24px;
                top: 50%;
                background-image: var(--icon-seo-arrow);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                transform: translateY(-50%);
            }

            &.is-open {
                background-color: #FFD07E;
                &:after {
                    background-image: var(--icon-seo-arrow-opened);
                }

                & ~ div {
                    display: block;
                }
            }
        }

        & div {
            width: 100%;
            max-width: 816px;
            margin: 0 auto;
            padding: 20px 20px 10px 20px;
            display: none;
            overflow: hidden;

            & h2 {
                color: #AA69AC;
                position: static;
                max-width: 100%;
                line-height: 25px;
                font-size: 20px;
                padding: 0;
                background-image: none;
                margin: 10px 0;
            }
        }

        & ul {
            list-style: disc;
            margin-bottom: 10px;
            margin: 10px 0 10px 36px;

            & li {
                line-height: 1.8em;
                font-size: 14px;
                margin: 7px 0;
                color: #3F3F3F;
                list-style: disc;

                & a {
                    font-size: 14px;
                    line-height: 30px;
                    font-weight: bold;
                }
            }
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 1.8em;
            color: #3F3F3F;
            margin: 0 0 10px 0;
            text-align: justify;
        }

        & a {
            font-size: 14px;
            line-height: 1.8em;
            color: #AA69AC;
            text-decoration: underline;
            font-weight: bold;
        }
    }
}