$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
@import 'styles/search-grid';
@import 'styles/search-cols';
@import 'styles/search-results';
@import 'styles/search-categories';

.search-popup {
  --offset-x: 24px;

  padding: 0 var(--offset-x) 32px;

  &__input {
    margin-top: -3px;
    margin-bottom: 14px;
    display: block;
  }

  @include bp-1440 {
    --offset-x: 70px;

    &__input {
      margin-bottom: 22px;
    }
  }
}
