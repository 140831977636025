$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
//- s - size 36px
//- m - size 40px

.btn-circle {
  $this: &;

  --rect-btn-size: 32px;
  --rect-btn-icon-size: 22px;

  position: relative;
  display: inline-block;
  vertical-align: top;
  width: var(--rect-btn-size);
  height: var(--rect-btn-size);
  background-color: transparent;
  border: 1px solid $violet;
  color: $violet;
  border-radius: 50%;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;

  &__icon {
    @include absolute-center;
    display: block;
    width: var(--rect-btn-icon-size);
    height: var(--rect-btn-icon-size);
    fill: currentColor;
    transition: fill 0.2s ease-in-out;
  }

  &.is-green {
    background-color: $green;
    color: $white;
    border-color: $green;

    &:hover {
      background-color: $green-dark;
    }
  }

  &.is-fav {
    color: $violet;

    #{$this}__icon {
      fill: none;
      stroke-width: 2px;
      stroke: currentColor;
      width: calc(var(--rect-btn-icon-size) - 2px);
      height: calc(var(--rect-btn-icon-size) - 2px);
    }

    &:hover {
      #{$this}__icon {
        fill: currentColor;
      }
    }

    &.is-active {
      background-color: transparent;
      color: $violet;

      #{$this}__icon {
        fill: currentColor;
      }
    }
  }

  &:hover {
    background-color: $violet;
    color: $white;
  }

  &.is-active {
    background-color: $violet;
    color: $white;
  }

  &.is-m {
    --rect-btn-size: 40px;
    --rect-btn-icon-size: 24px;
  }
}
