$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.category-header {
  $this: &;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;

  &__title {
    @include ellipsis;
    @include text(16px, 19px, 700, rgba(65, 48, 52, 0.80));
    margin: 0;
    padding: 0 12px 0 0;
  }

  &__more {
    flex-shrink: 0;
    display: block;
    color: #9746A5;
    @include ellipsis();
    max-width: 35%;
    text-decoration: underline;
    font-weight: 500;

    &:hover {
      color: #81348E;
    }
  }

  @include bp-1440 {

    &__title {
      font-size: 20px;
      line-height: 24px;
    }

    &__more {
      font-size: 16px;
      line-height: 20px;
    }

  }
}
  