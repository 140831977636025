$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.slider-games {
  display: block;
  padding-top: 29px;
  padding-left: 40px;
  margin-bottom: 80px;
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 100%;
    top: 0;
  }

  &::before {
    background: linear-gradient(90deg, #EA4642, transparent);
    left: 20px;
  }
  &:after {
    background: linear-gradient(-90deg, #EA4642, transparent);
    right: 0;
  }

  &__block {
    position: relative;
  }
  &__title {
    padding-bottom: 25px;
    max-width: calc(100% - 550px);
    display: flex;
    align-items: baseline;
    padding-left: 10px;
  }
  &__span {
    color: #fff;
    font-weight: 500;
    font-size: 34px;
    line-height: 40px;
    padding-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
  }
  &__link {
    font-weight: bold;
    font-size: 14px;
    line-height: 25px;
    text-decoration-line: underline;
    color: #F9FBE7;
    flex-shrink: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
  }
  &__img {
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    width: 188px;
    height: 282px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  &__btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: white;
    position: absolute;
    top: -65px;
    z-index: 3;
    cursor: pointer;

    &:hover,
    &:active {
      background: #EA4642;
      border: 1px solid #fff;
    }
    &_prev {
      right: 95px;
    }
    &_next {
      right: 40px;
      transform: rotate(180deg);
    }
  }
  .icon-icon-arr-right__before {
    &:before {
      content: "";
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px 14px;
      display: block;
      width: 20px;
      height: 40px;
      position: absolute;
      top: 0px;
      right: 10px;
      filter: invert(54%) sepia(84%) saturate(4607%) hue-rotate(335deg) brightness(95%) contrast(93%);
    }

    &:hover:before,
    &:active:before {
      filter: none;
    }
  }
  .slick-slide {
    margin: 0 5px;
  }
  .slick-list {
    margin-right: -5px;
  }
  &__roullette-image {
    height: 430px;
    right: -175px;
    left: 0;
    bottom: 0;
    position: absolute;
    overflow: hidden;
    text-align: right;
    max-width: 901px;
    margin-left: auto;
    z-index: 2;

    & img {
      max-width: unset;
      right: 0;
      position: absolute;
      animation: rouletteRotate 60s linear infinite;
    }
  }

  &__item {
    margin: 0;
    padding-bottom: 24px;
  }

  @media (min-width: 1920px) {
    &__roullette-image {
      right: 0;
    }
    &__title {
      padding-bottom: 25px;
      max-width: calc(100% - 750px);
    }
  }

  @include l {
    margin-bottom: 70px;
    padding-left: 20px;

    &::before {
      left: 0;
    }


    &__roullette-image {
      right: -390px;
    }
    &__title {
      max-width: calc(100% - 360px);
      padding-left: 12px;
    }
  }
  @include m {
    margin-bottom: 60px;

    &__roullette-image {
      right: -50%;
    }
    &__title {
      max-width: calc(100% - 255px);
    }
  }

  @include s {
    margin-bottom: 38px;
    overflow: hidden;
    padding-top: 16px;
    padding-left: 15px;

    &::before,
    &::after {
      width: 15px;
    }
    &__item {
      padding-bottom: 15px;
    }
    &__img {
      width: 140px;
      height: 210px;
    }
    &__title {
      padding-bottom: 12px;
      padding-left: 5px;
    }
    &__span {
      font-size: 24px;
      line-height: 28px;
    }
    .slick-list {
      min-height: 210px;
    }
    &__roullette-image {
      height: 550px;
      max-width: 550px;
      right: -156px;
      bottom: -289px;

      & img {
        height: 100%;
      }
    }
  }

  @include xs {
    &__roullette-image {
      right: -380px;
    }
    &__title {
      max-width: calc(100% - 35px);
    }
  }

  @include xxs {
    margin-bottom: 25px;

    &__roullette-image {
      display: none;
    }
    &__title {
      max-width: 100%;
      padding-left: 0px;
      padding-right: 15px;
    }
  }
}