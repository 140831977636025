$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.game-page-header {
  $this: &;

  flex-shrink: 0;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 64px;
  padding: 8px 15px;
  background: linear-gradient(0deg, #F9FBE7 0%, #F9FBE7 100%), #FFF;
  box-shadow: 1px 4px 16px 0px rgba(33, 1, 74, 0.15);

  &__back {
    flex-shrink: 0;
    margin-right: auto;
  }

  &__search {
    flex-shrink: 0;
  }

  &__deposit{
    flex-shrink: 0;
    max-width: 250px;
  }

  &__title {
    text-align: center;
    @include absolute-center-x;
    width: 100%;
    max-width: calc(100% - 350px * 2);
  }

  &__name {
    margin: 0 0 2px;
    @include text(16px, 120%, 700, #3f3f3f);
    @include ellipsis;
  }

  &__provider {
    margin: 0;
    @include text(12px, 120%, 400, #8f8f8f);
    @include ellipsis;
    text-transform: capitalize;
  }
}
