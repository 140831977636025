$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
@use 'sass:math';

.events {
  display: block;
  position: relative;

  &__title {
    position: absolute;
    top: -26px;
    left: 0;
  }

  &__in {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 0;
    padding-top: percentage(math.div(536, 187));
  }

  &__list {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    @include absolute-cover;
    @include without-scrollbar;
  }

  &__item {
    flex-shrink: 0;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.events-item {
  width: 100%;
  height: 70px;
  background: #f9fbe7;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 10px 8px 10px;
  display: flex;
  align-items: center;

  &__text {
    @include text(12px, 14px, 400, #3f3f3f);
    @include text-lines-clip;

    a {
      color: currentColor;
      text-decoration: underline;

      &:hover {
        color: #81348e;
      }
    }
  }

  &__img {
    flex-shrink: 0;
    width: 60px;
    height: 50px;
    margin-left: 8px;
    border-radius: 6px;
  }
}
