$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.reality-check {

  &__item-box {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid #d9b472;
    line-height: 1.2;
    margin-bottom: 15px;
    justify-content: space-between;
  }
  &__value {
    color: #3e3e3e;
    font-weight: 600;
    font-size: 16px;
  }
  &__key {
    font-size: 14px;
    color: #66554f;
    text-align: left;
  }
  &__exit {
    text-align: center;
    margin-top: 20px;
  }
  &__btn-label {
    font-size: 14px;
    line-height: 1.2;
    color: #3e3e3e;
    margin: 20px 0;
  }
}