$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.header-l-logo{
    margin-right:12.5%;
    svg{
        width:132px;
        height:54px;
    }

    @include l(){
        margin-right: 87px;
    }
    @include s(){
        margin-right:0;
        svg{
            width:98px;
            height:40px;
        }
    }
}