$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.bankid-banner-text {
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
  & a{
    color: rgba(255, 255, 255, 0.4);

    &:hover {
      text-decoration: underline;
    }

  }
}
