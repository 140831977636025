$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.unsubscribe {

  &__title {
    font-size: 16px;
    font-weight: bold;
    color: rgba(65, 48, 52, 0.8);
    text-align:center;
    margin: 30px 0 20px;
  }

  &__form {
    border-radius: 10px;
    border: solid 1px #ffd180;
  }

  &__separate {
    border-bottom: solid 1px #ffd180;
    padding: 30px 100px 20px;
    display: flex;
    justify-content: space-between;
  }

  &__all {
    padding: 30px 100px;
  }

  &__block  {
    width: 330px;
  }

  &__block-title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: rgba(65, 48, 52, 0.8);
    margin-bottom: 20px;
  }

  &__item {
    display: flex;
    margin-bottom: 15px;

    &.unsubscribe__item_checked {

      .unsubscribe__item-label {
        border: solid 1px #00be6e;
      }

      .unsubscribe__item-label::before  {
        background-color: #00be6e;
        transform: translateX(101%);
        margin-left: 0;
      }

      .unsubscribe__item-info {
        opacity: 1;
      }
    }
  }

  &__item-switch {
    margin-right: 10px;
  }

  &__item-label {
    display: block;
    width: 40px;
    height: 20px;
    border-radius: 10px;
    border: solid 1px #ea4642;

    &::before {
      display: block;
      content: '';
      width: 20px;
      height: 20px;
      border-radius: 20px;
      margin-left: -1px;
      background-color: #ea4642;
      transition: all 0.2s;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__item-input {
    position: absolute;
    opacity: 0;
  }


  &__item-info {
    opacity: 0.7;
    line-height: 1.76;
    color: #3f3f3f;
    transition: opacity 0.2s;
  }

  &__item-title {
    font-size: 16px;
    font-weight: bold;
  }

  &__item-desc {
    font-size: 12px;
  }

  &__btn {
    width: 100%;
    max-width: 290px;
    display: block;
    margin: auto;
    opacity: 0.7;
  }

  @include l {
    &__title {
      margin: 25px 0 15px;
    }
  }

  @include s {

    &__title {
      margin: 20px 0 15px;
    }

    &__block  {
      width: 300px;
    }
    &__separate {
      padding: 30px 40px 20px;
     }

    &__all {
      padding: 30px 40px;
    }
  }

  @include xs {

    &__block {
      width: 100%;
    }

    &__block:first-child {
      margin-bottom: 10px;
    }

    &__block-title {
      margin-bottom: 15px;

    }
    &__separate {
      flex-direction: column;
      align-items: center;
      padding: 30px 20px 20px;
    }

    &__all {
      padding: 30px 20px;
    }
  }
}