$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.header-l-top{
    position: relative;
    display:flex;
    justify-content: flex-end;
    margin-bottom: 60px;
    padding: 28px 18px 0;

    @include l() {
        padding-right: 8px;
        padding-left: 8px;
    }

    @include s(){
        padding-top: 24px;
        margin-bottom:253px;
        justify-content: center;
    }

    @include xs(){
        margin-bottom: 140px;
    }
}