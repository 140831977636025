$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.paymentcb {
  position: relative;
  // margin-top: -90px;
  min-height: 100vh;
  &__content {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    &.background-status-success {
      background-image: url($cdn + "/yoyo/paymсb_done.jpg");
    }
    &.background-status-fail {
      background-image: url($cdn + "/yoyo/paymсb_error.jpg");
    }
  }
  &__content-text-wrap {
    background: url(/img/blotter.svg);
    background-position: center top;
    background-size: 1119px 968px;
    background-repeat: no-repeat;
    padding-top: 101px;
    padding-bottom: 227px;
    margin-top: auto;
  }
  &__title {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.52;
    margin-bottom: 20px;
    color: #3e3e3e;
  }
  &__subtitle {
    font-size: 14px;
    line-height: 1.52;
    color: #3e3e3e;
    margin-bottom: 40px;
  }
  &__text-or {
    font-size: 14px;
    line-height: 1.52;
    color: #3e3e3e;
    margin-bottom: 20px;
  }
  &__btn {
    height: 40px;
    min-width: 179px;
  }
  .header-logo {
    display: block;
    svg {
      width: 188px;
      height: 76px;
    }
  }
  &__success {
    .header-logo {
      margin-bottom: 50px;
    }
  }
  &__fail {
    .header-logo {
      margin-bottom: 60px;
    }
  }
  @include m(){
    // margin-top: -70px;
    &__success {
      .header-logo {
        margin-bottom: 40px;
      }
    }
    &__title {
      line-height: 1;
    }
    &__subtitle {
      margin-bottom: 30px;
    }
    .paymentcb-timer {
      margin-bottom: 30px;
    }
  }
  @include s(){
    &__content-text-wrap {
      background-size: cover;
      margin: auto;
      width: 100%;
    }
  }
  @include xs(){
    &__content {
      &.background-status-success,
      &.background-status-fail {
        background: none;
      }
    }
    &__content-text-wrap {
      background: none;
      padding: 30px;
    }
    &__subtitle {
      margin-bottom: 30px;
    }
    &__header-wrap {
      .header-logo {
        margin-bottom: 30px;
      }
    }
    .paymentcb-timer {
      margin-bottom: 30px;
    }
  }
}
.paymentcb-timer {
  position: relative;
  margin-bottom: 40px;
  svg {
    width: 262px;
    height: 262px;
    transform: rotate(-90deg);
  }
  &__timer-grad-circle {
    animation-delay: 1s;
    animation-name: PaymentcbTimer;
    animation-duration: 20s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }
  &__seconds {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    color: #ffffff;
  }
  &__time-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  &__text {
    margin-bottom: 10px;
    color: rgba(63, 63, 63, .5);
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
  }
  &__seconds {
    height: 35px;
    display: inline-block;
    border-radius: 3px;
    background-color: rgba(63, 63, 63, .5);
    padding: 0px 12px;
    line-height: 35px;
  }
}
@keyframes PaymentcbTimer {
  0% {
    stroke-dashoffset: 0;
  }
  99% {
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 728;
    opacity: 0;
  }
}
