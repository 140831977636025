$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.secure-block {

  color: #3E3E3E;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  &__content {
    background-image: var(--icon-secure);
    background-repeat: no-repeat;
    background-position: left top 1px;
    background-size: 14px 16px;
    padding-left: 22px;
  }

   &__title {
     font-weight: 700;
     font-size: 15px;
     line-height: 18px;
     margin: 0;
     @include text-dots;
   }

    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      margin: 0;
      @include text-dots;
    }


}