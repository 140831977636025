$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
// THIS IS FILE WITH REUSABLE MIXINS (FROM BETINIA ALL LISENCES)

// --- TEXTS
@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin text($size: null, $lineHeight: null, $weight: null, $color: null) {
  @if $size != null {
    font-size: $size;
  }

  @if $lineHeight != null {
    line-height: $lineHeight;
  }

  @if $weight != null {
    font-weight: $weight;
  }

  @if $color != null {
    color: $color;
  }
}

@mixin text-lines-clip($lines: 2) {
  display: -webkit-box;
  overflow-wrap: break-word;
  line-clamp: $lines;
  -webkit-line-clamp: $lines;
  overflow: hidden;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  /*! autoprefixer: on */
}

// --- CONTROLS
@mixin reset-btn {
  appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
}

@mixin btn-modify($height: null, $paddingBlock: null, $fontSize: null, $borderRadius: null, $borderWidth: null) {
  @if $height != null {
    height: $height;
    line-height: $height;
  }

  @if $paddingBlock != null {
    padding-left: $paddingBlock;
    padding-right: $paddingBlock;
  }

  @if $fontSize != null {
    font-size: $fontSize;
  }

  @if $fontSize != null {
    border-radius: $borderRadius;
  }

  @if $borderWidth != null {
    border-width: $borderWidth;
    line-height: $height - $borderWidth * 2;
  }
}

// --- BACKGROUND
@mixin cover {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

@mixin contain {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}

@mixin color-gradient($value) {
  background: #{$value};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin pattern-noise {
  background-image: url($pattern-noise);
}
@mixin pattern-noise-h {
  background-image: url($pattern-noise-h);
}

// --- POSITIONING
@mixin absolute-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin absolute-center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

// --- SCROLLBAR
@mixin without-scrollbar {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0;
  }
}

// --- ANIMATIONS
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}