$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.app-page {
  position: relative;
  background-image:
          url($cdn + '/yoyo/fish_right.png'),
          url($cdn + '/yoyo/mex.png'),
          url($cdn + '/yoyo/horse_left.png'),
          url($cdn + '/yoyo/conffeti.png'),
          url($cdn + '/yoyo/conffeti.png');
  background-size:
          187px 199px,
          254px 248px,
          259px,
          503px 386px,
          503px 386px;
  background-position:
          left 28px top 113px,
          left 47px top 401px,
          right 32px top 139px,
          top 63px right -169px,
          top -46px left -220px;
  background-repeat: no-repeat;
  &::before {
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 529px;
    position: absolute;
    z-index: -1;

    background-image: url("/img/app-blob.svg"),
                      linear-gradient(180deg,#ffeaa1 0,#ffc600 100%);
    background-size:  auto 100%, cover;
    background-position: top center;
    background-repeat: no-repeat;

    mask-image: url("/img/app-mask.svg");
    mask-repeat: no-repeat;
    mask-size: cover;
    mask-position: bottom center;
  }
  &__banner {
    position: relative;
    text-align: center;
    padding-bottom: 704px;
    padding-top: 59px;
    background-image: url($cdn + "/yoyo/application/phones_1440.png"),
                      url($cdn + "/yoyo/conffeti.png");
    background-repeat: no-repeat;
    background-size: auto 704px, 508px auto;
    background-position: center bottom, center bottom 150px;
    width: 727px;
    margin: 0 auto;
  }
  &__content {
    position: relative;
    &::before {
      content: "";
      left: 0;
      top: 98px;
      width: 187px;
      height: 472px;
      background-image: url("/img/app-vector-green.svg"),
                        url($cdn + "/yoyo/conffeti.png");
      background-size: contain, 484px 370px;
      background-position: left -64px top, bottom right;
      background-repeat: no-repeat;
      position: absolute;
      display: block;
      z-index: -1;
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
      width: 300px;
      height: 300px;
      transform: translateY(-300px);
      background-image: url("/img/app-vector-yellow.svg");
      background-repeat: no-repeat;
      background-position: right -105px center;
    }
  }
  &__wrap {
    width: 696px;
    text-align: center;
    margin: 0 auto 47px;
  }
  &__title {
    font-weight: 900;
    font-size: 32px;
    line-height: 37px;
    color: #9746A5;
    margin-top: 0;
    &.is-adaptive {
      margin-bottom: 30px;
    }
  }
  &__text {
    font-size: 14px;
    color: #3F3F3F;
    margin-bottom: 25px;
    margin-top: 0;
    line-height: 24px;
  }
  &__qr-code {
    width: 87px;
    height: 87px;
    background-image: url($cdn + "/yoyo/qr.png");
    background-size: cover;
    margin: 0 auto;
  }
  &__promo {
    width: 983px;
    min-height: 484px;
    margin: 0 auto 60px;
    padding: 50px 480px 50px 50px;

    position: relative;
    background-image: url($cdn + "/yoyo/application/phones-1440.png"),
                      url($cdn + "/yoyo/conffeti.png"),
                      url($cdn + "/yoyo/candy_menu.svg"),
                      linear-gradient(149.69deg, #A745B0 9%, #700E66 92%);
    background-size: 516px auto,480px auto,cover,cover;
    background-position: right -10px top 25px, top 28px right, top, center;
    box-shadow: 0 3px 8px rgba(41, 11, 40, 0.39);
    border-radius: 14px;
    background-repeat: no-repeat;
    &::before {
      content: "";
      width: 219px;
      height: 193px;
      position: absolute;
      right: 27px;
      top: 0;
      transform: translateY(-110px);
      background-image: url($cdn + "/yoyo/bird_left.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
    }
  }
  &__promo-title {
    font-weight: 900;
    font-size: 32px;
    line-height: 1.2;
    color: #FFFFFF;
    margin-bottom: 37px;
    margin-top: 0;
  }
  &__promo-list {
    padding-left: 27px;
    list-style: none;
    position: relative;
  }
  &__promo-item {
    font-size: 14px;
    line-height: 25px;
    text-align: justify;
    color: #FFFFFF;
    margin-bottom: 10px;
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      left: 0;
      border-radius: 10px;
      transform: translateY(7px);
      background-color: #01C02A;
      display: inline-block;
      position: absolute;
    }
  }
  &__footer {
    text-align: center;
    width: 983px;
    margin: 0 auto 49px;
    .app-page__text {margin-bottom: 0;}
  }
  @include xl {
    background-size:
            187px 199px,
            254px 248px,
            259px,
            574px 438px,
            574px 438px;
    background-position:
            left 75px top 85px,
            left 153px top 314px,
            right 131px top 43px,
            top right,
            top left -109px;

    &::before {
      height: 656px;
    }
    &__wrap {
      width: 1129px;
      margin: 0 auto 98px;
    }
    &__content {
      &::before {
        top: 150px;
        width: 395px;
        height: 472px;
        background-size: 249px 313px, 617px 472px;
      }
    }
    &__content::after { background-position: right top }
    &__title.is-adaptive { margin-bottom: 40px; }
    &__banner {
      padding-top: 49px;
    }
    &__footer { margin: 0 auto 65px; }
  }
  @include xxxl {
    background-size:
            219px 233px,
            268px 262px,
            333px,
            650px 509px,
            650px 509px;
    background-position:
            left 154px top 102px,
            left 290px top 403px,
            right 157px top 90px,
            top 57px right -32px,
            top left -35px;
    &__content::after {
      transform: translateY(-180px);
    }
    &__banner {
      background-size: auto 704px, 0;
      padding-top: 98px;
    }
    &__wrap {
      margin: 0 auto 64px;
    }
    &__footer { margin: 0 auto 26px; }
  }
}
