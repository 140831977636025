$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.christmas-day {

  padding-top: 14px;
  width: 80px;
  height: 75px;
  text-align: center;
  font-weight: 900;
  color: #9846a5;
  background: url('/img/christmas2019/christmas-day_open.svg') center no-repeat;
  background-size: cover;
  position: absolute;

  &_desctop:hover {
    .christmas-day__tooltip {
      display: block;
    }
  }

  // TOMORROW AND FUTURE DAYS
  &_status-2, &_status-3 {
    background: url('/img/christmas2019/christmas-day_closed.svg') center no-repeat;
    color: #828282;
  }

  //SPECIAL DAYS (by indexes)
  &_11, &_12, &_17, &_24, &_31 {
    background: url('/img/christmas2019/christmas-day_special_open.svg') center no-repeat;
    background-size: cover;
    color: #d78e00;
  }

  // SPECIAL DAYS FUTURE
  &_11.christmas-day_status-3,
  &_12.christmas-day_status-3,
  &_17.christmas-day_status-3,
  &_24.christmas-day_status-3,
  &_31.christmas-day_status-3 {
    background: url('/img/christmas2019/christmas-day_special_closed.svg') center no-repeat;
    background-size: cover;
    color: #d78e00;
  }

  //POSITIONING (left, bottom)

  &_1 {
    @include position (240px, 23px)
  }

  &_2 {
    @include position (350px, 23px)
  }

  &_3 {
    @include position (460px, 22px)
  }
  &_4 {
    @include position (555px, 72px)
  }
  &_5 {
    @include position (558px, 161px)
  }
  &_6 {
    @include position (469px, 210px)
  }
  &_7 {
    @include position (357px, 210px)
  }
  &_8 {
    @include position (237px, 210px)
  }
  &_9{
    @include position (121px, 210px)
  }
  &_10 {
    @include position (20px, 248px)
  }
  &_11 {
    @include position (10px, 344px);
  }
  &_12 {
   @include position (105px, 393px);
 }
  &_13 {
    @include position (226px, 394px)
  }
  &_14 {
    @include position (339px, 394px)
  }
  &_15 {
    @include position (458px, 394px)
  }
  &_16 {
    @include position (552px, 438px)
  }
  &_17 {
    @include position (561px, 524px);
  }
  &_18 {
    @include position (467px, 579px)
  }
  &_19 {
    @include position (353px, 579px)
  }
  &_20 {
    @include position (238px, 579px)
  }
  &_21 {
    @include position (121px, 579px)
  }
  &_22 {
    @include position (21px, 619px)
  }
  &_23 {
    @include position (6px, 713px)
  }
  &_24 {
    @include position (95px, 765px);
  }
  &_25 {
    @include position (217px, 765px)
  }
  &_26 {
    @include position (335px, 765px)
  }
  &_27 {
    @include position (455px, 765px)
  }
  &_28 {
    @include position (550px, 812px)
  }
  &_29 {
    @include position (561px, 899px)
  }
  &_30 {
    @include position (461px, 951px)
  }
  &_31 {
    @include position (342px, 952px);
  }
  &_32 {
    @include position (239px, 991px)
  }


  // TOOLTIPS FOR PAST, TODAY, TOMORROW
  &_status-0, &_status-1, &_status-2 {

    .christmas-day__tooltip-content_main {
      display: block;
    }

    .christmas-day__tooltip-content_future {
      display: none;
    }
  }

  // TOOLTIPS FOR FUTURE

  &_status-3 {
    .christmas-day__tooltip-content_main {
      display: none;
    }

    .christmas-day__tooltip-content_future {
      display: flex;
    }
  }

  &__date {
    font-size: 20px;
  }

  &__month {
    font-size: 12px;
    margin-top: -3px;
  }

    &__tooltip {
      display: none;
      position: absolute;
      top:0;
      transform: translate(-29%, calc(-100% - 12px));
      z-index: 10;
      font-size: 14px;
      color: #ffffff;

  }

    &__tooltip-content {
      width: 100%;
      height: 100%;
      position: relative;
      min-height: 80px;
      border-radius: 12px;

      &::after {
        display: block;
        content: '';
        width: 18px;
        height: 18px;
        transform: rotate(45deg) translateX(-33%) translateY(6px);
        position: absolute;
        bottom: -9px;
        left: 50%;
      }

      &_main {
        width: 182px;
        padding: 19px 20px;
        background-color: #ab69ad;

        &::after {
          background-color: #ab69ad;
        }

        .christmas-day__tooltip-text {
          font-weight: bold;
          overflow: hidden;

        }
      }

      &_future {
        width: 190px;
        padding: 19px 20px;
        background-color: #828282;
        text-align: left;

        &::after {
          background-color: #828282;
        }

        .christmas-day__tooltip-text {
          font-weight: normal;
          overflow: hidden;
        }
      }
    }

  &__tooltip-close {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;

    & svg {
      width: 8px;
      height: 8px;
      fill: #fff;
      padding: 8px;
    }
  }

    &__tooltip-bonus-name {
      font-weight: normal;
      margin-top: 10px;
    }

    &__tooltip-lock {
      width: 40px;
      height: 40px;
      margin-right: 18px;

      & svg {
        width: 40px;
        height: 40px;
        fill: #ffffff;
      }
    }

    &__tooltip-terms {
      margin-top: 6px;
    }

  &__tooltip-desc {
    max-width: 97px;
  }

  @include m {
    &_4, &_5, &_16, &_17, &_28, &_29 {
      .christmas-day__tooltip {
        transform: translate(-58%, calc(-100% - 12px));
      }
      .christmas-day__tooltip-content::after {
        right: 20px;
        left: auto;
      }
    }
    &_10, &_11, &_22, &_23 {
      .christmas-day__tooltip {
        transform: translate(0%, calc(-100% - 12px));
      }
      .christmas-day__tooltip-content::after {
        left: 40px;
      }
    }
  }
  @include xs {
    padding: 7px;
    width: 40px;
    height: 38px;
    background-size: cover;
    &_1 {
      @include position (103px, 23px)
    }

    &_2 {
      @include position (154px, 23px)
    }

    &_3 {
      @include position (203px, 23px)
    }
    &_4 {
      @include position (247px, 46px)
    }
    &_5 {
      @include position (247px, 85px)
    }
    &_6 {
      @include position (206px, 106px)
    }
    &_7 {
      @include position (153px, 106px)
    }
    &_8 {
      @include position (100px, 106px)
    }
    &_9{
      @include position (48px, 106px)
    }
    &_10 {
      @include position (4px, 124px)
    }
    &_11 {
      @include position (0px, 166px);
    }
    &_12 {
      @include position (43px, 190px)
    }
    &_13 {
      @include position (96px, 190px)
    }
    &_14 {
      @include position (149px, 190px)
    }
    &_15 {
      @include position (202px, 190px)
    }
    &_16 {
      @include position (245px, 209px)
    }
    &_17 {
      @include position (248px, 249px)
    }
    &_18 {
      @include position (205px, 274px)
    }
    &_19 {
      @include position (153px, 274px)
    }
    &_20 {
      @include position (100px, 274px)
    }
    &_21 {
      @include position (48px, 274px)
    }
    &_22 {
      @include position (4px, 294px)
    }
    &_23 {
      @include position (0px, 334px)
    }
    &_24 {
      @include position (40px, 359px);
      }
    &_25 {
      @include position (95px, 359px)
    }
    &_26 {
      @include position (149px, 358px)
    }
    &_27 {
      @include position (203px, 360px)
    }
    &_28 {
      @include position (244px, 380px)
    }
    &_29 {
      @include position (247px, 420px)
    }
    &_30 {
      @include position (202px, 441px)
    }
    &_31 {
      @include position (148px, 442px);
      }
    &_32 {
      @include position (103px, 459px)
    }


    &__wrapper.active-touch-christmas-day + .christmas-day__tooltip  {
        display: block;
    }
    &__tooltip {
      transform: translate(-44%, calc(-100% - 20px));
    }
    &__tooltip-content_main {
      min-height: auto;
    }
    &__date {
      font-size: 11px;
    }

    &__month {
      font-size: 7px;
      margin-top: -2px;
    }
    &_3, &_4, &_5, &_6, &_15, &_16, &_17, &_17, &_27, &_28, &_29, &_30 {
      .christmas-day__tooltip {
        transform: translate(-75%, calc(-100% - 20px));
      }
      .christmas-day__tooltip-content::after {
        right: 14px;
        left: auto;
      }
    }
    &_9, &_10, &_11, &_21, &_22, &_23, &_24 {
      .christmas-day__tooltip {
        transform: translate(-4%, calc(-100% - 20px));
      }
      .christmas-day__tooltip-content::after {
        left: 22px;
      }
    }
  }
}
