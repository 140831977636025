$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.game-page-demo {
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 540px;
  border-radius: 10px;
  background: #fbce7e;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;

  &__content {
    padding-right: 12px;
  }

  &__text {
    margin: 0;
    display: inline-block;
    @include text(14px, 16px, 400, #3e3e3e);

    &:not(:last-child) {
      margin-right: 4px;
    }
  }

  &__link {
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }
}
