$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
#morph2 {
  visibility: hidden;
}
.animation-live {
  right: 0;
  animation: 1.5s bankidButtonAnimation;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.animation-width {
  width: 214px;
  transition: 0.3s;
  padding-left: 10px;
}

@keyframes bankidButtonAnimation {
  from {
    right: 45%;
  }
  50% {
    right: 53%;
  }
  75% {
    right: 6%;
  }
  80% {
    right: 12%;
  }
  to {
    right: 50%;
    transform: translateX(50%);
  }
}

.bankid-morph {
  display: block;
  height: 314px;
  position: absolute;
  width: 429px;
  left: 50%;
  transform: translateX(-50%) translateY(-10px);
  margin-left: 20px;
  z-index: 1;
}
