$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-bonus-list {

    display: flex;
    flex-wrap:wrap;
    justify-content: center;
    margin: 0 -10px;

  &__item {
    margin: 20px 10px 0;
    max-width: 315px;
    min-width: 315px;
    flex-grow: 1;
  }

@include l {

  &__item {
    min-width: auto;
  }
}
@include s {

    margin: 0 - 7px;

  &__item {
    max-width: calc(100%/3 - 14px);
    flex-grow: 1;
    margin: 15px 7px 0;

  }

}

  @include xs {

      margin: 0 3px 0;

    &__item {
      max-width: calc(50% - 6px);
      margin: 15px 3px 0;
      flex-grow: 1;
    }
  }

  @include xxs {


    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    &__item {
      max-width: 100%;
      min-width: 100%;
    }

    &__item + &__item {
      margin-top: 16px;
    }
  }
}