$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.not-found {
  text-align: center;
  background: url($cdn + "/yoyo/404_bg.jpg") no-repeat;

  background-size: cover;
  background-position: center;
  position: relative;

  &__wrap {
    position: relative;
    padding: 165px 0px 270px;
    background: url(/img/blotter.svg) no-repeat;
    background-size: 890px 842px;
    background-position: center top -90px;
    }
  &__title {
    font-size: 150px;
    font-weight: 700;
    line-height: 1;
    color: rgb(234, 70, 66);
    margin-bottom: 5px;
  }
  &__forbidden {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.52;
    color: rgb(62, 62, 62);
    margin-bottom: 15px;
  }
  &__text {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.52;
    color: rgb(62, 62, 62);
    margin-bottom: 30px;
  }
  @include s {
    &__wrap {
      padding: 175px 0px 188px;
      background-size: 696px 661px;
      background-position: center top 5px;
    }
    &__text {
      margin-bottom: 20px;
    }
  }
  @include xs {
    background: none;
    &__wrap {
      padding: 104px 0px 109px;
      background: none;
    }
    &:before {
      background: none;
    }
    &__forbidden {
      margin-bottom: 20px;
    }
    &__text {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 30px;
    }
  }
}







