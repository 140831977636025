$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.restoration {
  padding-bottom: 57px;
  padding-top: 20px;

  &__text {
    font-size: 14px;
    line-height: 20px;
    color: #3E3E3E;
    margin-bottom: 20px;
    margin-top: 0;
  }
  &__btns {
    margin-top: 20px;
  }
  &__btn {
    width: 100%;
  }
  &__bottom {
    background: #FFC051;
    border-radius: 0 0 10px 10px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  &__link {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    color: #66554F;
    text-decoration: underline;
    background-color: transparent;
    border: none;
    &:hover {
      color: rgb(171, 105, 173);
    }
  }
}