$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.header {
  display: block;
  background-color: $cream;
  box-shadow: 0 10px 10px rgba(33, 1, 74, 0.15);
  position: sticky;
  width: 100%;
  z-index:700;
  top: 0;
  left: 0;

  &__inner {
    height: 90px;
    display: flex;
    align-items:center;
    justify-content:space-between;
    position:relative;
  }

  &__navbox {
    width: calc(50% - 65px);
    display:flex;
    align-items:center;
  }

  &__togglenav {
    flex-shrink:0;
    margin-right: 15px;
    position: relative;

    & svg {
      width: 23px;
      height:17px;
    }
  }

  &__mainnav {
    display: flex;
    align-items:center;
    overflow:hidden;

    & .main-nav__item:first-child {
      margin-right: 15px;
    }
    // & .main-nav__item:last-child {
    //   margin-left: 15px;
    // }
    & .main-nav__item.christmas-btn {
        width: 40px;
        border: solid 1px #2d9cdb;
        text-align: center;
        border-radius: 100%;
        padding: 6px 0 0;
      & svg {
        width: 22px;
        height: 24px;
        fill: #2d9cdb;
      }
      &:hover {
        background-color: #2d9cdb;
        & svg {
          fill: #fff;
        }
      }
    }
  }


  &__logo {
    margin: 0 auto;
    width: 112px;
    height: 46px;
    flex-shrink:0;

    & svg {
      width:100%;
      height:100%;
    }
  }

  &__balance {
    display: none;
  }

  &__userbox {
    width: calc(50% - 160px);
    display: flex;
    justify-content:flex-end;
    align-items: center;
    overflow: hidden;
  }

  &__user {
    display: none;
    align-items: center;
    overflow:hidden;
  }

  &__login {
    display: flex;
    align-items:center;
    justify-content: flex-end;
    overflow:hidden;
  }

  &__cashier-btn {
    margin-right: 15px;
  }

  &__profile-btn {
    margin-right: 15px;
    flex-shrink:0;
    position:relative;
  }

  &__login {
    height: 40px;
  }

  &__login-btn {
    min-width: 110px;
    margin-right: 15px;
  }

  &__registration-btn {
    min-width: 180px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__registration-btn svg, &__login-btn svg, &__ec-login svg {
    width: 100%;
    height: 100%;
    display: none;
  }

  &__buttons {
    width: 95px;
    display: flex;
    align-items:center;
    flex-shrink:0;
  }

   &__search {
     margin-right: 15px;

     & svg {
       width: 18px;
       height:18px;
     }
   }

  &__chat svg {
    width:13px;
    height:18px;
  }



  @include m {

    &__inner {
      height: 70px;
    }

    &__logo {
      width: 100px;
      height: 40px;
    }

    &__profile-btn {
      display:none;
    }
  }

  @include s {
    .main-nav__item:last-child {
      display: none;
    }

    &__registration-btn, &__login-btn {
      min-width:40px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      padding: 8px;
      box-sizing: border-box;
    }

    &__registration-btn-img {
      display: none;
    }

    &__registration-btn span, &__login-btn span, &__cashier-btn {
      display: none;
    }

    &__registration-btn svg, &__login-btn svg {
        display: block;
      }
    }

   @include xs {
    & .main-nav, &__registration-btn {
      display: none;
    }

     &__navbox {
       width: calc(50% - 90px);
     }
     &__userbox {
       width: calc(50% - 185px);
     }
  }

  @include xxs {
    &__userbox {
      width: calc(50% - 130px);
    }
    &__login-btn, &__chat, &__ec-login {
      display: none;
    }

    &__search, &__togglenav {
      margin-right: 0;
    }
    &__buttons {
      width:40px;
    }
  }
}



.linda-has-user {

  .header__balance {
    display: block;
  }
  .header__user {
    display: flex;
  }

  .header__login {
    display: none;
  }

  @include xs {
    .header__balance {
      display: none;
    }
  }
}



//--------------------------------//
//---------- ENTERCASH -----------//
//--------------------------------//
.header {
  &__ec-login {
    min-width: 180px;
    margin-right: 16px;
    @include m {
      min-width: 40px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      padding: 10px;
      box-sizing: border-box;
      span {
        display: none;
      }
      svg {
        display: block;
      }
    }
  }
  &__reg-bank {
    @include s {
      padding-left: 4px;
      padding-right: 7px;
    }
  }


}
