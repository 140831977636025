$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;


///----------------------------------/
// Flex fallback > 1024 //
///----------------------------------/

//@supports not (display: grid) {
//  .home-dashboard {
//    display: flex;
//    flex-wrap: wrap;
//    align-items: flex-start;
//    justify-content: space-between;
//    &__tournaments {
//      width: 50%;
//      flex-grow: 1;
//      margin-right: 16px;
//    }
//    &__events {
//      width: calc(100% / 6 - 16px);
//    }
//    @include l {
//      &__events {
//        width: calc(20% - 12px);
//      }
//    }
//  }
//}




///----------------------------------/
// Grid layout > 1024 //
///----------------------------------/

  .home-dashboard {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 16px;

    .dashboard-game-item {
      margin: 0;
      width: 100%;

      &.is-big-first {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
      }
      &.is-big-second {
        grid-column-start: 4;
        grid-column-end: 6;
        grid-row-start: 3;
        grid-row-end: 5;
      }
    }
    .dashboard-promo-game {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
      margin: 0;
      width: 100%;
      & > div { height: 100%; }
      &__pic {
        background-position: center top;
      }
    }
    &__tournaments {
      grid-column-start: 4;
      grid-column-end: 6;
      grid-row-start: 3;
      grid-row-end: 5;
      overflow: hidden;
    }
    &__events {
      position: relative;
      grid-column-start: 6;
      grid-column-end: 7;
      grid-row-start: 1;
      grid-row-end: 5;
    }

    @include l {
      grid-gap: 12px;
    }

    @include m {
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 11px;
      .dashboard-game-item{
        &:nth-of-type(n+19) {
          display: none;
        }
        &.is-big-first {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 3;
        }
        &.is-big-second {
          grid-column-start: 3;
          grid-column-end: 5;
        }

      }
      &__tournaments {
        grid-column-start: 3;
        grid-column-end: 5;
      }
      &__events {
        grid-column-start: 5;
        grid-column-end: 6;
      }
    }
  }


///----------------------------------/
// Common styles for viewport < 1024 //
///----------------------------------/

.home-dashboard {
  @include s {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;

    & .dashboard-game-item {
      &:nth-of-type(n+19) {
        display: block;
      }
      &.is-big-first {
        grid-column-start: 1;
        grid-column-end: -1;
        grid-row-start: 1;
        grid-row-end: 2;
      }
      &.is-big-second {
        grid-column-start: 1;
        grid-column-end: -1;
        grid-row-start: 7;
        grid-row-end: 8;
      }

      &.is-big {
        .game-item__pic::before {
          padding-top: 34.3%;
        }
      }
    }

    &__events {
      grid-column-start: 1;
      grid-column-end: -1;
      grid-row-start: 4;
      grid-row-end: 5;
    }
  }

  @include xs {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;

    & .dashboard-game-item {
      &:nth-of-type(n+13) {
        display: none;
      }
      &.is-big-second {
        grid-row-start: 6;
        grid-row-end: 7;
      }

      &.is-big {
        .game-item__pic::before {
          padding-top: 50%;
        }
      }
    }

    &__events {
      grid-row-start: 5;
      grid-row-end: 6;
    }

  }

  @include xs {
    & .dashboard-game-item {

      &.is-big {
        .game-item__pic::before {
          padding-top: 55%;
        }
      }
    }
  }
}






