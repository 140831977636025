$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.se-widget-balance {
  &__wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__btn {
    position: relative;
    right: auto;
    margin-left: 5px;
    top: 0;
  }
}