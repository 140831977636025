$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
@use "sass:math";

.game-item {

  position: relative;
  width: calc(100% / 6 - 16px);
  margin: 8px 8px;
  overflow: hidden;

  @include l {
    width: calc(20% - 12px);
    margin: 6px 6px;
  }
  @include s {
    width: calc(33% - 16px);
    margin: 8px 8px;
  }
  @include xs {
    width: calc(50% - 10px);
    margin: 5px 5px;
  }

  &__pic {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
    overflow: hidden;
    
    .is-big & {
      height: 100%;
    }

    &.has-jackpot {
     border: 2px solid #700E66;
    }
  }

  &__pic::before {
    content: "";
    display: block;
    padding-top: 66.6%;
  }

  &__label {
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    height: 20px;
    line-height: 20px;
    width: 100px;
    background-color: #35d290;
    position: absolute;
    top: 13px;
    left: -26px;
    transform: rotate(-45deg);
    z-index: 101;
    padding: 0 25px;
    @include text-dots;
    text-transform: capitalize;

    &.is-exclusive {
      background: #EA4642;
      font-size: 11px;
    }

    &.is-live {
      display: none !important;
    }

    &.is-dropAndWins {
      display: block;
      width: 36%;
      height: 32%;
      background-image: url($cdn + '/betinia/ribbons/drops-wins.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-color: transparent;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      transform: rotate(0);
      font-size: 0;
      border-top-left-radius: 10px;

      .is-big & {
        width: 40%;
        height: 29%;
      }
    }
  }

  &__hoverplay, &__touchplay {
    position: absolute;
    top: -2px;
    left: -2px;
    right:  -2px;
    bottom:  -2px;
    width: calc(100% + 4px);
    background-color: rgba(249, 251, 231, 0.8);
    padding: 0 24px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 100;
    overflow: hidden;
  }

  &__hoverplay {
    opacity: 0;
    height: calc(100% + 4px);
    transition: .2s;
  }
  &__touchplay {
    height: 0;
    .active-touch-game-item & {
      height: 100%;
    }
  }

  &__play {
    max-width: 100%;
  }

  &__demo {
    // display: none;
    margin-top: 5px;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.76;
    font-weight: 700;
    @include text-dots;
    max-width: 100%;

    // .linda-has-demo & {
    //   display: block;
    // }
  }

  &:hover {
    & .game-item__hoverplay {
      opacity: 1;
    }
  }
}


//- TOURNAMENT PAGE

.game-box-tournament {

  & .game-item {
    width: calc(25% - 10px);
    margin: 5px 5px;

    @include l {
      width: calc(33% - 16px);
      margin: 8px 8px;
    }
    @include xs {
      width: calc(50% - 10px);
      margin: 5px 5px;
    }
  }
}

//- GAME-DEMO-FOOTER

.game-demo-footer {

  @include l {
    & .game-item:nth-child(6) {
      display: none;
    }
  }
}

//- WIDGET GAME ITEM (HOMEPAGE DASHBOARD)

.game-item.widget-game-item {
  margin: 0;
  display: block;
  height: 100%;
  width: 100%;
  & > div {
    height: 100%;
  }
  .game-item__label,  .game-item__demo {
    display: block;
  }
}



/////// NEW STYLE FOR GAME ITEM //////

.game-item-new {
  $this: &;

  display: block;
  box-sizing: border-box;
  position: relative;

  &__in {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 0;
    padding-top: percentage(math.div(144, 96));
    cursor: pointer;
    overflow: hidden;
    transition: 0.2s transition;
    border-radius: 10px;

    #{$this}.is-stub & {
      cursor: default;
      background-image: url($cdn + '/yoyo/game-card-stub.png');
      background-color: #FFF5DA;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      border-radius: 10px;
    }

    &.has-hover {
      &:hover {
        box-shadow: 0 4px 10px 0 rgba(118, 103, 97, 0.20);
      }
    }

    &.is-active {
      #{$this}__overlay {
        opacity: 1;
        pointer-events: auto;
      }

      #{$this}__img picture {
        transform: scale(1.08);
      }

      #{$this}__badge,
      #{$this}__number {
        opacity: 0;
      }
    }
  }

  &__name {
    max-width: 100%;
    margin: 4px 0 0;
    @include text(12px, 140%, 500, $dark);
    @include ellipsis;
  }

  &__img picture {
    transition: transform .3s ease-out;
  }

  &__img picture {
    transition: transform .3s ease-out;
  }

  &__img,
  &__img picture,
  &__img img {
    @include absolute-cover;
    object-fit: cover;
    z-index: 2;
    border-radius: 10px;
    overflow: hidden;
    background-image: url($cdn + '/yoyo/game-card-stub.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
  }

  &__click-zone {
    @include absolute-cover;
    z-index: 10;
  }

  &__overlay {
    @include absolute-cover;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFF5DA;
    background-image: url($cdn + '/yoyo/game-card-add-fav.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    overflow: hidden;
  }

  &__overlay-content {
    box-sizing: border-box;
    padding: 0 8px;
    @include absolute-cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include text(12px, 140%, 700, rgba(65, 48, 52, 0.80));
    white-space: initial;
  }

  &__overlay-text {
    text-align: center;
    @include text-lines-clip(3);
  }

  &__overlay-icon {
    margin-top: 10px;
    margin-bottom: -10px;
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;

    &.is-fav {
      background-image: var(--icon-icon-add-fav);

      .game-item-new:hover & {
        filter: invert(33%) sepia(24%) saturate(1811%) hue-rotate(248deg) brightness(99%) contrast(89%);
      }

    }
    &.is-recently {
      background-image: var(--icon-btn-play);

      .game-item-new:hover & {
        background-image: var(--icon-btn-play-hover);
      }
    }

  }

  &__hoverplay {
    position: absolute;
    top: -2px;
    left: -2px;
    right:  -2px;
    bottom:  -2px;
    width: calc(100% + 4px);
    background-color: rgba(249, 251, 231, 0.8);
    padding: 0 10px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 102;
    overflow: hidden;

    opacity: 0;
    height: calc(100% + 4px);
    transition: .2s;
  }

  &__play {
    max-width: 100%;
    font-size: 14px;
    height: 36px;
    padding: 0 20px;
    line-height: 36px;
  }

  &__demo {
    margin-top: 8px;
    cursor: pointer;
    @include text(16px, 120%, 700);
    @include text-dots;
    max-width: 100%;
  }

  &:hover {
    & .game-item-new__hoverplay {
      opacity: 1;
    }
  }


  &__label {
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    height: 20px;
    line-height: 20px;
    width: 100px;
    background-color: #35d290;
    position: absolute;
    top: 13px;
    left: -26px;
    transform: rotate(-45deg);
    z-index: 101;
    padding: 0 25px;
    @include text-dots;

    &.is-exclusive {
      background: #EA4642;
      font-size: 11px;
    }

    &.is-live {
      display: none !important;
    }

    &.is-dropAndWins {
      display: block;
      width: 36%;
      height: 32%;
      background-image: url($cdn + '/betinia/ribbons/drops-wins.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-color: transparent;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      transform: rotate(0);
      font-size: 0;
      border-top-left-radius: 10px;

      .is-big & {
        width: 40%;
        height: 29%;
      }
    }
  }

  &__number {
    pointer-events: none;
    width: percentage(math.div(88, 96));
    height: percentage(math.div(72, 144));
    position: absolute;
    z-index: 15;
    bottom: -8px;
    right: -12px;
    background-size: 100% 100%;
    transition: opacity 0.2s ease-in-out;
  }

  @include bp-1440 {

    &.is-stub &__in {
      border-radius: 14px;
    }

    &__hoverplay {
      padding: 0 24px;
    }

    &__demo {
      font-size: 18px;
      margin-top: 12px;
    }
    &__play {
      height: 40px;
      padding: 0 24px;
      line-height: 40px;
      font-size: 16px;
    }

    &__overlay-content {
      font-size: 16px;
    }
    &__overlay-icon {
      width: 32px;
      height: 32px;
    }

    &__name {
      font-size: 16px;
      margin-top: 10px;
    }


  }
}



