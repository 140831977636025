$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.games-dashboard {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 12px;

  &__item {
    margin: 0 !important;
    width: 100% !important;

    &.is-big-first {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
    }

    &.is-big-second {
      grid-column-start: 3;
      grid-column-end: 5;
      grid-row-start: 3;
      grid-row-end: 5;
    }

    &:nth-of-type(n + 19) {
      display: none;
    }
  }

  &__events {
    position: relative;
    grid-column-start: 5;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 5;
  }

  &__btn-wrap {
    text-align: center;
    padding-top: 10px;
    grid-column-start: 1;
    grid-column-end: 6;
  }

  &__btn {
    min-width: 200px;
  }

  @include bp-1440 {
    grid-template-columns: repeat(6, 1fr);

    &__item {
      &:nth-of-type(n + 19) {
        display: block;
      }
    }

    &__events {
      grid-column-start: 6;
      grid-column-end: 6;
    }

    &__btn-wrap {
      grid-column-end: 7;
    }
  }
}
