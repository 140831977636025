$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.providers {
  margin-top: 24px;
  margin-bottom: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 40px;

  & .selected-filter {
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(65, 48, 52, 0.8);
    padding-left:10px;
    margin: 0;
  }

  &__wrap {
    width: calc(100%/6 - 15px);
    margin-right: 8px;
    border-radius: 25px;
    background-color: #dcdbd3;
    transition: all 200ms;
    &:hover {
      background-color: rgba(220,219,211, 0.5);
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.08);
    }
    &.active {
      background-color: #f9fbe7;
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.08);
    }
    &.providers-open {
      border-radius: 10px;
      background-color: rgb(249, 251, 231);
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.08);
      position: absolute;
      top: 0;
      right: 0;
      z-index: 150;

      &:hover {
        box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.08);
      }
    }
  }
  &__select {
    position: relative;
    line-height: 41px;
    height: 41px;
    cursor: pointer;
  }
  &__select-text {
    padding: 0 40px 0 30px;
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: normal;
    color: rgba(65, 48, 52, 0.8);
    -webkit-text-stroke: 0 rgba(65, 48, 52, 0.8);
  }
  &__select-arr {
    position: absolute;
    right: 20px;
    top: 15px;
    width: 11px;
    height: 7px;
    transition: 0.2s;
    line-height: 7px;
    .providers-open & {
      transform: rotate(180deg);
    }
    & svg {
      width: 11px;
      height: 7px;
      fill: rgba(65, 48, 52, 0.8);
    }
  }

  &__list {
    max-height: 430px;
    overflow-y:auto;
  }

  &__over {
    display: none;
    .providers-open & {
      display: block;
    }
  }
  &__link {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    padding: 3px 10px 3px 15px;
    height: 36px;
    transition: all 0.2ms;
    color: rgba(65, 48, 52, .8);
    &.active {
      background-color: #dcdbd3;
      color: rgba(65, 48, 52, 0.8);
    }
    &:hover {
      color: rgba(65, 48, 52, 0.8);
      background-color: rgba(220,219,211, 0.5);
    }
  }
  &__img {
    width: 30px;
    height: 30px;
    margin: auto 0px;
    & img {
      height: 100%;
    }
  }
  &__name {
    margin: auto 0px;
  }

  @include l {

    //margin-top: -35px;
    margin-bottom: 4px;

    &__wrap {
      width: calc(20% - 11px);
      margin-right: 6px;
    }
  }

  @include s {
    //margin-top: 8px;
    margin-bottom: 0;
    &__wrap {
      width: calc(100%/3 - 16px);
      margin-right:14px;
    }
  }
  @include xs {

    flex-direction:column;
    align-items:flex-start;
    margin-bottom: 10px;
    margin-top: 20px;
    height: 75px;

    &__wrap {
      width: calc(100% - 9px);
      margin-left: 5px;

      &.providers-open {
        top:35px;
        right:-10px;
      }
    }
  }
}


