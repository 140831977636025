$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
@use "sass:math";

@import 'styles/no-result-block';

.pregame-page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 997;

  .rg-bar {
    flex-shrink: 0;
    position: relative;
    z-index: 2;
  }

  .game-page-header {
    flex-shrink: 0;
  }

  &__seo {
    position: relative;
    z-index: 1;
  }

  &__in {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 40px 0 0;

    &::before {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%);
    }
  }

  &__content {
    flex-grow: 1;
    position: relative;
    z-index: 1;
    padding: 0 32px;
    width: 100%;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
  }

  &__name {
    margin: 0 0 4px;
    @include text(20px, 120%, 700, $white);
    text-transform: capitalize;
  }

  &__provider {
    margin: 0 0 20px;
    @include text(16px, 160%, 400, #DCDBCA);
    text-transform: capitalize;
  }

  &__img {
    display: block;
    width: 100%;
    margin-bottom: 20px;

    img {
      border-radius: 10px;
    }
  }

  &__btn {
    width: 100%;
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
  }
}
