$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.session-timer {
  display: flex;
  align-items: center;
  padding: 0 4px;

  &__icon {
    width: 12px;
    height: 12px;
    margin-right: 4px;
    fill: currentColor;
  }

  &__text {
    @include text(10px, 110%, 500, #66554F);
    @include ellipsis;
  }
}
