$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.entrance-jackpot {
  background: linear-gradient(180deg, #F5D86F 0%, #FFD02B 100%);
  padding: 44px 20px 24px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  // border-radius: 10px;
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;

  &:before {
    position: absolute;
    content: '';
    background-image: url($cdn + '/sweden-yoyo/jp_bg_main.svg');
    width: 1930px;
    height: 1930px;
    top: -200%;
    transform: translate(100%, 100%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;

    animation-name: rotate;
    transform-origin: center;
    animation-duration: 60s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @include m {
    &:before {
      left: -37vh;
    }
  }

  @include l {
    &:before {
      left: -30vh;
    }
  }

  @include xl {
    &:before {
      left: -22vh;
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }

  &__amount {
    font-weight: 900;
    font-size: 56px;
    line-height: 56px;
    text-align: center;
    color: #663170;
    padding-bottom: 20px;
    display: block;
  }

  &__link {
    width: fit-content;
    display: flex;
    margin: 20px auto 0;
    position: relative;
    z-index: 1;
  }

  .has-jackpot {
    .game-item-jackpot__text {
      display: none;
    }
  }

  .anim-wrap {
    position: relative;
    overflow: hidden;
  }
}


#anim_elem_l {
  position: absolute;
  top: -5px;
  right: 59%;
  width: 1240px;
  @include m {
    right: 62%;
  }
}

#anim_elem_r {
  transform: rotate(180deg);
  position: absolute;
  top: -10px;
  left: 59%;
  width: 1240px;
  @include m {
    left: 62%;
  }
}


#anim_elem_btn_l {
  position: absolute;
  top: 17px;
  right: 52%;
  width: 1240px;
}

#anim_elem_btn_r {
  transform: rotate(180deg);
  position: absolute;
  top: 17px;
  left: 52%;
  width: 1240px;
}

.entrance-jackpot__title {
  font-weight: 900;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #66396F;

  background-image: url($cdn + '/sweden-yoyo/jp_bg.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  width: fit-content;
  padding: 12px 24px;
  margin: 0 auto 0;

  position: relative;
  bottom: -24px;
  z-index: 1;
}

.slider-games {
  display: block;
  padding-top: 29px;
  margin-bottom: 80px;
  position: relative;

  &__wrap {
    position: relative;

    .slick-list {
      margin: 0 -7px;
    }

    .slick-slide {
      padding: 0 7px;
    }

    .game-item {
      margin: 0;
    }

  }

  &__image {
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    width: 100%;
    height: auto;
    min-height: 156px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__bt {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(169, 107, 15, 0.5);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    cursor: pointer;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;

    &:hover,
    &:active {
      background-color: rgba(169, 107, 15, 0.8);
    }
    &_prev {
      left: -10px;
      background-image: url($cdn + '/sweden-yoyo/slider_arrow_l.svg');
    }
    &_next {
      right: -10px;
      background-image: url($cdn + '/sweden-yoyo/slider_arrow_r.svg');
    }
  }

  &__roullette-image {
    height: 430px;
    right: -175px;
    left: 0;
    bottom: 0;
    position: absolute;
    overflow: hidden;
    text-align: right;
    max-width: 901px;
    margin-left: auto;
    z-index: 2;

    & img {
      max-width: unset;
      right: 0;
      position: absolute;
      animation: rouletteRotate 60s linear infinite;
    }
  }

  &__elem {
    margin: 0;
  }

}