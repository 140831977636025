$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.footer {

  &__content-wrap {
    background-color: #f2ece0;
    padding-bottom: 35px;
  }

  &__top {
    padding: 13px 0;
    background-color: #9746A5;
  }

  &__top-wrap {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: hidden;
  }

  &__lang {
    padding-left: 30px;
    height: 30px;
    line-height: 30px;
    color: #f9fbe7;
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;
    position: relative;

    &:hover {
      .footer__icon-wrap {
        background: #f9fbe7;

        & svg {
          fill: #aa69ac;
        }
      }
    }
  }

  &__icon-wrap {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    transition: all .2s;
    padding-top: 2px;

    & svg {
      width: 20px;
      height: 20px;
      fill: #f9fbe7;
      padding-top: 5px;
    }
  }

  &__payments-list {
    padding: 20px 0;
    border-bottom: 1px solid #e0e0e0;
  }

  .footerLicense {
    padding-top: 24px;
    text-align: center;
    @include text(10px, 18px, 400, #3f3f3f);
  }

  .footerLicense-icons {
    flex-wrap: wrap;
    gap: 10px 24px;
    margin-bottom: 0;
  }

  .footerLicense-icons li {
    height: 32px;
  }

  .footerLicense-icons a:not([href]) {
    pointer-events: none;
    cursor: initial;
  }

  .footerLicense-text a,
  .footerLicense-text button  {
    text-decoration: underline;
    color: #aa69ac;
    transition: color, text-decoration 0.2s ease-in-out;

    &:hover { 
      color: #ab69ad;
      text-decoration: none;
    }
  }

  .footerLicense-text button {
    font-size: 10px;
    padding: 0;
    border: 0;
    background: none;
  }

  .footerLicense-text {
    padding-top: 24px;
  }

  .footerLicense-text p {
    margin: 10px 0 0;

    &:first-child {
      margin-top: 0;
    }
  }

  @include l {
    &__content-wrap {
      padding-bottom: 30px;
    }
  }

  @include s {
    &__content-wrap {
      padding-bottom: 25px;
    }

    &__payments-list {
      padding: 20px 0;
    }
  }
}
