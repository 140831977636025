$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.game-slider {
  $this: &;

  --game-slider-header-height: 36px;
  --game-slider-arrow-size: 24px;
  --game-slider-arrow-gap: 8px;
  --game-slider-arrow-offset: 16px;

  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-template-rows: var(--game-slider-header-height) 1fr;

  &__header {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  &__arrows {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    margin-left: var(--game-slider-arrow-offset);

    display: inline-grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: var(--game-slider-arrow-gap);
    place-items: center;

    .splide__arrow {
      background: none;
      border: none;
      border-radius: 0;
      position: static;
      transform: none;
      width: var(--game-slider-arrow-size);
      height: var(--game-slider-arrow-size);
      color: #9746A5;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: #81348E;
      }

      &:disabled {
        opacity: 0.2;
      }

      svg {
        transform: none;
        display: block;
        width: 100%;
        height: 100%;
        fill: currentColor;
      }
    }
  }

  .splide__track {
    margin-top: 4px;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;

    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }

  }

  .splide__slide.has-width {
    margin-right: 12px;
    width: calc(((100% + 12px) / 6) - 12px);
  }

  &.has-extra-paddings {
    .splide__track {
      margin-left: -6px;
      margin-right: -6px;
    }
    .splide__list {
      padding: 0 6px !important;
    }
  }

  &.no-arrows,
  &:not(.is-overflow) {
    .splide__list {
      transform: none !important;
    }

    .splide__arrows {
      display: none;
    }
  }


  & {
    @include bp-1440 {

      .splide__track {
        margin-top: 11px;
      }
    }
  }



}
