$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.christmas-page {

    background-image: url($cdn + "/yoyo/christmas/bg_img.jpg");
    background-color: #FAFDFF;
    background-repeat:no-repeat;
    background-position:center top -359px;
    padding: 20px 30px 0;


    &__content-wrap {
        max-width: 1365px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        flex-wrap:wrap;
        align-items: flex-end;
        height: 1594px;
    }

    &__days {
        align-self: flex-start;
        display: block;
        width: 650px;
        height: 1500px;
        position: relative;
        margin-left: auto;
    }

    &__promo {
        padding: 13px 30px;
        display: flex;
        align-items: center;
        width: 400px;
        min-height: 80px;
        border-radius: 10px;
        margin-top: 10px;
        font-size: 16px;
        line-height: 1.41;
        color: #ffffff;
        overflow: hidden;
    }


    &__promo-bonus {
        background-color: #2d9cdb;
    }

    &__promo-img {
        width: 44px;
        height: 44px;
        flex-shrink: 0;
        margin-right: 30px;

        & svg {
            width: 100%;
            height: 100%;
            fill: white;
        }
    }

    &__promo-tournament {
        background-color: #aa69ac;
    }

    @include m {

        padding: 20px;
        background-position-x: calc(50% + 40px);

        &__bonus {
            margin-left: 20px;
        }

        &__promo {
            width: 300px;
        }
    }


    @include s {

        background-position-x: calc(50% + 197px);
        background-position-y: -80px;
        &__content-wrap {
            align-items:center;
            height: 1961px;
        }

        &__bonus {
            order: 0;
            margin-left: 0;
            max-width: 100%;
        }

        &__days {
            margin: auto;
            order: 1;
            height: 700px;
            margin: auto auto 0px;
        }

        &__promo-bonus {
            order: 2;
            height: 70px;
        }

        &__promo-tournament {
            order: 3;
            height: 70px;
        }
    }

    @include xs {

        background: #FAFDFF url($cdn + "/yoyo/christmas/christmas_bg_mob.jpg") center top no-repeat;
        background-position: center -65px;
        padding: 20px 10px;

        &__bonus {
            order: 0;
        }
        &__content-wrap {
            height: 1180px;
        }
       &__days {
           order: 1;
           height: 500px;
           width: 300px;
           margin-top: auto;
           margin-bottom: 0;
       }
    }
}
