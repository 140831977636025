$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.ec-baner {
  padding: 10px 0 75px;
  height: 450px;
  background-color: #FFD07E;;
  background-image: url($cdn + '/yoyo/entercash/ec-banner-bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  h1 {
    text-shadow: 0 4px 0 #00000040;
    font-size: 36px;
    font-weight: 900;
    color: #ffd180;
    margin: 30px 0 15px;
    text-align: center;
    overflow: hidden;
    width: 550px;
    max-height: 45px;
  }
  &__content {
    text-align: center;
    line-height: normal;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    background: url(/img/slider-text-background.svg) no-repeat top center;
    background-origin: content-box;
    padding-bottom: 20px;
  }
  &__btn {
    background-color: #35d290;
    &:hover {
      background-color: #00be6e;
      color: #fff;
    }
  }
  &__text-or {
    text-align: center;
    font-size: 14px;
    color: #66554f;
    line-height: 1.79;
    & a {
      text-decoration: underline;
      font-weight: 600;
    }
  }
  &__payments-wrap {
    display: flex;
    justify-content: center;
    margin-bottom: 13px;
  }
  &__list {
    justify-content: center;
    display: flex;
    margin-bottom: 13px;
    & li {
      margin: 0px 10px;
    }
  }
  @include s {
    height: 390px;
    h1 {
      margin-bottom: 10px;
    }
  }
  @include xs {
    height: 340px;
    background: linear-gradient(rgba(63, 63, 63, 0.7), rgba(63, 63, 63, 0.7)), url($cdn + '/yoyo/entercash/ec-banner-bg.jpg') center;
    padding-bottom: 0px;
    &__content {
      background-image: none;
    }
    &__text-or {
      color: #ffd180;
    }
    &__content {
      padding-bottom: 10px;
    }
    h1 {
      width: 100%;
      font-size: 32px;
      margin-top: 0;
      max-height: 40px;
    }
    &__list {
      & li {
        margin: 0 7px;
        width: 80px;
        height: 20px;
      }
    }
  }
  @include xxs {
    &__btn {
      height: 40px;
      line-height: 40px;
      font-size: 16px;
    }
    & li.icons-list__item {
      width: 60px;
      height: 14px;
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        display: none;
      }
    }
    h1 {
      max-height: 71px;
      min-height: 55px;
    }
  }
}
