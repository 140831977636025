$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.slider-games-h {

  .slick-slide {
    padding: 0 8px;
  }

  .game-item {
    margin: 0;
  }

  &__arrow {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.5);
    @include absolute-center-y;
    z-index: 3;
    cursor: pointer;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;

    &.is-prev {
      left: 0;
      background-image: url($cdn + '/sweden-yoyo/slider_arrow_l.svg');
    }

    &.is-next {
      right: 0;
      background-image: url($cdn + '/sweden-yoyo/slider_arrow_r.svg');
    }
  }

  &__in {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    margin: 0 -8px;
  
    &.slick-initialized {
      display: block;
  
      .game-item {
        padding: 0;
      }
    }
  
    .game-item {
      flex: 20% 0 0;
      padding: 0 8px;
  
      @include bp-1440 {
        flex-basis: 25%;
      }
    }
  }
}
