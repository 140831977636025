$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.search-categories {
  min-height: 300px;

  .preloader {
    background-color: transparent;
  }
  
  &__item {
    margin-top: 16px;
  }
  @include bp-1440 {
    &__item {
      margin-top: 24px;
    }

  }
}
