$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
@use 'sass:math';

.game-banner {
  display: block;
  position: relative;

  &__in {
    position: relative;
    overflow: hidden;
    background: linear-gradient(180deg, #f5d86f 56.48%, #ffd02b 100%);
    padding: 53px 20px 124px;

    &.is-flex {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    &.has-anim-top {
      padding-top: 124px;
    }

    &.has-anim-bottom {
      padding-bottom: 124px;
    }
  }

  &__slider {
    position: relative;
    z-index: 2;

    .slick-list {
      padding: 0;
    }
  }

  &__bg {
    @include absolute-center;
    width: 1974px;
    height: 1974px;
    z-index: 1;

    &::before {
      content: '';
      display: block;
      @include absolute-cover;
      background-image: url($cdn + '/yoyo/elements/bg/spiral.svg');
      animation: rotation 60s linear infinite;
    }
  }

  &__badge {
    @include absolute-center-x;
    top: calc(-1 * var(--badge-height) / 2);
    z-index: 5;
  }

  &__img-box {
    width: percentage(math.div(480, 984));
  }

  &__img {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 52.5%;
  }


  &__img picture,
  &__img img {
    @include absolute-cover;
    object-fit: cover;
    z-index: 2;
    overflow: hidden;
  }

  &__content {
    padding-left: 28px;
    width: percentage(math.div(504, 984));
    text-align: center;
    position: relative;
  }

  &__title {
    position: relative;
    z-index: 2;
    margin: 0;
    @include text(28px, 140%, 700, #663170);
    @include text-lines-clip(3);
    text-align: left;
  }

  &__desc {
    margin: 10px 0 0;
    position: relative;
    z-index: 2;
    text-align: left;
    @include text(16px, 140%, 400, #3F3F3F);
    @include text-lines-clip(8);
  }

  &__anim-box  {
    @include absolute-center-x;
    z-index: 2;
    display: flex;
    align-items: center;

    &.is-top {
      top: 44px;
    }

    &.is-bottom {
      bottom: 24px;
    }
  }

  &__anim-text {
    flex-shrink: 0;
  }

  &__anim-line {
    position: relative;
    width: 1240px;
    height: 60px;
    flex-shrink: 0;

    &.is-left {
      left: 100px;
    }

    &.is-right {
      right: 100px;
      transform: rotate(-180deg);
    }
  }

  &__anim-btn {
    min-width: 200px;
    position: relative;
    z-index: 2;
  }

  @include bp-1440 {
    &__in {
      padding: 48px 54px 116px;
    }

    &__img-box {
      flex-shrink: 0;
      width: 510px;
    }

    &__img {
      padding-top: 0;
      height: 269px;
    }

    &__content {
      width: 604px;
      padding-left: 76px;
    }

    &__title {
      font-size: 36px;
    }

    &__desc {
      font-size: 18px;
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
