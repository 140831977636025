$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.bankid-banner-form {
  width: 762px;
  border-radius: 14px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  padding:40px 33px;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    border-radius: 14px;
    box-shadow: 0 3px 8px rgba(41, 11, 40, 0.4);
    background-image: linear-gradient(150deg, #a745b0 0, #700e66 100%);
  }

  &__in {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    border-radius: 14px;
    &::before {
      content: '';
      position: absolute;
      left: calc(50% - 400px);
      top: calc(50% - 360px);
      width: 800px;
      height: 800px;
      background-repeat: no-repeat;
      background-size: 100%;
      z-index: -1;
      animation-name: rotate;
      transform-origin: center;
      animation-duration: 60s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      background-image: url($cdn + '/sweden-yoyo/bankid-spinner.svg');
    }
  }
  &__content{
    position: relative;
    z-index: 10;
    width: 400px;
    padding: 0 32px;
  }

  &__rules{
    margin-top: 35px;
  }
  &__img{
    position: absolute;
    width: 340px;
    height: 200px;
    top: 0;
    right: 40px;
    background-image: url($cdn + '/yoyo/fish_right.png')  , url($cdn + '/yoyo/bird_left.png') ;
    background-position: right center , left center ;
    background-repeat: no-repeat , no-repeat;
    background-size: 200px 200px, 200px 200px;
  }



  



  &__btn {
    align-items: center;
    font-weight: 700;
    font-size: 22px;
    line-height: 57px;
    text-align: center;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
    border-radius: 54px;
    width: 57px;
    height: 57px;
    color: #fff;
    position: absolute;
    right: 45%;
    z-index: 20;
    padding: 0;
    @include text-dots;
    bottom: 0;
    &::after {
      display: block;
      content: '';
      width: 21px;
      height: 12px;
      background-image: url(/img/arrow-white.svg);
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__btn-text {
    display: block;
    position: absolute;
    right: 69px;
    top: 0;
    line-height: 57px;
  }

  &__trustly {
    margin: 12px auto 6px;
    width: 88px;
    height: 30px;
    background-image: var(--icon-trustly2);
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  &__heroes {

    width: 1460px;
    height: 380px;
    position: absolute;
    display: block;
    top: -48px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    pointer-events: none;
    background-image:
            url($cdn + '/yoyo/mex.png'),
            url($cdn + '/yoyo/fish_right.png'),
            url($cdn + '/yoyo/bird_left.png'),
            url($cdn + '/yoyo/horse_left.png');

    background-repeat: no-repeat;
    background-size:
            225px 220px,
            126px 135px,
            200px 176px,
            138px 138px;
    background-position:
            107px  123px,
            36px 44px,
            right 116px top 167px,
            right 58px top 47px;
  }

  &__bg {
    position: absolute;
    display: block;
    top: -48px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 2100px;
    width: 100%;
    height: 370px;

    background-image: url($cdn + '/yoyo/bnr-confetti.png'), url($cdn + '/yoyo/bnr-confetti.png');
    background-size: auto 100%, auto 100%;
    background-repeat: no-repeat;
    background-position: right calc(50% + 500px) center, left calc(50% + 500px) center;
    z-index: -2;
    pointer-events: none;
  }
}
