$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.badge {
  --badge-height: 49px;
  --badge-font-size: 24px;
  --badge-padding-x: 24px;

  display: block;
  box-sizing: border-box;
  max-width: fit-content;
  min-width: 208px;
  height: var(--badge-height);
  position: relative;
  padding: 0 var(--badge-padding-x);
  @include text(var(--badge-font-size), var(--badge-height), 900, #66396F);
  @include ellipsis;
  text-transform: uppercase;
  text-align: center;

  background-image: url($cdn + '/yoyo/elements/badges/rect-bg.svg');
  background-size: 100% 100%;
}
