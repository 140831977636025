$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.user-page {

  &__main-background {
    .is-main-page & {
      padding-top: 60px;
      background-image: url($cdn + '/yoyo/conffeti.png'),
                        url($cdn + '/yoyo/conffeti.png');
      background-size: 41% 214%,
                        41% 214%;
      background-position: top left 130px,
                            top right 130px;
      background-repeat: no-repeat;

      @include xxxl {
        background-position: top left 211px,
                            top right 211px;
        background-size: 33% 291%,
                         33% 291%;
      }
      @include xl {
        background-position: top left 158px,
                            top right 158px;
      }

      @include m {
        padding-top: 57px;
        background-position: top left 20px,
                            top right 20px;
      }
    }
  }

  &__mask {
    .is-main-page & {
      width: 100%;
      height: 360px;
      background-image: linear-gradient(180deg, #FFEAA1 0%, #FFC600 100%);
      background-size: cover;
      background-position: center;
      -webkit-mask-image: url(/img/mask.svg);
      mask-image: url(/img/mask.svg);
      -webkit-mask-position: center top;
      mask-position: center top;
      -webkit-mask-size: 100% 158px;
      mask-size: 100% 158px;
      -webkit-mask-repeat: no-repeat;
      position: absolute;
      z-index: -1;
    }
    .is-main-page &::after {
      display: block;
      content: '';
      width: 59%;
      height: 140%;
      background-image: url($cdn+ '/yoyo/blob.svg');
      background-size: 100% 122%;
      background-position: top -62px center;
      background-repeat: no-repeat;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      @include l {
        background-position: top -73px center;
      }
      @include m {
        width: 73%;
        background-position: top -68px center;
      }
    }
  }

  &__container {
    max-width: 1270px;
    position:relative;

    &:after {
      //content: '';
      //display: block;
      //width: 15px;
      //height: 42px;
      //background-image: linear-gradient(to left, #f9fbe7 0%, transparent 100%);
      //position: absolute;
      //right:15px;
      //top: 48px;
    }
  }

  &__container-inner {
    max-width: 984px;
    margin:auto;

    .is-main-page & {
      width: 762px;
      background: linear-gradient(149.69deg, #A745B0 9.65%, #700E66 92%);
      box-shadow: 0 3px 8px rgba(41, 11, 40, 0.39);
      border-radius: 14px;
      overflow: hidden;
    }
    .is-main-page &::after {
      width: 1300px;
      height: 1300px;
      left: 64%;
      top: -543px;
      display: block;
      content: '';
      background-image: url($cdn + '/yoyo/candy_menu.svg');
      background-position: center;
      background-repeat: no-repeat;
      animation: rotateCandy 50s infinite linear;
      position: absolute;
      z-index: 0;
    }
  }

  &__menu {
    height: 110px;
    padding-top: 46px;
    margin-bottom: 76px;
    background: url($cdn + '/yoyo/profile-cashbox_top-bg.jpg') center;
  }
  &__content {
    margin-bottom: 40px;
  }
  &__title {
    @include text(24px, 34px, 700, #66554F);
    margin-bottom: 20px;
    text-align: center;
  }

  @include l {
    &__content {
      margin-bottom: 45px;
    }
  }

  @include m {
    &__menu {
      padding-top: 65px;
    }
  }
  @include s {
    &__content {
      margin-bottom: 35px;
    }
  }
}

.user-page-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 560px;
  height: 110px;
  margin: 0 auto;
  background-color: $cream;
  border-radius: 10px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.08);
  &__item {
    width: calc(25% - 20px);
    margin: 0 10px;
  }

  @include m {
    width: 540px;
    height: 90px;
  }

  @include xs {
    width: 100%;
    border-radius: 0;
  }
}


.user-submenu {
  display: flex;
  justify-content: center;
  overflow-x: auto;

  @include margin-rhythm-30;

  &_scroll {
    &::-webkit-scrollbar-track {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.08) 0%, transparent 100%);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 5px;
      border-radius: 5px;
      background-color: rgb(251, 206, 126);
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgb(251, 206, 126);
    }
    &::-webkit-resizer {
      background-image: none;
      background-repeat: no-repeat;
      width: 4px;
      height: 4px
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
  }
  &__item {
    width: 290px;
    padding: 0 30px;
    height: 40px;
    text-align: center;
    border-radius: 20px;
    font-size: 16px;
    line-height: 40px;
    font-weight: 700;
    white-space: nowrap;
    //@include text-dots
  }
  &__item + &__item {
    margin-left: 20px;
  }

  @include xs {
    padding-bottom: 5px;
    justify-content: flex-start;

    &__item + &__item {
      margin-left: 10px;
    }

  }
}
.is-entrance {
  .entrance-cancel {
    display: none;
  }
}
