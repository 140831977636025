$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
linda-registration-bank-page {
  display: flex;
  min-height: 100%;
}

.reg-bank {
  background-image: url($cdn + '/yoyo/entercash/ec-reg-bg.jpg');
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  display: flex;
  width: 100%;
  min-height: 750px;
  @media (min-width: 1921px) {
    background-size: cover;
  }
  &__iframe {
    min-height: 480px;
    width: 100%;
    box-shadow: 0 6px 15px 0 #0000004d;
    z-index: 2;
  }
  &__container {
    max-width: 910px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 5px;
    position: relative;
  }
  &__top-promo {
    min-height: 83px;
    width: 485px;
    margin: 0 auto;
    padding-top: 15px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.2;
    color: #f9fbe7;
    text-align: center;
    position: relative;
    overflow: hidden;
    &::before {
      content: "";
      width: 485px;
      height: 485px;
      background-color: #35d290;
      border-radius: 100%;
      background-position: bottom center;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
    span {
      display: block;
      position: relative;
      font-size: 18px;
      font-weight: 600;
      color: #f9fbe7;
      line-height: 1.2;
    }
  }
  &__title {
    font-size: 36px;
    font-weight: 900;
    line-height: 1.2;
    color: #ffd180;
    text-shadow: 0 4px 0 rgba(0, 0, 0, 0.25);
    text-align: center;
    margin-bottom: 15px;
  }
  &__amount-btns {
    margin: 0 -7px 20px;
  }

  &__iframe-header {
    margin-top: auto;
  }

  &__content-form {
    width: 404px;
    margin: 0 auto auto;
  }

  &__content-iframe {
    display: flex;
    position: relative;
    margin-bottom: 20px;
  }
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__form-btn {
    margin-bottom: 20px;
    padding: 0 30px;
  }
  &__btn-label {
    text-align: center;

    display: flex;
    align-items: flex-end;
    margin-left: -20px;

    svg {
      width: 28px;
      height: 29px;
      fill: #f9fbe7;
      margin-right: 3px;
      margin-bottom: 4px;
    }
    span {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.79;
      color: #f9fbe7;
    }
  }

  &__input-wrap {
    min-height: 82px;
  }
  &__content-wrap {
    margin: auto;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  @include s {
    padding-bottom: 10px;
    &__container {
      padding: 0 44px;
    }

  }
  @include xs {
    min-height: 670px;

    .reg-bank__iframe {
      min-height: 450px;
    }
    &__container {
      padding: 0;
    }
  }
  @include xxs {
    min-height: 720px;
    &__top-promo {
      width: 100%;
      overflow: hidden;
      &::before {
        left: 50%;
        transform: translateX(-50%);
        right: auto;
      }
    }
    &__form-btn {
      width: 200px;
      height: 40px;
      font-size: 16px;
      line-height: 40px;
    }
    &__content-form {
      width: 100%;
    }
    .reg-bank__iframe {
      min-height: 492px;
    }
    &__btn-label {
      margin-top: 10px;
      margin-bottom: 45px;
    }
  }
}

.ec-btns-choice-amount {
  display: flex;
  justify-content: space-between;
  &__btn {
    min-width: 124px;
    height: 45px;
    font-size: 18px;
    line-height: 45px;
    color: #66554f;
    border-radius: 10px;
    background-color: #f9fbe7;
    transition: background-color .2s;
    border: none;
    cursor: pointer;
    font-weight: 600;
    margin-right: 15px;
    padding: 0 7px;
    &:last-child {
      margin: 0;
    }
    &:hover {
      background-color: #ffd180;
    }

    &.active {
      color: #fff;
      background-color: #ffa800;
      cursor: default;
    }
  }
  @include xs {
    margin: 0 -7px 15px;
  }
  @include xxs {
    &__btn {
      min-width: 94px;
      height: 37px;
      font-size: 16px;
      line-height: 37px;
      margin-right: 5px;
    }
  }
}

.ec-enter-amount-field {
  position: relative;
  display: block;
  width: 304px;
  height: 60px;
  margin: 0 auto;
  &__cur {
    position: absolute;
    text-shadow: 0 4px 0 #00000040;
    font-size: 18px;
    font-weight: 900;
    color: #ffffff;
    z-index: 3;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
  }
}

.ec-enter-amount-error {
  width: 304px;
  margin: 0 auto;
  padding: 3px 20px 0;
  text-align: center;

  &__text {
    color: #F9FBE7;
    font-size: 12px;
    line-height: normal;
    width: 100%;
    max-width: 100%;
    margin-bottom: 4px;
  }
}



.g-input-entercash {
  background-color: rgb(90, 74, 65);
  border-radius: 30px;
  height: 60px;
  text-shadow: rgba(0, 0, 0, 0.25) 0 4px 0;
  font-size: 36px;
  font-weight: 900;
  color: rgb(255, 255, 255);
  text-align: center;
  line-height: 60px;
  padding: 0px 50px;
  border: none;
  &:hover {
    background-color: rgba(90, 74, 65, 0.7);
  }
  &:focus {
    background-color: #f9fbe7;
  }
  &:focus + span {
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
    color: #66554f;
  }
  &.ng-invalid.ng-touched {
    background-color: #f9fbe7;
    border: 2px solid #ea4642;
    color: #3f3f3f;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
    & + span {
      text-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
      color: #66554f;
    }
  }
  &.ng-valid {
    border: none;
  }
  &.ng-valid.ng-touched {
    background-color: #f9fbe7;
    border: 2px solid #35d290;
    color: #3f3f3f;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
    & + span {
      text-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
      color: #66554f;
    }
  }
  @include xs {
    width: 290px;
  }
}



.reg-bank-bot-links {
  text-align: center;
  a {
    color: inherit;
    text-decoration: underline;
    font-weight: bold;
  }
  &__1 {
    color: #66554f;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 23px;
  }
  &__2 {
    font-size: 12px;
    color: #3f3f3f;
    font-weight: 600;
    margin-bottom: 46px;
  }
  @include m {
    &__1 {
      display: none;
    }
  }
}


.ec-fast-reg-icons {
  max-width: 710px;
  margin: 0 auto;
  .icons-list__list {
    justify-content: center;
  }
  .icons-list__list li {
    margin: 0 8px;
  }
  @include xs {
    .icons-list__list li:nth-child(4),
    .icons-list__list li:nth-child(5),
    .icons-list__list li:nth-child(6),
    .icons-list__list li:nth-child(7) {
      display: none;
    }
  }

  @media(min-width: 1920px) {
    margin-bottom: 10px;
  }
}

.reg-bank-bot-icons {
  margin: 20px auto 0;
}


.linda-step-2 {
  .reg-bank__iframe-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 16px 0;
    font-weight: 600;
    color: #ffffff;
  }
  .reg-bank__title {
    margin-top: 0;
    flex-grow: 1;
  }
}

.reg-bank__user-amount {
  font-size: 18px;
}
.reg-bank__step-back-link {
  display: flex;
  align-items: center;
  & span {
    font-size: 16px;
    margin-left: 10px;
  }
}

@include xs {
  .reg-bank__iframe-header {
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  .reg-bank__title {
    order: 2;
    width: 100%;
    margin-top: 10px;
  }
}
//@include xxs {
//  background-color: #388fba;
//  background-image: none;
//}


.reg-bank-hide-on-2step {
  .linda-step-2 & {
    display: none;
  }
}


.show-on-2step-reg-with-dep {
  display: none;
  .linda-step-2.linda-signup-dep & {
    display: flex;
  }
}

.linda-step-2 .reg-bank {
  .progress-bar__step.pr-2 {
    opacity: 1;
  }
  .progress-bar__step.pr-1 .progress-bar__step-number::after {
    content: "";
    background-image: url(/img/icon-tooltip-check.svg);
    width: 20px;
    height: 15px;
    position: absolute;
    right: -5px;
    top: -3px;
  }
}

// progress bar and steps
.progress-bar__step-number2 {
  width: 40px;
  height: 40px;
  background-color: #f9fbe7;
  color: #66554f;
  font-size: 24px;
  font-weight: 900;
  text-align: center;
  line-height: 40px;
  margin-bottom: 10px;
  border-radius: 100%;
}

.progress-bar {
  display: flex;
  justify-content: center;
  margin-bottom: auto;
  &__step {
    width: 140px;
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    opacity: 0.6;
    &.pr-1 {
      opacity: 1;
    }
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      background-color: #f9fbe7;
      position: absolute;
      right: 0;
      top: 15px;
      border-radius: 100%;
    }
    &:last-child::before {
      display: none;
    }
  }
  &__step-number {
    width: 40px;
    height: 40px;
    background-color: #f9fbe7;
    color: #66554f;
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    line-height: 40px;
    margin-bottom: 10px;
    border-radius: 100%;
    position: relative;
  }
  &__step-text {
    font-size: 14px;
    line-height: 1.2;
    color: #f9fbe7;
    text-align: center;
    width: 130px;
  }

  @include xxs {
    &__step {
      width: 90px;
    }
    &__step-text {
      font-size: 12px;
      width: 100%;
    }
  }


  @media (min-width: 1920px) {
    margin-bottom: 30px;
  }

}



.homepage-reg-bank {
  min-height: 750px;
  
  @include margin-rhythm-60;

  @include xs {
    min-height: 670px;
  }
  @include xxs {
    min-height: 720px;
  }

  .reg-bank {
    background-size: 1920px 1000px;
    @media (min-width: 1920px) {
      background-size: cover;
    }
  }
}



