$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
// --- IMAGES WITH LAZY LOAD IS USED IN YOYO-IMG COMPONENT FOR GAME ITEM

.lazy-img {
  display: block;
  
  &.is-lazy-loading {
    &::before {
      content: '';
      @include absolute-cover;
      z-index: 1;
      background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDQiIGhlaWdodD0iNDQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgc3Ryb2tlPSIjNjg2YjZjIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS13aWR0aD0iMiI+PGNpcmNsZSBjeD0iMjIiIGN5PSIyMiIgcj0iMSI+PGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iciIgYmVnaW49IjBzIiBkdXI9IjEuOHMiIHZhbHVlcz0iMTsgMjAiIGNhbGNNb2RlPSJzcGxpbmUiIGtleVRpbWVzPSIwOyAxIiBrZXlTcGxpbmVzPSIwLjE2NSwgMC44NCwgMC40NCwgMSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiLz48YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJzdHJva2Utb3BhY2l0eSIgYmVnaW49IjBzIiBkdXI9IjEuOHMiIHZhbHVlcz0iMTsgMCIgY2FsY01vZGU9InNwbGluZSIga2V5VGltZXM9IjA7IDEiIGtleVNwbGluZXM9IjAuMywgMC42MSwgMC4zNTUsIDEiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+PC9jaXJjbGU+PGNpcmNsZSBjeD0iMjIiIGN5PSIyMiIgcj0iMSI+PGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iciIgYmVnaW49Ii0wLjlzIiBkdXI9IjEuOHMiIHZhbHVlcz0iMTsgMjAiIGNhbGNNb2RlPSJzcGxpbmUiIGtleVRpbWVzPSIwOyAxIiBrZXlTcGxpbmVzPSIwLjE2NSwgMC44NCwgMC40NCwgMSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiLz48YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJzdHJva2Utb3BhY2l0eSIgYmVnaW49Ii0wLjlzIiBkdXI9IjEuOHMiIHZhbHVlcz0iMTsgMCIgY2FsY01vZGU9InNwbGluZSIga2V5VGltZXM9IjA7IDEiIGtleVNwbGluZXM9IjAuMywgMC42MSwgMC4zNTUsIDEiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+PC9jaXJjbGU+PC9nPjwvc3ZnPg==') no-repeat center / 50px 50px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 50px;
    }
  }

  &.is-lazy-loaded {
    img {
      width: 100%;
      height: 100%;
      opacity: 1;
      transform: scale(1);
      transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    }
  }

  &.is-lazy-loading,
  &.is-lazy-error {
    img {
      opacity: 0;
      transform: scale(0.9);
    }
  }
}
