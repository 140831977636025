$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-bonus-item {

  border-radius: 10px;
  padding-bottom: 35px;
  position: relative;

  &_active {

    background-color: #FAF3D0;

    .profile-bonus-item__header-wrap:before {
      background-color: #FFA800;
      content: 'Active';
    }

    .profile-bonus-item__field:first-child {
      border-top: solid 2px #FFA800;
    }
  }

  &_new {
    background-color: #E6F7DE;

    .profile-bonus-item__header-wrap:before {
      background-color: #35d290;
      content: 'New';
    }

    .profile-bonus-item__field:first-child {
      border-top: solid 2px #00be6e;
    }

    .profile-bonus-item__btn {
      display: block;
    }
  }

  &__header-wrap {
    overflow: hidden;
    position: relative;

    &:before {
      color: #ffffff;
      font-size: 12px;
      font-weight: 700;
      text-align: center;
      height: 19px;
      line-height: 19px;
      width: 100px;
      position: absolute;
      top: 15px;
      left: -25px;
      transform: rotate(-45deg);
    }
  }

  &__header {
    min-height: 120px;
    padding: 30px 60px 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #3f3f3f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
 }

  &__title {
    font-size: 18px;
    line-height: 1.76;
   }

  &__game {
    margin-top: 10px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f3f3f;
  }

  &__game-link {
    text-decoration: underline;
  }

  &__amount {
    font-size: 24px;
  }

  &__content {
    padding: 0 25px;
  }

  &__field {
    padding: 10px 0;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    line-height: normal;
    letter-spacing: normal;
    color: #3f3f3f;
    position:relative;
  }

  &__field:first-child:hover &__tooltip-wrap {
    display: block;
  }

  &__field + &__field {
    border-top: 1px solid #D5CFB3;
  }

  &__field-name {
    max-width: 40%;
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
  }

  &__field-value {
    font-size: 16px;
    font-weight: bold;
    text-align:right;
    padding-left: 10px;
  }

  &__tooltip-wrap {
    display: none;
    position:absolute;
    left: -170px;
    bottom: -22px;
  }

  &__tooltip {
    width: 156px;
    min-height: 78px;
    background-color: #dcdbca;
    border-radius: 3px;
    position:relative;
    padding: 6px 14px;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.76;
    letter-spacing: normal;
    color: #3e3e3e;
    word-break: break-word;

    &:before {
      display: block;
      content: '';
      height: 10px;
      width: 10px;
      background-color: #dcdbca;
      position: absolute;
      top: calc(50% - 5px);
      right: -5px;
      transform: rotate(-45deg);
    }

  }

  &__tooltip-value {
    font-weight: bold;
  }

  &__link {
    display: block;
    text-align: center;
    font-weight: bold;
    color: #3f3f3f;
    text-decoration: underline;
    margin-top: 20px;
  }

  &__btn {
    display: none;
    font-size: 16px;
    text-align: center;
    margin: 10px auto 0;
  }

  @include l {

    &__tooltip-wrap {
      right: -170px;
      left: auto;
      z-index: 1;
    }

    &__tooltip:before {
      left: -5px;
    }
  }

  @include s {

    &__header {
      padding: 30px 30px 15px;
    }

    &__title {
      font-size: 16px;
          }

    &__amount {
      font-size: 18px;
    }
  }

  @include xs {

    &__tooltip-wrap {
      right: 0;
      left: 0;
      top: 55px;

    }

    &__tooltip {
      width: 100%;
    }

    &__tooltip:before {
      left: calc( 50% - 5px);
      top:-5px;
    }

    &__tooltip-item {
      width: 65%;
    }
  }
}

