$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.bankid-banner-choice-amount {
  display: flex;
  justify-content: center;
  margin: 20px 0 14px;
  position: relative;
  z-index: 2;

  &__btn {
    width: 102px;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
    color: rgba(255, 255, 255, 0.6);
    border-radius: 28px;
    background-color: #7a2a6a;
    transition: color 0.2s;
    font-weight: 900;
    margin-right: 8px;
    padding: 0 15px;
    border: none;
    cursor: pointer;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: #ffd850;
    }
    &.active {
      color: #ffd850;
      cursor: default;
    }
  }
}
