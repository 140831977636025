$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.info-self-control {
  p,
  h2 {
    margin: 0;
  }

  p + p,
  li + li,
  ul {
    padding-top: 10px;
  }

  ul {
    padding-bottom: 10px;
  }

  & section {
    padding: 24px 28px;
    border-radius: 10px;
    border: 1px solid #ffa800;
    margin-bottom: 24px;
  }

  & a {
    color: #9746A5;
    text-decoration: underline;
    font-weight: bold;

    &:hover {
      text-decoration: none;
    }
  }

  & h2 {
    @include text(20px, 24px, 700, #3F3F3F);
    padding-bottom: 20px;
  }

  & p, & li {
    @include text(16px, 22px, 400, #3F3F3F);
  }
}