$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.welcom-bonus {
  border-radius: 10px;
  border: 1px solid #00be6e;
  display: flex;
  height: 360px;
  background-repeat: no-repeat;
  padding-left: 60px;
  position: relative;
  background-size: 340px, cover;
  background-position: right 85px center, top center;

  &__left-wrap {
    margin: auto 0;
    max-width: 485px;
    width: 100%;
    position: relative;
  }
  &__title-small {
    color: #3f3f3f;
    font-size: 32px;
    font-weight: 900;
    margin-bottom: 20px;
  }
  &__title-big {
    color: #00be6e;
    font-weight: 900;
    font-size: 36px;
    margin-bottom: 20px;
    max-height: 86px;
    overflow: hidden;
  }
  @include m {
    height: 264px;
    padding-left: 30px;
    background-position: right 45px center, right center;
    background-size: 255px, cover;

    &__title-big {
      font-size: 24px;
      margin-bottom: 10px;
    }
    &__title-small {
      margin-bottom: 10px;
      font-size: 18px;
    }
    &__btn {
      margin-top: 10px;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      padding: 0 30px;
    }
  }
  @include s {
    height: 250px;
    padding-left: 20px;
    background-position: right 50px center, right center;
    &__left-wrap {
      width: 240px;
    }
  }
  @include xs {
    padding-left: 30px;
    background-position: right 10px center, calc(50%);
    background-size: 203px 194px, cover;
  }
  @include xxs {
    background-image: none;
    background-size: 203px 194px, 0 0;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #f9fbe7;
      opacity: 0.7;
      border-radius: 10px;
    }
  }
}
