$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.modal {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  max-height: 100%;
  overflow-y: auto;

  &__wrap {
    min-height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    //background: rgba(62, 62, 62, 0.8);
    background: rgba(62, 62, 62, 1);
    @include xs {
      background: rgba(62, 62, 62, 1);
    }
  }
  &__box {
    position: relative;
    margin: 100px 0;
    background: #ffd180;;
    border-radius: 10px;
    padding: 30px 30px 32px;
    box-sizing: border-box;
    overflow: hidden;
    width: 350px;
    line-height: normal;

    @include xs {
      margin: 0;
      border-radius: 0;
      padding: 30px 17px 40px;
    }
  }
  &__content {
    width: 100%;
    margin: 0 auto;
  }
  &__title {
    color: #3e3e3e;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: left;
    margin-top: 0;
  }

  &__text {
    font-weight: 400;
    line-height: 1.76;
    color: #3e3e3e;
    font-size: 14px;
    text-align: left;
    margin-bottom: 6px;
  }

  &__timer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    margin: 4px 0 8px;
    border-bottom: 1px solid rgba(63,63,63,0.2);
  }

  &__timer-text {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #66554F;

  }
  &__timer-count {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #3e3e3e;
  }

  &__sums {
    margin-bottom: 10px;
  }
  &__sum {
    text-transform: uppercase;
    text-align: center;
    color:  $violetLight;
    font-size: 32px;
    font-weight: 900;
  }
  &__currency {
    display: inline-block;
  }
  &__btns {
    margin-top: 12px;
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }
  &__btns-item {
    width: 290px;
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .has-margin-top-20 {
    margin-top: 20px;
  }
}