$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.sidemenu-promo {
  margin: 0 16px 24px;
  position: relative;
  &__mill{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(150deg, #a745b0 0, #700e66 100%);
    box-shadow: 0px 3px 9px rgba(41, 11, 40, 0.39);
    filter: box-shadow(0px 14px 35px 0px #21014A 51%);
    border-radius: 15px;
    z-index: 1;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      left: calc(50% - 300px);
      top: calc(50% - 230px);
      width: 600px;
      height: 600px;
      background-repeat: no-repeat;
      background-size: 100%;
      z-index: 2;
      animation-name: rotate;
      transform-origin: center;
      animation-duration: 60s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      background-image: url($cdn + '/sweden-yoyo/bankid-spinner.svg');
    }
  }

  &__content{
    position: relative;
    z-index: 10;
    padding: 90px 12px 40px;
    &::before{
      content: '';
      display: block;
      width: 210px;
      height: 120px;
      position: absolute;
      top: -32px;
      left: calc( 50% - 105px);
      background-image: url($cdn + '/yoyo/bird_left.png') , url($cdn + '/yoyo/fish_right.png') ;
      background-position: left top , right top;
      background-repeat: no-repeat , no-repeat;
      background-size: 120px 120px, 120px 120px;

    }
  }
  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.6);
  }

  &__text {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: $white;
    text-align: center;
    max-width: 202px;
    margin: 0 auto 8px;
    @include text-lines-clip(3);

    &.is-big {
      max-width: 100%;
      font-weight: 900;
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 0;
    }
  }

  &__btns {
    width: 100%;
    margin-top: 16px;
  }

  &__link {
    display: block;
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    text-decoration: underline;
    text-align: center;
    &:hover {
      opacity: .8;
    }
  }
}