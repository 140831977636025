$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.search-results {
  &__text {
    @include text(16px, 160%, 400, rgba(63, 63, 63, 0.60));
    margin-top: 18px;

    strong {
      color: $violet;
      font-weight: 500;
    }
  }

  &__category-block {
    margin-top: 18px;
  }

  @include bp-1440 {
    &__text {
      font-size: 18px;
      margin-top: 24px;
    }
    &__category-block {
      margin-top: 24px;
    }
  }
}
