$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.game-hall-container {
  @include margin-rhythm-60;
}

.no-favourite-text {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.76;
  letter-spacing: normal;
  color: #3f3f3f;
  padding-left: 10px;
  text-align: justify;

  .linda-has-games & {
    display: none;
  }
}