$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.land-page{
    display:flex;
    flex-direction: column;
    min-height:100%;
    background:#f9fbe6;

    &__container{
        max-width: 100%;
        margin: 0 auto;
        padding: 0 40px;
        @include m() {
            padding: 0 20px;
        }
    }

    .footer-icons__list{
        justify-content: center;
    }
}