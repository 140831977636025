$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.new-pass {
  &__body-text {
    font-size: 14px;
    line-height: 1.76;
    color: rgb(62, 62, 62);
    margin-bottom: 20px;
  }
  &__btns {
    margin-top: 15px;
    & button {
      max-width: 100%;
      width: 100%;
    }
  }
  &__formbox {
    input {
      padding-right: 40px;
    }
  }
}