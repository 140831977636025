$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;

.popup-acc-stat {
  position: relative;
  &__title {
    max-width: 200px;
  }
  &__logo {
    width: 68px;
    height: 28px;
    margin: 0 auto 20px;
    svg{
      width: 100%;
      height: 100%;
    }
  }
  &__item {
    border-bottom: 1px solid rgba(63, 63, 63, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 16px;
    color: #3e3e3e;
    &:last-child {
      border-bottom: none;
      margin-bottom: 10px;
    }
  }
  &__item-name {
    margin: 0;
  }
  &__value {
    font-weight: bold;
  }
  &__button {
    display: grid;
    grid-gap: 12px;
    text-align: center;
  }
}