$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.sitemap {
  padding: 35px 0;
  &__wrap {
    box-sizing: border-box;
    padding: 0 120px;
  }

  &__section {
    margin-bottom: 8px;
    box-sizing: border-box;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title,
  &__subtitle {
    font-weight: bold;
    color: $violetDark;
    margin-bottom: 22px;
  }

  &__title {
    font-size: 32px;
    margin-top: 0;
  }

  &__subtitle {
    font-size: 24px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    font-size: 14px;
    line-height: 1.76;
    width: calc(100%/4);
    font-weight: 400;
    display: flex;
    align-items:center;
    margin-bottom: 16px;
  }

  &__item-ref {
    color: $dark;
    transition: color 0.2s ease-in;
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;


    &:hover {
      color: $violetOld;
    }
  }

  &__label {
    width: 30px;
    height: 15px;
    border-radius: 15px;
    font-size: 10px;
    line-height: 14px;
    background-color: $greenLight;
    text-align:center;
    display: inline-block;
    color: $white;
    margin-left: 5px;
  }
  @include l {
    &__item-ref {
      max-width: 200px;
    }
  }

  @include m {
    padding: 22px 0 14px;

    &__wrap {
      padding: 0 20px;
    }
    &__item {
      width: calc(100%/3);
      margin-bottom: 10px;
    }

    &__item-ref {
      max-width: 200px;
    }
  }

  @include xs {
    padding: 22px 0 9px;

    &__wrap {
      padding: 0 15px;
    }

    &__item {
      width: 100%;
    }
  }
}

