$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.t-page {

  margin-top: 30px;
  @include margin-rhythm-60;

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  &_content {
    padding: 0 65px;
  }

  &__key {
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(65, 48, 52, 0.8);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__value {
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: normal;
    color: #3f3f3f;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__ico {
    width: 25px;
    height: 30px;
    margin-right: 10px;

    & svg {
      width: 100%;
      height: 100%;
      display: none;
    }
  }

  &__main-info-container {
    order: 1;
  }

  &__main-info {
    padding-left: 65px;
    width: calc(100% - 415px);
    display: flex;
    flex-direction: column;
  }

  &__banner {
    height: 315px;
    background-size:cover;
    background-position: left;
    background-repeat: no-repeat;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
  }

  &__banner-label {
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    height: 20px;
    line-height: 20px;
    width: 100px;
    position: absolute;
    top: 15px;
    left: -27px;
    transform: rotate(-45deg);
    padding: 0 20px;
    @include text-dots;
  }

  &__banner_t-status-0 {
    .t-page__banner-label {
      background-color: #ffc051;
    }
  }

  &__banner_t-status-1 {
    .t-page__banner-label {
      background-color: #35d290;
    }
  }

  &__banner_t-status-2 {
    .t-page__banner-label {
      background-color: #ea4642;
    }
  }

  &__title-block {
    margin: 30px 0 14px;
    display: flex;
    align-items: center;
  }

  &__tournament-title {
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: normal;
    color: #3f3f3f;
  }

  &__tournament-prize {
    font-size: 36px;
    font-weight: 900;
    line-height: normal;
    letter-spacing: normal;
    color: #00be6e;
    margin-left: 25px;
  }

  &__text {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.76;
    letter-spacing: normal;
    color: #3f3f3f;
  }

  &__rules-link {
    margin: 20px 0 50px;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: normal;
    color: #3f3f3f;
    text-decoration: underline;
  }

  &__info-container {
    order: 3;
  }

  &__info {
    width: 385px;
    padding-right: 65px;
    display: flex;
    flex-direction: column;
    float: right;
  }

  &__info-block {
    width: 320px;
    min-height: 490px;
    border-radius: 10px;
    margin-bottom: 30px;

    & .t-page__key {
      width: 50%;
    }

    & .t-page__value {
      margin-left: auto;
      margin-right: 10px;
    }

    &_prizes {

      padding: 30px 68px 30px 53px;
      border: 1px solid #ffc200;
      background: url('/img/one-tournament_prizes-bg.svg') bottom no-repeat;

      & .t-page__key {
        width: 15%;
      }
    }
    &_score {

      height: 490px;
      padding: 30px 38px 30px 48px;
      position: relative;
      border: 1px solid #00be6e;
      background: url('/img/one-tournament_score-bg.svg') bottom no-repeat;

      & .t-page__info-block-list {
        overflow-y: auto;
        height: 399px;
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
      }

      & .t-page__list-gradient {
        position: absolute;
        bottom: 30px;
        height: 60px;
        width: 70%;
        background-image: linear-gradient(to bottom, rgba(230, 247, 222, 0), #e6f7de);

      }
    }
    &_score-0 {

      padding: 30px 15px;

      & .t-page__info-block-noscore-text {
        display: block;
      }

      & .t-page__info-block-noscore-ico {
        display: block;
      }
    }
  }

  &__info-block-title {
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: normal;
    color: #3f3f3f;
    text-align: center;
    margin-bottom: 7px;
  }

  &__info-block-noscore-text {
    display: none;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.79;
    letter-spacing: normal;
    color: rgba(65, 48, 52, 0.8);
    text-align: center;
    margin-top: 20px;
  }

  &__info-block-noscore-ico {
    display: none;
    width: 150px;
    height: 170px;
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);

    & svg {
      width: 100%;
      height: 100%;
      fill: rgba(0, 190, 110, 0.3);
    }
  }

  &__info-block-list {
    min-height: 399px;
    display: flex;
    flex-direction: column;
  }

  &__info-block-item {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 25px;
    margin-top: 10px;

  }
  &__info-block-item_1, &__info-block-item_2, &__info-block-item_3 {
    .t-page__ico svg {
      display: block;
    }
  }

  &__info-block-item_1 {
    .t-page__ico svg {
      fill: #00be6e;
    }
  }

  &__info-block-item_2 {
    .t-page__ico svg {
      fill: #ffa800;
    }
  }

  &__info-block-item_3 {
    .t-page__ico svg {
      fill: #ea4642;
    }
  }

  &__details-wrapper {
    order: 2;
    min-height: 140px;
    background: linear-gradient(to right, #FCDF74 0%, #FCDF74 29%, transparent 29%, transparent 100%)
  }

  &__details {
    width: calc(100% - 415px);
    padding-left: 65px;
    display: flex;
    align-items: center;
    position: relative;
    min-height: 140px;

  }

  &__details-text {
    font-size: 14px;
    line-height: normal;
    letter-spacing: normal;
    color: #3f3f3f;
    margin-bottom: 15px;
    z-index: 10;
  }

  &__details-block {
    width: 50%;
    z-index: 10;

    & .timer {
      z-index: 10;
    }
  }

  &__details-title {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: normal;
    color: #3f3f3f;
    margin-bottom: 15px;
  }

  &__details-list {
    display: flex;
  }

  &__details-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__details-list-half {
    max-width: 50%;
  }

  &__details-list-half:first-child {
    min-width: 135px;
  }
  &__details-list-half:last-child {
    min-width: 200px;
    margin-left: auto;

    .t-page__details-list-item:last-child {
      margin-bottom: 0;
    }

  }

  &__details-key {
    padding-right: 10px;
  }

  &__details-value {
    white-space: nowrap;
  }

  &__timer {
    min-height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 320px;
    background: #FCDF74;
    position: relative;

    &:after {
      content: '';
      display: block;
      height: 100%;
      width: 130px;
      position: absolute;
      right: -128px;
      top: 0;
      background: url('/img/tournament_timer_bg.svg') left no-repeat;
      background-size: contain;

    }
  }

  &__games-container {
    order: 4;
  }

  &__games {
    line-height:0;
    width: calc(100% - 415px);
    padding-left: 65px;
  }

  &__games-title {
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(65, 48, 52, 0.8);
    margin: 30px 0 20px;
  }

  @include l {

    &__banner {
      height: 256px;
    }

    &__main-info, &__details, &__games {
      padding-left: 20px;
      width: calc(100% - 370px);

    }

    &__info {
      padding-right: 20px;
      width: 340px;
    }

    &__details-wrapper {
      background: linear-gradient(to right, #FCDF74 0%, #FCDF74 20%, transparent 20%, transparent 100%);
    }

    &__details {
      justify-content: space-between;
      height: 160px;
    }

    &__details-block {
      width: 290px;
    }

    &__details-list {
      flex-direction: column;
      align-items: center;
    }

    &__details-list-half:first-child, &__details-list-half:last-child {
      width: 100%;
      min-width: 100%;
    }

    &__details-list-item {
      justify-content: space-between;
    }

    &__timer {
      width: 250px;
      min-height: 160px;
    }
  }
  @include s {

    display: flex;
    flex-direction: column;

    &__games-container {
      margin: 0;
    }

    &__main-info, &__games, &__info {
      width: 100%;
      padding: 0;
    }

    &__timer {
      width: 280px;
    }

    &__details {
      width: 728px;
      margin: auto;
      padding-left: 0;
    }

    &__rules-link {
      margin: 20px 0 30px;
    }

    &__info {
      margin-top: 30px;
      float: none;
      flex-direction: row;
      order: 3;
    }

    &__info-block_score {
      padding: 30px 60px;
      margin-left: 28px;
      width: 380px;

    }

    &__games-title {
      margin: 0 0 6px;
    }
    &__banner {
      height: auto;
      background-size: cover;
      padding-top: 35%;
    }
  }

  @include xs {

    &__banner {
      height: 230px;
    }

    &__title-block {
      flex-direction: column;
      margin: 25px 0 15px;
      align-items: flex-start;
    }

    &__tournament-title {
      font-size: 18px;
      margin-bottom: 5px;
    }

    &__tournament-prize {
      font-size: 32px;
      margin-left: 0;
    }

    &__details-wrapper {
      background: linear-gradient(to right, #FCDF74 0%, #FCDF74 40%, transparent 40%, transparent 100%);
      background-size: 100% 120px;
      background-repeat: no-repeat;
      overflow: hidden;
    }

    &__timer {
      width: auto;
      min-height: 120px;
      align-items: end;
      background: linear-gradient(to right, #FCDF74 0%, #FCDF74 80%, transparent 80%, transparent 100%);

      &:after {
        width: 65px;
        right: -10px;
        background-size: cover;
      }
    }

    &__details {
      flex-direction: column;
      width: auto;
      height: auto;
    }

    &__details-block:first-child {
      height: 120px;
      display: flex;
      flex-direction: column;
      padding-left: 18px;
    }
    &__details-block:last-child {
      margin-top: 30px;
    }

    &__info {
      flex-direction: column;
    }
    &__info-block {
      width: 290px;
      margin-left: 0;

      &_prizes {
        padding: 30px 35px 30px 35px;
      }
    }

    &__info-block_score {
      padding: 30px 25px 30px 20px;
    }
    &__details-text {
      width: 290px;
    }
    &__timer-wrap {
      width: 240px;
    }
  }
}

