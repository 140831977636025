$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.search-cols {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 6px;

  @include bp-1440 {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 8px;
    margin-top: 12px;
  }
}
