$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.home-tournaments {
  display: block;
  height: 100%;

  &__list {
    height: 100%;
    background-color: #ffc051;
    background-image: url("/img/widg-tournaments-text-back.svg"), url($cdn + "/yoyo/widget-tournaments-back.jpg");
    background-repeat: no-repeat, no-repeat;
    background-size: auto 100%, cover;
    background-position: right -100px center, center;
    border-radius: 10px;
  }

  &__item {
    width: 100%;
    height: 100%;
    max-width: 100%;
    border-radius: 10px;
    box-sizing: border-box;
    .slick-list, .slick-track, .slick-slide>div {
      height: 100%;
    }
    & .slick-dots {
      position: absolute;
      left: 20px;
      bottom: 20px;
      display: flex;
      margin-right: 10px;
      li {
        margin-right: 10px;
        button {
          padding: 0;
          width: 10px;
          height: 10px;
          background: rgba(249, 251, 231, 0.5);
          border-radius: 50%;
          font-size: 0;
          border: none;
          cursor: pointer;
        }
        &.slick-active {
          button {
            background-color: #ffffff;
          }
        }
      }
    }
  }

  &__item-content {
    position: relative;
    display: flex !important;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: flex-end;
    padding: 30px 20px 20px 20px;
    border-radius: 10px;
  }
  &__status {
    width: 100px;
    position: absolute;
    top: 19px;
    right: -21px;
    border-bottom: 20px solid #35d290;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    height: 0;
    color: #fff;
    transform: rotate(45deg);
  }
  &__status-text {
    display: block;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    line-height: 21px;
    text-align: center;
    @include text-dots();
  }
  &__title {
    max-width: 100%;
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    color: #3f3f3f;
    margin: 0 12px 25px 0;
    text-align: right;
    @include text-dots();
  }
  &__timer {
    margin-bottom: 18px;
  }
  &__prize-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    margin-right: 12px;
    & svg {
      width: 26px;
      height: 30px;
      margin-right: 8px;
    }
  }
  &__prize-title {
    max-width: 193px;
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    color: #3e3e3e;
    @include text-dots();
    text-align: right;
  }
  &__prize {
    width: 228px;
    font-size: 32px;
    font-weight: bold;
    line-height: normal;
    color: #3e3e3e;
    margin: 0 12px 30px 0;
    @include text-dots();
    text-align: right;
  }
  &__link {
    min-width: 150px;
    max-width: 230px;
  }

  @include l {
    &__item-content {
      padding: 20px;
    }
    &__status {
      top: 12px;
      right: -26px;
    }
    &__title {
      margin-bottom: 14px;
    }
    &__timer {
      margin-bottom: 15px;
    }
    &__prize {
      margin-bottom: 12px;
    }
  }

  @include s() {
    max-width: 100%;
    &__item-content {
      padding: 20px 30px;
    }
    &__item {
      max-width: 100%;
    }
    &__list {
      background-position: right center, center;
    }
  }

  @include xs() {
    &__item-content {
      padding: 20px;
    }
  }

  @include xxs() {
    &__list {
      background-image: none;
      text-align: center;
    }
    &__status {
      top: 14px;
      right: -25px;
    }
    &__item-content {
      padding: 20px 30px;
      align-items: center;
      background-color: #ffc051;
    }
    &__title {
      margin-right: 0;
    }
    &__prize-wrap {
      margin-right: 0;
    }
    &__prize {
      margin-right: 0;
      text-align: center;
    }
    &__link {
      margin-right: 0;
    }
  }
}



