$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
// --- GLOBAL VARIABLES
html {
  --rg-bar-height: 28px;
}

[disabled],
a.is-disabled {
  pointer-events: none;
  cursor: default;
}

body {
  html.linda-has-modal &,
  html.linda-has-popup & {
    overflow: hidden !important;
    position: relative;

    &::before {
      content: '';
      display: block;
      @include absolute-cover;
      background-color: rgba($black, .8);
      z-index: 998;
    }
  }
}
