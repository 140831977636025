$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.item-limit {
  height: 258px;
  padding: 20px 16px;
  background-color: #FAEAB9;
  border-radius: 12px;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.is-self-ctrl {
    width: 420px;
    margin-right: auto;
    margin-left: auto;
  }

  &__text {
    @include text(16px, 24px, 400, #3E3E3E);
    @include ellipsis;
  }

  &__value {
    @include text(24px, 28px, 700, #3E3E3E);
    @include ellipsis;
    padding-bottom: 8px;
  }

  &__btn {
    display: block;
    width: 100%;
    margin-top: 12px;
  }

  &__link {
    margin-top: 20px;
    color: #fff !important;
  }

  &__notice {
    @include text(12px, 14px, 400, #3E3E3E);
    @include text-lines-clip(2);
  }

  &__span {
    text-transform: lowercase;
  }

  &__form {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin-top: 12px;
  }

  &__wrapper {
    margin-top: auto;
  }

  & form:not(.set-editmode) .show-on-editMode {
    display: none
  }

  & form.set-editmode .hide-on-editMode {
    display: none
  }


}
