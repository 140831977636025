$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.promo-info {
  max-width: 768px;
  margin: 30px auto 0;
  color: #3f3f3f;
  font-size: 14px;
  width: 100%;

  & p {
    line-height: 1.76;
  }

  & table {
    width: 100%;

    & td:first-child {
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
      text-align: left !important;
      padding-left: 20px;
      height: 36px;
    }

    & td:last-child {
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
      text-align: right !important;
      padding-right: 20px;
    }

    & tr:nth-child( 2n - 1 ) td {
      background-color: rgba(220, 219, 202, .5);
    }
  }

  & ol {
    margin-bottom: 10px;
  }

  & ul {
      list-style: disc;
  }

  & ol,
  & ul {
      margin-left: 28px;
  }

  & ol li {
    font-size: 14px;
    line-height: 25px;
    color: #3F3F3F;
  }

  &__terms {
    & a {
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__text-main-title {
    font-size: 32px;
    font-weight: 900;
    color: #9846a5;
    text-align: center;
    margin-bottom: 30px;
  }

  &__text-sub-title {
    font-size: 24px;
    font-weight: 900;
    color: #66554f;
    margin-bottom: 20px;
    text-align: center;
  }

  &__list {
    font-size: 14px;
    color: #3f3f3f;
    line-height: 1.2;
  }

  @include m {
    &__text-main-title {
      font-size: 24px;
      margin-bottom: 25px;
    }
    &__text-sub-title {
      margin-bottom: 15px;
    }
  }
  @include s {
    &__left-wrap {
      width: 240px;
    }
    &__text-info-wrap {
      margin: 20px auto 30px;
    }
  }
}