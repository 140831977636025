$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.christmas-today-bonus {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  background: #ffd180 url($cdn + "/yoyo/christmas/christmas_bg_snowflakes.png") center top no-repeat;
  padding: 30px 25px;
  box-sizing: border-box;
  text-align: center;
  color: #3f3f3f;
  font-size: 16px;

  &__date-text {
    font-weight: bold;
    display: none;
    margin-bottom: 5px;
  }

  &__name {
    font-size: 30px;
    font-weight: bold;
    margin: 26px 0 23px;
  }

  &__pic {
    width: 140px;
    height: 140px;
    margin: 13px auto 17px;
    color: #9846a5;
    background: url('/img/christmas2019/christmas-day_open.svg') center no-repeat;
    background-size: cover;
    font-weight: 700;
    padding-top: 23px;
  }


  // SPECIAL DAYS {
  &__pic_25-12, &__pic_26-12, &__pic_31-12, &__pic_7-1, &__pic_14-1 {
    background: url('/img/christmas2019/christmas-day_special_open.svg') center no-repeat;
    background-size: cover;
    color: #d78e00;
  }

  &__date {
    font-size: 40px;
    font-weight: 900;

  }

  &__month {
    font-size: 22px;
    font-weight: 900;
    margin-top: -4px;
  }

  &__desc {
    margin-bottom: 14px;
    line-height: 1.41;

  }

  &__timer {
    margin: 12px 0 30px;
  }

  &__timer-label {
    font-weight: bold;
  }


  &__btn-label {
    font-size: 24px;
    font-weight: bold;
  }


  &__btn {
    margin: 22px auto 21px;
    width: 100%;
    max-width: 300px;
    padding: 0px 10px;
    font-size: 24px;
    height: 60px;
    line-height: 60px;
    border-radius: 80px;
  }

  &__rules {
    font-weight:bold;
    text-decoration: underline;
  }

  @include m {
    max-width: 300px;
  }

  @include s {

    max-width: 400px;
    padding: 30px;

    &__pic, &__desc, &__btn-label, &__rules  {
      display: none;
    }

    &__date-text {
      display: block;
    }

    &__name {
      font-size: 24px;
      margin: 18px 0 11px;
    }

    &__timer {
      margin: 3px 0 14px;
    }

    &__timer-label {
      font-weight: normal;
    }

    &__btn {
      margin: 0 auto;
    }
  }

  @include xs {
    max-width: 100%;
  }

}

.christmas-timer {

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;

  &__num {
    width: 50px;
    height: 35px;
    line-height: 35px;
    border-radius: 3px;
    background: rgba(63, 63, 63, 0.5);
  }

  &__dots {
    color: rgba(63, 63, 63, 0.5);
    margin: 0 4px;
  }

  @include s {

    font-size: 16px;
    color: #ED612B;

    &__num {
      background:none;
      width: auto;
      height: auto;
      line-height: normal;
    }

    &__dots {
      color: #ED612B;
      margin: 0;
    }

  }
}
