$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.promo-item {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 54px 24px 24px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 60% 0;
    border-radius: 10px;
    border: 1px solid #00BE6E;

    &::before {
        content:'';
        position: absolute;
        top: 50%;
        right: 22px; 
        transform: translateY(-50%);
        width: 227px;
        height: 232px;
        background: #E6E9D0;
        box-shadow: inset 0px 2.68641px 2.68641px rgba(0, 0, 0, 0.25);
        border-radius: 100%;
    }
    &__img {
        position: absolute;
        top: 50%;
        right: 22px; 
        transform: translateY(-50%); 
        width: 232px;
        height: 232px;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &__content {
        flex-grow: 1;
        display: flex; 
        flex-direction: column;
        max-width: 277px;
    }
    &__title {
        margin-bottom: 16px;
        font-weight: bold;
        font-size: 24px;
        color: #3F3F3F;
    }
    &__description {
        margin-bottom: 16px;
        color: #00BE6E;
        font-weight: 900;
        font-size: 36px;
        @include text-lines-clip(3);
    }
    &__button {
        min-width: 212px;
        background: #35D290;
        &:hover {
            background-color: #01c02a;
        }
    }
    &__btns {
        max-width: min-content;  
        display: flex; 
        flex-direction: column;
        align-items: flex-start;
        margin-top: auto;
    }
    &__link{
        margin-top: 12px;
        align-self: center;
    }
    &__text {
        z-index: 1;
        align-self: start;
        margin-top: 25px;
        background: #F9FBE7;
        border-radius: 50px;
        padding: 10px;
        font-size: 10px;
        color: #3F3F3F;
    }

    @include m {
        padding: 24px 14px 14px;
        &::before {
            right: 14px; 
            width: 170px;
            height: 170px;
        }
        &__img{
            right: 14px; 
            width: 170px;
            height: 170px;
        }
        &__content {
            max-width: 240px;
        }
        &__title {
            font-size: 16px;
        }
        &__description {
            font-size: 24px;
        }
        &__button {
            min-width: unset;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
        }
        &__text {
            margin-top: 10px;
        }
    }
}