$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.popup-get-bonus {

  background-image: url('/img/get-bonus-popup_top-bg.svg'), url('/img/get-bonus-popup_bottom-bg.svg');
  background-repeat: no-repeat, no-repeat;
  background-position: top right, bottom left;

  &__list {
    padding: 0 0 0 17px;
    margin:0;
    font-size: 14px;
    line-height: 1.76;
    color: #3e3e3e;
  }

  &__list-item {
    padding-left: 5px;
  }

  &__btn {
    width: 100%;
    min-width: 100%;
    margin: 15px 0;
  }

  &__link {
    display: block;
    text-align: center;
  }
}
