$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.btn-bankid{

    position: relative;
    cursor: pointer;
    background-color: #01C02A;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    border-radius: 40px;
    height: 48px;
    font-weight: 700;
    font-size: 22px;
    line-height: 48px;
    color: #FFFFFF;
    border: none;
    padding: 0 10px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    width: 268px;
    max-width: 100%;

    &:hover{
        background-color: #20cb4a;
    }

    &.no-animate{
        background-image: var(--icon-btn-arrow);
        background-position: right 20px center;
        background-repeat: no-repeat;
        background-size: 32px;
    }

    &__text{
        display: block;
        flex-grow: 1;
        margin: 0 40px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__pay{
        position: absolute;
        left: 10px;
        top: 12px;
        width: 24px;
        height: 24px;
        background: var(--icon-bankid) no-repeat center / contain ;
        transform: translateX( 10px );
    }

    &__arr{
        position: absolute;
        right: -2px;
        top: 8px;
        width: 32px;
        height: 32px;
        background: var(--icon-btn-arrow) no-repeat center / contain ;
        transform: translateX( -10px );
    }

}