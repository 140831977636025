.dvs-alert {
  display: none;
  min-height: 50px;
  width: 100%;
  background-color: rgba(218, 36, 36, 1);
  color: #ffffff;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  transform: translateZ(0);

  font-size: 14px;
  line-height: 20px;
  font-weight: 600;

  &:before {
    width: 26px;
    height: 26px;
    flex-shrink: 0;
    display: block;
    content: '';
    background: url($cdn + '/dvs/verification-status/non-verified.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 12px;
  }

  &.is-verified {
    background-color: rgba(69, 184, 50, 1);
    &:before {
      background: url($cdn + '/dvs/verification-status/verified.svg') no-repeat;
    }
  }

  &.is-verification-changed {
    display: flex;
  }

  &__text {
    margin: 0;
    padding: 0;
    display: -webkit-box;
    overflow-wrap: break-word;
    line-clamp: 7;
    -webkit-line-clamp: 7;
    overflow: hidden;
    /*! autoprefixer: off */
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    /*! autoprefixer: on */
  }

  &__link {
    text-decoration: underline;
    color: #ffffff;
    font-weight: 600;
    padding: 0 4px;
  }

  &__icon {
    width: 26px;
    aspect-ratio: 1;
    margin-right: 12px;
    filter: brightness(0) invert(100%);
  }

  &__icon-verified {
    width: 26px;
    aspect-ratio: 1;
    margin-right: 12px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #fff;
  }

  &__button {
    appearance: none;
    background-color: transparent;
    border: none;
    border-radius: 0;

    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px;
    flex: 0 0 auto;
    margin-left: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    filter: invert(100%);

    &.is-arrow {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTYgMTYiICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgPHBhdGggZD0iTTQuNzgzNzggMTUuMzU3QzUuNjY1MzggMTYuMiA2Ljk5NjkzIDE2LjIxNTkgNy44OTYwMSAxNS4zOTQxTDE0LjY4OSA5LjE4NDk0QzE1LjM3MDYgOC41NjE5MiAxNS4zNzA2IDcuNDM4MDggMTQuNjg5IDYuODE1MDZMNy44OTYwMSAwLjYwNTkyQzYuOTk2OTMgLTAuMjE1ODg3IDUuNjY1MzggLTAuMjAwMDEgNC43ODM3OCAwLjY0MzAzMUMzLjcxMzE3IDEuNjY2ODIgMy43NDQwOSAzLjQ2NDQgNC44NDkyIDQuNDQ1NjlMOC43NjE4NiA3LjkxOTk4QzguNzg0MTUgNy45Mzk3NyA4Ljc5NzAzIDcuOTY5MDkgOC43OTcwMyA4QzguNzk3MDMgOC4wMzA5MSA4Ljc4NDE1IDguMDYwMjMgOC43NjE4NiA4LjA4MDAyTDQuODQ5MiAxMS41NTQzQzMuNzQ0MDkgMTIuNTM1NiAzLjcxMzE3IDE0LjMzMzIgNC43ODM3OCAxNS4zNTdaIiAvPgo8L3N2Zz4K');
    }

    &.is-close {
      background-image: url('#{$cdn}/dvs/i-close-rounded.svg');
      background-repeat: no-repeat;
      background-position: center;
      filter: brightness(0) invert(100%);
    }
  }
  @media (max-width: 540px) {
    &__wrap {
      justify-content: space-between;
    }
  }
}