$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.bank-id {
  &__frame {
    --btn-radius: 100px;
    --btn-radius-hover: 100px;
    --divider-text-bg: #ffd180; 
    --dialog-title-weight: 800;
    --dialog-title-color: #3E3E3E;
    --divider-bg: rgba(63, 63, 63, .2);
    --description-text-color: #66554F;
    --divider-text-color: #66554F;
    --btn-weight: 700;
  }
}

.g-input-bankid {
  background-color: rgb(90, 74, 65);
  border-radius: 30px;
  height: 60px;
  text-shadow: rgba(0, 0, 0, 0.25) 0 4px 0;
  font-size: 36px;
  font-weight: 900;
  color: rgb(255, 255, 255);
  text-align: center;
  line-height: 60px;
  padding: 0 50px;
  border: none;
  &:hover {
    background-color: rgba(90, 74, 65, 0.7);
  }
  &:focus {
    background-color: #f9fbe7;
  }
  &:focus + span {
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
    color: #66554f;
  }
  &.ng-invalid.ng-touched {
    background-color: #f9fbe7;
    border: 2px solid #ea4642;
    color: #3f3f3f;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
    & + span {
      text-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
      color: #66554f;
    }
  }
  &.ng-valid {
    border: none;
  }
  &.ng-valid.ng-touched {
    background-color: #f9fbe7;
    border: 2px solid #35d290;
    color: #3f3f3f;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
    & + span {
      text-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
      color: #66554f;
    }
  }
  @include xs {
    width: 290px;
  }
}


.bankid-enter-amount-field {
  min-height: 82px;
  text-align: center;
  &__label {
    position: relative;
    display: block;
    width: 304px;
    height: 60px;
    margin: 0 auto;
  }
  &__cur {
    position: absolute;
    text-shadow: 0 4px 0 #00000040;
    font-size: 18px;
    font-weight: 900;
    color: #ffffff;
    z-index: 3;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
  }
}


.bankid-enter-amount-error {
  width: 304px;
  margin: 0 auto;
  padding: 3px 20px 0;
  text-align: center;
  &__text {
    color: #F9FBE7;
    font-size: 12px;
    line-height: normal;
    width: 100%;
    max-width: 100%;
  }
}



.bankid-btns-choice-amount {
  display: flex;
  justify-content: space-between;
  &__btn {
    min-width: 124px;
    height: 45px;
    font-size: 18px;
    line-height: 45px;
    color: #66554f;
    border-radius: 10px;
    background-color: #f9fbe7;
    transition: background-color .2s;
    border: none;
    cursor: pointer;
    font-weight: 600;
    margin-right: 15px;
    padding: 0 7px;
    white-space: nowrap;
    &:last-child {
      margin: 0;
    }
    &:hover {
      background-color: #ffd180;
    }

    &.active {
      color: #fff;
      background-color: #ffa800;
      cursor: default;
    }
  }
  @include xs {
    margin: 0 -7px 15px;
  }
  @include xxs {
    &__btn {
      min-width: 94px;
      height: 37px;
      font-size: 16px;
      line-height: 37px;
      margin-right: 5px;
    }
  }
}

