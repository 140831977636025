$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.timer {
  display: flex;

  &__label {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: #3f3f3f;
    display: none;
  }

  &__num {
    width: 50px;
    height: 35px;
    border-radius: 3px;
    background: rgba(63, 63, 63, 0.5);
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    color: #ffffff;
    margin-right: 14px;
    padding: 3px 11px;
    position: relative;

    &:nth-child(4):after, &:nth-child(5):after {
    display: block;
    content: ":";
    color: rgba(63, 63, 63, 0.5);
    position: absolute;
    right: -10px;
    top: 2px;
    }

    &:nth-child(2) {
      background: rgba(63, 63, 63, 0.7);
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.timer_mini {

  .timer__label {
    display: block;
  }
  .timer__num {
    width: auto;
    height: auto;
    border-radius: none;
    background: none;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: #3f3f3f;
    margin-right: 0;
    padding: 0;

    &:nth-child(4):after, &:nth-child(5):after {
      display: block;
      content: ":";
      color: #3f3f3f;
      position: absolute;
      right: 2px;
      top: 0;
    }
  }

  @include xs {
    .timer__label, .timer__num {
      color: #f9fbe7;
    }

    .timer__num:nth-child(4):after, .timer__num:nth-child(5):after {
      color: #f9fbe7;
    }
  }
}
