$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-balance {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 30px 30px;
  display: flex;
  border: solid 1px #00be6e;
  border-radius: 10px;
  overflow:hidden;

  &__field {
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    color: #3f3f3f;
    margin-top: 20px;
    margin-right: 40px;
    min-width: 132px;
  }

  &__field:last-child {
    margin-right: 0;
    flex-shrink:2;
  }

  &__key {
    max-width: 80%;
    @include text-dots;
  }

  &__value {
    color: #00be6e;
    margin-left: 10px;
    @include text-dots;
  }

  &__update-btn {
    width: 13px;
    height: 14px;
    margin-left: 5px;
    flex-shrink:0;
    cursor:pointer;
    & svg {
      fill: #35d290;
      width: 100%;
      height: 100%;
    }
    &.linda-has-preloader {
      animation: spin 0.8s infinite linear;
    }
  }

  &.cahbox-withdraw__balance {
    margin-bottom: 20px;
  }

  @include s {

    & {
      flex-wrap:wrap;
    }

    .profile-details__balance & {
      flex-direction: column;
      justify-content: center;
    }
     .profile-details__balance   &__field {
        margin-right: 0;
       justify-content: flex-start;
      }
    }

  @include xs {

    &.cahbox-withdraw__balance {
      margin: 20px 0;
    }

    .profile-details__balance & {
      flex-direction: row;
      justify-content: flex-start;
    }
    .profile-details__balance   &__field, &__field {
      margin-right: 30px
    }
    .profile-details__balance   &__field:last-child, &__field:last-child{
      margin-right: 0;
    }
  }
}
.se-cachbox-balance {
  text-align: center;
  &__refresh-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__wrap {
    margin-top: 10px;
  }
  &__refresh {
    width: 13px;
    height: 14px;
    margin-right: 5px;
    flex-shrink:0;
    cursor:pointer;
    & svg {
      fill: #35d290;
      width: 100%;
      height: 100%;
    }
    &.animate {
      animation: spin 0.8s infinite linear;
    }
  }
  &__refresh-text {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.79;
    color: #3f3f3f;
  }
  &__key {
    font-size: 16px;
    font-weight: bold;
    color: #3f3f3f;
    margin-bottom: 10px;
  }
  &__value {
    font-size: 24px;
    font-weight: bold;
    color: #00be6e;
  }
  &__wrap-balance {
    margin-top: 25px;
  }
  @include s {
    &__wrap-value {
      display: flex;
      justify-content: center;
    }
    &__refresh-text {
      display: none;
    }
    &__wrap-balance {
      margin-top: 15px;
    }
    &__refresh-wrap {
      margin-left: 10px;
    }
    &__vip {
      width: 100%;
    }
  }
  @include xs {

    &__wrap {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__key {
      margin: 0;
    }
    &__value {
      margin-left: 18px;
    }
  }
  @include xxs {
    &__value {
      font-size: 16px;
      margin-left: 5px;
    }
  }
}

.se-profile-balance {
  justify-content: center;
}

.cash-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #3E3E3E;
  padding-bottom: 8px;
}

.cash-wrap {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-areas:
    "title title"
    "left right";
  width: 598px;
  margin: 0 auto;
  grid-column-gap: 18px;


  &.is-dep {
    margin-top: 11px;
    grid-column-gap: initial;

    .cash-bonus,
    .self-title {
      display: none;
    }

    .cash-balans {
      min-width: 595px;
      display: flex;
      justify-content: center;

      &__image {
        margin-left: auto;
      }

      &__total {
        margin: 0 auto;
      }



      &__total,
      &__total-bonus {

        p {
          padding-left: 8px;
        }
      }
    }
  }
}

.cash-bonus {
  background: #00BE6E;
  border-radius: 10px;
  padding: 3px 4px;
  max-width: 290px;
  width: 100%;
  display: flex;
  grid-gap: 7px;
  align-items: center;
  justify-content: space-between;

  &__title {
    font-weight: 900;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF;
    max-width: 162px;
  }

  &__title-sub {
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
  }
}

.cash-balans {
  background: rgb(237, 238, 219);
  border-radius: 10px;
  padding: 12px;
  width: 100%;
  min-width: 290px;

  grid-area: right;
  display: grid;
  grid-template-columns: .8fr .2fr;
  grid-template-rows: .8fr .2fr;
  align-items: center;
  gap: 0 0;
  grid-template-areas:
    "area_1 area_3"
    "area_2 area_3";

  &__total {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #3E3E3E;
    display: flex;
    grid-area: area_1;
    justify-content: space-between;
  }

  &__total-bonus {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #3E3E3E;
    display: flex;
    grid-area: area_2;
    justify-content: space-between;
  }

  &__image {
    grid-area: area_3;
    margin-left: 16px;
    cursor: pointer;

    &.linda-has-preloader {
      transform: rotate(380deg);
      transition: .5s;
    }
  }

  p {
    margin: 0;
  }
}


.self-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #3E3E3E;
  padding: 16px 0 8px;
  margin: 0;
  grid-area: title;
}