$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.info-page {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.76em;
  color: $dark;

  &__container {
    max-width: 730px;
    margin: 0 auto;
  }

  &__wrap {
    padding: 32px 0 40px;
  }

  & ul:not(.accordion) {
    padding-left: 35px;
    list-style-type: disc;
    margin-bottom: 32px;
  }

  & li:not(.accordion__item) {
    font-size: 14px;
    line-height: 1.76;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & h1{
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
    color: $violetDark;
    margin: 0 0 20px;
  }

  & h2{
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    color: $violetDark;
  }
  & a{
    color: $dark;

    &:hover {
      color: $violetOld;
    }
  }

  & p {
    > span {
      font-weight: 700;
      margin-right: 5px;
    }
  }
  & table {
    width: 100%;

    td, th {
      border: 1px solid #3f3f3f;
    }

    tr>td:nth-child(3) {
      word-break: break-word;
    }

    & td {
      padding: 5px;
      vertical-align: top;
    }
  }
  &__table-wrap {
    overflow: auto;
  }
  ol {
    padding:0;
    counter-reset:item;
    list-style-type:none;

    > li {
      margin:0;
      padding:0 0 0 18px;
      text-indent: -18px;

      counter-increment:item;

      &:before {
        content: counter(item) ".";
        width: 20px;
        padding-right: 5px;
        font-weight: bold;
      }
    }
  }

  @include m {
    &__container {
      max-width: 100%;
      padding: 0 20px;
    }
  }

  @include xs {
    &__container {
      padding:  0 15px;
    }

    & h1 {
      line-height: 1.52;
    }
  }
  .text-just {
    text-align: justify;
  }
}

/*------------------------------
============= faq__a ============
-------------------------------*/
.accordion__item {
  margin-bottom: 10px;
  &.open {
    .faq__q {
      font-size: 18px;
      font-weight: 700;
      background-color: rgba(255,209,128, 0.5);

      &:before {
        transform: rotate(-180deg) translateY(-50%);
        margin-top: -15px;
        right: 18px;
      }
    }
    .faq__a {
      display: block;
      background: transparent;
      padding: 5px 32px;
    }
  }
}

.faq {
  &__q{
    border-radius: 25px;
    background-color: rgba(255,209,128, 0.2);
    padding: 10px 40px 10px 30px;
    cursor:pointer;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.41;
    margin:0;
    position: relative;
    min-height: 50px;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    &:hover {
      background-color: rgba(255,209,128, 0.5);
    }

    &:before {
      content: '';
      display: block;
      background: url(/img/arr_b-dark.svg) no-repeat 100% 50%;
      width: 15px;
      height: 15px;
      background-size: 11px 7px;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: rotate(0deg) translateY(-50%);
      transition: all 0.2s ease;
    }
  }

  &__a{
    display:none;
    font-size: 14px;
    line-height: 1.76;
  }
}
