$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.page-confetti{
  position: relative;
  width: 100%;
  height: 1px;

  &::before {
    content: '';
    mask-image: url(/img/mask.svg);
    mask-position: center bottom;
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
    background-image:
            url($cdn + '/yoyo/blob.svg'),
            linear-gradient(#ffeaa1 0, #ffc600 100%);
    background-repeat: no-repeat;
    background-position:
            center top -100px,
            center;
    background-size:
            1100px auto,
            100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
    z-index: -1;
  }

  &__img{
    &::before,
    &::after{
      content: '';
      display: block;
      width: 447px;
      height: 120px;
      position: absolute;
      top: 0;
      background: url($cdn + '/yoyo/bnr-confetti.png') no-repeat center top / 100% auto ;
    }
    &::before{
      right: calc(50% + 85px);
      transform: scale( -1 , 1);
    }
    &::after{
      left: calc(50% + 85px);

    }
  }
}
