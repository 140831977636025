$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.bankid-banner-icons {
  &__list {
    display: flex;
    justify-content:space-between;
  }
  &__item {
    width: 86px;
    height: 23px;
    margin: 0 10px;
  }
  &__img {
    width: 100%;
    height: 100%;
    font-size: 10px;
    word-break: break-all;
  }
  @include xs {
    &__list {
      justify-content: center;
    }
    &__item {
      margin: 0 7px;
      width: 60px;
      height: 18px;
    }
  }
  @include xxs {
    &__item {
      &:nth-child(n+5) {
        display: none;
      }
    }
  }
}