$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.meta-box {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 44px;
  border-radius: 10px;
  border: 1px solid rgba(255, 190, 23, 0.80);
  background-color: #FFF5DA;
  padding: 12px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include text(14px, 140%, 700, rgba(65, 48, 52, 0.80));


  &:hover {
    box-shadow: 0 4px 10px 0 rgba(118, 103, 97, 0.20);
  }

  &__content {
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    width: calc(100% - 28px);
  }

  &__icon {
    flex-shrink: 0;
    display: block;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    filter: brightness(0%) invert(36%) sepia(7%) saturate(840%) hue-rotate(322deg) brightness(92%) contrast(89%);
  }

  &__text {
    @include ellipsis;
  }

  &__count {
    position: relative;
    z-index: 1;
    flex-shrink: 0;
    width: 28px;
    text-align: right;
    color: rgba(65, 48, 52, 0.50);
    font-weight: 400;
  }
}
