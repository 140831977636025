$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.autobonus-popup {

  //max-width: 670px;

  &__bonus-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -14px;
  }

  &__bonus-item {
    margin: 30px 14px 0;
  }
  &__btn {
    text-align: center;
    margin-top: 10px;
  }
  @include xxs {
    padding:30px 15px 35px;
    &__bonus-item {
      margin: 15px 0 0;
    }
  }
}
