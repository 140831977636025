$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.session-limit-end-modal {
  &__sub-title {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 1.76;
    color: #3e3e3e;
    font-weight: 500;
    text-align: left;
    & span.bold {
      font-weight: 600;
    }
  }
  &__text {
    margin-bottom: 20px;
  }
  &__btn{
    margin-bottom: 20px;
  }
  @include xs {
    &__modal-box {
      width: 320px;
      padding: 30px 15px 40px;
    }
  }
}