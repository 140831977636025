$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.games-dashboard-small {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 12px;

  &__item {
    margin: 0 !important;
    width: 100% !important;

    &.is-big-first {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
    }
  }

  &__btn-wrap {
    text-align: center;
    padding-top: 10px;
    grid-column-start: 1;
    grid-column-end: 6;
  }

  &__btn {
    min-width: 200px;
  }

  @include bp-1440 {
    &__item {
      &:nth-of-type(n + 9) {
        display: block;
      }
    }
  }
}
