$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-session-limit {
  &__title {
    font-size: 16px;
    font-weight: 600;
    color: #3f3f3f;
    margin-bottom: 20px;
  }
  &__form-box {
    display: flex;
    max-width: 395px;
    margin: 0 auto 20px;
  }
  &__input-wrap {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
  }
  &__input {
    width: 190px;
    text-align: left;
  }
  &__btns-wrap {
    width: 290px;
    margin: auto;
  }
  &__save {
    display: none;
  }
  &__infoline {
    margin-top: -10px;
    font-size: 12px;
    color: #3f3f3f;
    margin-bottom: 10px;
  }
  &__infoline-text {
    font-weight: 600;
  }
  &__link {
    margin-top: 15px;
  }
  &__select {
    background-color: #fff;
  }
  @include xxs {
    &__input-wrap {
      margin: 0;
      width: 100%;
      margin-bottom: 20px;
    }
    &__input {
      width: 100%;
    }
    &__form-box {
      flex-direction: column;
      max-width: 250px;
    }
    &__btns-wrap {
      width: 250px;
      & button {
        width: 100%;
        min-width: auto;
      }
    }
  }
}

.set-editmode {
  .profile-session-limit__change {
    display: none;
  }
  .profile-session-limit__save {
    display: block;
  }
}
.set-editmode.ng-invalid .self-control__btn-save {
  opacity: .6;
}