$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
//- xs - font-size 12px (default)
//- s - font-size 14px
//- m - font-size 16px
//- l - font-size 18px

.btn-link {
  --btn-link-fs: 12px;
  @include reset-btn;
  max-width: 100%;
  display: inline-block;
  vertical-align: top;
  text-decoration: underline;
  @include roboto;
  @include ellipsis;
  @include text(var(--btn-link-fs), inherit, 500, currentColor);
  transition: color 0.2s ease-in-out;

  &:hover {
    text-decoration: none;
  }

  &__svg {
    display: block;
    width: var(--btn-link-fs);
    height: var(--btn-link-fs);
    @include absolute-center-y;
    right: 0;
    fill: currentColor;
    margin-top: 1px;
    flex-shrink: 0;
    transition: transform .2s ease-in-out;

    .has-arrow.is-opened & {
      transform: rotate(-180deg) translateY(50%);
      margin-top: 0;
    }
  }

  &.is-white {
    color: rgba($white, .85);

    &:hover {
      color: $white;
    }

    &.is-active {
      color: $white;
    }
  }

  &.is-s {
    --btn-link-fs: 14px;
    line-height: 25px;
  }

  &.is-m {
    --btn-link-fs: 16px;
    line-height: 27px;
  }

  &.is-l {
    --btn-link-fs: 18px;
    line-height: 27px;
  }

  &.has-arrow {
    position: relative;
    overflow: visible;
    padding-right: calc(var(--btn-link-fs) + 6px);
  }

  &.is-lh-inherit {
    line-height: inherit;
  }
}


