$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
//- s - height 36px (default)
//- m - height 40px
//- l - height 60px

.btn-fill {
  --btn-fill: transparent;
  --btn-fill-active: transparent;

  @include reset-btn;
  box-sizing: border-box;
  background-color: var(--btn-fill);
  max-width: fill-available;
  @include roboto;
  @include btn-modify(36px, 20px, 14px, 60px, 1px);
  border: 1px solid var(--btn-fill);
  display: inline-block;
  vertical-align: top;
  font-weight: 700;
  color: $white;
  @include ellipsis;
  text-align: center;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  cursor: pointer;

  &__icon {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -0.1em;
    width: 1.286em;
    height: 1.286em;
    margin-right: .2857em;
    fill: currentColor;
  }

  &.is-green {
    --btn-fill: #{$green};
    --btn-fill-active: #{$green-dark};
  }

  &.is-violet {
    --btn-fill: #{$violet};
    --btn-fill-active: #{$violet-dark};
  }

  &.is-red {
    --btn-fill: #{$red};
    --btn-fill-active: transparent;

    &:hover {
      border-color: var(--btn-fill);
      color: var(--btn-fill);
    }
  }

  &.is-m {
    @include btn-modify(40px, 30px, 16px, 80px, 1px);
  }

  &.is-l {
    @include btn-modify(60px, 32px, 24px, 80px, 1px);
  }

  &:hover {
    background-color: var(--btn-fill-active);
    border-color: var(--btn-fill-active);
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.20);
  }

  &[disabled],
  &.is-disabled {
    opacity: 0.6;
  }

  &.is-full {
    width: 100%;
  }
}
