$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
linda-slider-main {
    display: block;
    position: relative;
    z-index: 1;
}

.main-slider {
  min-height: 200px;
  position: relative;
  z-index: 1;
  &__slide-wrap {
    background-size: cover;
  }
  &__inner {
    justify-content: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #FFD07E;
    position: relative;
  }

  &__inner-content {
    text-align: center;
    line-height: normal;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: 450px;
    align-items: center;
    background: url(/img/slider-text-background.svg) no-repeat top center;
    padding-bottom: 111px;
    padding-top: 40px;
    background-origin: content-box;
  }
  &__title1 {
    font-size: 24px;
    font-weight: 700;
    color: #ffd180;
    margin-bottom: 3px;
    max-height: 102px;
    overflow: hidden;
  }

  &__title2 {
    color: #ffffff;
    font-weight: 900;
    font-size: 60px;
    margin-bottom: 12px;
    max-height: 65px;
  }

  &__title3 {
    font-size: 36px;
    font-weight: 900;
    line-height: normal;
    color: #f9fbe7;
    min-height: 17px;
  }
  &__title1,
  &__title2,
  &__title3 {
    text-shadow: 0 4px 0 rgba(0, 0, 0, 0.25);
    overflow: hidden;
  }
  &__btn-sign{
    border: solid 1px #ffffff;
    display: none;
    margin-top: 15px;
  }
  &__btn {
    box-sizing: border-box;
    position: relative;
    margin-top: 20px;
  }

  .slick-dots {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 72px;
    width: 100%;
    & li {
      padding: 8px 0;
      margin: 2px 5px;
      cursor: pointer;
      line-height: 0;
    }
    & button {
      width: 10px;
      height: 10px;
      border-radius: 50%;

      border: 2px solid transparent;
      background: $dark;
      cursor: pointer;
      font-size: 0;
      padding:0;
      transition: background .2s;
      opacity: 0.5;
    }
    & li:hover button {
      background-color: $dark;
      opacity: 1;
    }
    & .slick-active button {
      width: 10px;
      height: 10px;
      background: $dark;
      opacity: 1;
    }
  }
  @include m {
    &__inner-content {
      height: 430px;
      padding-bottom: 96px;
    }

    .slick-dots {
      bottom: 55px;
    }
  }

  @include s {
    &__title2 {
      font-size: 50px;
    }
    &__inner-content {
      height: 390px;
      padding-bottom: 56px;
    }
    .slick-dots {
      bottom: 16px;
    }
  }

  @include xs {
    &__inner {

      &:before {
        background-color: rgba(63, 63, 63, 0.7);
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
      }
    }
    &__inner-content {
      width: 100%;
      z-index: 1;
      height: 340px;
      background: none;
      position: relative;
      padding: 15px;
      justify-content: center;
      overflow: hidden;
    }

    &__title2 {
      margin-bottom: 10px;
    }

  }
  @include xxs {
    &__inner-content {
      padding: 15px 15px 40px;
      justify-content: flex-end;
    }
    &__inner {
      background-position-x: calc(50% + 270px);
    }
    &__title1 {
      font-size: 18px;
      margin-bottom: 10px;
      max-height: 40px;
    }
    &__title2 {
      font-size: 32px;
      margin-bottom: 10px;
      max-height: 75px;
    }
    &__title3 {
      font-size: 24px;
      min-height: auto;
      max-height: 55px;
    }
    &__btn {
      margin-top: auto;
    }
    &__btn-sign,
    &__btn {
      height: 40px;
      border-radius: 20px;
      line-height: 40px;
      font-size: 16px;
    }
    &__btn-sign {
      display: block;
    }
    &__text-wrap {
      margin: auto;
    }
  }
  .slick-dots {
    z-index: 1;
  }
}
.linda-has-user .main-slider__btn-sign {
    display: none;
}
.linda-has-user .main-slider__title1,
.linda-has-user .main-slider__title2 {
  margin-bottom: 10px;
}
.linda-has-user .main-slider__btn {
  margin-top: 40px;
}
@include xxs {
  .linda-has-user .main-slider__text-wrap {
    margin: unset;
  }
  .linda-has-user .main-slider__inner-content {
    padding: 15px;
    justify-content: center;
  }
}
