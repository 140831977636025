$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.notifications{
  font-family: 'Roboto', sans-serif;
  position:fixed;
  top:0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 350px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items:center;

  &__item{
    position:relative;
    padding:24px 30px;
    margin-top: 10px;
    width:100%;
    transition:0.3s;
    overflow:hidden;
    border-radius: 10px;
    color: $white;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.79;
    animation: showNotification 0.3s infinite linear;
    animation-iteration-count: initial;
    background: $orange;
    // todo: найти классы, проверить
    &.ng-move,
    &.ng-enter {
      transition: 0.5s;
    }

    &.ng-leave.ng-leave-active,
    &.ng-move,
    &.ng-enter {
      margin-top:-70px;
    }

    &.ng-leave,
    &.ng-move.ng-move-active,
    &.ng-enter.ng-enter-active {
      margin-top:20px;
      @include xs{ margin-top:10px; }
    }

    &_error{
      background: $red;
    }

    &_warning{
      background: $orange;
    }

    &_info{
      background: $violetOld;
    }

    &_success{
      background: $greenOld;
    }
  }

  &__message {
    padding-right: 10px;
  }
  &__close{
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    padding: 7px;
    & svg{
      width: 15px;
      height: 15px;
      fill: $white;
    }
  }

  @include xs{
    width: calc(100% - 30px);
    max-width: 500px;
  }
}

// todo: Проверить анимацию
@keyframes showNotification {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}