$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.deposit-page {
  color: #3e3e3e;
  min-height: 100vh;
  background-image: url($cdn + "/sweden-yoyo/img-bg-dep_lim_page.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &__logo {
    width: 188px;
    height: 76px;
    margin: auto auto 60px;
    display: block;
    & svg {
      width: 100%;
      height: 100%;
    }
  }
  &__blot {
    background-image: url("/img/img-deposit-blot.svg");
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
  }
  &__title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  &__sub-title {
    font-size: 14px;
    margin-bottom: 20px;
  }
  &__wrap {
    width: 100%;
    max-width: 698px;
    margin: auto;
    text-align: center;
    padding: 60px 0 40px;
  }
  &__item {
    padding: 15px;
    background-color: rgba(249, 251, 231, .5);
    width: 100%;
    margin-bottom: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &_small{
        width: 85%;
    }
  }
  &__btns-wrap {
    display: flex;
    justify-content: space-between;
    overflow-x:auto;
    width: 100%;
    align-items: center;
  }
  &__method-name {
    flex: 0 0 62px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-right: 12px;
  }
  &__item-title {
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 500;
    color: #3f3f3f;
  }
  &__btn {
    width: 124px;
    height: 45px;
    border-radius: 10px;
    overflow: hidden;
    line-height: 45px;
    font-size: 18px;
    font-weight: 600;
    color: #66554f;
    text-align: center;
    background-color: #f9fbe7;
    border: none;
    cursor: pointer;
    flex-shrink:0;

    margin-right: 12px;

    &.active {
      background-color: #ffa800;
      color: #fff;
    }
  }

  &__input-wrap {
    margin-top: 5px;
    flex: 0 0 142px;
  }

  &__link {
    margin-top: 5px;
  }

  &__link.clicked {
    display: none;
  }

  &__link.clicked + &__input-wrap {
    display: block;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @include xl {
    &__blot {
      background-size: contain;
    }
  }

  @include xs {

    &__blot {
      background: #ffd180;
    }

    &__wrap {
      background: #ffd180;
      padding: 30px 0 40px;
      max-width: 100%;
    }

    &__logo {
      width: 135px;
      height: 54px;
      margin: auto auto 30px;
    }

    &__item {
      border-radius: 0;
      padding: 15px 0;
    }

    &__btns-wrap {
      justify-content: flex-start;
    }
  }
}
