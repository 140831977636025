$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.se-profile-details {
  &__user {
    flex-direction: row;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    max-height: 100%;
  }
  &__balance {
    width: 263px;
    border-radius: 10px;
    border: 1px solid #00be6e;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  &__bonus-wrap {
    margin-left: 20px;
    margin-bottom: 20px;
  }
  &__info-wrap {
    display: flex;
    margin-bottom: 20px;
  }
  @include s {
    &__balance {
      width: 320px;
    }
    &__bonus-wrap {
      margin-top: 15px;
      margin-left: 15px;
    }
    &__vip {
      width: 392px;
    }
    &__info-wrap {
      width: 100%;
      justify-content: center;
    }
  }
  @include xs {
    &__vip {
      width: 100%;
    }
    &__balance {
      width: 100%;
      margin-top: 20px;
    }
    &__info-wrap {
      flex-direction: column;
    }
    &__balance {
      padding: 32px 0 30px;
    }
    &__bonus-wrap {
      width: 290px;
      margin-top: 20px;
    }
  }
}