$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.bankid-pay-icons {
  &__list {
    display: flex;
    justify-content:space-between;
  }
  &__item {
    width: 86px;
    height: 23px;
    margin: 0 8px;
  }
  &__img {
    width: 100%;
    height: 100%;
    font-size: 10px;
    word-break: break-all;
  }
  @media(min-width: 1920px) {
    margin-bottom: 10px;
  }
  @include s {
    &__item {
      width: 65px;
      height: 23px;
    }
  }
  @include xs {
    flex-wrap: wrap;
    &__list {
      justify-content: center;
      flex-wrap: wrap;
    }
    &__item {
      margin-top: 10px;
      width: 60px;
      height: 16px;
      &:nth-child(n+5) {
        display: none;
      }
    }
  }
}