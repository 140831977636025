$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.pnp-flow {

  &__form-btn {
    margin: 32px auto 52px;
    display: block;
  }

  &__content-iframe {
    margin-top: 16px;
    margin-bottom: 28px;
  }

  &__iframe iframe {
    min-height: 660px;
    height: auto;
    border-radius: 10px;
  }



}
