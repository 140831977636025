$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.christmas-popup {

  &__content {
    position: relative;
    max-width: 700px;
    padding:0;
    color: #3f3f3f;
  }

  &__title {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 25px;
  }

  &__wrap {
    padding: 30px 150px;
    border-radius: 10px;
    width: 700px;
    background: #ffd180 url($cdn + "/yoyo/christmas/christmas_bg_snowflakes-garland.png") center top no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    line-height: 1.41;
    overflow:hidden;
    text-align: center;
  }

  &__bonus-name {
    font-size: 30px;
    line-height: normal;
    font-weight: bold;
    color: #3f3f3f;
  }

  &__bonus-terms {
    margin: 20px 0 25px;
  }

  &__instructions {
    padding:0;
    counter-reset: item;
    margin: 20px auto;
    overflow: hidden;
  }

  &__instruction {
    margin: 0;
    padding: 0 0 0 21px;
    text-indent: -21px;
    list-style-type: none;
    counter-increment: item;
  }

  &__instruction:before {
    display: inline-block;
    width: 14px;
    padding-right: 7px;
    text-align: right;
    content: counter(item) ".";
  }

  &__btn {
    max-width: 300px;
    margin: 0 auto;
    width: 100%;
    font-size: 24px;
    height: 60px;
    line-height: 60px;
    border-radius: 80px;
    padding: 0px 10px;
  }

  &__btn-deposit {
    display: none;
  }

  .linda-has-user & {

    .christmas-popup__btn-deposit {
      display: block;
    }
    .christmas-popup__btn-login {
      display: none;
    }
  }
  &__chat-link {
    text-decoration: underline;
  }

  @include s {
    &__close {
      top: -40px;
      right:0;
    }
  }

  @include xs {
    margin-top: 100px;
    &__content {
      max-width: 300px;
      border-radius: 10px;
    }

    &__wrap {
      width: 300px;
      padding: 30px;
      font-size: 14px;
      background: #ffd180 url($cdn + "/yoyo/christmas/christmas_bg_snowflakes.png") center top no-repeat;

    }

    &__title {
      order:0;
    }
    &__bonus-name {
      order: 1;
      font-size: 24px;
    }
    &__btn {
      order: 2;
      margin-top: 18px;
      font-size: 22px;
    }

    &__bonus-terms {
      order: 3;
    }
    &__instructions {
      max-width: 100%;
      margin: 20px auto 0;
      order: 5;
    }

    &__bonus-info {
      order: 4;
    }
  }
}
.christmas-terms-popup {
  @include xs {
    background-color: rgba(62, 62, 62, 0.8);
  }
}