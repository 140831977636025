$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-details {

  margin-top: 30px;
  display: flex;
  flex-direction: column;

  &__user-block {
    border-radius: 10px;
  }

  &__user {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-height: 402px;

  }

  &__vip {
    width: 350px;
    min-height: 390px;
    margin-right: 20px;
    border: solid 1px #ffa800;
    padding: 20px;
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__balance {
    min-height: 80px;
    max-height: 80px;
    width: calc(100% - 370px);
    display: flex;
    align-items: center;
  }

  &__bonus {
    margin: 25px auto 0;
    width: 100%;
  }

  &__bonus-title {
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(65, 48, 52, 0.8);
    text-align: center;
  }

  &__bonus-btn {
    display: block;
    max-width: 290px;
    margin: 20px auto 0;
  }

  &__no-bonus {
    padding: 20px 230px;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 160px;
    max-width: 753px;
    flex-grow:1;
    margin: 20px auto 0;
    background: url('/img/no-bonus_bg.svg') center no-repeat;
    background-size: 100% 100%;
    position: relative;

    & svg {
      fill: #E3D3D6;
      width: 123px;
      height: 113px;
      position: absolute;
      left: 98px;
      top: 23px;

    }
  }

  &__no-bonus-text {
    font-size: 24px;
    font-weight: bold;
    color: #3f3f3f;
    text-align: center;
    width: 273px;
  }

  &__no-bonus-link {
    margin-top: 12px;
    display: block;
    text-align: center;
  }

  & .profile-details__bonus_0 {

    .profile-details__no-bonus {
      display: flex;
    }

    .profile-details__bonus-btn {
      display: none;
    }
  }

  @include s {
    &__user {
      flex-direction: row;
      justify-content: center;
      max-height: unset;
    }
    &__vip {
      max-width: 392px;
      flex-grow: 1;
      min-height: 188px;
      max-height: 188px;
      margin-right: 15px;
    }

    &__balance {
      max-width: 320px;
      flex-grow: 1;
      min-height: 188px;
      max-height: 188px;
    }

    &__bonus {
      max-width: 728px;
      flex-grow: 1;
    }
    &__bonus-title {
      margin-bottom: 7px;
    }

    &__no-bonus {
      margin-left: -15px;
    }


  }
  @include xs {

    &__user-block {
      max-width: 100%;
      max-height: unset;
      width: 100%;
      margin-right: 0;
    }

    &__balance {
      margin-top: 20px;
      min-height: auto;
    }

    &__bonus {
      max-width: 100%;
      width: 100%;
    }

    &__bonus-title {
      margin-bottom: 0;
    }
    &__no-bonus {
      padding: 20px;
      width: calc(100% + 40px);
      background: #F2ECE1;
      margin-left: -20px;

      & svg {
        display: none;
      }
    }
  }

  @include xxs {
    &__no-bonus {
      width: calc(100% + 30px);
      margin-left: -15px;
    }
  }
}

.profile-vip {
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  &__percent {
    position: absolute;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
    color: #3f3f3f;
    top: 1px;
    left: -30px;
  }

  &__profile-icon {
    margin-bottom: 30px;

    svg {
      height: 108px;
    }
  }

  &__level {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    color: #3f3f3f;
    margin-bottom: 20px;
  }

  &__progress-bar {
    width: 250px;
    height: 15px;
    background-color: rgba(255, 209, 128, .2);
    border-radius: 2px;
    position: relative;
    margin: auto;
    margin-bottom: 25px;
    background-repeat: no-repeat;
  }

  &__progress-bar-status {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 18%;
    border-radius: 2px;
    background-color: #ffa800;
  }

  &__registration {
    margin-bottom: 13px;
  }

  &__registration-title {
    font-size: 14px;
    line-height: normal;
    color: #3f3f3f;
    margin-bottom: 5px;
  }

  &__created {
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    color: #3f3f3f;
  }

  &__profile-logout {
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    color: #413034cc;
  }

  &__login {
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    color: rgba(65, 48, 52, 0.8);
    margin-bottom: 22px;
  }
}
/*
  @include s() {
    &__wrap {
      height: 160px;
    }
    &__login {
      order: 1;
    }
    &__profile-icon {
      order: 2;
    }
    &__level {
      order: 4;
    }
    &__progress-bar-wrap {
      order: 5;
    }
    &__registration {
      order: 6;
    }
    &__profile-logout {
      order: 3;
    }
    &__profile-icon {
      margin-right: 40px;
      margin-bottom: 20px;
      svg {
        max-width: 130px;
        height: 70px;
      }
    }
    &__created {
      text-align: left;
    }
    &__profile-logout {
      position: relative;
      text-align: center;
      margin-right: 40px;
    }
    &__registration {
      margin: 0px auto 0px 0px;
      position: relative;
      text-align: left;
    }
    &__progress-bar {
      width: 180px;
    }
    &__login {
      margin-bottom: 15px;
      max-width: 130px;
      @include text-dots;
    }
    &__level {
      margin-top: 10px;
      text-align: left;
    }
  }
  @include xs() {
    &__profile-icon {
      margin-right: 70px;
      margin-left: auto;
      svg {
        width: 98px;
        text-align: center;
      }
    }
    &__progress-bar {
      margin: 0 0 20px;
    }
    &__profile-logout {
      text-align: center;
      margin-left: auto;
      min-width: 98px;
      margin-right: 80px;
    }
    &__login {
      margin-left: auto;
      margin-right: 60px
    }
  }
  @include xxs() {
    &__wrap {
      height: auto;
      flex-direction: row;
      width: 255px;
      margin: auto;
    }
    &__level {
      margin: auto 0px;
      min-width: 100px;
    }
    &__profile-icon {
      margin: 0;
      margin-right: 20px;
      svg {
        width: 92px;
      }
    }
    &__progress-bar-wrap {
      width: 100%;
      margin-top: 20px;
    }
    &__progress-bar {
      margin:0px auto 20px;
      width: 200px;
    }
    &__profile-logout {
      min-width: auto;
      margin: 0px;
      order: 6;
      padding-top: 20px;
    }
    &__registration {
      margin: 0px 34px 0px 0px;
      text-align: left;
    }
    &__login {
      min-width: 100%;
      margin-right: 0px;
    }
  }
}

*/
