$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.change-pass {

  &__formbox input {
    padding-right: 40px;
  }

  &__btn {
    margin-top: 15px;
    max-width: 100%;
    width: 100%;
  }
}