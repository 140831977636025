$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.registration-promo {
    min-height: 80px;
    width: 390px; 
    padding: 10px 24px 9px 86px;
    position: relative;
    display: flex;
    align-items: center;
    color: #616A6D;
    background-image: linear-gradient(0deg, #F9FBE7, #F9FBE7);
    border-radius: 10px;
    cursor: pointer;

    &:not(.is-active):hover {
        box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.1), 2px 2px 4px rgba(0, 0, 0, 0.1);
    }

    &.is-active {
        background: #00BE6E;
        color: #FFFFFF;
        cursor: auto;

        .is-step-2 & {
            cursor: pointer;
        }
    }

    &.is-active::after {
        content: '';
        width: 25px;
        height: 25px;
        position: absolute;
        top: 10px;
        right: 14px;
        background-image: url(/img/icon-tooltip-check-yellow.svg);
        background-repeat: no-repeat;
        background-size: contain;
    }
    &.is-step-2 {
        cursor: initial;
    }

    &__img {
        position: absolute;
        top: 3px;
        left: 6px;
        width: 74px;
        height: 74px;
        z-index: 100;
        object-fit: contain;

        &::before {
            content:'';
            width: 64px;
            height: 64px;
            position: absolute;
            top: 9px;
            left: 6px;
            background: #F9FBE7;;
            box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.25);
            border-radius: 100%;
            z-index: -1;
        }
    }

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow: hidden;
    }

    &__name {
        font-size: 14px;
        line-height: 16px;
        margin-top: 0;
        margin-bottom: 6px;
    }

    &__text {
        font-weight: 900;
        font-size: 20px;
        line-height: 23px;
        margin: 0;
        @include text-lines-clip(2);
    }

    &__terms {
        margin: 0;
        font-size: 10px;
        line-height: 12px;

        & a {
            text-decoration: underline;
            color: inherit;
        }

        &.is-link {
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            color: rgba(97, 106, 109, 0.75);
            text-decoration: underline;

            .is-active & {
                display: none;
            }
        }

        &.is-text {
            display: none;
            .is-active & {
                display: block;
            }
        }

    }
}
