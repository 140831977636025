$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.no-result-block {
  width: 100%;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.60);
  box-shadow: 0px 4px 4px 0px rgba(32, 32, 32, 0.10);
  padding: 12px 20px;
  text-align: center;
  @include text(14px, 140%, 500, $white);
}
