@import "https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,500;0,600;0,700;1,600;1,700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Jost:wght@500;600;700;800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap";
.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid #0000;
  height: auto;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

.bottom-61 {
  margin-bottom: 61px !important;
}

.top-61 {
  margin-top: 61px !important;
}

.bottom-62 {
  margin-bottom: 62px !important;
}

.top-62 {
  margin-top: 62px !important;
}

.bottom-63 {
  margin-bottom: 63px !important;
}

.top-63 {
  margin-top: 63px !important;
}

.bottom-64 {
  margin-bottom: 64px !important;
}

.top-64 {
  margin-top: 64px !important;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline: none;
  height: 100%;
  line-height: 1.15;
}

body {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-track-color: #070d10;
  scrollbar-arrow-color: #f5f5f5;
  -webkit-user-select: none;
  user-select: none;
  background-repeat: no-repeat;
  outline: none;
  margin: 0;
  overflow-y: scroll;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

:focus {
  outline: 0;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
  font-size: 100%;
  text-decoration: none;
}

.clearfix:after {
  content: "";
  clear: both;
  display: table;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a:hover {
  cursor: pointer;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: Roboto, sans-serif;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

div {
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}

input[type="checkbox"].hidden {
  display: none;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

ul {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  list-style-type: none;
}

img {
  border-style: none;
  max-width: 100%;
  height: auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

h1 {
  font-size: 300%;
  font-weight: 300;
}

@media screen and (width <= 767px) {
  h1 {
    font-size: 220%;
  }
}

h2 {
  font-size: 200%;
  font-weight: 300;
}

@media screen and (width <= 767px) {
  h2 {
    font-size: 150%;
  }
}

select {
  border: #0000;
  line-height: 2em;
}

option {
  line-height: 2em;
}

*, :before, :after {
  box-sizing: inherit;
}

@media (width >= 1440px) {
  .container {
    --offset-x: 35px;
  }

  .container.has-no-offset {
    --offset-x: 0;
  }

  .category-header__title {
    font-size: 20px;
    line-height: 24px;
  }

  .category-header__more {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (width >= 1440px) {
  .modal-box-inner {
    max-width: 1200px;
  }

  .modal-box-header {
    margin-bottom: 8px;
    padding: 0 70px;
  }

  .modal-box-btn-icon.is-close {
    width: 24px;
    height: 24px;
    right: 28px;
  }
}

@keyframes bankidButtonAnimation {
  from {
    right: 45%;
  }

  50% {
    right: 53%;
  }

  75% {
    right: 6%;
  }

  80% {
    right: 12%;
  }

  to {
    right: 50%;
    transform: translateX(50%);
  }
}

@media (width >= 1440px) {
  .game-banner__in {
    padding: 48px 54px 116px;
  }

  .game-banner__img-box {
    flex-shrink: 0;
    width: 510px;
  }

  .game-banner__img {
    height: 269px;
    padding-top: 0;
  }

  .game-banner__content {
    width: 604px;
    padding-left: 76px;
  }

  .game-banner__title {
    font-size: 36px;
  }

  .game-banner__desc {
    font-size: 18px;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (width >= 1440px) {
  .game-slider .splide__track {
    margin-top: 11px;
  }

  .games-dashboard-small__item:nth-of-type(n+9) {
    display: block;
  }

  .games-dashboard {
    grid-template-columns: repeat(6, 1fr);
  }

  .games-dashboard__item:nth-of-type(n+19) {
    display: block;
  }

  .games-dashboard__events {
    grid-column: 6 / 6;
  }

  .games-dashboard__btn-wrap {
    grid-column-end: 7;
  }
}

@media screen and (width <= 1439px) {
  .profile__submenu {
    margin: 0 -8px;
  }

  .profile__item {
    width: calc(25% - 16px);
    margin: 0 8px 16px;
  }

  .profile__item:first-child {
    margin-left: 8px;
  }

  .profile__item + .user-submenu__item {
    margin: 0 8px 16px;
  }
}

@media screen and (width <= 1023px) {
  .profile__submenu {
    margin: 0 -5px;
  }

  .profile__item + .user-submenu__item {
    margin: 0 5px 10px;
  }

  .profile__item {
    width: auto;
    min-width: 151px;
  }

  .profile__item:first-child {
    margin-bottom: 10px;
    margin-left: 5px;
  }

  .profile__title {
    width: calc(100% - 130px);
    padding-left: 130px;
  }

  .profile__time {
    width: 130px;
    padding: 0;
  }
}

@media screen and (width <= 1239px) {
  .promo-info__text-main-title {
    margin-bottom: 25px;
    font-size: 24px;
  }

  .promo-info__text-sub-title {
    margin-bottom: 15px;
  }
}

@media screen and (width <= 1023px) {
  .promo-info__left-wrap {
    width: 240px;
  }

  .promo-info__text-info-wrap {
    margin: 20px auto 30px;
  }
}

@media screen and (width <= 1239px) {
  .promo-item-full {
    min-height: 264px;
    padding: 26px 67px 0;
  }

  .promo-item-full:before {
    width: 250px;
    height: 250px;
    right: 50px;
  }

  .promo-item-full__img {
    width: 255px;
    height: 255px;
    right: 50px;
  }

  .promo-item-full__button {
    min-width: unset;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
  }

  .promo-item {
    padding: 24px 14px 14px;
  }

  .promo-item:before, .promo-item__img {
    width: 170px;
    height: 170px;
    right: 14px;
  }

  .promo-item__content {
    max-width: 240px;
  }

  .promo-item__title {
    font-size: 16px;
  }

  .promo-item__description {
    font-size: 24px;
  }

  .promo-item__button {
    min-width: unset;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
  }

  .promo-item__text {
    margin-top: 10px;
  }

  .promotions {
    background-position: -250px 0, right -250px top;
  }
}

@media (width >= 1440px) {
  .slider-games-h__in .game-item {
    flex-basis: 25%;
  }
}

.g-btn-icon {
  cursor: pointer;
  box-sizing: border-box;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 8px;
  line-height: 0;
  display: flex;
}

.g-btn-icon svg, .g-btn-icon path {
  max-width: 100%;
  max-height: 100%;
  fill: inherit;
  margin: auto;
}

.g-btn-text {
  cursor: pointer;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: none;
  border-radius: 20px;
  max-width: 100%;
  height: 40px;
  padding: 0 30px;
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
  display: inline-block;
  overflow: hidden;
}

.g-btn-text:disabled {
  pointer-events: none;
  cursor: default;
}

.g-btn-text_high {
  border-radius: 80px;
  height: 60px;
  padding: 0 50px;
  font-size: 24px;
  line-height: 60px;
}

.g-btn-width290 {
  width: 290px;
}

.g-btn-green-bg {
  color: #fff;
  fill: #fff;
  background-color: #00be6e;
  box-shadow: inset 0 0 0 1px #00be6e;
}

.g-btn-green-bg:hover, .g-btn-green-bg.active {
  color: #00be6e;
  fill: #00be6e;
  background-color: #0000;
}

@media (any-hover: none) {
  .g-btn-green-bg:hover {
    color: #fff;
    fill: #fff;
    box-shadow: none;
    background-color: #00be6e;
  }

  .g-btn-green-bg.active {
    color: #00be6e;
    fill: #00be6e;
    background-color: #0000;
    box-shadow: inset 0 0 0 1px #00be6e;
  }
}

.g-btn-light-green-bg {
  color: #fff;
  fill: #fff;
  background-color: #35d290;
  transition: background-color .2s, color .2s;
  box-shadow: inset 0 0 0 1px #35d290;
}

.g-btn-light-green-bg:hover, .g-btn-light-green-bg.active {
  color: #35d290;
  fill: #35d290;
  background-color: #0000;
}

@media (any-hover: none) {
  .g-btn-light-green-bg:hover {
    color: #fff;
    fill: #fff;
    box-shadow: none;
    background-color: #35d290;
  }

  .g-btn-light-green-bg.active {
    color: #35d290;
    fill: #35d290;
    background-color: #0000;
    box-shadow: inset 0 0 0 1px #35d290;
  }
}

.g-btn-light-green-bg:disabled {
  pointer-events: none;
  background: #00be6e66;
}

.g-btn-red-bg {
  color: #fff;
  fill: #fff;
  background-color: #ea4642;
  box-shadow: inset 0 0 0 1px #ea4642;
}

.g-btn-red-bg:hover, .g-btn-red-bg.active {
  color: #ea4642;
  fill: #ea4642;
  background-color: #0000;
}

@media (any-hover: none) {
  .g-btn-red-bg:hover {
    color: #fff;
    fill: #fff;
    box-shadow: none;
    background-color: #ea4642;
  }

  .g-btn-red-bg.active {
    color: #ea4642;
    fill: #ea4642;
    background-color: #0000;
    box-shadow: inset 0 0 0 1px #ea4642;
  }
}

.g-btn-violet-bg {
  color: #fff;
  fill: #fff;
  background-color: #ab69ad;
  box-shadow: inset 0 0 0 1px #ab69ad;
}

.g-btn-violet-bg:hover, .g-btn-violet-bg.active {
  color: #ab69ad;
  fill: #ab69ad;
  background-color: #0000;
}

@media (any-hover: none) {
  .g-btn-violet-bg:hover {
    color: #fff;
    fill: #fff;
    box-shadow: none;
    background-color: #ab69ad;
  }

  .g-btn-violet-bg.active {
    color: #ab69ad;
    fill: #ab69ad;
    background-color: #0000;
    box-shadow: inset 0 0 0 1px #ab69ad;
  }
}

.g-btn-orange-bg {
  color: #fff;
  fill: #fff;
  background-color: #ffd180;
  box-shadow: inset 0 0 0 1px #ffd180;
}

.g-btn-orange-bg:hover, .g-btn-orange-bg.active {
  color: #ffd180;
  fill: #ffd180;
  background-color: #0000;
}

@media (any-hover: none) {
  .g-btn-orange-bg:hover {
    color: #fff;
    fill: #fff;
    box-shadow: none;
    background-color: #ffd180;
  }

  .g-btn-orange-bg.active {
    color: #ffd180;
    fill: #ffd180;
    background-color: #0000;
    box-shadow: inset 0 0 0 1px #ffd180;
  }
}

.g-btn-green-border {
  color: #00be6e;
  fill: #00be6e;
  background-color: #0000;
  box-shadow: inset 0 0 0 1px #00be6e;
}

.g-btn-green-border:hover, .g-btn-green-border.active {
  color: #fff;
  fill: #fff;
  box-shadow: none;
  background-color: #00be6e;
}

@media (any-hover: none) {
  .g-btn-green-border:hover {
    color: #00be6e;
    fill: #00be6e;
    background-color: #0000;
    box-shadow: inset 0 0 0 1px #00be6e;
  }

  .g-btn-green-border.active {
    color: #fff;
    fill: #fff;
    box-shadow: none;
    background-color: #00be6e;
  }
}

.g-btn-light-green-border {
  color: #35d290;
  fill: #35d290;
  background-color: #0000;
  box-shadow: inset 0 0 0 1px #35d290;
}

.g-btn-light-green-border:hover, .g-btn-light-green-border.active {
  color: #fff;
  fill: #fff;
  box-shadow: none;
  background-color: #35d290;
}

@media (any-hover: none) {
  .g-btn-light-green-border:hover {
    color: #35d290;
    fill: #35d290;
    background-color: #0000;
    box-shadow: inset 0 0 0 1px #35d290;
  }

  .g-btn-light-green-border.active {
    color: #fff;
    fill: #fff;
    box-shadow: none;
    background-color: #35d290;
  }
}

.g-btn-red-border {
  color: #ea4642;
  fill: #ea4642;
  background-color: #0000;
  box-shadow: inset 0 0 0 1px #ea4642;
}

.g-btn-red-border:hover, .g-btn-red-border.active {
  color: #fff;
  fill: #fff;
  box-shadow: none;
  background-color: #ea4642;
}

@media (any-hover: none) {
  .g-btn-red-border:hover {
    color: #ea4642;
    fill: #ea4642;
    background-color: #0000;
    box-shadow: inset 0 0 0 1px #ea4642;
  }

  .g-btn-red-border.active {
    color: #fff;
    fill: #fff;
    box-shadow: none;
    background-color: #ea4642;
  }
}

.g-btn-violet-border {
  color: #ab69ad;
  fill: #ab69ad;
  background-color: #0000;
  box-shadow: inset 0 0 0 1px #ab69ad;
}

.g-btn-violet-border:hover, .g-btn-violet-border.active {
  color: #fff;
  fill: #fff;
  box-shadow: none;
  background-color: #ab69ad;
}

@media (any-hover: none) {
  .g-btn-violet-border:hover {
    color: #ab69ad;
    fill: #ab69ad;
    background-color: #0000;
    box-shadow: inset 0 0 0 1px #ab69ad;
  }

  .g-btn-violet-border.active {
    color: #fff;
    fill: #fff;
    box-shadow: none;
    background-color: #ab69ad;
  }
}

.g-btn-orange-border {
  color: #ffd180;
  fill: #ffd180;
  background-color: #0000;
  box-shadow: inset 0 0 0 1px #ffd180;
}

.g-btn-orange-border:hover, .g-btn-orange-border.active {
  color: #fff;
  fill: #fff;
  box-shadow: none;
  background-color: #ffd180;
}

@media (any-hover: none) {
  .g-btn-orange-border:hover {
    color: #ffd180;
    fill: #ffd180;
    background-color: #0000;
    box-shadow: inset 0 0 0 1px #ffd180;
  }

  .g-btn-orange-border.active {
    color: #fff;
    fill: #fff;
    box-shadow: none;
    background-color: #ffd180;
  }
}

.g-btn-white-border {
  color: #fff;
  fill: #fff;
  background-color: #0000;
  box-shadow: inset 0 0 0 1px #fff;
}

.g-btn-white-border:hover, .g-btn-white-border.active {
  color: #ea4642;
  fill: #ea4642;
  box-shadow: none;
  background-color: #fff;
}

@media (any-hover: none) {
  .g-btn-white-border:hover {
    color: #fff;
    fill: #fff;
    background-color: #0000;
    box-shadow: inset 0 0 0 1px #fff;
  }

  .g-btn-white-border.active {
    color: #ea4642;
    fill: #ea4642;
    box-shadow: none;
    background-color: #fff;
  }
}

.g-btn-white-border-orange-bg {
  color: #fff;
  fill: #fff;
  background-color: #0000;
  box-shadow: inset 0 0 0 1px #fff;
}

.g-btn-white-border-orange-bg:hover, .g-btn-white-border-orange-bg.active {
  color: #ffd180;
  fill: #ffd180;
  box-shadow: none;
  background-color: #fff;
}

@media (any-hover: none) {
  .g-btn-white-border-orange-bg:hover {
    color: #fff;
    fill: #fff;
    background-color: #0000;
    box-shadow: inset 0 0 0 1px #fff;
  }

  .g-btn-white-border-orange-bg.active {
    color: #ffd180;
    fill: #ffd180;
    box-shadow: none;
    background-color: #fff;
  }
}

.g-btn-dark-violet-border {
  color: #9746a5;
  fill: #9746a5;
  background-color: #0000;
  box-shadow: inset 0 0 0 1px #9746a5;
}

.g-btn-dark-violet-border:hover, .g-btn-dark-violet-border.active {
  color: #9746a5;
  fill: #9746a5;
  box-shadow: none;
  background-color: #9746a5;
}

@media (any-hover: none) {
  .g-btn-dark-violet-border:hover {
    color: #9746a5;
    fill: #9746a5;
    background-color: #0000;
    box-shadow: inset 0 0 0 1px #9746a5;
  }

  .g-btn-dark-violet-border.active {
    color: #9746a5;
    fill: #9746a5;
    box-shadow: none;
    background-color: #9746a5;
  }
}

.g-btn-dark-violet-border:hover {
  color: #fff;
  fill: #fff;
}

.g-btn-dark-violet-border.active {
  color: #fff;
}

.g-btn-light-green-always-bg {
  color: #fff;
  fill: #fff;
  background-color: #35d290;
}

.g-btn-light-green-always-bg:hover, .g-btn-light-green-always-bg.active {
  background-color: #00be6e;
}

@media (any-hover: none) {
  .g-btn-light-green-always-bg:hover {
    background-color: #35d290;
  }
}

.g-btn-white-bg {
  color: #ea4642;
  background-color: #fff;
}

.g-btn-white-bg.is-violet-text {
  color: #9746a5;
}

.g-btn-white-bg.is-violet-text:hover {
  color: #fff;
  box-shadow: none;
  background-color: #9746a5;
}

.g-btn-white-bg:hover {
  color: #fff;
  background-color: #0000;
  box-shadow: inset 0 0 0 1px #fff;
}

@media (any-hover: none) {
  .g-btn-white-bg:hover {
    color: #ea4642;
    box-shadow: none;
    background-color: #fff;
  }
}

.g-btn-dark-violet-border-wide {
  color: #933196;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  background-color: #0000;
  border-radius: 50px;
  width: 100%;
  height: auto;
  padding: 0 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 49px;
  display: block;
  overflow: hidden;
  box-shadow: inset 0 0 0 3px #9746a5;
}

.g-btn-dark-violet-border-wide:hover {
  color: #fff;
  box-shadow: none;
  background: linear-gradient(154.66deg, #a745b0 11.99%, #700e66 83.71%);
}

.g-btn-dark-green {
  color: #fff;
  background-color: #01c02a;
}

.g-btn-dark-green:hover {
  background: #009d22;
}

.g-btn-dark-green:disabled {
  color: #fffc;
  background-color: #00be6e66;
}

.g-btn-orange {
  color: #fff;
  background-color: #ffa800;
}

.g-btn-orange:hover {
  background-color: #ed9c00;
}

.g-btn-dark-violet {
  color: #fff;
  fill: #fff;
  background-color: #9746a5;
}

.g-btn-dark-violet:hover {
  background-color: #81348e;
}

.g-btn-minw-252 {
  min-width: 252px;
}

html {
  --header-height: 90px;
  --rg-bar-height: 28px;
}

.g-sprite {
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

article, section, div, p {
  box-sizing: border-box;
}

body {
  box-sizing: border-box;
  background-color: #f9fbe7;
  min-width: 1024px;
  height: 100%;
  font-family: Roboto, Arial, sans-serif;
}

a, button {
  cursor: pointer;
}

.page {
  --fixed-offset: calc(var(--header-height)  + var(--rg-bar-height));
  box-sizing: border-box;
  height: 100%;
  display: block;
  overflow: hidden scroll;
  -webkit-overflow-scrolling: touch !important;
}

body.is-install-bar-shown .page {
  --install-bar-height: 70px;
}

body.is-install-bar-shown .page .install-bar {
  position: relative;
}

@media screen and (width <= 1239px) {
  .page {
    --header-height: 70px;
  }
}

@media screen and (width <= 767px) {
  .page {
    --header-height: 104px;
  }
}

.g-link {
  color: #3e3e3e;
}

.g-link:not(.no-hover):hover {
  color: #ab69ad;
}

.g-link-bold {
  font-weight: 700;
}

.g-link-s {
  font-size: 12px;
}

.g-link-m {
  font-size: 14px;
}

.g-link-l {
  font-size: 16px;
}

.g-link-decor {
  text-decoration: underline;
}

.g-link.is-violet {
  color: #9746a5;
}

.g-link:hover {
  text-decoration: none;
}

.g-link-reverse {
  color: #6e0e57;
}

.g-link-reverse:hover {
  color: #3e3e3e;
}

.g-formbox-item {
  width: 100%;
  margin-top: 12px;
  position: relative;
}

.g-formbox-item.has-checkbox {
  margin-top: 16px;
}

.g-formbox-item.is-flex {
  justify-content: center;
  display: flex;
}

input:disabled, input:disabled + label {
  pointer-events: none;
}

.g-input {
  color: #3e3e3e;
  box-sizing: border-box;
  z-index: 2;
  background-color: #fff;
  border: 1px solid #0000;
  border-radius: 20px;
  width: 100%;
  height: 40px;
  padding: 0 25px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  position: relative;
}

.g-input::-webkit-input-placeholder {
  color: #3e3e3ecc;
  font-size: 14px;
}

.g-input::placeholder {
  color: #3e3e3ecc;
  font-size: 14px;
}

.g-input:-ms-input-placeholder {
  color: #3e3e3ecc;
  font-size: 14px;
}

.g-input:-moz-placeholder {
  color: #3e3e3ecc;
  font-size: 14px;
}

.g-input:hover {
  border-color: #dcdbd3;
}

.g-input:focus, .g-input:active {
  color: #3f3f3f;
  border: 1px solid #aa69ac;
}

.g-input.is-search {
  padding: 0 72px 0 44px;
}

.g-input.is-search.ng-not-empty + .form-clear-btn {
  display: block;
}

.g-input.is-search.novalidate:focus, .g-input.is-search.novalidate:active {
  color: #3f3f3f;
  border: 1px solid #aa69ac;
}

.g-select:disabled, .ui-datepicker-year:disabled, .ui-datepicker-month:disabled, .g-input:disabled, .g-input.ng-valid:disabled, .g-select.ng-valid:disabled {
  pointer-events: none;
  opacity: 1;
  background-color: #dcdbca;
  border-color: #0000;
}

.g-select:disabled:hover, .ui-datepicker-year:disabled:hover, .ui-datepicker-month:disabled:hover, .g-input:disabled:hover, .g-input.ng-valid:disabled:hover, .g-select.ng-valid:disabled:hover {
  cursor: default;
}

.g-input.ng-valid.ng-dirty:not(.novalidate), .g-select.ng-valid, .ng-valid.ui-datepicker-year, .ng-valid.ui-datepicker-month {
  border: 1px solid #35d290;
}

.is-placeholder-hidden::placeholder {
  color: #0000 !important;
}

.form-input-icon {
  fill: #656565;
  z-index: 2;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translateY(-50%);
}

.form-clear-btn {
  color: #a8a8a8;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 2;
  background-color: #0000;
  border: none;
  max-width: 48px;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  display: none;
  position: absolute;
  top: 50%;
  right: 15px;
  overflow: hidden;
  transform: translateY(-50%);
}

.form-clear-btn:hover {
  color: #404040;
}

.g-select, .ui-datepicker-year, .ui-datepicker-month {
  color: #3e3e3e;
  z-index: 2;
  cursor: pointer;
  -webkit-appearance: none;
  text-indent: .01px;
  width: 100%;
  max-width: 100%;
  height: 40px;
  text-overflow: "";
  background-color: #0000;
  background-image: url("/img/arr-select.svg");
  background-position: calc(100% - 15px);
  background-repeat: no-repeat;
  border: 1px solid #0000;
  border-radius: 20px;
  padding: 0 25px;
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  position: relative;
}

.g-select.is-white, .is-white.ui-datepicker-year, .is-white.ui-datepicker-month {
  background-color: #fff;
}

.g-select::-ms-expand {
  display: none;
}

.ui-datepicker-year::-ms-expand {
  display: none;
}

.ui-datepicker-month::-ms-expand {
  display: none;
}

.g-select:hover, .ui-datepicker-year:hover, .ui-datepicker-month:hover {
  border-color: #dcdbd3;
}

.g-select:focus, .ui-datepicker-year:focus, .ui-datepicker-month:focus, .g-select:active, .ui-datepicker-year:active, .ui-datepicker-month:active {
  color: #3f3f3f;
  border: 1px solid #aa69ac !important;
}

option {
  background: #fff;
  font-size: 16px;
  line-height: 30px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="date"] {
  padding: 0 25px;
  overflow: hidden;
}

input[type="date"].ng-valid.ng-dirty {
  padding: 0 25px;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  opacity: 0;
}

input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  opacity: 0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  cursor: pointer;
  width: 20px;
  height: 25px;
}

input[type="date"]::-webkit-inner-spin-button {
  padding: 0;
  display: none;
}

input[type="date"]::-webkit-clear-button {
  padding: 0;
  display: none;
}

input[type="date"]::-webkit-datetime-edit {
  margin: 0;
  padding: 0;
}

input[type="date"]::-webkit-datetime-edit-fields-wrapper {
  margin: 0;
  padding: 0;
}

.g-input.novalidate, .g-input.novalidate:focus, .g-input.novalidate:active, .g-select.novalidate, .novalidate.ui-datepicker-year, .novalidate.ui-datepicker-month {
  border: 1px solid #0000;
}

.g-select.novalidate:focus, .novalidate.ui-datepicker-year:focus, .novalidate.ui-datepicker-month:focus, .g-select.novalidate:active, .novalidate.ui-datepicker-year:active, .novalidate.ui-datepicker-month:active {
  border: 1px solid #0000 !important;
}

.g-form-disabled-date {
  width: calc(100% - 60px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.g-check {
  display: none;
}

.g-check-label {
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  display: flex;
}

.g-check-text {
  cursor: pointer;
  width: calc(100% - 35px);
  padding-left: 10px;
  font-size: 12px;
  line-height: normal;
}

.g-check-text a {
  font-weight: bold;
  text-decoration: underline;
}

.g-check-text a:hover {
  text-decoration: none;
}

.g-radio {
  display: none;
}

.g-radio:disabled + .g-radio-label .g-radio-block, .g-radio:disabled + .g-radio-label .g-check-text {
  cursor: default;
}

.g-radio-label {
  align-items: center;
  display: flex;
}

.g-radio-label_2nd {
  margin-left: 20px;
}

.g-check-block, .g-radio-block {
  cursor: pointer;
  background: #fff;
  position: relative;
}

.g-check-block:after, .g-radio-block:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.g-check-wrap {
  align-items: center;
  display: flex;
}

.g-check-block {
  cursor: pointer;
  border: 1px solid #a2a2a2;
  border-radius: 10px;
  width: 25px;
  min-width: 25px;
  height: 25px;
}

.g-radio-block {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: relative;
}

.g-radio:checked + label .g-radio-block:after {
  content: "";
  background: #35d290;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.g-check:checked + label .g-check-block:after {
  background: #fff url("/img/check.svg") no-repeat;
  width: 13px;
  height: 9px;
}

.g-radio.ng-valid:disabled + label .g-radio-block {
  background-color: #dcdbca;
}

.g-radio-text {
  cursor: pointer;
  padding-left: 10px;
  font-size: 12px;
  line-height: 12px;
}

.g-form-error {
  padding: 8px 11px 0 26px;
}

.g-form-error.g-form-error_checkbox {
  padding-left: 20px;
}

.g-form-error__text {
  color: #fa635c;
  text-align: left;
  width: 100%;
  max-width: 100%;
  font-size: 12px;
  font-style: italic;
  line-height: normal;
  position: relative;
}

.g-form-error__text:before {
  content: "";
  background-image: var(--icon-attention);
  background-repeat: no-repeat;
  background-size: contain;
  width: 13px;
  height: 15px;
  position: absolute;
  top: 0;
  left: -17px;
}

.g-form-error__text p {
  margin: 0;
}

.is-main-page .g-form-error__text {
  color: #fff;
}

.is-main-page .g-form-error {
  position: absolute;
  bottom: -14px;
}

.g-form-error {
  display: none;
}

.ng-invalid.ng-touched + .g-form-error {
  display: block;
}

.ng-untouched + .g-form-error, .ng-valid + .g-form-error, .g-minlength, .g-maxlength, .g-pattern, .g-email, .g-required, .g-passwordVer, .g-min, .g-max, .g-mask-phone, .g-amount {
  display: none;
}

.ng-invalid-minlength + .g-form-error .g-minlength, .ng-invalid-maxlength + .g-form-error .g-maxlength, .ng-invalid-pattern + .g-form-error .g-pattern, .ng-invalid-number + .g-form-error .g-pattern, .ng-invalid-phone + .g-form-error .g-pattern, .ng-invalid-email + .g-form-error .g-email, .ng-invalid-required + .g-form-error .g-required, .ng-invalid-password-verify + .g-form-error .g-passwordVer, .ng-invalid-min + .g-form-error .g-min, .ng-invalid-max + .g-form-error .g-max, .ng-invalid-required + .g-form-error .g-required, .ng-invalid-amount + .g-form-error .g-amount, .ng-invalid-phone + .g-form-error .g-mask-phone, .ng-invalid-phone + .g-form-error .g-required, .g-check.ng-invalid-required.ng-touched + label + .g-form-error_inline .g-required, .g-check.ng-touched.ng-invalid + label .g-form-error .g-required, .g-check.ng-dirty.ng-invalid + label .g-form-error .g-required {
  display: block;
}

.g-check.ng-invalid + label .g-required {
  text-align: left;
  min-width: 100%;
  display: block !important;
}

.g-check.ng-invalid.ng-touched + label .g-form-error, .g-check.ng-invalid.ng-dirty + label .g-form-error {
  display: block;
}

.ng-invalid.datepicker-open + .g-form-error {
  display: none;
}

.g-input.ng-valid.ng-dirty + .g-form-error + .g-form-ico, .g-input.ng-valid.ng-dirty.ng-not-empty + .g-form-ico, .g-select.ng-valid.ng-dirty + .g-form-ico, .ng-valid.ng-dirty.ui-datepicker-year + .g-form-ico, .ng-valid.ng-dirty.ui-datepicker-month + .g-form-ico {
  fill: #35d290;
}

input.ng-invalid.ng-touched, select.ng-invalid-required.ng-touched {
  color: #3f3f3f;
  border: 1px solid #ea4642;
}

.g-input.ng-invalid.ng-touched + .g-form-error + .g-form-ico, .g-select.ng-invalid.ng-dirty + .g-form-ico, .ng-invalid.ng-dirty.ui-datepicker-year + .g-form-ico, .ng-invalid.ng-dirty.ui-datepicker-month + .g-form-ico {
  fill: #ea4642;
}

input.ng-invalid.ng-touched.datepicker-open {
  border: 1px solid #ea4642 !important;
}

.g-check.ng-invalid.ng-touched + label .g-check-block, .g-check.ng-invalid.ng-dirty + label .g-check-block {
  border: 1px solid #ea4642;
}

.g-showPass {
  cursor: pointer;
  z-index: 3;
  position: absolute;
  top: 11px;
  right: 15px;
}

.g-showPass svg {
  width: 20px;
  height: 20px;
}

.g-showPass__true {
  line-height: 0;
  display: none;
}

.g-showPass__true svg {
  fill: #35d290;
}

.g-showPass__false {
  line-height: 0;
}

.g-showPass__false svg {
  fill: #ea4642;
}

.g-showPass__check {
  display: none;
}

.g-showPass svg {
  cursor: pointer;
}

.g-showPass__check:checked + label .g-showPass__true {
  display: block;
}

.g-showPass__check:checked + label .g-showPass__false {
  display: none;
}

.datepicker-body {
  position: relative;
}

.ui-datepicker {
  box-sizing: border-box;
  background: #f9fbe7;
  border-radius: 10px;
  width: 290px;
  box-shadow: 0 6px 20px #00000014;
  top: inherit !important;
  position: absolute !important;
  left: 0 !important;
}

.ui-datepicker-header {
  color: #fff;
  border-radius: 8px 8px 0 0;
  padding: 25px 15px 0;
  position: relative;
}

.ui-datepicker-title {
  margin: auto;
}

.ui-datepicker-year, .ui-datepicker-month {
  background-color: #fff;
  border-color: #0000;
  margin-bottom: 15px;
}

.ui-datepicker-month {
  font-size: 12px;
}

.ui-datepicker-year {
  font-size: 14px;
}

.ui-datepicker-prev, .ui-datepicker-next {
  display: none;
}

.ui-datepicker-calendar {
  width: 100%;
  max-width: 260px;
  margin: 0 15px 15px;
  font-size: 12px;
}

.ui-datepicker th, .ui-datepicker td {
  padding: 8px 3px;
}

.ui-datepicker th {
  color: #3f3f3fcc;
  font-weight: bold;
}

.ui-datepicker td {
  text-align: center;
  box-sizing: border-box;
}

.ui-datepicker td a {
  color: #3f3f3f;
  z-index: 1;
  display: block;
  position: relative;
}

.ui-datepicker td a:after {
  content: "";
  z-index: -1;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ui-datepicker td a:hover:after {
  background: #fbce7e;
}

.ui-datepicker option {
  color: #3e3e3e;
  background: #fff;
}

@media only screen and (width <= 539px) {
  .ui-datepicker {
    left: -21px !important;
  }
}

.date-history-t .ui-datepicker {
  right: 0 !important;
  left: inherit !important;
}

.trans-history-f .ui-datepicker {
  top: 0 !important;
  bottom: inherit !important;
  right: inherit !important;
  left: 0 !important;
}

.trans-history-t .ui-datepicker {
  top: 0 !important;
  bottom: inherit !important;
  right: 0 !important;
  left: inherit !important;
}

.ui-datepicker-right .ui-datepicker {
  right: 0 !important;
  left: inherit !important;
}

@media screen and (width <= 767px) {
  .ui-datepicker-right .ui-datepicker {
    left: 0 !important;
    right: inherit !important;
  }
}

.g-scroll_touch {
  -webkit-overflow-scrolling: touch;
}

.g-scroll::-webkit-scrollbar-track {
  background-color: #e5e5e5;
  border-radius: 5px;
}

.g-scroll::-webkit-scrollbar-thumb {
  background-color: #fbce7e;
  border-radius: 5px;
}

.g-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #fbce7e;
}

.g-scroll::-webkit-resizer {
  background-image: none;
  background-repeat: no-repeat;
  width: 4px;
  height: 4px;
}

.g-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.page-contet-wrap {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.page-contet-wrap__page-content {
  flex: 1 0 auto;
}

.page-contet-wrap__page-footer-wrap {
  flex: none;
}

.g-go-top {
  z-index: 120;
  background-color: #9746a5;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
  position: fixed;
  bottom: 35px;
  right: 35px;
}

.g-go-top svg {
  fill: #fff;
  width: 20px;
  height: 13.1px;
  transform: rotate(180deg);
}

.g-go-top:active, .g-go-top:hover {
  background-color: #81348e;
}

.is-full-width {
  width: 100%;
}

.profile-filter {
  background-color: #ffd180;
  border-radius: 10px 10px 0 0;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  padding: 20px;
  display: flex;
}

.profile-filter__item {
  width: 190px;
}

.profile-filter__item-deposit {
  display: none;
}

.profile-filter__item + .profile-filter__item {
  margin-left: 20px;
}

.profile-filter__field {
  letter-spacing: normal;
  color: #3f3f3f;
  background-color: #fff;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.76;
}

@media screen and (width <= 1023px) {
  .profile-filter__item + .profile-filter__item {
    margin-left: 16px;
  }
}

@media screen and (width <= 767px) {
  .profile-filter {
    flex-direction: column;
  }

  .profile-filter__item {
    width: 100%;
  }

  .profile-filter__item + .profile-filter__item {
    margin-top: 15px;
    margin-left: 0;
  }
}

.profile-table {
  border: 1px solid #ffa800;
  border-radius: 10px;
  padding-bottom: 6px;
}

.profile-table__thead {
  letter-spacing: normal;
  color: #413034cc;
  justify-content: space-between;
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
  display: flex;
}

.scroll .profile-table__thead {
  margin-right: 22px;
}

.profile-table__th {
  align-items: center;
  min-height: 40px;
  display: flex;
  overflow: hidden;
}

.profile-table__th + .profile-table__th {
  padding-left: 10px;
}

.profile-table__tbody {
  letter-spacing: normal;
  color: #3f3f3f;
  max-height: 400px;
  font-size: 14px;
  font-weight: 500;
  overflow-y: auto;
}

.scroll .profile-table__tbody {
  margin-right: 8px;
  padding-right: 8px;
}

.profile-table__tr {
  align-items: center;
  height: 40px;
  display: flex;
}

.profile-table__tr:nth-child(odd) {
  background: #f2f3e0;
}

.profile-table__tr-subrow + .profile-table__tr-subrow {
  padding-left: 10px;
}

.profile-table__td-key {
  letter-spacing: normal;
  color: #413034cc;
  flex-shrink: 0;
  width: 45%;
  padding-right: 10px;
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
  display: none;
}

.profile-table__td-key, .profile-table__td-value {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media screen and (width <= 1023px) {
  .profile-table .profile-table__thead, .profile-table .profile-table__tr {
    padding-left: 20px;
  }
}

@media screen and (width <= 767px) {
  .profile-table {
    max-width: 480px;
    margin: 20px auto 0;
    padding: 0;
  }

  .profile-table__thead {
    display: none;
  }

  .profile-table .profile-table__tbody {
    max-height: unset;
    margin-right: 0;
    padding: 0;
  }

  .profile-table .profile-table__tr {
    flex-direction: column;
    height: auto;
    padding: 20px;
  }

  .profile-table .profile-table__tr-subrow {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 29px;
    display: flex;
  }

  .profile-table__tr-subrow + .profile-table__tr-subrow {
    padding-left: 0;
  }

  .profile-table__td-key {
    display: block;
  }
}

.payment-box {
  border: 1px solid #ffd180;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
}

.payment-box.is-depos {
  margin-bottom: 30px;
}

.is-main-page .payment-box {
  border: none;
}

.payment-box__trustly {
  display: none;
}

.is-main-page .payment-box__trustly {
  z-index: 2;
  border: none;
  width: 71px;
  height: 20px;
  display: block;
  position: absolute;
  top: 20px;
  right: 28px;
}

.is-main-page .payment-box__trustly svg {
  width: 100%;
  height: 100%;
}

.payment-box_withdraw {
  margin-bottom: 30px;
}

@media screen and (width <= 1439px) {
  .payment-box_withdraw {
    margin-bottom: 25px;
  }
}

@media screen and (width <= 1023px) {
  .payment-box_withdraw {
    margin-bottom: 20px;
  }
}

.payment-box_withdraw.is-hide {
  display: none;
}

.payment-box__paysystems {
  flex-shrink: 0;
  width: 50%;
  padding: 40px 30px 40px 50px;
}

.payment-box__data {
  background-image: url("/img/cashbox_bg-vertical.svg"), linear-gradient(#faebb9, #faebb9);
  background-position: 0 0, 35px 0;
  background-repeat: repeat-y, no-repeat;
  background-size: 35px 70px, 100%;
  border-radius: 0 10px 10px 0;
  flex-shrink: 0;
  width: 50%;
  padding: 40px 40px 40px 62px;
}

.is-main-page .payment-box__data {
  width: 100%;
  padding: 16px 0 10px;
}

.payment-box__data-inner-cont {
  width: 100%;
  max-width: 390px;
  min-height: 100px;
  margin: auto;
}

.is-main-page .payment-box__paysystems {
  display: none;
}

.is-main-page .payment-box__data {
  background: none;
}

.is-main-page .payment-box:after {
  content: "";
  width: 71px;
  height: 20px;
  display: block;
}

@media screen and (width <= 1023px) {
  .payment-box {
    display: block;
  }

  .payment-box__paysystems {
    width: 100%;
    padding: 40px 80px 20px;
  }

  .payment-box__data {
    background-image: url("/img/cashbox_bg-horizontal.svg"), linear-gradient(#faebb9, #faebb9);
    background-position: 0 0, 0 35px;
    background-repeat: repeat-x, no-repeat;
    background-size: 70px 35px, 100%;
    border-radius: 0 0 10px 10px;
    width: 100%;
    padding: 60px 40px 40px;
  }
}

@media screen and (width <= 767px) {
  .payment-box__paysystems {
    padding: 18px;
  }

  .payment-box__data {
    padding: 60px 20px 40px;
  }
}

.payments {
  flex-wrap: wrap;
  margin: -7px -7px -8px -8px;
  display: flex;
}

.payments__item {
  box-sizing: border-box;
  background-color: #edefdc;
  border-radius: 10px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 124px;
  height: 55px;
  margin: 7px 7px 8px 8px;
  padding: 12px 6px;
  display: inline-flex;
}

.payments__item:hover {
  cursor: pointer;
  background-color: #fce6b4;
}

.payments__item.active {
  background-color: #fbda8b;
}

.payments__item svg {
  width: 100%;
  height: 100%;
}

@media screen and (width <= 1023px) {
  .payments {
    justify-content: center;
  }
}

@media screen and (width <= 767px) {
  .payments {
    margin: -5px;
  }

  .payments__item {
    width: 120px;
    height: 53px;
    margin: 5px;
  }
}

.payment-descr {
  margin-bottom: 20px;
}

.is-main-page .payment-descr {
  margin-bottom: 16px;
}

.payment-descr__system {
  text-align: center;
  color: #3f3f3f;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
}

.is-main-page .payment-descr__system, .payment-descr__title {
  display: none;
}

.is-main-page .payment-descr__title {
  color: #fff;
  text-align: center;
  z-index: 2;
  width: 100%;
  font-size: 24px;
  font-weight: 900;
  line-height: 28px;
  display: block;
  position: relative;
}

.payment-descr__amounts {
  justify-content: center;
  display: flex;
}

.is-main-page .payment-descr__amounts {
  display: none;
}

.payment-descr__amount-item + .payment-descr__amount-item {
  margin-left: 20px;
}

.payment-descr__amount-item {
  color: #413034cc;
  align-items: center;
  font-size: 14px;
  line-height: 1.2;
  display: flex;
}

.is-main-page .payment-descr__amount-item {
  display: none;
}

.payment-descr__value {
  font-weight: bold;
}

.payment-notification {
  color: #3f3f3f;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
}

.payment-notification ol {
  margin: 0;
}

.payment-notification ol li {
  margin-left: 24px;
}

.payment-notification ol li::marker {
  font-weight: 600;
}

.payment-notification ol li:not(:last-child) {
  margin-bottom: 8px;
}

.payment-form__amount {
  width: 100%;
  max-width: 290px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.hide-amount .payment-form__amount {
  display: none;
}

.is-main-page .payment-form__amount {
  width: 227px;
  margin-right: unset;
  margin-left: 20px;
}

.is-main-page .payment-form__amount:before {
  content: "";
  z-index: 20;
  background: url("/joxi/yoyo/coin.png") 0 0 / 100% no-repeat;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  display: block;
  position: absolute;
  top: 50%;
  left: 10px;
}

.is-main-page .payment-form__amount input {
  text-align: center;
  color: #404040;
  border-radius: 60px;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
  line-height: 50px;
}

.is-main-page .payment-form__amount input::placeholder {
  color: #0000;
  font-size: 20px;
  line-height: 50px;
}

.payment-form__amount-input-label {
  color: #3f3f3f;
  text-align: center;
  margin-bottom: 15px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.is-main-page .payment-form__amount-input-label, .payment-form__amount-cur {
  display: none;
}

.is-main-page .payment-form__amount-cur {
  z-index: 4;
  opacity: .4;
  font-size: 15px;
  font-weight: 900;
  line-height: 50px;
  display: block;
  position: absolute;
  top: 0;
  right: 51px;
}

.payment-form__btn-wrap {
  text-align: center;
  max-width: 290px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.is-main-page .payment-form__btn-wrap {
  margin-top: 0;
}

.payment-form__btn {
  width: 100%;
  max-width: 290px;
}

.payment-form__btn.is-dark, .is-main-page .payment-form__btn {
  display: none;
}

.is-main-page .payment-form__btn.is-dark {
  text-align: left;
  z-index: 25;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 54px;
  width: 169px;
  height: 50px;
  padding: 0 48px 0 30px;
  font-size: 18px;
  font-weight: 900;
  line-height: 21px;
  display: block;
  position: absolute;
  bottom: 16px;
  right: 204px;
  overflow: hidden;
  box-shadow: 0 10px 10px #0000004d;
}

.is-main-page .payment-form__btn.is-dark:after {
  content: "";
  background-image: url("/img/arrow-white.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 21px;
  height: 12px;
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.payment-form__fields-wrap {
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -5px 20px;
  display: flex;
}

.payment-form__fields-title {
  text-align: center;
  color: #3f3f3f;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.payment-form__field {
  width: calc(50% - 10px);
  max-width: 290px;
  margin-left: 5px;
  margin-right: 5px;
}

.payment-form__fields-wrap .payment-form__field:last-child:nth-child(2n) {
  width: 100%;
}

.payment-form__description {
  color: #ea4642;
  padding-top: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

@media only screen and (width <= 539px) {
  .payment-form__field {
    width: 100%;
  }
}

.linda-has-popup, .linda-has-popup body {
  height: 100%;
  position: relative;
  overflow: hidden;
}

@media screen and (width <= 767px) {
  .open-left-menu, .open-left-menu body {
    height: 100%;
    position: relative;
    overflow: hidden;
  }
}

.page-title {
  text-align: center;
  color: #413034cc;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

@media screen and (width <= 1439px) {
  .page-title {
    margin-top: 20px;
    margin-bottom: 15px;
  }
}

@media only screen and (width <= 539px) {
  .page-title {
    font-size: 18px;
  }
}

.popup {
  z-index: 1000;
  background-color: #3e3e3ecc;
  justify-content: center;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  position: fixed;
  inset: 0;
  overflow-y: scroll;
}

.popup.is-hidden {
  visibility: hidden;
}

.popup__content {
  background-color: #ffd180;
  border-radius: 10px;
  max-width: 350px;
  margin: 100px auto 20px;
  padding: 30px 30px 40px;
  position: relative;
}

.popup__close {
  z-index: 100;
  background-color: #0000;
  border: none;
  transition: all .2s;
  position: absolute;
  top: -30px;
  right: -30px;
}

.popup__close svg {
  fill: #fff;
  width: 28px;
  height: 28px;
}

.popup__close:hover {
  transform: rotate(-360deg);
}

.popup__logo {
  width: 135px;
  height: 54px;
  margin: 0 auto;
}

.popup__logo svg {
  width: 100%;
  height: 100%;
}

.popup__title {
  color: #3e3e3e;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}

.popup__text {
  color: #3e3e3e;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.76;
}

@media only screen and (width <= 539px) {
  .popup {
    background: #3e3e3e;
  }

  .popup__content {
    border-radius: 0;
    max-width: 320px;
    margin-top: 69px;
    padding: 30px 15px 40px;
  }

  .popup__close {
    top: -49px;
    right: 20px;
  }
}

.popup-cashier {
  max-width: 410px;
}

.popup-cashier .company {
  text-align: center;
  color: #3f3f3f;
  font-size: 10px;
}

@media only screen and (width <= 539px) {
  .popup-cashier__content {
    max-width: 410px;
  }
}

.popup-rule {
  background-color: #f9fbe7;
  width: 100%;
  max-width: 635px;
  height: calc(100% - 190px);
  min-height: 320px;
  max-height: 835px;
  margin: 104px auto 0;
}

.popup-rule__content {
  color: #3f3f3f;
  background-color: #0000;
  max-width: 100%;
  font-size: 14px;
  line-height: 1.76;
  overflow: hidden;
}

.popup-rule__content p {
  margin: 0 0 15px;
}

.popup-rule__content p:last-child {
  margin: 0;
}

.popup-rule__content table {
  max-width: 100%;
  width: auto !important;
}

.popup-rule__wrap {
  height: calc(100% - 110px);
  overflow: hidden;
}

.popup-rule__wrap.is-flex {
  flex-direction: column;
  display: flex;
}

.popup-rule__block {
  height: 100%;
  padding-right: 25px;
  overflow-y: auto;
}

.popup-rule__close-btn-wrap {
  text-align: center;
  margin-top: 20px;
}

@media screen and (width <= 767px) {
  .popup-rule {
    border-radius: 0;
    max-width: 100%;
  }

  .popup-rule__close {
    top: -49px;
    right: 20px;
  }

  .popup-rule__close-btn-wrap {
    margin-top: 20px;
  }

  .popup-rule__block {
    margin-right: -37px;
    padding: 0 37px 0 0;
  }
}

@media only screen and (width <= 539px) {
  .popup-rule {
    height: calc(100% - 70px);
    margin: 68px auto 0;
  }
}

.modal {
  text-align: center;
  z-index: 3000;
  max-height: 100%;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.modal__wrap {
  box-sizing: border-box;
  background: #3e3e3e;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  display: flex;
}

@media screen and (width <= 767px) {
  .modal__wrap {
    background: #3e3e3e;
  }
}

.modal__box {
  box-sizing: border-box;
  background: #ffd180;
  border-radius: 10px;
  width: 350px;
  margin: 100px 0;
  padding: 30px 30px 32px;
  line-height: normal;
  position: relative;
  overflow: hidden;
}

@media screen and (width <= 767px) {
  .modal__box {
    border-radius: 0;
    margin: 0;
    padding: 30px 17px 40px;
  }
}

.modal__content {
  width: 100%;
  margin: 0 auto;
}

.modal__title {
  color: #3e3e3e;
  text-align: left;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
}

.modal__text {
  color: #3e3e3e;
  text-align: left;
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.76;
}

.modal__timer {
  border-bottom: 1px solid #3f3f3f33;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0 8px;
  padding: 10px 0;
  display: flex;
}

.modal__timer-text {
  color: #66554f;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
}

.modal__timer-count {
  text-align: right;
  color: #3e3e3e;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
}

.modal__sums {
  margin-bottom: 10px;
}

.modal__sum {
  text-transform: uppercase;
  text-align: center;
  color: #aa69ac;
  font-size: 32px;
  font-weight: 900;
}

.modal__currency {
  display: inline-block;
}

.modal__btns {
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  font-size: 16px;
  display: flex;
}

.modal__btns-item {
  width: 290px;
  margin-bottom: 14px;
}

.modal__btns-item:last-child {
  margin-bottom: 0;
}

.modal .has-margin-top-20 {
  margin-top: 20px;
}

.date-picker-wrapper {
  z-index: 4;
  background: #f9fbe7;
  border-radius: 10px;
  position: absolute;
  box-shadow: 0 10px 20px #00000026, 0 3px 4px #00000026;
}

.date-picker-wrapper .month-wrapper {
  position: relative;
  overflow: hidden;
  width: 640px !important;
}

.date-picker-wrapper .month-wrapper table {
  float: left;
  width: 270px;
}

.date-picker-wrapper .month-wrapper tbody {
  border-bottom: 10px solid #0000;
}

.date-picker-wrapper .month-wrapper tbody td {
  cursor: pointer;
}

.date-picker-wrapper .month-wrapper .day {
  text-align: center;
  color: #3e3e3e;
  border-radius: 15px;
  height: 30px;
  padding: 9px 0;
  font-size: 12px;
  line-height: 12px;
}

.date-picker-wrapper .month-wrapper .day.real-today:not(.checked), .date-picker-wrapper .month-wrapper .day.real-today:not(.hovering), .date-picker-wrapper .month-wrapper .day.checked, .date-picker-wrapper .month-wrapper .day.toMonth.hovering {
  background-color: #fbce7e;
}

.date-picker-wrapper .month-wrapper .first-date-selected, .date-picker-wrapper .month-wrapper .last-date-selected {
  color: #fff;
  background-color: #fbce7e;
  font-weight: 700;
}

.date-picker-wrapper.single-date.single-month .month-wrapper {
  width: 320px !important;
}

.date-picker-wrapper .caption {
  background-color: #f9fbe7;
}

.date-picker-wrapper .month-name {
  text-transform: capitalize;
  color: #ab69ad;
  height: 64px;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
}

.date-picker-wrapper .prev, .date-picker-wrapper .next {
  float: left;
  cursor: pointer;
  background-color: #f9fbe7;
  background-image: url("/img/arr_b-dark.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 11px 9px;
  width: 24px;
  height: 40px;
  margin-top: 11px;
  font-size: 0;
}

.date-picker-wrapper .prev {
  transform: translate(30px)rotate(90deg);
}

.date-picker-wrapper .next {
  transform: translate(-30px)rotate(270deg);
}

.date-picker-wrapper .week-name {
  letter-spacing: .05em;
  text-transform: uppercase;
  color: #3f3f3f;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.date-picker-wrapper .week-name th {
  width: 38px;
  height: 35px;
}

.date-picker-wrapper .date-range-length-tip {
  color: #fff;
  background-color: #3e3e3e;
  border-radius: 2px;
  margin-top: -4px;
  margin-left: -8px;
  padding: 0 6px;
  font-size: 12px;
  line-height: 16px;
  display: none;
  position: absolute;
}

.date-picker-wrapper .date-range-length-tip:after {
  content: "";
  border-top: 4px solid #3e3e3e;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  margin-left: -4px;
  position: absolute;
  bottom: -4px;
  left: 50%;
}

.date-picker-wrapper .select-wrapper {
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.date-picker-wrapper .month-element {
  vertical-align: middle;
  display: inline-block;
}

.date-picker-wrapper .select-wrapper select {
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  text-transform: inherit;
  color: inherit;
  cursor: pointer;
  appearance: none;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
  filter: alpha(opacity= 1);
  opacity: .01;
  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: -1px;
  left: 0;
}

.date-picker-wrapper .month-wrapper table div.day.lastMonth, .date-picker-wrapper .month-wrapper table div.day.nextMonth {
  color: #999;
  cursor: default;
}

.date-picker-wrapper .month-wrapper table .day.has-tooltip {
  cursor: help !important;
}

.date-picker-wrapper .month-wrapper table .day.has-tooltip .tooltip, .date-picker-wrapper .time label {
  white-space: nowrap;
}

.date-picker-wrapper .month-wrapper table .day.lastMonth, .date-picker-wrapper .month-wrapper table .day.nextMonth, .date-picker-wrapper .selected-days {
  display: none;
}

.date-picker-wrapper .time {
  position: relative;
}

.date-picker-wrapper.single-month .time {
  display: block;
}

.date-picker-wrapper .hide {
  display: none;
}

.bank-id__frame {
  --btn-radius: 100px;
  --btn-radius-hover: 100px;
  --divider-text-bg: #ffd180;
  --dialog-title-weight: 800;
  --dialog-title-color: #3e3e3e;
  --divider-bg: #3f3f3f33;
  --description-text-color: #66554f;
  --divider-text-color: #66554f;
  --btn-weight: 700;
}

.g-input-bankid {
  text-shadow: 0 4px #00000040;
  color: #fff;
  text-align: center;
  background-color: #5a4a41;
  border: none;
  border-radius: 30px;
  height: 60px;
  padding: 0 50px;
  font-size: 36px;
  font-weight: 900;
  line-height: 60px;
}

.g-input-bankid:hover {
  background-color: #5a4a41b3;
}

.g-input-bankid:focus {
  background-color: #f9fbe7;
}

.g-input-bankid:focus + span {
  text-shadow: 0 2px #0000001a;
  color: #66554f;
}

.g-input-bankid.ng-invalid.ng-touched {
  color: #3f3f3f;
  text-shadow: 0 2px #0000001a;
  background-color: #f9fbe7;
  border: 2px solid #ea4642;
}

.g-input-bankid.ng-invalid.ng-touched + span {
  text-shadow: 0 2px #0000001a;
  color: #66554f;
}

.g-input-bankid.ng-valid {
  border: none;
}

.g-input-bankid.ng-valid.ng-touched {
  color: #3f3f3f;
  text-shadow: 0 2px #0000001a;
  background-color: #f9fbe7;
  border: 2px solid #35d290;
}

.g-input-bankid.ng-valid.ng-touched + span {
  text-shadow: 0 2px #0000001a;
  color: #66554f;
}

@media screen and (width <= 767px) {
  .g-input-bankid {
    width: 290px;
  }
}

.bankid-enter-amount-field {
  text-align: center;
  min-height: 82px;
}

.bankid-enter-amount-field__label {
  width: 304px;
  height: 60px;
  margin: 0 auto;
  display: block;
  position: relative;
}

.bankid-enter-amount-field__cur {
  text-shadow: 0 4px #00000040;
  color: #fff;
  z-index: 3;
  font-size: 18px;
  font-weight: 900;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
}

.bankid-enter-amount-error {
  text-align: center;
  width: 304px;
  margin: 0 auto;
  padding: 3px 20px 0;
}

.bankid-enter-amount-error__text {
  color: #f9fbe7;
  width: 100%;
  max-width: 100%;
  font-size: 12px;
  line-height: normal;
}

.bankid-btns-choice-amount {
  justify-content: space-between;
  display: flex;
}

.bankid-btns-choice-amount__btn {
  color: #66554f;
  cursor: pointer;
  white-space: nowrap;
  background-color: #f9fbe7;
  border: none;
  border-radius: 10px;
  min-width: 124px;
  height: 45px;
  margin-right: 15px;
  padding: 0 7px;
  font-size: 18px;
  font-weight: 600;
  line-height: 45px;
  transition: background-color .2s;
}

.bankid-btns-choice-amount__btn:last-child {
  margin: 0;
}

.bankid-btns-choice-amount__btn:hover {
  background-color: #ffd180;
}

.bankid-btns-choice-amount__btn.active {
  color: #fff;
  cursor: default;
  background-color: #ffa800;
}

@media screen and (width <= 767px) {
  .bankid-btns-choice-amount {
    margin: 0 -7px 15px;
  }
}

@media only screen and (width <= 539px) {
  .bankid-btns-choice-amount__btn {
    min-width: 94px;
    height: 37px;
    margin-right: 5px;
    font-size: 16px;
    line-height: 37px;
  }
}

html {
  --rg-bar-height: 28px;
}

[disabled], a.is-disabled {
  pointer-events: none;
  cursor: default;
}

html.linda-has-modal body, html.linda-has-popup body {
  position: relative;
  overflow: hidden !important;
}

html.linda-has-modal body:before, html.linda-has-popup body:before {
  content: "";
  z-index: 998;
  background-color: #000c;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

body.linda-has-user .show-guest, body:not(.linda-has-user) .show-user, .is-hidden {
  display: none !important;
}

.is-block {
  display: block !important;
}

.has-full-width {
  width: 100% !important;
}

.has-auto-width {
  width: fit-content !important;
}

.has-text-center {
  text-align: center !important;
}

.has-text-right {
  text-align: right !important;
}

.has-text-left {
  text-align: left !important;
}

.is-center {
  margin: 0 auto !important;
}

.has-no-padding {
  padding: 0 !important;
}

.is-relative {
  position: relative;
}

.top-0 {
  margin-top: 0 !important;
}

.bottom-0 {
  margin-bottom: 0 !important;
}

.top-1 {
  margin-top: 1px !important;
}

.bottom-1 {
  margin-bottom: 1px !important;
}

.top-2 {
  margin-top: 2px !important;
}

.bottom-2 {
  margin-bottom: 2px !important;
}

.top-3 {
  margin-top: 3px !important;
}

.bottom-3 {
  margin-bottom: 3px !important;
}

.top-4 {
  margin-top: 4px !important;
}

.bottom-4 {
  margin-bottom: 4px !important;
}

.top-5 {
  margin-top: 5px !important;
}

.bottom-5 {
  margin-bottom: 5px !important;
}

.top-6 {
  margin-top: 6px !important;
}

.bottom-6 {
  margin-bottom: 6px !important;
}

.top-7 {
  margin-top: 7px !important;
}

.bottom-7 {
  margin-bottom: 7px !important;
}

.top-8 {
  margin-top: 8px !important;
}

.bottom-8 {
  margin-bottom: 8px !important;
}

.top-9 {
  margin-top: 9px !important;
}

.bottom-9 {
  margin-bottom: 9px !important;
}

.top-10 {
  margin-top: 10px !important;
}

.bottom-10 {
  margin-bottom: 10px !important;
}

.top-11 {
  margin-top: 11px !important;
}

.bottom-11 {
  margin-bottom: 11px !important;
}

.top-12 {
  margin-top: 12px !important;
}

.bottom-12 {
  margin-bottom: 12px !important;
}

.top-13 {
  margin-top: 13px !important;
}

.bottom-13 {
  margin-bottom: 13px !important;
}

.top-14 {
  margin-top: 14px !important;
}

.bottom-14 {
  margin-bottom: 14px !important;
}

.top-15 {
  margin-top: 15px !important;
}

.bottom-15 {
  margin-bottom: 15px !important;
}

.top-16 {
  margin-top: 16px !important;
}

.bottom-16 {
  margin-bottom: 16px !important;
}

.top-17 {
  margin-top: 17px !important;
}

.bottom-17 {
  margin-bottom: 17px !important;
}

.top-18 {
  margin-top: 18px !important;
}

.bottom-18 {
  margin-bottom: 18px !important;
}

.top-19 {
  margin-top: 19px !important;
}

.bottom-19 {
  margin-bottom: 19px !important;
}

.top-20 {
  margin-top: 20px !important;
}

.bottom-20 {
  margin-bottom: 20px !important;
}

.top-21 {
  margin-top: 21px !important;
}

.bottom-21 {
  margin-bottom: 21px !important;
}

.top-22 {
  margin-top: 22px !important;
}

.bottom-22 {
  margin-bottom: 22px !important;
}

.top-23 {
  margin-top: 23px !important;
}

.bottom-23 {
  margin-bottom: 23px !important;
}

.top-24 {
  margin-top: 24px !important;
}

.bottom-24 {
  margin-bottom: 24px !important;
}

.top-25 {
  margin-top: 25px !important;
}

.bottom-25 {
  margin-bottom: 25px !important;
}

.top-26 {
  margin-top: 26px !important;
}

.bottom-26 {
  margin-bottom: 26px !important;
}

.top-27 {
  margin-top: 27px !important;
}

.bottom-27 {
  margin-bottom: 27px !important;
}

.top-28 {
  margin-top: 28px !important;
}

.bottom-28 {
  margin-bottom: 28px !important;
}

.top-29 {
  margin-top: 29px !important;
}

.bottom-29 {
  margin-bottom: 29px !important;
}

.top-30 {
  margin-top: 30px !important;
}

.bottom-30 {
  margin-bottom: 30px !important;
}

.top-31 {
  margin-top: 31px !important;
}

.bottom-31 {
  margin-bottom: 31px !important;
}

.top-32 {
  margin-top: 32px !important;
}

.bottom-32 {
  margin-bottom: 32px !important;
}

.top-33 {
  margin-top: 33px !important;
}

.bottom-33 {
  margin-bottom: 33px !important;
}

.top-34 {
  margin-top: 34px !important;
}

.bottom-34 {
  margin-bottom: 34px !important;
}

.top-35 {
  margin-top: 35px !important;
}

.bottom-35 {
  margin-bottom: 35px !important;
}

.top-36 {
  margin-top: 36px !important;
}

.bottom-36 {
  margin-bottom: 36px !important;
}

.top-37 {
  margin-top: 37px !important;
}

.bottom-37 {
  margin-bottom: 37px !important;
}

.top-38 {
  margin-top: 38px !important;
}

.bottom-38 {
  margin-bottom: 38px !important;
}

.top-39 {
  margin-top: 39px !important;
}

.bottom-39 {
  margin-bottom: 39px !important;
}

.top-40 {
  margin-top: 40px !important;
}

.bottom-40 {
  margin-bottom: 40px !important;
}

.top-41 {
  margin-top: 41px !important;
}

.bottom-41 {
  margin-bottom: 41px !important;
}

.top-42 {
  margin-top: 42px !important;
}

.bottom-42 {
  margin-bottom: 42px !important;
}

.top-43 {
  margin-top: 43px !important;
}

.bottom-43 {
  margin-bottom: 43px !important;
}

.top-44 {
  margin-top: 44px !important;
}

.bottom-44 {
  margin-bottom: 44px !important;
}

.top-45 {
  margin-top: 45px !important;
}

.bottom-45 {
  margin-bottom: 45px !important;
}

.top-46 {
  margin-top: 46px !important;
}

.bottom-46 {
  margin-bottom: 46px !important;
}

.top-47 {
  margin-top: 47px !important;
}

.bottom-47 {
  margin-bottom: 47px !important;
}

.top-48 {
  margin-top: 48px !important;
}

.bottom-48 {
  margin-bottom: 48px !important;
}

.top-49 {
  margin-top: 49px !important;
}

.bottom-49 {
  margin-bottom: 49px !important;
}

.top-50 {
  margin-top: 50px !important;
}

.bottom-50 {
  margin-bottom: 50px !important;
}

.top-51 {
  margin-top: 51px !important;
}

.bottom-51 {
  margin-bottom: 51px !important;
}

.top-52 {
  margin-top: 52px !important;
}

.bottom-52 {
  margin-bottom: 52px !important;
}

.top-53 {
  margin-top: 53px !important;
}

.bottom-53 {
  margin-bottom: 53px !important;
}

.top-54 {
  margin-top: 54px !important;
}

.bottom-54 {
  margin-bottom: 54px !important;
}

.top-55 {
  margin-top: 55px !important;
}

.bottom-55 {
  margin-bottom: 55px !important;
}

.top-56 {
  margin-top: 56px !important;
}

.bottom-56 {
  margin-bottom: 56px !important;
}

.top-57 {
  margin-top: 57px !important;
}

.bottom-57 {
  margin-bottom: 57px !important;
}

.top-58 {
  margin-top: 58px !important;
}

.bottom-58 {
  margin-bottom: 58px !important;
}

.top-59 {
  margin-top: 59px !important;
}

.bottom-59 {
  margin-bottom: 59px !important;
}

.top-60 {
  margin-top: 60px !important;
}

.bottom-60 {
  margin-bottom: 60px !important;
}

.is-capitalize {
  text-transform: capitalize;
}

.is-uppercase {
  text-transform: uppercase;
}

.is-lowercase {
  text-transform: lowercase;
}

.is-underlined {
  text-decoration: underline;
}

.has-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.has-no-scrollbar {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.has-no-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

body.no-min-width {
  min-width: unset !important;
}

.btn-bankid {
  cursor: pointer;
  color: #fff;
  background-color: #01c02a;
  border: none;
  border-radius: 40px;
  align-items: center;
  width: 268px;
  max-width: 100%;
  height: 48px;
  margin: 0 auto;
  padding: 0 10px;
  font-size: 22px;
  font-weight: 700;
  line-height: 48px;
  display: flex;
  position: relative;
  box-shadow: 0 10px 10px #0003;
}

.btn-bankid:hover {
  background-color: #20cb4a;
}

.btn-bankid.no-animate {
  background-image: var(--icon-btn-arrow);
  background-position: right 20px center;
  background-repeat: no-repeat;
  background-size: 32px;
}

.btn-bankid__text {
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  margin: 0 40px;
  display: block;
  overflow: hidden;
}

.btn-bankid__pay {
  background: var(--icon-bankid) no-repeat center / contain;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 12px;
  left: 10px;
  transform: translateX(10px);
}

.btn-bankid__arr {
  background: var(--icon-btn-arrow) no-repeat center / contain;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 8px;
  right: -2px;
  transform: translateX(-10px);
}

.item-limit {
  background-color: #faeab9;
  border-radius: 12px;
  flex-direction: column;
  align-self: flex-start;
  height: 258px;
  padding: 20px 16px;
  display: flex;
  overflow: hidden;
}

.item-limit.is-self-ctrl {
  width: 420px;
  margin-left: auto;
  margin-right: auto;
}

.item-limit__text {
  color: #3e3e3e;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  overflow: hidden;
}

.item-limit__value {
  color: #3e3e3e;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-bottom: 8px;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  overflow: hidden;
}

.item-limit__btn {
  width: 100%;
  margin-top: 12px;
  display: block;
}

.item-limit__link {
  margin-top: 20px;
  color: #fff !important;
}

.item-limit__notice {
  color: #3e3e3e;
  overflow-wrap: break-word;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  display: -webkit-box;
  overflow: hidden;
}

.item-limit__span {
  text-transform: lowercase;
}

.item-limit__form {
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 12px;
  display: flex;
}

.item-limit__wrapper {
  margin-top: auto;
}

.item-limit form:not(.set-editmode) .show-on-editMode, .item-limit form.set-editmode .hide-on-editMode {
  display: none;
}

.meta-box {
  box-sizing: border-box;
  color: #413034cc;
  background-color: #fff5da;
  border: 1px solid #ffbe17cc;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
  padding: 12px 15px;
  font-size: 14px;
  font-weight: 700;
  line-height: 140%;
  display: flex;
  position: relative;
}

.meta-box:hover {
  box-shadow: 0 4px 10px #76676133;
}

.meta-box__content {
  box-sizing: border-box;
  z-index: 1;
  align-items: center;
  width: calc(100% - 28px);
  display: flex;
  position: relative;
}

.meta-box__icon {
  filter: brightness(0%) invert(36%) sepia(7%) saturate(840%) hue-rotate(322deg) brightness(92%) contrast(89%);
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  display: block;
}

.meta-box__text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.meta-box__count {
  z-index: 1;
  text-align: right;
  color: #41303480;
  flex-shrink: 0;
  width: 28px;
  font-weight: 400;
  position: relative;
}

.secure-block {
  color: #3e3e3e;
  justify-content: center;
  margin: 0 auto;
  display: flex;
}

.secure-block__content {
  background-image: var(--icon-secure);
  background-position: 0 1px;
  background-repeat: no-repeat;
  background-size: 14px 16px;
  padding-left: 22px;
}

.secure-block__title {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  overflow: hidden;
}

.secure-block__text {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  overflow: hidden;
}

.switcher {
  position: relative;
}

.switcher__label {
  z-index: 0;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 24px;
  width: 50px;
  height: 26px;
  transition: border-color .2s ease-in-out, background-color .2s ease-in-out;
  display: block;
  position: relative;
}

.switcher__label:before {
  content: "";
  background-color: #01c02a;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  transition: transform .2s ease-in-out, background-color .2s ease-in-out;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.switcher input {
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.switcher input:checked + .switcher__label {
  background-color: #01c02a;
  border-color: #01c02a;
}

.switcher input:checked + .switcher__label:before {
  background-color: #fff;
  transform: translateX(100%);
}

.badge {
  --badge-height: 49px;
  --badge-font-size: 24px;
  --badge-padding-x: 24px;
  box-sizing: border-box;
  min-width: 208px;
  max-width: fit-content;
  height: var(--badge-height);
  padding: 0 var(--badge-padding-x);
  font-size: var(--badge-font-size);
  line-height: var(--badge-height);
  color: #66396f;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  text-align: center;
  background-image: url("/joxi/yoyo/elements/badges/rect-bg.svg");
  background-size: 100% 100%;
  font-weight: 900;
  display: block;
  position: relative;
  overflow: hidden;
}

.btn-app {
  background-size: 100% 100%;
  width: 126px;
  height: 42px;
  transition: transform .2s;
  display: block;
}

.is-apple .btn-app {
  background-image: url("/img/app-store.svg");
}

.is-google .btn-app {
  background-image: url("/img/google-play.svg");
}

.btn-app:hover {
  transition: transform .2s;
  transform: scale(1.05);
}

.btn-circle {
  --rect-btn-size: 32px;
  --rect-btn-icon-size: 22px;
  vertical-align: top;
  width: var(--rect-btn-size);
  height: var(--rect-btn-size);
  color: #9746a5;
  background-color: #0000;
  border: 1px solid #9746a5;
  border-radius: 50%;
  transition: border-color .2s ease-in-out, background-color .2s ease-in-out;
  display: inline-block;
  position: relative;
}

.btn-circle__icon {
  width: var(--rect-btn-icon-size);
  height: var(--rect-btn-icon-size);
  fill: currentColor;
  transition: fill .2s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-circle.is-green {
  color: #fff;
  background-color: #01c02a;
  border-color: #01c02a;
}

.btn-circle.is-green:hover {
  background-color: #009d22;
}

.btn-circle.is-fav {
  color: #9746a5;
}

.btn-circle.is-fav .btn-circle__icon {
  fill: none;
  stroke-width: 2px;
  stroke: currentColor;
  width: calc(var(--rect-btn-icon-size)  - 2px);
  height: calc(var(--rect-btn-icon-size)  - 2px);
}

.btn-circle.is-fav:hover .btn-circle__icon {
  fill: currentColor;
}

.btn-circle.is-fav.is-active {
  color: #9746a5;
  background-color: #0000;
}

.btn-circle.is-fav.is-active .btn-circle__icon {
  fill: currentColor;
}

.btn-circle:hover, .btn-circle.is-active {
  color: #fff;
  background-color: #9746a5;
}

.btn-circle.is-m {
  --rect-btn-size: 40px;
  --rect-btn-icon-size: 24px;
}

.btn-fill {
  --btn-fill: transparent;
  --btn-fill-active: transparent;
  appearance: none;
  box-sizing: border-box;
  background-color: #0000;
  background-color: var(--btn-fill);
  max-width: fill-available;
  border: 1px;
  border: 1px solid var(--btn-fill);
  vertical-align: top;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  cursor: pointer;
  border-radius: 60px;
  height: 36px;
  padding: 0 20px;
  font-family: Roboto, Arial, BlinkMacSystemFont, -apple-system, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 34px;
  transition: background-color .2s ease-in-out, border-color .2s ease-in-out, color .2s ease-in-out;
  display: inline-block;
  overflow: hidden;
}

.btn-fill__icon {
  vertical-align: middle;
  fill: currentColor;
  width: 1.286em;
  height: 1.286em;
  margin-right: .2857em;
  display: inline-block;
  position: relative;
  top: -.1em;
}

.btn-fill.is-green {
  --btn-fill: #01c02a;
  --btn-fill-active: #009d22;
}

.btn-fill.is-violet {
  --btn-fill: #9746a5;
  --btn-fill-active: #81348e;
}

.btn-fill.is-red {
  --btn-fill: #ea4642;
  --btn-fill-active: transparent;
}

.btn-fill.is-red:hover {
  border-color: var(--btn-fill);
  color: var(--btn-fill);
}

.btn-fill.is-m {
  border-width: 1px;
  border-radius: 80px;
  height: 40px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 16px;
  line-height: 38px;
}

.btn-fill.is-l {
  border-width: 1px;
  border-radius: 80px;
  height: 60px;
  padding-left: 32px;
  padding-right: 32px;
  font-size: 24px;
  line-height: 58px;
}

.btn-fill:hover {
  background-color: var(--btn-fill-active);
  border-color: var(--btn-fill-active);
  box-shadow: 0 4px 15px #0003;
}

.btn-fill[disabled], .btn-fill.is-disabled {
  opacity: .6;
}

.btn-fill.is-full {
  width: 100%;
}

.btn-line {
  appearance: none;
  box-sizing: border-box;
  max-width: fill-available;
  vertical-align: top;
  color: #9746a5;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #9746a5;
  border-radius: 60px;
  height: 36px;
  padding: 0 20px;
  font-family: Roboto, Arial, BlinkMacSystemFont, -apple-system, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 34px;
  transition: background-color .2s ease-in-out, border-color .2s ease-in-out, color .2s ease-in-out;
  display: inline-block;
  overflow: hidden;
}

.btn-line__icon {
  vertical-align: middle;
  fill: currentColor;
  width: 1.286em;
  height: 1.286em;
  margin-right: .2857em;
  display: inline-block;
  position: relative;
  top: -.1em;
}

.btn-line.is-m {
  border-width: 1px;
  border-radius: 80px;
  height: 40px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 16px;
  line-height: 38px;
}

.btn-line.is-l {
  border-width: 1px;
  border-radius: 80px;
  height: 60px;
  padding-left: 32px;
  padding-right: 32px;
  font-size: 24px;
  line-height: 58px;
}

.btn-line:hover {
  color: #81348e;
  background-color: #0000;
  border-color: #81348e;
  box-shadow: 0 4px 15px #0003;
}

.btn-line[disabled], .btn-line.is-disabled {
  opacity: .6;
}

.btn-line.is-full {
  width: 100%;
}

.btn-link {
  --btn-link-fs: 12px;
  appearance: none;
  vertical-align: top;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  font-family: Roboto, Arial, BlinkMacSystemFont, -apple-system, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, sans-serif;
  font-size: var(--btn-link-fs);
  line-height: inherit;
  color: currentColor;
  background-color: #0000;
  border: none;
  border-radius: 0;
  padding: 0;
  font-weight: 500;
  text-decoration: underline;
  transition: color .2s ease-in-out;
  display: inline-block;
  overflow: hidden;
}

.btn-link:hover {
  text-decoration: none;
}

.btn-link__svg {
  width: var(--btn-link-fs);
  height: var(--btn-link-fs);
  fill: currentColor;
  flex-shrink: 0;
  margin-top: 1px;
  transition: transform .2s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.has-arrow.is-opened .btn-link__svg {
  margin-top: 0;
  transform: rotate(-180deg)translateY(50%);
}

.btn-link.is-white {
  color: #ffffffd9;
}

.btn-link.is-white:hover, .btn-link.is-white.is-active {
  color: #fff;
}

.btn-link.is-s {
  --btn-link-fs: 14px;
  line-height: 25px;
}

.btn-link.is-m {
  --btn-link-fs: 16px;
  line-height: 27px;
}

.btn-link.is-l {
  --btn-link-fs: 18px;
  line-height: 27px;
}

.btn-link.has-arrow {
  padding-right: calc(var(--btn-link-fs)  + 6px);
  position: relative;
  overflow: visible;
}

.btn-link.is-lh-inherit {
  line-height: inherit;
}

.container {
  --offset-x: 20px;
  width: calc(100% - var(--offset-x) * 2);
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.container.has-no-offset {
  --offset-x: 0;
}

@media (width >= 1440px) {
  .container {
    --offset-x: 35px;
  }

  .container.has-no-offset {
    --offset-x: 0;
  }
}

.lazy-img {
  display: block;
}

.lazy-img.is-lazy-loading:before {
  content: "";
  z-index: 1;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDQiIGhlaWdodD0iNDQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgc3Ryb2tlPSIjNjg2YjZjIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS13aWR0aD0iMiI+PGNpcmNsZSBjeD0iMjIiIGN5PSIyMiIgcj0iMSI+PGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iciIgYmVnaW49IjBzIiBkdXI9IjEuOHMiIHZhbHVlcz0iMTsgMjAiIGNhbGNNb2RlPSJzcGxpbmUiIGtleVRpbWVzPSIwOyAxIiBrZXlTcGxpbmVzPSIwLjE2NSwgMC44NCwgMC40NCwgMSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiLz48YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJzdHJva2Utb3BhY2l0eSIgYmVnaW49IjBzIiBkdXI9IjEuOHMiIHZhbHVlcz0iMTsgMCIgY2FsY01vZGU9InNwbGluZSIga2V5VGltZXM9IjA7IDEiIGtleVNwbGluZXM9IjAuMywgMC42MSwgMC4zNTUsIDEiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+PC9jaXJjbGU+PGNpcmNsZSBjeD0iMjIiIGN5PSIyMiIgcj0iMSI+PGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iciIgYmVnaW49Ii0wLjlzIiBkdXI9IjEuOHMiIHZhbHVlcz0iMTsgMjAiIGNhbGNNb2RlPSJzcGxpbmUiIGtleVRpbWVzPSIwOyAxIiBrZXlTcGxpbmVzPSIwLjE2NSwgMC44NCwgMC40NCwgMSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiLz48YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJzdHJva2Utb3BhY2l0eSIgYmVnaW49Ii0wLjlzIiBkdXI9IjEuOHMiIHZhbHVlcz0iMTsgMCIgY2FsY01vZGU9InNwbGluZSIga2V5VGltZXM9IjA7IDEiIGtleVNwbGluZXM9IjAuMywgMC42MSwgMC4zNTUsIDEiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+PC9jaXJjbGU+PC9nPjwvc3ZnPg==") 50% / 50px no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.lazy-img.is-lazy-loaded img {
  opacity: 1;
  width: 100%;
  height: 100%;
  transition: opacity .2s ease-in-out, transform .2s ease-in-out;
  transform: scale(1);
}

.lazy-img.is-lazy-loading img, .lazy-img.is-lazy-error img {
  opacity: 0;
  transform: scale(.9);
}

.block-title {
  color: #66554f;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  overflow: hidden;
}

.category-header {
  justify-content: space-between;
  align-items: center;
  height: 36px;
  display: flex;
}

.category-header__title {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #413034cc;
  margin: 0;
  padding: 0 12px 0 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  overflow: hidden;
}

.category-header__more {
  color: #9746a5;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 0;
  max-width: 35%;
  font-weight: 500;
  text-decoration: underline;
  display: block;
  overflow: hidden;
}

.category-header__more:hover {
  color: #81348e;
}

@media (width >= 1440px) {
  .category-header__title {
    font-size: 20px;
    line-height: 24px;
  }

  .category-header__more {
    font-size: 16px;
    line-height: 20px;
  }
}

.cookie {
  z-index: 999;
  color: #3e3e3e;
  width: 100%;
  margin: 0 auto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  position: fixed;
  bottom: 20px;
}

.cookie__wrap {
  background: #fbce7e;
  border-radius: 10px;
  align-items: center;
  max-width: 600px;
  min-height: 56px;
  margin: auto;
  padding: 8px 12px;
  display: flex;
  box-shadow: 0 0 15px #0000001a;
}

.cookie__left-wrap {
  flex-grow: 1;
  margin: 0 12px 0 0;
}

.cookie__link {
  appearance: none;
  color: #3e3e3e;
  background-color: #0000;
  border: none;
  border-radius: 0;
  padding: 0;
  font-size: 12px;
  text-decoration: underline;
}

.cookie__link:hover {
  cursor: pointer;
  text-decoration: none;
}

.cookie__btn {
  appearance: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  color: #fbfbfb;
  background: #9746a5;
  border: none;
  border-radius: 20px;
  width: fit-content;
  min-width: 140px;
  max-width: 220px;
  height: 40px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  overflow: hidden;
}

.cookie__btn:hover {
  cursor: pointer;
  background-color: #81348e;
}

#air-datepicker-global-container .air-datepicker {
  --adp-z-index: 1000;
  --adp-border-radius: 10px;
  --adp-width: 270px;
  --adp-padding: 8px;
  --adp-overlay-background-color: #f9fbe7;
  --adp-background-color: #f9fbe7;
  --adp-background-color-hover: #f9fbe7;
  --adp-cell-background-color-in-range: #fbce7e50;
  --adp-color: #404040;
  --adp-color-secondary: #ab69ad;
  --adp-nav-arrow-color: #404040;
  --adp-day-name-color: #404040;
  --adp-day-cell-height: 30px;
  --adp-cell-border-radius: 0;
  --adp-cell-background-color-hover: none;
  --adp-border-color: transparent;
  --adp-border-color-inner: transparent;
  background: #f9fbe7 !important;
  box-shadow: 0 10px 20px #00000026, 0 3px 4px #00000026 !important;
}

#air-datepicker-global-container .air-datepicker-nav--title {
  color: #ab69ad;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  text-transform: capitalize !important;
}

#air-datepicker-global-container .air-datepicker-cell.-focus-, #air-datepicker-global-container .air-datepicker-cell.-current- {
  color: #3e3e3e;
  background-color: #fbce7e;
  border-radius: 15px;
  padding: 9px 0;
}

#air-datepicker-global-container .air-datepicker-cell.-selected-, #air-datepicker-global-container .air-datepicker-cell.-selected-.-current- {
  border-radius: 15px;
  color: #fff !important;
  background: #fbce7e !important;
}

#air-datepicker-global-container .air-datepicker-body--day-name {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

#air-datepicker-global-container .air-datepicker-cell.-day-.-other-month- {
  visibility: hidden;
  pointer-events: none;
}

#air-datepicker-global-container .air-datepicker-nav--action:hover {
  background: none;
}

#air-datepicker-global-container .air-datepicker-nav--action path {
  stroke: #404040;
}

#air-datepicker-global-container .air-datepicker--pointer {
  display: none;
}

#air-datepicker-global-container .air-datepicker-cell.-range-from- {
  border-radius: 15px 0 0 15px;
}

#air-datepicker-global-container .air-datepicker-cell.-range-to- {
  border-radius: 0 15px 15px 0;
}

.modal-box {
  position: fixed;
  inset: 0;
  overflow: hidden auto;
  transform: translate3d(0, 0, 0);
}

.modal-box.is-popup {
  z-index: 999;
}

.modal-box.is-modal {
  z-index: 2000;
}

.modal-box-inner {
  background-color: #ffd180;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  height: auto;
  min-height: auto;
  margin: 60px auto;
  display: flex;
}

@media (width >= 1440px) {
  .modal-box-inner {
    max-width: 1200px;
  }
}

.modal-box-header {
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 68px;
  padding: 0 24px;
  display: flex;
  position: relative;
}

.modal-box-header__title {
  color: #3e3e3e;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 72px);
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 100%;
  overflow: hidden;
}

.modal-box-header__title-span {
  opacity: .6;
}

.modal-box-header__logo {
  background-image: url("/joxi/campobet/elements/logo/logo-lg.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 144px;
  height: 30px;
}

@media (width >= 1440px) {
  .modal-box-header {
    margin-bottom: 8px;
    padding: 0 70px;
  }
}

.modal-box-btn-icon {
  z-index: 5;
  background-color: #0000;
  border: none;
  width: 20px;
  height: 20px;
  padding: 0;
  transition: transform .2s;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.modal-box-btn-icon.is-close {
  filter: invert(64%) sepia(15%) saturate(1946%) hue-rotate(360deg) brightness(101%) contrast(76%);
}

.modal-box-btn-icon.is-close:hover {
  transform: translateY(-50%)rotate(-360deg);
}

.modal-box-btn-icon svg {
  width: 100%;
  height: 100%;
}

@media (width >= 1440px) {
  .modal-box-btn-icon.is-close {
    width: 24px;
    height: 24px;
    right: 28px;
  }
}

.modal-box-body {
  flex-grow: 1;
  overflow: hidden auto;
  transform: translate3d(0, 0, 0);
}

.page-confetti {
  width: 100%;
  height: 1px;
  position: relative;
}

.page-confetti:before {
  content: "";
  z-index: -1;
  background-image: url("/joxi/yoyo/blob.svg"), linear-gradient(#ffeaa1 0, #ffc600 100%);
  background-position: 50% -100px, center;
  background-repeat: no-repeat;
  background-size: 1100px, 100%;
  width: 100%;
  height: 120px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  mask-image: url("/img/mask.svg");
  mask-position: bottom;
  mask-size: 100% 100%;
  mask-repeat: no-repeat;
}

.page-confetti__img:before, .page-confetti__img:after {
  content: "";
  background: url("/joxi/yoyo/bnr-confetti.png") top / 100% no-repeat;
  width: 447px;
  height: 120px;
  display: block;
  position: absolute;
  top: 0;
}

.page-confetti__img:before {
  right: calc(50% + 85px);
  transform: scale(-1, 1);
}

.page-confetti__img:after {
  left: calc(50% + 85px);
}

.pnp-flow__form-btn {
  margin: 32px auto 52px;
  display: block;
}

.pnp-flow__content-iframe {
  margin-top: 16px;
  margin-bottom: 28px;
}

.pnp-flow__iframe iframe {
  border-radius: 10px;
  height: auto;
  min-height: 660px;
}

.rg-bar {
  background-color: #ebebeb;
  padding: 0 12px;
  box-shadow: inset 0 -2px 4px #00000021;
}

.rg-bar__wrap {
  height: var(--rg-bar-height);
  align-items: center;
  display: flex;
}

.rg-bar__item {
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #fff;
  background-position: 4px;
  background-repeat: no-repeat;
  background-size: 14px;
  align-items: center;
  height: 17px;
  padding: 0 4px 0 21px;
  display: flex;
  overflow: hidden;
  box-shadow: inset 0 0 0 .025em #020203;
}

.rg-bar__item.is-spelpaus {
  background-image: url("/joxi/campobet/elements/icons/rg-bar/control-spelpaus.svg");
}

.rg-bar__item.is-spelgranser {
  background-image: url("/joxi/campobet/elements/icons/rg-bar/control-spelgranser.svg");
}

.rg-bar__item.is-sjalvtest {
  background-image: url("/joxi/campobet/elements/icons/rg-bar/control-sjalvtest.svg");
}

.rg-bar__text {
  color: #020203;
  font-size: 8px;
}

.rg-bar__btns {
  grid-gap: 4px;
  flex-shrink: 0;
  grid-template-columns: repeat(3, minmax(min-content, min-content));
  max-width: 100%;
  margin-left: auto;
  display: grid;
}

.rg-bar__timer {
  margin-right: 8px;
}

.seo-game {
  background-color: #fdffee;
  padding: 25px 35px;
}

.seo-container-v2 {
  background: none;
  padding: 0;
}

.seo-container-v2 section {
  border: 1px solid #ffc200;
  border-radius: 10px;
}

.seo-container-v2 section:not(:last-child) {
  margin-bottom: 20px;
}

.seo-container-v2 h1, .seo-container-v2 h2 {
  text-align: center;
  cursor: pointer;
  color: #aa69ac;
  background-color: #0000;
  border-radius: 10px 10px 0 0;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  margin: 0;
  padding: 12px 60px;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.4em;
  transition: background-color .3s;
  display: flex;
  position: relative;
}

.seo-container-v2 h1:after, .seo-container-v2 h2:after {
  content: "";
  background-image: var(--icon-seo-arrow);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 19px;
  height: 12px;
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
}

.seo-container-v2 h1.is-open, .seo-container-v2 h2.is-open {
  background-color: #ffd07e;
}

.seo-container-v2 h1.is-open:after, .seo-container-v2 h2.is-open:after {
  background-image: var(--icon-seo-arrow-opened);
}

.seo-container-v2 h1.is-open ~ div, .seo-container-v2 h2.is-open ~ div {
  display: block;
}

.seo-container-v2 div {
  width: 100%;
  max-width: 816px;
  margin: 0 auto;
  padding: 20px 20px 10px;
  display: none;
  overflow: hidden;
}

.seo-container-v2 div h2 {
  color: #aa69ac;
  background-image: none;
  max-width: 100%;
  margin: 10px 0;
  padding: 0;
  font-size: 20px;
  line-height: 25px;
  position: static;
}

.seo-container-v2 ul {
  margin: 10px 0 10px 36px;
  list-style: disc;
}

.seo-container-v2 ul li {
  color: #3f3f3f;
  margin: 7px 0;
  font-size: 14px;
  line-height: 1.8em;
  list-style: disc;
}

.seo-container-v2 ul li a {
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
}

.seo-container-v2 p {
  color: #3f3f3f;
  text-align: justify;
  margin: 0 0 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8em;
}

.seo-container-v2 a {
  color: #aa69ac;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.8em;
  text-decoration: underline;
}

.sweden-deposit-limit-form {
  color: #3e3e3e;
}

.sweden-deposit-limit-form__title {
  text-align: center;
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
}

.has-terms .sweden-deposit-limit-form__title {
  padding-top: 11px;
}

.sweden-deposit-limit-form__text {
  text-align: center;
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 16px;
}

.sweden-deposit-limit-form__limits {
  grid-column-gap: 12px;
  grid-template-columns: repeat(3, 1fr);
  margin: 12px 0 20px;
  display: grid;
}

.has-terms .sweden-deposit-limit-form__limits {
  margin-bottom: 0;
}

.sweden-deposit-limit-form__limit {
  border-radius: 12px;
  margin-top: 0;
  padding: 12px;
}

.sweden-deposit-limit-form__limit input {
  border: 1px solid #686b6c;
}

.sweden-deposit-limit-form__limit input:hover {
  border: 1px solid #19191d;
}

.sweden-deposit-limit-form__limit input.ng-invalid.ng-touched {
  border: 1px solid #e12d2d;
}

.sweden-deposit-limit-form__limit input.ng-not-empty.ng-valid {
  border: 1px solid #01b93c;
}

.sweden-deposit-limit-form__limit-label {
  text-align: center;
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.sweden-deposit-limit-form__limit-span {
  text-transform: uppercase;
}

.sweden-deposit-limit-form__info {
  background: #fce6b3 10px / 30px no-repeat;
  background-image: var(--icon-warning);
  border-radius: 10px;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px 10px 10px 50px;
}

.sweden-deposit-limit-form__info-text {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
}

.sweden-deposit-limit-form__info-text:first-child {
  margin-bottom: 10px;
}

.bankid-banner-amount {
  text-align: center;
  height: 50px;
  margin-bottom: 20px;
}

.bankid-banner-amount__label {
  width: 247px;
  height: 50px;
  margin: 0 auto;
  display: block;
  position: relative;
}

.bankid-banner-amount__label:before {
  content: "";
  z-index: 20;
  background: url("/joxi/yoyo/coin.png") 0 0 / 100% no-repeat;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  display: block;
  position: absolute;
  top: 50%;
  left: 15px;
}

.bankid-banner-amount__cur {
  color: #40404066;
  z-index: 3;
  margin-top: -9px;
  font-size: 15px;
  font-weight: 900;
  line-height: 18px;
  position: absolute;
  top: 50%;
  right: 25px;
}

.bankid-banner-amount__error {
  text-align: center;
  width: 304px;
  margin: 0 auto;
  padding: 3px 20px 0;
}

.bankid-banner-amount__error-text {
  color: #f9fbe7;
  width: 100%;
  max-width: 100%;
  font-size: 12px;
  line-height: normal;
}

.bankid-banner-amount__input {
  color: #404040;
  max-width: 247px;
  height: 50px;
  text-shadow: none;
  background-color: #fff;
  border-radius: 60px;
  padding: 0 53px;
  font-size: 27px;
  line-height: 50px;
  box-shadow: inset 0 .770316px 3.08126px #0000006e;
}

.bankid-banner-amount__input:hover {
  background-color: #fff;
}

.bankid-banner-choice-amount {
  z-index: 2;
  justify-content: center;
  margin: 20px 0 14px;
  display: flex;
  position: relative;
}

.bankid-banner-choice-amount__btn {
  color: #fff9;
  cursor: pointer;
  white-space: nowrap;
  background-color: #7a2a6a;
  border: none;
  border-radius: 28px;
  width: 102px;
  height: 40px;
  margin-right: 8px;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 900;
  line-height: 40px;
  transition: color .2s;
}

.bankid-banner-choice-amount__btn:last-child {
  margin-right: 0;
}

.bankid-banner-choice-amount__btn:hover {
  color: #ffd850;
}

.bankid-banner-choice-amount__btn.active {
  color: #ffd850;
  cursor: default;
}

.bankid-banner-form {
  z-index: 2;
  border-radius: 14px;
  width: 762px;
  margin: 0 auto;
  padding: 40px 33px;
  position: relative;
}

.bankid-banner-form:after {
  content: "";
  z-index: -2;
  background-image: linear-gradient(150deg, #a745b0 0, #700e66 100%);
  border-radius: 14px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 3px 8px #290b2866;
}

.bankid-banner-form__in {
  pointer-events: none;
  border-radius: 14px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.bankid-banner-form__in:before {
  content: "";
  z-index: -1;
  transform-origin: center;
  background-image: url("/joxi/sweden-yoyo/bankid-spinner.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 800px;
  height: 800px;
  animation-name: rotate;
  animation-duration: 60s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  top: calc(50% - 360px);
  left: calc(50% - 400px);
}

.bankid-banner-form__content {
  z-index: 10;
  width: 400px;
  padding: 0 32px;
  position: relative;
}

.bankid-banner-form__rules {
  margin-top: 35px;
}

.bankid-banner-form__img {
  background-image: url("/joxi/yoyo/fish_right.png"), url("/joxi/yoyo/bird_left.png");
  background-position: 100%, 0;
  background-repeat: no-repeat, no-repeat;
  background-size: 200px 200px, 200px 200px;
  width: 340px;
  height: 200px;
  position: absolute;
  top: 0;
  right: 40px;
}

.bankid-banner-form__btn {
  text-align: center;
  color: #fff;
  z-index: 20;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 54px;
  align-items: center;
  width: 57px;
  height: 57px;
  padding: 0;
  font-size: 22px;
  font-weight: 700;
  line-height: 57px;
  position: absolute;
  bottom: 0;
  right: 45%;
  overflow: hidden;
  box-shadow: 0 10px 10px #0000004d;
}

.bankid-banner-form__btn:after {
  content: "";
  background-image: url("/img/arrow-white.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 21px;
  height: 12px;
  display: block;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.bankid-banner-form__btn-text {
  line-height: 57px;
  display: block;
  position: absolute;
  top: 0;
  right: 69px;
}

.bankid-banner-form__trustly {
  background-image: var(--icon-trustly2);
  background-repeat: no-repeat;
  background-size: 100%;
  width: 88px;
  height: 30px;
  margin: 12px auto 6px;
}

.bankid-banner-form__heroes {
  z-index: 1;
  pointer-events: none;
  background-image: url("/joxi/yoyo/mex.png"), url("/joxi/yoyo/fish_right.png"), url("/joxi/yoyo/bird_left.png"), url("/joxi/yoyo/horse_left.png");
  background-position: 107px 123px, 36px 44px, right 116px top 167px, right 58px top 47px;
  background-repeat: no-repeat;
  background-size: 225px 220px, 126px 135px, 200px 176px, 138px 138px;
  width: 1460px;
  height: 380px;
  display: block;
  position: absolute;
  top: -48px;
  left: 50%;
  transform: translateX(-50%);
}

.bankid-banner-form__bg {
  z-index: -2;
  pointer-events: none;
  background-image: url("/joxi/yoyo/bnr-confetti.png"), url("/joxi/yoyo/bnr-confetti.png");
  background-position: right calc(50% + 500px) center, calc(50% + 500px);
  background-repeat: no-repeat;
  background-size: auto 100%, auto 100%;
  width: 100%;
  min-width: 2100px;
  height: 370px;
  display: block;
  position: absolute;
  top: -48px;
  left: 50%;
  transform: translateX(-50%);
}

.bankid-banner-promo {
  text-align: center;
}

.bankid-banner-promo__title {
  color: #fff9;
  font-size: 16px;
  line-height: 20px;
}

.bankid-banner-promo__description {
  color: #fff;
  overflow-wrap: break-word;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  margin-top: 4px;
  font-size: 32px;
  font-weight: 900;
  line-height: 36px;
  display: -webkit-box;
  overflow: hidden;
}

.bankid-banner-promo__btns {
  text-align: center;
  justify-content: center;
  height: 48px;
  margin-top: 16px;
  display: flex;
}

.bankid-banner-text {
  text-align: center;
  color: #fff6;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
}

.bankid-banner-text a {
  color: #fff6;
}

.bankid-banner-text a:hover {
  text-decoration: underline;
}

.bankid-banner {
  z-index: 1;
  background-color: #f9fbe7;
  min-height: 370px;
  margin-bottom: 25px;
  padding: 48px 0;
  position: relative;
  overflow: hidden;
}

.bankid-banner:before {
  content: "";
  z-index: -1;
  background-image: url("/joxi/yoyo/blob.svg"), linear-gradient(#ffeaa1 0, #ffc600 100%);
  background-position: 50% -100px, center;
  background-repeat: no-repeat;
  background-size: 1100px, 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  mask-image: url("/img/mask.svg");
  mask-position: bottom;
  mask-size: cover;
  mask-repeat: no-repeat;
}

#morph2 {
  visibility: hidden;
}

.animation-live {
  animation: 1.5s forwards bankidButtonAnimation;
  right: 0;
}

.animation-width {
  width: 214px;
  padding-left: 10px;
  transition: all .3s;
}

.bankid-morph {
  z-index: 1;
  width: 429px;
  height: 314px;
  margin-left: 20px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%)translateY(-10px);
}

.game-page-header {
  z-index: 2;
  box-sizing: border-box;
  background: linear-gradient(0deg, #f9fbe7 0% 100%), #fff;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 64px;
  padding: 8px 15px;
  display: flex;
  position: relative;
  box-shadow: 1px 4px 16px #21014a26;
}

.game-page-header__back {
  flex-shrink: 0;
  margin-right: auto;
}

.game-page-header__search {
  flex-shrink: 0;
}

.game-page-header__deposit {
  flex-shrink: 0;
  max-width: 250px;
}

.game-page-header__title {
  text-align: center;
  width: 100%;
  max-width: calc(100% - 700px);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.game-page-header__name {
  color: #3f3f3f;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 0 2px;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  overflow: hidden;
}

.game-page-header__provider {
  color: #8f8f8f;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
  overflow: hidden;
}

.game-seo {
  height: 157px;
  padding: 32px 32px 40px;
  position: relative;
  overflow: hidden;
}

.game-seo:before {
  content: "";
  pointer-events: none;
  z-index: 1;
  background: linear-gradient(#0000 0%, #000 64.5%);
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.game-seo__content {
  color: #fffc;
  margin: 0;
  padding-bottom: 30px;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
}

.game-seo__content h1, .game-seo__content h2 {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
}

.game-seo__content h1, .game-seo__content h2, .game-seo__content h3, .game-seo__content h4, .game-seo__content h5, .game-seo__content h6, .game-seo__content p, .game-seo__content div, .game-seo__content table {
  margin: 0 0 12px;
}

.game-seo__content > :last-child {
  margin-bottom: 0;
}

.game-seo__btn {
  z-index: 2;
  bottom: 30px;
  position: absolute !important;
}

.game-seo.is-active {
  height: auto;
}

.game-seo.is-active:before {
  opacity: 0;
}

.game-banner {
  display: block;
  position: relative;
}

.game-banner__in {
  background: linear-gradient(#f5d86f 56.48%, #ffd02b 100%);
  padding: 53px 20px 124px;
  position: relative;
  overflow: hidden;
}

.game-banner__in.is-flex {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.game-banner__in.has-anim-top {
  padding-top: 124px;
}

.game-banner__in.has-anim-bottom {
  padding-bottom: 124px;
}

.game-banner__slider {
  z-index: 2;
  position: relative;
}

.game-banner__slider .slick-list {
  padding: 0;
}

.game-banner__bg {
  z-index: 1;
  width: 1974px;
  height: 1974px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.game-banner__bg:before {
  content: "";
  background-image: url("/joxi/yoyo/elements/bg/spiral.svg");
  width: 100%;
  height: 100%;
  animation: 60s linear infinite rotation;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.game-banner__badge {
  left: 50%;
  top: calc(-1 * var(--badge-height) / 2);
  z-index: 5;
  position: absolute;
  transform: translateX(-50%);
}

.game-banner__img-box {
  width: 48.7805%;
}

.game-banner__img {
  width: 100%;
  height: 0;
  padding-top: 52.5%;
  position: relative;
}

.game-banner__img picture, .game-banner__img img {
  object-fit: cover;
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.game-banner__content {
  text-align: center;
  width: 51.2195%;
  padding-left: 28px;
  position: relative;
}

.game-banner__title {
  z-index: 2;
  color: #663170;
  overflow-wrap: break-word;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  text-align: left;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  margin: 0;
  font-size: 28px;
  font-weight: 700;
  line-height: 140%;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.game-banner__desc {
  z-index: 2;
  text-align: left;
  color: #3f3f3f;
  overflow-wrap: break-word;
  line-clamp: 8;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  margin: 10px 0 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.game-banner__anim-box {
  z-index: 2;
  align-items: center;
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.game-banner__anim-box.is-top {
  top: 44px;
}

.game-banner__anim-box.is-bottom {
  bottom: 24px;
}

.game-banner__anim-text {
  flex-shrink: 0;
}

.game-banner__anim-line {
  flex-shrink: 0;
  width: 1240px;
  height: 60px;
  position: relative;
}

.game-banner__anim-line.is-left {
  left: 100px;
}

.game-banner__anim-line.is-right {
  right: 100px;
  transform: rotate(-180deg);
}

.game-banner__anim-btn {
  z-index: 2;
  min-width: 200px;
  position: relative;
}

@media (width >= 1440px) {
  .game-banner__in {
    padding: 48px 54px 116px;
  }

  .game-banner__img-box {
    flex-shrink: 0;
    width: 510px;
  }

  .game-banner__img {
    height: 269px;
    padding-top: 0;
  }

  .game-banner__content {
    width: 604px;
    padding-left: 76px;
  }

  .game-banner__title {
    font-size: 36px;
  }

  .game-banner__desc {
    font-size: 18px;
  }
}

.game-jackpot-amount {
  color: #663170;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 56px;
  font-weight: 900;
  line-height: 1;
  overflow: hidden;
}

.game-scrollbox {
  --columnCount: 6;
}

.game-scrollbox__in {
  width: calc(100% + var(--offset-x) * 2);
  margin-left: calc(-1 * var(--offset-x));
  margin-top: 3px;
}

.game-scrollbox__track {
  --item-width: calc((100vw - 28px ) / var(--columnCount));
  padding: 0 calc(var(--offset-x)  - 3px);
  display: flex;
  overflow: auto hidden;
}

.game-scrollbox__item {
  width: calc(100% / var(--columnCount));
  flex-shrink: 0;
  padding: 0 3px;
}

.game-slider {
  --game-slider-header-height: 36px;
  --game-slider-arrow-size: 24px;
  --game-slider-arrow-gap: 8px;
  --game-slider-arrow-offset: 16px;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-template-rows: var(--game-slider-header-height) 1fr;
  display: grid;
}

.game-slider__header {
  grid-area: 1 / 1 / 2 / 2;
}

.game-slider__arrows {
  margin-left: var(--game-slider-arrow-offset);
  grid-gap: var(--game-slider-arrow-gap);
  grid-area: 1 / 2 / 2 / 3;
  grid-template-columns: repeat(2, auto);
  place-items: center;
  display: inline-grid;
}

.game-slider__arrows .splide__arrow {
  width: var(--game-slider-arrow-size);
  height: var(--game-slider-arrow-size);
  color: #9746a5;
  background: none;
  border: none;
  border-radius: 0;
  transition: color .2s ease-in-out;
  position: static;
  transform: none;
}

.game-slider__arrows .splide__arrow:hover {
  color: #81348e;
}

.game-slider__arrows .splide__arrow:disabled {
  opacity: .2;
}

.game-slider__arrows .splide__arrow svg {
  fill: currentColor;
  width: 100%;
  height: 100%;
  display: block;
  transform: none;
}

.game-slider .splide__track {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  grid-area: 2 / 1 / 3 / 3;
  margin-top: 4px;
}

.game-slider .splide__track:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.game-slider .splide__slide.has-width {
  width: calc(16.6667% - 10px);
  margin-right: 12px;
}

.game-slider.has-extra-paddings .splide__track {
  margin-left: -6px;
  margin-right: -6px;
}

.game-slider.has-extra-paddings .splide__list {
  padding: 0 6px !important;
}

.game-slider.no-arrows .splide__list, .game-slider:not(.is-overflow) .splide__list {
  transform: none !important;
}

.game-slider.no-arrows .splide__arrows, .game-slider:not(.is-overflow) .splide__arrows {
  display: none;
}

@media (width >= 1440px) {
  .game-slider .splide__track {
    margin-top: 11px;
  }
}

.games-dashboard-small {
  grid-gap: 12px;
  grid-template-columns: repeat(5, 1fr);
  display: grid;
}

.games-dashboard-small__item {
  width: 100% !important;
  margin: 0 !important;
}

.games-dashboard-small__item.is-big-first {
  grid-area: 1 / 1 / 3 / 3;
}

.games-dashboard-small__btn-wrap {
  text-align: center;
  grid-column: 1 / 6;
  padding-top: 10px;
}

.games-dashboard-small__btn {
  min-width: 200px;
}

@media (width >= 1440px) {
  .games-dashboard-small__item:nth-of-type(n+9) {
    display: block;
  }
}

.games-dashboard {
  grid-gap: 12px;
  grid-template-columns: repeat(5, 1fr);
  display: grid;
}

.games-dashboard__item {
  width: 100% !important;
  margin: 0 !important;
}

.games-dashboard__item.is-big-first {
  grid-area: 1 / 1 / 3 / 3;
}

.games-dashboard__item.is-big-second {
  grid-area: 3 / 3 / 5 / 5;
}

.games-dashboard__item:nth-of-type(n+19) {
  display: none;
}

.games-dashboard__events {
  grid-area: 1 / 5 / 5 / 5;
  position: relative;
}

.games-dashboard__btn-wrap {
  text-align: center;
  grid-column: 1 / 6;
  padding-top: 10px;
}

.games-dashboard__btn {
  min-width: 200px;
}

@media (width >= 1440px) {
  .games-dashboard {
    grid-template-columns: repeat(6, 1fr);
  }

  .games-dashboard__item:nth-of-type(n+19) {
    display: block;
  }

  .games-dashboard__events {
    grid-column: 6 / 6;
  }

  .games-dashboard__btn-wrap {
    grid-column-end: 7;
  }
}

.profile p {
  margin: 0;
}

.profile__top-wrap {
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.profile__submenu {
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -118px;
}

.profile__item {
  box-sizing: border-box;
  margin: 0 0 20px 20px;
}

.profile__item:nth-child(5n), .profile__item:first-child {
  margin-left: 0;
}

.profile__title {
  width: calc(100% - 175px);
  margin: 0;
  padding-left: 175px;
}

.profile__time {
  text-align: right;
  color: #66554f;
  width: 175px;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
}

@media screen and (width <= 1439px) {
  .profile__submenu {
    margin: 0 -8px;
  }

  .profile__item {
    width: calc(25% - 16px);
    margin: 0 8px 16px;
  }

  .profile__item:first-child {
    margin-left: 8px;
  }

  .profile__item + .user-submenu__item {
    margin: 0 8px 16px;
  }
}

@media screen and (width <= 1023px) {
  .profile__submenu {
    margin: 0 -5px;
  }

  .profile__item + .user-submenu__item {
    margin: 0 5px 10px;
  }

  .profile__item {
    width: auto;
    min-width: 151px;
  }

  .profile__item:first-child {
    margin-bottom: 10px;
    margin-left: 5px;
  }

  .profile__title {
    width: calc(100% - 130px);
    padding-left: 130px;
  }

  .profile__time {
    width: 130px;
    padding: 0;
  }
}

.promo-info {
  color: #3f3f3f;
  width: 100%;
  max-width: 768px;
  margin: 30px auto 0;
  font-size: 14px;
}

.promo-info p {
  line-height: 1.76;
}

.promo-info table {
  width: 100%;
}

.promo-info table td:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  height: 36px;
  padding-left: 20px;
  text-align: left !important;
}

.promo-info table td:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-right: 20px;
  text-align: right !important;
}

.promo-info table tr:nth-child(2n-1) td {
  background-color: #dcdbca80;
}

.promo-info ol {
  margin-bottom: 10px;
}

.promo-info ul {
  list-style: disc;
}

.promo-info ol, .promo-info ul {
  margin-left: 28px;
}

.promo-info ol li {
  color: #3f3f3f;
  font-size: 14px;
  line-height: 25px;
}

.promo-info__terms a {
  font-weight: bold;
}

.promo-info__terms a:hover {
  text-decoration: underline;
}

.promo-info__text-main-title {
  color: #9846a5;
  text-align: center;
  margin-bottom: 30px;
  font-size: 32px;
  font-weight: 900;
}

.promo-info__text-sub-title {
  color: #66554f;
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 900;
}

.promo-info__list {
  color: #3f3f3f;
  font-size: 14px;
  line-height: 1.2;
}

@media screen and (width <= 1239px) {
  .promo-info__text-main-title {
    margin-bottom: 25px;
    font-size: 24px;
  }

  .promo-info__text-sub-title {
    margin-bottom: 15px;
  }
}

@media screen and (width <= 1023px) {
  .promo-info__left-wrap {
    width: 240px;
  }

  .promo-info__text-info-wrap {
    margin: 20px auto 30px;
  }
}

.promo-item-full {
  background-position: 60% 0;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #00be6e;
  border-radius: 10px;
  flex-direction: column;
  min-height: 360px;
  padding: 58px 113px 0;
  display: flex;
  position: relative;
}

.promo-item-full:before {
  content: "";
  background: #e6e9d0;
  border-radius: 100%;
  width: 340px;
  height: 340px;
  position: absolute;
  top: 50%;
  right: 70px;
  transform: translateY(-50%);
  box-shadow: inset 0 2.68641px 2.68641px #00000040;
}

.promo-item-full__img {
  background-repeat: no-repeat;
  background-size: contain;
  width: 347px;
  height: 347px;
  position: absolute;
  top: 50%;
  right: 70px;
  transform: translateY(-50%)scaleX(-1);
}

.promo-item-full__content {
  max-width: 490px;
}

.promo-item-full__title {
  color: #3f3f3f;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 10px;
  font-size: 28px;
  font-weight: 700;
  line-height: 120%;
  overflow: hidden;
}

.promo-item-full__description {
  color: #00be6e;
  overflow-wrap: break-word;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  margin: 0 0 20px;
  font-size: 36px;
  font-weight: 900;
  line-height: 120%;
  display: -webkit-box;
  overflow: hidden;
}

.promo-item-full__button {
  background: #35d290;
  min-width: 138px;
}

.promo-item-full__button:hover {
  background-color: #01c02a;
}

.promo-item-full__text {
  z-index: 1;
  color: #3f3f3f;
  background: #f9fbe7;
  border-radius: 50px;
  align-self: start;
  max-width: 490px;
  margin-top: 33px;
  font-size: 8px;
}

.promo-item-full__text a {
  color: #6e0e57;
}

.promo-item-full__text a:hover {
  color: #3e3e3e;
}

.promo-item-full__back-link {
  color: #3f3f3f;
  background-image: var(--icon-back);
  text-transform: capitalize;
  cursor: pointer;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 30px;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 20px;
  left: 20px;
}

.promo-item-full__back-link:hover {
  filter: invert(33%) sepia(42%) saturate(1685%) hue-rotate(256deg) brightness(89%) contrast(83%);
}

@media screen and (width <= 1239px) {
  .promo-item-full {
    min-height: 264px;
    padding: 26px 67px 0;
  }

  .promo-item-full:before {
    width: 250px;
    height: 250px;
    right: 50px;
  }

  .promo-item-full__img {
    width: 255px;
    height: 255px;
    right: 50px;
  }

  .promo-item-full__button {
    min-width: unset;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
  }
}

.promo-item {
  background-position: 60% 0;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #00be6e;
  border-radius: 10px;
  flex-direction: column;
  height: 100%;
  padding: 54px 24px 24px;
  display: flex;
  position: relative;
}

.promo-item:before {
  content: "";
  background: #e6e9d0;
  border-radius: 100%;
  width: 227px;
  height: 232px;
  position: absolute;
  top: 50%;
  right: 22px;
  transform: translateY(-50%);
  box-shadow: inset 0 2.68641px 2.68641px #00000040;
}

.promo-item__img {
  background-repeat: no-repeat;
  background-size: contain;
  width: 232px;
  height: 232px;
  position: absolute;
  top: 50%;
  right: 22px;
  transform: translateY(-50%);
}

.promo-item__content {
  flex-direction: column;
  flex-grow: 1;
  max-width: 277px;
  display: flex;
}

.promo-item__title {
  color: #3f3f3f;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: bold;
}

.promo-item__description {
  color: #00be6e;
  overflow-wrap: break-word;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  margin-bottom: 16px;
  font-size: 36px;
  font-weight: 900;
  display: -webkit-box;
  overflow: hidden;
}

.promo-item__button {
  background: #35d290;
  min-width: 212px;
}

.promo-item__button:hover {
  background-color: #01c02a;
}

.promo-item__btns {
  flex-direction: column;
  align-items: flex-start;
  max-width: min-content;
  margin-top: auto;
  display: flex;
}

.promo-item__link {
  align-self: center;
  margin-top: 12px;
}

.promo-item__text {
  z-index: 1;
  color: #3f3f3f;
  background: #f9fbe7;
  border-radius: 50px;
  align-self: start;
  margin-top: 25px;
  padding: 10px;
  font-size: 10px;
}

@media screen and (width <= 1239px) {
  .promo-item {
    padding: 24px 14px 14px;
  }

  .promo-item:before, .promo-item__img {
    width: 170px;
    height: 170px;
    right: 14px;
  }

  .promo-item__content {
    max-width: 240px;
  }

  .promo-item__title {
    font-size: 16px;
  }

  .promo-item__description {
    font-size: 24px;
  }

  .promo-item__button {
    min-width: unset;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
  }

  .promo-item__text {
    margin-top: 10px;
  }
}

.promo-list__item:not(:last-of-type) {
  margin-bottom: 20px;
}

.promotions-title {
  color: #66554f;
  text-align: center;
  margin: 0 0 17px;
  font-size: 24px;
  font-weight: 700;
}

.promotions {
  background-image: url("/img/img-promo-left_element.svg"), url("/img/img-promo-right_element.svg");
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
  height: 100%;
  padding: 23px 0 50px;
  line-height: 1.2;
}

@media screen and (width <= 1239px) {
  .promotions {
    background-position: -250px 0, right -250px top;
  }
}

.registration-promo {
  color: #616a6d;
  cursor: pointer;
  background-image: linear-gradient(0deg, #f9fbe7, #f9fbe7);
  border-radius: 10px;
  align-items: center;
  width: 390px;
  min-height: 80px;
  padding: 10px 24px 9px 86px;
  display: flex;
  position: relative;
}

.registration-promo:not(.is-active):hover {
  box-shadow: -2px -2px 4px #0000001a, 2px 2px 4px #0000001a;
}

.registration-promo.is-active {
  color: #fff;
  cursor: auto;
  background: #00be6e;
}

.is-step-2 .registration-promo.is-active {
  cursor: pointer;
}

.registration-promo.is-active:after {
  content: "";
  background-image: url("/img/icon-tooltip-check-yellow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 10px;
  right: 14px;
}

.registration-promo.is-step-2 {
  cursor: initial;
}

.registration-promo__img {
  z-index: 100;
  object-fit: contain;
  width: 74px;
  height: 74px;
  position: absolute;
  top: 3px;
  left: 6px;
}

.registration-promo__img:before {
  content: "";
  z-index: -1;
  background: #f9fbe7;
  border-radius: 100%;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 9px;
  left: 6px;
  box-shadow: inset 0 3px 3px #00000040;
}

.registration-promo__content {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.registration-promo__name {
  margin-top: 0;
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 16px;
}

.registration-promo__text {
  overflow-wrap: break-word;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  margin: 0;
  font-size: 20px;
  font-weight: 900;
  line-height: 23px;
  display: -webkit-box;
  overflow: hidden;
}

.registration-promo__terms {
  margin: 0;
  font-size: 10px;
  line-height: 12px;
}

.registration-promo__terms a {
  color: inherit;
  text-decoration: underline;
}

.registration-promo__terms.is-link {
  color: #616a6dbf;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-decoration: underline;
}

.is-active .registration-promo__terms.is-link, .registration-promo__terms.is-text {
  display: none;
}

.is-active .registration-promo__terms.is-text {
  display: block;
}

.slider-games-h .slick-slide {
  padding: 0 8px;
}

.slider-games-h .game-item {
  margin: 0;
}

.slider-games-h__arrow {
  z-index: 3;
  cursor: pointer;
  background: #00000080 center no-repeat;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.slider-games-h__arrow.is-prev {
  background-image: url("/joxi/sweden-yoyo/slider_arrow_l.svg");
  left: 0;
}

.slider-games-h__arrow.is-next {
  background-image: url("/joxi/sweden-yoyo/slider_arrow_r.svg");
  right: 0;
}

.slider-games-h__in {
  flex-wrap: nowrap;
  margin: 0 -8px;
  display: flex;
  overflow: hidden;
}

.slider-games-h__in.slick-initialized {
  display: block;
}

.slider-games-h__in.slick-initialized .game-item {
  padding: 0;
}

.slider-games-h__in .game-item {
  flex: 0 0 20%;
  padding: 0 8px;
}

@media (width >= 1440px) {
  .slider-games-h__in .game-item {
    flex-basis: 25%;
  }
}

.header-l-lang {
  cursor: pointer;
  color: #3f3f3f;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  transition: all .2s;
  display: flex;
}

.header-l-lang:hover {
  cursor: pointer;
}

.header-l-lang:hover .header-l-lang__ico {
  fill: #9746a5;
  transition: all .2s;
}

.header-l-lang:hover span {
  color: #9746a5;
  transition: all .2s;
}

.header-l-lang__ico {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.header-l-lang__ico svg {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (width <= 1023px) {
  .header-l-lang:hover .header-l-lang__ico {
    fill: #35d290;
    transition: all .2s;
  }

  .header-l-lang span {
    display: none;
  }

  .header-l-lang__ico {
    fill: #35d290;
    width: 24px;
    height: 24px;
    margin-right: 0;
    position: absolute;
    right: 0;
  }
}

.header-l-logo {
  margin-right: 12.5%;
}

.header-l-logo svg {
  width: 132px;
  height: 54px;
}

@media screen and (width <= 1439px) {
  .header-l-logo {
    margin-right: 87px;
  }
}

@media screen and (width <= 1023px) {
  .header-l-logo {
    margin-right: 0;
  }

  .header-l-logo svg {
    width: 98px;
    height: 40px;
  }
}

.header-l-promo {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 185px;
  display: flex;
}

.header-l-promo__icon {
  width: 204px;
}

.header-l-promo__content {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  max-width: 400px;
  display: flex;
  position: relative;
}

.header-l-promo__text {
  letter-spacing: 1px;
  color: #3f3f3f;
  text-transform: uppercase;
  margin: 0 0 12px;
  font-size: 26px;
  font-weight: bold;
  line-height: 37px;
  overflow: hidden;
}

.header-l-promo__text.is-violet {
  color: #9746a5;
  text-shadow: 0 3px #fff;
}

.header-l-promo__text.is-medium {
  letter-spacing: .08em;
  font-size: 26px;
}

.header-l-promo__text.is-big {
  font-size: 48px;
  font-weight: 900;
}

.header-l-promo__item {
  color: #646464;
  text-align: left;
  font-size: 14px;
  line-height: 24px;
}

@media screen and (width <= 1730px) {
  .header-l-promo {
    padding: 0 38px;
  }
}

@media screen and (width <= 1023px) {
  .header-l-promo {
    justify-content: center;
    align-items: center;
  }
}

@media screen and (width <= 767px) {
  .header-l-promo {
    padding: 0;
  }

  .header-l-promo__icon {
    width: 84px;
    height: 38px;
    margin: 0 auto;
    display: block;
  }

  .header-l-promo__item {
    line-height: 19px;
  }

  .header-l-promo__content {
    width: 100%;
    max-width: 100%;
    display: inline-block;
  }

  .header-l-promo__text {
    font-size: 16px;
  }

  .header-l-promo__text.is-big {
    font-size: 44px;
  }

  .header-l-promo__text.is-medium {
    font-size: 18px;
  }
}

.header-l-top {
  justify-content: flex-end;
  margin-bottom: 60px;
  padding: 28px 18px 0;
  display: flex;
  position: relative;
}

@media screen and (width <= 1439px) {
  .header-l-top {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media screen and (width <= 1023px) {
  .header-l-top {
    justify-content: center;
    margin-bottom: 253px;
    padding-top: 24px;
  }
}

@media screen and (width <= 767px) {
  .header-l-top {
    margin-bottom: 140px;
  }
}

.header-l {
  background: url("/joxi/landings/yoyo/background.jpg") no-repeat;
  min-height: 800px;
  margin-bottom: 28px;
  display: block;
}

.header-l__bonus-btn {
  margin-top: 57px;
}

@media screen and (width <= 1023px) {
  .header-l__bonus-btn {
    margin-top: 35px;
  }
}

.header-l__arrow-icon {
  width: 14px;
  height: 14px;
  margin: 12px 0;
  animation: 1s linear infinite arrow;
  position: relative;
  top: -1px;
}

.header-l__arrow-icon svg {
  max-width: 100%;
  max-height: 100%;
}

.header-l__arrow-text {
  color: #646464;
  font-size: 14px;
}

@media screen and (width <= 1730px) {
  .header-l {
    background-position: 55%;
  }
}

@media screen and (width <= 1439px) {
  .header-l {
    background-position: 66%;
  }
}

@media screen and (width <= 1239px) {
  .header-l {
    background-position: 72%;
  }
}

@media screen and (width <= 1023px) {
  .header-l {
    background: url("/joxi/landings/yoyo/banner_mobile.jpg") top no-repeat;
    min-height: auto;
    margin-bottom: 20px;
  }

  .header-l__arrow-icon {
    animation: none;
    top: 5px;
  }
}

@media screen and (width <= 767px) {
  .header-l {
    background-color: #0000;
    background-image: linear-gradient(#f9fbe700 60%, #f9fbe7 100%), url("/joxi/landings/yoyo/banner_mobile.jpg");
    background-position: 0 -324px, 50% 0;
    background-repeat: no-repeat;
    background-size: 100% 570px, 720px;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
  }

  .header-l__mobile-text {
    margin-right: 5px;
  }
}

@keyframes arrow {
  50% {
    top: 5px;
  }
}

.land-page {
  background: #f9fbe6;
  flex-direction: column;
  min-height: 100%;
  display: flex;
}

.land-page__container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 40px;
}

@media screen and (width <= 1239px) {
  .land-page__container {
    padding: 0 20px;
  }
}

.land-page .footer-icons__list {
  justify-content: center;
}

.rules-l {
  flex: 1 0 auto;
  margin-bottom: 28px;
  padding: 0 40px;
  display: block;
  position: relative;
}

.rules-l .land-page__container {
  background: #ffd1804d;
  border-radius: 10px;
}

.rules-l__wrap {
  max-width: 1294px;
  margin: 0 auto;
  padding: 40px 40px 60px;
  position: relative;
}

.rules-l__text {
  color: #646464;
  font-size: 24px;
  font-weight: 500;
  line-height: 20px;
}

.rules-l__text.is-bold {
  letter-spacing: .08em;
  text-transform: uppercase;
  color: #3f3f3f;
  text-align: left;
  margin: 0;
  padding-left: 7px;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
}

.rules-l__content {
  height: 230px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}

.rules-l__content:after {
  content: "";
  background: linear-gradient(#fbeec800 0%, #fbeec8 100%) 0 0 / 100% no-repeat;
  width: 100%;
  height: 26px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.rules-l__content strong {
  color: #0c1533;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
}

.rules-l__content p {
  color: #0c1533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.rules-l__content p:last-child {
  display: none;
}

.rules-l__content p a {
  color: #0c1533;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  text-decoration: underline;
}

.rules-l__content table {
  max-width: 100%;
}

.rules-l__input {
  display: none;
}

.rules-l__input:checked + .rules-l__content {
  height: auto;
}

.rules-l__input:checked + .rules-l__content:after {
  display: none;
}

.rules-l__label {
  --color: #9746a5;
  color: var(--color);
  fill: var(--color);
  box-shadow: inset 0 0 0 1px var(--color);
  text-align: center;
  background-color: #0000;
  margin-left: -72px;
  position: absolute;
  bottom: 28px;
  left: 50%;
}

.rules-l__label:hover {
  background-color: var(--color);
  color: #fff;
}

@media screen and (width <= 1439px) {
  .rules-l__content {
    height: 275px;
  }
}

@media screen and (width <= 1023px) {
  .rules-l__content {
    height: 170px;
  }
}

@media screen and (width <= 767px) {
  .rules-l {
    padding: 0 20px;
  }

  .rules-l__content {
    height: 442px;
  }

  .rules-l__wrap {
    padding: 24px 0;
  }

  .rules-l__content {
    margin-bottom: 60px;
  }

  .rules-l__content table {
    font-size: 12px;
  }

  .rules-l__text.is-bold {
    font-size: 16px;
  }
}

@media only screen and (width <= 539px) {
  .rules-l__content p {
    font-size: 12px;
    line-height: 17px;
  }
}

.rules-l table {
  width: 100%;
}

.rules-l table td:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  height: 36px;
  padding-left: 20px;
  text-align: left !important;
}

.rules-l table td:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-right: 20px;
  text-align: right !important;
}

.rules-l table tr:nth-child(2n-1) td {
  background-color: #dcdbca80;
}

.rules-l ol {
  margin-bottom: 10px;
  padding-left: 22px;
}

@media screen and (width <= 767px) {
  .rules-l ol {
    padding-left: 22px;
  }
}

.rules-l ol li {
  color: #646464;
  font-size: 14px;
  line-height: 25px;
}

.entrance-jackpot {
  background: linear-gradient(#f5d86f 0%, #ffd02b 100%) center / cover no-repeat;
  margin-bottom: 40px;
  padding: 44px 20px 24px;
  position: relative;
  overflow: hidden;
}

.entrance-jackpot:before {
  content: "";
  transform-origin: center;
  background-image: url("/joxi/sweden-yoyo/jp_bg_main.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  width: 1930px;
  height: 1930px;
  animation-name: rotate;
  animation-duration: 60s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  top: -200%;
  transform: translate(100%, 100%);
}

@media screen and (width <= 1239px) {
  .entrance-jackpot:before {
    left: -37vh;
  }
}

@media screen and (width <= 1439px) {
  .entrance-jackpot:before {
    left: -30vh;
  }
}

@media screen and (width >= 1440px) {
  .entrance-jackpot:before {
    left: -22vh;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.entrance-jackpot__amount {
  text-align: center;
  color: #663170;
  padding-bottom: 20px;
  font-size: 56px;
  font-weight: 900;
  line-height: 56px;
  display: block;
}

.entrance-jackpot__link {
  z-index: 1;
  width: fit-content;
  margin: 20px auto 0;
  display: flex;
  position: relative;
}

.entrance-jackpot .has-jackpot .game-item-jackpot__text {
  display: none;
}

.entrance-jackpot .anim-wrap {
  position: relative;
  overflow: hidden;
}

#anim_elem_l {
  width: 1240px;
  position: absolute;
  top: -5px;
  right: 59%;
}

@media screen and (width <= 1239px) {
  #anim_elem_l {
    right: 62%;
  }
}

#anim_elem_r {
  width: 1240px;
  position: absolute;
  top: -10px;
  left: 59%;
  transform: rotate(180deg);
}

@media screen and (width <= 1239px) {
  #anim_elem_r {
    left: 62%;
  }
}

#anim_elem_btn_l {
  width: 1240px;
  position: absolute;
  top: 17px;
  right: 52%;
}

#anim_elem_btn_r {
  width: 1240px;
  position: absolute;
  top: 17px;
  left: 52%;
  transform: rotate(180deg);
}

.entrance-jackpot__title {
  text-align: center;
  text-transform: uppercase;
  color: #66396f;
  z-index: 1;
  background-image: url("/joxi/sweden-yoyo/jp_bg.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: fit-content;
  margin: 0 auto;
  padding: 12px 24px;
  font-size: 24px;
  font-weight: 900;
  line-height: 24px;
  position: relative;
  bottom: -24px;
}

.slider-games {
  margin-bottom: 80px;
  padding-top: 29px;
  display: block;
  position: relative;
}

.slider-games__wrap {
  position: relative;
}

.slider-games__wrap .slick-list {
  margin: 0 -7px;
}

.slider-games__wrap .slick-slide {
  padding: 0 7px;
}

.slider-games__wrap .game-item {
  margin: 0;
}

.slider-games__image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  width: 100%;
  height: auto;
  min-height: 156px;
  box-shadow: 0 4px 5px #00000026;
}

.slider-games__bt {
  z-index: 3;
  cursor: pointer;
  background-color: #a96b0f80;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.slider-games__bt:hover, .slider-games__bt:active {
  background-color: #a96b0fcc;
}

.slider-games__bt_prev {
  background-image: url("/joxi/sweden-yoyo/slider_arrow_l.svg");
  left: -10px;
}

.slider-games__bt_next {
  background-image: url("/joxi/sweden-yoyo/slider_arrow_r.svg");
  right: -10px;
}

.slider-games__elem {
  margin: 0;
}

.jackpot-main {
  padding-bottom: 28px;
}

.jp-title {
  color: #66554f;
  padding-bottom: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
}

.grid-wrap {
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  margin-bottom: 20px;
  display: grid;
}

@media screen and (width <= 1239px) {
  .grid-wrap {
    grid-template-columns: repeat(5, 1fr);
  }
}

.grid-wrap .has-jackpot .game-item-jackpot__text {
  display: none;
}

.grid-wrap__item-main .game-item__pic {
  height: 100%;
}

.grid-wrap__item-main:first-child {
  grid-area: 1 / 1 / 3 / 3;
}

.grid-wrap__item-main:hover .game-item__hoverplay {
  opacity: 1;
}

.grid-wrap__item-sub {
  display: block;
  overflow: hidden;
}

.grid-wrap__item-sub:nth-last-child(-n+2) {
  display: block;
}

@media screen and (width <= 1239px) {
  .grid-wrap__item-sub:nth-last-child(-n+2) {
    display: none;
  }
}

.grid-wrap__item-sub:hover .game-item__hoverplay {
  opacity: 1;
}

.has-padding-bottom-0 {
  padding-bottom: 0 !important;
}

.has-padding-top-0 {
  padding-top: 0 !important;
}

.has-padding-right-0 {
  padding-right: 0 !important;
}

.has-padding-left-0 {
  padding-left: 0 !important;
}

.has-padding-bottom-2 {
  padding-bottom: 2px !important;
}

.has-padding-top-2 {
  padding-top: 2px !important;
}

.has-padding-right-2 {
  padding-right: 2px !important;
}

.has-padding-left-2 {
  padding-left: 2px !important;
}

.has-padding-bottom-4 {
  padding-bottom: 4px !important;
}

.has-padding-top-4 {
  padding-top: 4px !important;
}

.has-padding-right-4 {
  padding-right: 4px !important;
}

.has-padding-left-4 {
  padding-left: 4px !important;
}

.has-padding-bottom-8 {
  padding-bottom: 8px !important;
}

.has-padding-top-8 {
  padding-top: 8px !important;
}

.has-padding-right-8 {
  padding-right: 8px !important;
}

.has-padding-left-8 {
  padding-left: 8px !important;
}

.has-padding-bottom-10 {
  padding-bottom: 10px !important;
}

.has-padding-top-10 {
  padding-top: 10px !important;
}

.has-padding-right-10 {
  padding-right: 10px !important;
}

.has-padding-left-10 {
  padding-left: 10px !important;
}

.has-padding-bottom-12 {
  padding-bottom: 12px !important;
}

.has-padding-top-12 {
  padding-top: 12px !important;
}

.has-padding-right-12 {
  padding-right: 12px !important;
}

.has-padding-left-12 {
  padding-left: 12px !important;
}

.has-padding-bottom-14 {
  padding-bottom: 14px !important;
}

.has-padding-top-14 {
  padding-top: 14px !important;
}

.has-padding-right-14 {
  padding-right: 14px !important;
}

.has-padding-left-14 {
  padding-left: 14px !important;
}

.has-padding-bottom-15 {
  padding-bottom: 15px !important;
}

.has-padding-top-15 {
  padding-top: 15px !important;
}

.has-padding-right-15 {
  padding-right: 15px !important;
}

.has-padding-left-15 {
  padding-left: 15px !important;
}

.has-padding-bottom-16 {
  padding-bottom: 16px !important;
}

.has-padding-top-16 {
  padding-top: 16px !important;
}

.has-padding-right-16 {
  padding-right: 16px !important;
}

.has-padding-left-16 {
  padding-left: 16px !important;
}

.has-padding-bottom-18 {
  padding-bottom: 18px !important;
}

.has-padding-top-18 {
  padding-top: 18px !important;
}

.has-padding-right-18 {
  padding-right: 18px !important;
}

.has-padding-left-18 {
  padding-left: 18px !important;
}

.has-padding-bottom-19 {
  padding-bottom: 19px !important;
}

.has-padding-top-19 {
  padding-top: 19px !important;
}

.has-padding-right-19 {
  padding-right: 19px !important;
}

.has-padding-left-19 {
  padding-left: 19px !important;
}

.has-padding-bottom-20 {
  padding-bottom: 20px !important;
}

.has-padding-top-20 {
  padding-top: 20px !important;
}

.has-padding-right-20 {
  padding-right: 20px !important;
}

.has-padding-left-20 {
  padding-left: 20px !important;
}

.has-padding-bottom-22 {
  padding-bottom: 22px !important;
}

.has-padding-top-22 {
  padding-top: 22px !important;
}

.has-padding-right-22 {
  padding-right: 22px !important;
}

.has-padding-left-22 {
  padding-left: 22px !important;
}

.has-padding-bottom-24 {
  padding-bottom: 24px !important;
}

.has-padding-top-24 {
  padding-top: 24px !important;
}

.has-padding-right-24 {
  padding-right: 24px !important;
}

.has-padding-left-24 {
  padding-left: 24px !important;
}

.has-padding-bottom-25 {
  padding-bottom: 25px !important;
}

.has-padding-top-25 {
  padding-top: 25px !important;
}

.has-padding-right-25 {
  padding-right: 25px !important;
}

.has-padding-left-25 {
  padding-left: 25px !important;
}

.has-padding-bottom-26 {
  padding-bottom: 26px !important;
}

.has-padding-top-26 {
  padding-top: 26px !important;
}

.has-padding-right-26 {
  padding-right: 26px !important;
}

.has-padding-left-26 {
  padding-left: 26px !important;
}

.has-padding-bottom-28 {
  padding-bottom: 28px !important;
}

.has-padding-top-28 {
  padding-top: 28px !important;
}

.has-padding-right-28 {
  padding-right: 28px !important;
}

.has-padding-left-28 {
  padding-left: 28px !important;
}

.has-padding-bottom-30 {
  padding-bottom: 30px !important;
}

.has-padding-top-30 {
  padding-top: 30px !important;
}

.has-padding-right-30 {
  padding-right: 30px !important;
}

.has-padding-left-30 {
  padding-left: 30px !important;
}

.has-padding-bottom-32 {
  padding-bottom: 32px !important;
}

.has-padding-top-32 {
  padding-top: 32px !important;
}

.has-padding-right-32 {
  padding-right: 32px !important;
}

.has-padding-left-32 {
  padding-left: 32px !important;
}

.has-padding-bottom-36 {
  padding-bottom: 36px !important;
}

.has-padding-top-36 {
  padding-top: 36px !important;
}

.has-padding-right-36 {
  padding-right: 36px !important;
}

.has-padding-left-36 {
  padding-left: 36px !important;
}

.has-padding-bottom-40 {
  padding-bottom: 40px !important;
}

.has-padding-top-40 {
  padding-top: 40px !important;
}

.has-padding-right-40 {
  padding-right: 40px !important;
}

.has-padding-left-40 {
  padding-left: 40px !important;
}

.has-padding-bottom-48 {
  padding-bottom: 48px !important;
}

.has-padding-top-48 {
  padding-top: 48px !important;
}

.has-padding-right-48 {
  padding-right: 48px !important;
}

.has-padding-left-48 {
  padding-left: 48px !important;
}

.has-padding-bottom-60 {
  padding-bottom: 60px !important;
}

.has-padding-top-60 {
  padding-top: 60px !important;
}

.has-padding-right-60 {
  padding-right: 60px !important;
}

.has-padding-left-60 {
  padding-left: 60px !important;
}

.has-padding-bottom-92 {
  padding-bottom: 92px !important;
}

.has-padding-top-92 {
  padding-top: 92px !important;
}

.has-padding-right-92 {
  padding-right: 92px !important;
}

.has-padding-left-92 {
  padding-left: 92px !important;
}

.has-mb-25 {
  margin-bottom: 25px;
}

.has-mb-40 {
  margin-bottom: 40px;
}

.is-notification:after {
  content: "";
  box-sizing: content-box;
  background-color: #ff5300;
  border: 1px solid #282828;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.is-notification-small:after {
  content: "";
  box-sizing: content-box;
  background-color: #ff5300;
  border: 1px solid #282828;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  position: absolute;
  bottom: 3px;
  right: 0;
}

.is-notification-item:after {
  content: "";
  box-sizing: content-box;
  background-color: #ff5300;
  border: 1px solid #282828;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 20px;
  left: 8px;
}

.notification-wrap {
  justify-content: center;
  align-items: center;
  display: flex;
}

.notification-wrap svg {
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.game-page-smart-bnr {
  z-index: 2;
  position: relative;
}

.game-page {
  z-index: 1;
  background: #111111d9;
  flex-direction: column;
  display: flex;
  position: absolute;
  inset: 0;
}

.game-page .rg-bar {
  flex-shrink: 0;
}

.game-page__seo {
  background: #111;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
}

.game-page__in, .game-page__frame {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.game-page__btn {
  line-height: 20px;
  bottom: 0;
  left: 0;
}

.game-page__btn.has-arrow {
  position: absolute;
}

.game-page__btn.is-opened {
  position: relative;
}

.game-page__btn.is-opened svg {
  display: none;
}

.game-page-demo {
  box-sizing: border-box;
  background: #fbce7e;
  border-radius: 10px;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;
  padding: 14px 16px;
  display: flex;
  box-shadow: 0 0 15px #0000001a;
}

.game-page-demo__content {
  padding-right: 12px;
}

.game-page-demo__text {
  color: #3e3e3e;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  display: inline-block;
}

.game-page-demo__text:not(:last-child) {
  margin-right: 4px;
}

.game-page-demo__link {
  font-weight: 600;
}

.game-page-demo__link:hover {
  text-decoration: underline;
}

.session-timer {
  align-items: center;
  padding: 0 4px;
  display: flex;
}

.session-timer__icon {
  fill: currentColor;
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.session-timer__text {
  color: #66554f;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 10px;
  font-weight: 500;
  line-height: 110%;
  overflow: hidden;
}

.header {
  z-index: 700;
  background-color: #f9fbe7;
  width: 100%;
  display: block;
  position: sticky;
  top: 0;
  left: 0;
  box-shadow: 0 10px 10px #21014a26;
}

.header__inner {
  justify-content: space-between;
  align-items: center;
  height: 90px;
  display: flex;
  position: relative;
}

.header__navbox {
  align-items: center;
  width: calc(50% - 65px);
  display: flex;
}

.header__togglenav {
  flex-shrink: 0;
  margin-right: 15px;
  position: relative;
}

.header__togglenav svg {
  width: 23px;
  height: 17px;
}

.header__mainnav {
  align-items: center;
  display: flex;
  overflow: hidden;
}

.header__mainnav .main-nav__item:first-child {
  margin-right: 15px;
}

.header__mainnav .main-nav__item.christmas-btn {
  text-align: center;
  border: 1px solid #2d9cdb;
  border-radius: 100%;
  width: 40px;
  padding: 6px 0 0;
}

.header__mainnav .main-nav__item.christmas-btn svg {
  fill: #2d9cdb;
  width: 22px;
  height: 24px;
}

.header__mainnav .main-nav__item.christmas-btn:hover {
  background-color: #2d9cdb;
}

.header__mainnav .main-nav__item.christmas-btn:hover svg {
  fill: #fff;
}

.header__logo {
  flex-shrink: 0;
  width: 112px;
  height: 46px;
  margin: 0 auto;
}

.header__logo svg {
  width: 100%;
  height: 100%;
}

.header__balance {
  display: none;
}

.header__userbox {
  justify-content: flex-end;
  align-items: center;
  width: calc(50% - 160px);
  display: flex;
  overflow: hidden;
}

.header__user {
  align-items: center;
  display: none;
  overflow: hidden;
}

.header__login {
  justify-content: flex-end;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.header__cashier-btn {
  margin-right: 15px;
}

.header__profile-btn {
  flex-shrink: 0;
  margin-right: 15px;
  position: relative;
}

.header__login {
  height: 40px;
}

.header__login-btn {
  min-width: 110px;
  margin-right: 15px;
}

.header__registration-btn {
  justify-content: center;
  align-items: center;
  min-width: 180px;
  margin-right: 16px;
  display: flex;
}

.header__registration-btn svg, .header__login-btn svg, .header__ec-login svg {
  width: 100%;
  height: 100%;
  display: none;
}

.header__buttons {
  flex-shrink: 0;
  align-items: center;
  width: 95px;
  display: flex;
}

.header__search {
  margin-right: 15px;
}

.header__search svg {
  width: 18px;
  height: 18px;
}

.header__chat svg {
  width: 13px;
  height: 18px;
}

@media screen and (width <= 1239px) {
  .header__inner {
    height: 70px;
  }

  .header__logo {
    width: 100px;
    height: 40px;
  }

  .header__profile-btn {
    display: none;
  }
}

@media screen and (width <= 1023px) {
  .header .main-nav__item:last-child {
    display: none;
  }

  .header__registration-btn, .header__login-btn {
    box-sizing: border-box;
    border-radius: 50%;
    width: 40px;
    min-width: 40px;
    height: 40px;
    padding: 8px;
  }

  .header__registration-btn-img, .header__registration-btn span, .header__login-btn span, .header__cashier-btn {
    display: none;
  }

  .header__registration-btn svg, .header__login-btn svg {
    display: block;
  }
}

@media screen and (width <= 767px) {
  .header .main-nav, .header__registration-btn {
    display: none;
  }

  .header__navbox {
    width: calc(50% - 90px);
  }

  .header__userbox {
    width: calc(50% - 185px);
  }
}

@media only screen and (width <= 539px) {
  .header__userbox {
    width: calc(50% - 130px);
  }

  .header__login-btn, .header__chat, .header__ec-login {
    display: none;
  }

  .header__search, .header__togglenav {
    margin-right: 0;
  }

  .header__buttons {
    width: 40px;
  }
}

.linda-has-user .header__balance {
  display: block;
}

.linda-has-user .header__user {
  display: flex;
}

.linda-has-user .header__login {
  display: none;
}

@media screen and (width <= 767px) {
  .linda-has-user .header__balance {
    display: none;
  }
}

.header__ec-login {
  min-width: 180px;
  margin-right: 16px;
}

@media screen and (width <= 1239px) {
  .header__ec-login {
    box-sizing: border-box;
    border-radius: 50%;
    width: 40px;
    min-width: 40px;
    height: 40px;
    padding: 10px;
  }

  .header__ec-login span {
    display: none;
  }

  .header__ec-login svg {
    display: block;
  }
}

@media screen and (width <= 1023px) {
  .header__reg-bank {
    padding-left: 4px;
    padding-right: 7px;
  }
}

.header-balance {
  text-align: right;
  flex-grow: 1;
  width: 48%;
}

.header-balance__block {
  letter-spacing: normal;
  color: #3f3f3f;
  max-width: 100%;
  padding-right: 30px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.41;
  display: inline-block;
}

.header-balance__field {
  justify-content: space-between;
  display: flex;
}

.header-balance__key {
  text-transform: capitalize;
}

.header-balance__value {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.header-balance__balance {
  position: relative;
}

.header-balance__balance-value {
  color: #ab69ad;
}

.header-balance__update-btn {
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  padding: 0;
  position: absolute;
  top: 3px;
  right: -16px;
}

.header-balance__update-btn svg {
  fill: #35d290;
}

.header-balance__update-btn.linda-has-preloader {
  animation: .8s linear infinite spin;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.linda-has-user .left-menu__user, .linda-has-user .left-menu__btn-logout, .linda-has-user .left-menu__username {
  display: block;
}

.linda-has-user .left-menu__login {
  display: none;
}

.left-menu-overlay {
  z-index: 999;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.open-left-menu .left-menu-overlay {
  background: #00000026;
  display: block;
}

.left-menu {
  z-index: 1011;
  will-change: transform;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #fee486;
  width: 330px;
  height: 100%;
  min-height: 100%;
  transition: all .2s ease-out;
  position: fixed;
  top: 0;
  overflow: hidden;
  transform: translate(-350px);
  box-shadow: 0 1px 22px #0006;
}

.left-menu::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.open-left-menu .left-menu {
  max-height: 100vh;
  overflow: auto;
  transform: translate(0);
}

.left-menu__item {
  position: relative;
}

.left-menu__scroll {
  background-image: url("/joxi/yoyo/half_candy.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: 100%;
  padding-top: 24px;
}

.left-menu__logo {
  width: 94px;
  height: 40px;
  margin: 0 auto 54px;
  display: block;
}

.left-menu__logo svg {
  width: 100%;
  height: 100%;
}

.linda-has-user .left-menu__logo {
  margin-bottom: 38px;
}

.left-menu__border-btn {
  border: none;
  width: 100%;
  height: 49px;
  margin: 0 auto 24px;
}

.left-menu__link {
  color: #3f3f3f;
  text-align: left;
  align-items: center;
  width: 300px;
  height: 48px;
  padding: 0 10px 0 20px;
  font-size: 20px;
  line-height: 8px;
  transition: all .2s;
  display: flex;
  position: relative;
}

.left-menu__link:after {
  content: "";
  background-image: url("/img/arrow-right.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 15px;
  height: 9px;
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.left-menu__link:before {
  content: "";
  opacity: 0;
  background-color: #ab69ad;
  width: 265px;
  height: 3px;
  transition: all .2s;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.left-menu__link:hover {
  color: #ab69ad;
}

.left-menu__link:hover:after {
  filter: invert(33%) sepia(42%) saturate(1685%) hue-rotate(256deg) brightness(89%) contrast(83%);
}

.left-menu__link:hover:before {
  opacity: 1;
}

.left-menu__link.active {
  color: #fff;
  background: linear-gradient(154.66deg, #a745b0 11.99%, #700e66 83.71%);
  border-radius: 50px;
}

.left-menu__link.active :after {
  filter: brightness();
}

.left-menu__link.active:hover {
  color: #fff;
}

.left-menu__link.active:hover:after {
  filter: invert() sepia(0%) saturate(7500%) hue-rotate(351deg) brightness(104%) contrast(104%);
}

.left-menu__link.active:hover:before {
  opacity: 0;
}

.left-menu__link.active:after {
  filter: invert() sepia(0%) saturate(7500%) hue-rotate(351deg) brightness(104%) contrast(104%);
}

.left-menu__link.christmas-btn {
  color: #2d9cdb;
  border: 1px solid #2d9cdb;
  justify-content: center;
  align-items: center;
  display: flex;
}

.left-menu__link.christmas-btn svg {
  fill: #2d9cdb;
  width: 16.5px;
  height: 18px;
  margin-right: 5px;
}

.left-menu__link.christmas-btn:hover {
  color: #fff;
  background-color: #2d9cdb;
}

.left-menu__link.christmas-btn:hover svg {
  fill: #fff;
}

.left-menu__closebox {
  align-items: center;
  height: 19px;
  display: flex;
  position: absolute;
  top: 35px;
  overflow: hidden;
}

.left-menu__close-btn {
  flex-shrink: 0;
  width: 13px;
  height: 14px;
  margin-left: 20px;
}

.left-menu__close-btn svg {
  width: 100%;
  height: 100%;
}

.left-menu__close-text {
  color: #aa69ac;
  letter-spacing: normal;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 0;
  margin-left: 9px;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  overflow: hidden;
}

.left-menu__username {
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 13px;
  margin-left: auto;
  padding-left: 2px;
  font-size: 26px;
  font-weight: 900;
  line-height: 30px;
  display: none;
  overflow: hidden;
}

.left-menu__userbox {
  filter: box-shadow(0px 14px 35px 0px #21014a 51%);
  background: url("/joxi/yoyo/shape.png") -10px -10px / 110%, linear-gradient(#a745b0 9.65%, #700e66 92%) 0 0 / 100%;
  border-radius: 18px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px 21px;
  padding: 23px 16px 16px;
  box-shadow: 0 4px 11px #290b2863;
}

.left-menu__update-btn {
  width: 18px;
  height: 16px;
}

.left-menu__userbox-deposit {
  text-transform: capitalize;
  border-radius: 50px;
  height: 48px;
  font-size: 22px;
  font-weight: 900;
  line-height: 48px;
}

.left-menu__userbox-btn {
  width: 15px;
  height: 15px;
  margin: 0 auto 8px;
  display: block;
}

.left-menu__user {
  display: none;
}

.left-menu__list {
  padding: 0 20px 35px;
  display: block;
}

.left-menu__item {
  justify-content: center;
  display: flex;
}

.left-menu__btn-logout {
  display: none;
}

.left-menu__balance-wrap {
  word-break: break-word;
  grid-gap: 5px 0;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(2, minmax(50%, 1fr));
  align-items: center;
  min-width: 0;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  display: grid;
}

.left-menu__balance-key {
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 5px;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  overflow: hidden;
}

.left-menu__balance-key:first-child {
  background-image: url("/joxi/yoyo/coin.png");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  padding-left: 33px;
  line-height: 30px;
}

.left-menu__balance-value {
  color: #fff;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 20px;
  font-weight: bold;
  line-height: 23px;
  overflow: hidden;
}

.left-menu__balance-value.is-flex {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.left-menu__apps {
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-bottom: 20px;
  padding: 0 20px;
  display: grid;
}

.left-menu__bottom {
  justify-content: center;
  align-items: center;
  margin-top: auto;
  padding-bottom: 30px;
  display: flex;
}

.left-menu__lang {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  border: 1px solid #ab69ad;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  transition: all .2s;
}

.linda-has-user .left-menu__lang {
  margin-left: 140px;
}

.left-menu__lang:hover {
  cursor: pointer;
  background-size: 34px;
}

.left-menu__logout {
  background-image: url("/img/logout.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  border: 1px solid #ab69ad;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 36px;
  transition: all .2s;
}

.left-menu__logout:hover {
  cursor: pointer;
  background-size: 32px;
}

@media only screen and (width <= 539px) {
  .left-menu {
    width: 320px;
  }
}

.left-menu__list {
  padding: 0 16px 10px;
}

.homepage {
  margin-top: 47px;
}

.homepage__dashboard {
  padding: 20px 0 52px;
}

.homepage__dashboard-title {
  max-width: 80%;
  margin-bottom: 16px;
}

.homepage__banner .game-banner {
  margin-bottom: 32px;
}

.homepage__dashboard-btn-wrap {
  text-align: center;
  margin-top: 30px;
  line-height: 0;
}

.homepage__dashboard-btn {
  min-width: 200px;
  transition: all .2s;
}

.homepage__slider-games {
  background-color: #ea4642;
  background-position: 10% 54%;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 10px;
  margin-bottom: 16px;
  position: relative;
  overflow: hidden;
}

.homepage__slider-games .slider-games {
  margin-bottom: 0;
}

.homepage__slider-games:before {
  content: "";
  transform-origin: center;
  background-image: url("/joxi/yoyo/candy_menu.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  width: 1930px;
  height: 1930px;
  animation-name: rotate;
  animation-duration: 60s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  top: -200%;
  left: -17%;
  transform: translate(100%, 100%);
}

.homepage__slider-games .slider-games:before {
  display: none;
}

.homepage__slider-games .slider-games {
  padding: 30px 20px 0;
}

.homepage__slider-games .slider-games:after {
  content: none;
}

.homepage__slider-games .slider-games__img {
  width: 100%;
}

.homepage__slider-games .slider-games__btn_next {
  right: 0;
}

.homepage__slider-games .slider-games__btn_prev {
  right: 54px;
}

.homepage__slider-games .slider-games .slick-list {
  margin: 0 -7px;
}

.homepage__slider-games .slider-games .slick-slide {
  margin: 0;
}

.homepage__bonuses {
  margin-bottom: 60px;
}

@media screen and (width <= 1439px) {
  .homepage__bonuses {
    margin-bottom: 50px;
  }
}

@media screen and (width <= 1239px) {
  .homepage__bonuses {
    margin-bottom: 40px;
  }
}

@media screen and (width <= 1023px) {
  .homepage__bonuses {
    margin-bottom: 30px;
  }
}

.homepage__bonuses-content {
  justify-content: center;
  max-width: 1200px;
  margin: auto;
  display: flex;
}

@media screen and (width <= 1439px) {
  .homepage__bonuses-content {
    padding: 0;
  }
}

.homepage__widget-bigwins {
  margin-bottom: 60px;
}

@media screen and (width <= 1439px) {
  .homepage__widget-bigwins {
    margin-bottom: 50px;
  }
}

@media screen and (width <= 1239px) {
  .homepage__widget-bigwins {
    margin-bottom: 40px;
  }
}

@media screen and (width <= 1023px) {
  .homepage__widget-bigwins {
    margin-bottom: 30px;
  }
}

.homepage__reg {
  filter: contrast(2);
  background: #be9f68 url("/joxi/yoyo/registration_1920_50contrast.jpg") top no-repeat;
  margin-bottom: 60px;
  position: relative;
  overflow: hidden;
}

@media screen and (width <= 1439px) {
  .homepage__reg {
    margin-bottom: 50px;
  }
}

@media screen and (width <= 1239px) {
  .homepage__reg {
    margin-bottom: 40px;
  }
}

@media screen and (width <= 1023px) {
  .homepage__reg {
    margin-bottom: 30px;
  }
}

.homepage__reg-form {
  filter: contrast(.5);
  min-height: 565px;
  padding: 27px 0 35px;
}

@media (width >= 1440px) {
  .homepage__dashboard {
    padding-bottom: 55px;
  }

  .homepage__banner .game-banner {
    margin-bottom: 114px;
  }

  .homepage__slider-games {
    margin-bottom: 28px;
  }
}

@media screen and (width <= 767px) {
  .homepage__reg {
    background: url("/joxi/yoyo/registration_768_50contrast.jpg") top no-repeat;
  }

  .homepage__user-bonus-1, .homepage__user-bonus-2 {
    display: none;
  }
}

@media only screen and (width <= 539px) {
  .homepage__reg {
    filter: contrast();
    background: #fcbe50;
  }

  .homepage__reg-form {
    filter: contrast();
  }
}

.linda-has-user .homepage__welcome-bonus {
  display: none;
}

.linda-has-user .homepage__user-bonus-2 {
  display: block;
}

@media screen and (width <= 767px) {
  .linda-has-user .homepage__user-bonus-1 {
    display: block;
  }

  .linda-has-user .homepage__user-bonus-2 {
    display: none;
  }
}

.home-event-widgets-section {
  background-color: #3e3e3e;
  padding: 30px 0;
}

.home-event-widgets-section__wrap {
  justify-content: space-between;
  margin: 0 -7px;
  display: flex;
}

.home-event-widgets-section__item {
  text-align: center;
  background-color: #f9fbe7;
  border-radius: 10px;
  flex: 1 1 0;
  margin: 0 7px;
  padding: 15px 10px 10px;
}

.home-event-widgets-section__item.app-for-gadget {
  word-break: break-word;
  background-image: url("/joxi/yoyo/banner_devices.jpg");
  background-position: 100%;
  background-size: cover;
  flex-direction: column;
  min-height: 100px;
  padding-bottom: 15px;
  display: flex;
  position: relative;
}

.home-event-widgets-section__title {
  color: #3f3f3f;
  margin-bottom: 5px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.home-event-widgets-section .app-for-gadget {
  background-position-x: -80px;
}

.home-event-widgets-section .app-for-gadget__title {
  color: #3e3e3e;
  text-align: left;
  width: 50%;
  margin: auto auto auto 50%;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.home-event-widgets-section .widget_month {
  color: #aa69ac;
}

.home-event-widgets-section .widget_day {
  color: #35d290;
}

@media screen and (width <= 1023px) {
  .home-event-widgets-section .home-widget-rewards {
    display: none;
  }
}

@media screen and (width <= 767px) {
  .home-event-widgets-section .home-widget-bigwins_day {
    display: none;
  }
}

@media only screen and (width <= 539px) {
  .home-event-widgets-section .home-widget-bigwins_month {
    display: none;
  }
}

.widget-bigwins-home {
  padding-top: 40px;
}

.widget-bigwins-home__wrap {
  margin: 0 auto;
  display: flex;
}

.widget-bigwins-home__item {
  text-align: right;
  background-position: 0 0;
  background-repeat: no-repeat;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 50%;
  height: 260px;
  padding-right: 60px;
  display: flex;
  position: relative;
}

.widget-bigwins-home__item_month {
  background-color: #f1ece1;
  margin-right: 10px;
}

.widget-bigwins-home__item_day {
  background-color: #e5f7de;
  margin-left: 10px;
}

.widget-bigwins-home__title {
  z-index: 1;
  width: 50%;
  margin-top: 0;
  margin-bottom: 9px;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
}

@media screen and (width <= 1439px) {
  .widget-bigwins-home__item_month {
    margin-right: 6px;
  }

  .widget-bigwins-home__item_day {
    margin-left: 6px;
  }

  .widget-bigwins-home__title {
    max-width: calc(50% - 20px);
    margin-bottom: 14px;
  }
}

@media screen and (width <= 1239px) {
  .widget-bigwins-home__item {
    padding-right: 24px;
  }
}

@media screen and (width <= 1023px) {
  .widget-bigwins-home__item {
    height: 210px;
    padding-right: 20px;
  }

  .widget-bigwins-home__title {
    margin-bottom: 17px;
    font-size: 14px;
    line-height: 25px;
  }
}

@media screen and (width <= 767px) {
  .widget-bigwins-home__wrap {
    flex-wrap: wrap;
  }

  .widget-bigwins-home__item {
    width: 100%;
  }

  .widget-bigwins-home__item_month {
    margin-bottom: 38px;
    margin-right: 0;
  }

  .widget-bigwins-home__item_day {
    margin-left: 0;
  }

  .widget-bigwins-home__title {
    max-width: 29%;
  }
}

@media only screen and (width <= 539px) {
  .widget-bigwins-home__item {
    height: 200px;
    padding-right: 12px;
  }

  .widget-bigwins-home__item_month {
    margin-bottom: 25px;
  }

  .widget-bigwins-home__title {
    max-width: calc(100% - 150px);
    margin-bottom: 12px;
  }
}

linda-slider-main {
  z-index: 1;
  display: block;
  position: relative;
}

.main-slider {
  z-index: 1;
  min-height: 200px;
  position: relative;
}

.main-slider__slide-wrap {
  background-size: cover;
}

.main-slider__inner {
  background-color: #ffd07e;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
  position: relative;
}

.main-slider__inner-content {
  text-align: center;
  background: url("/img/slider-text-background.svg") top no-repeat content-box border-box;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 450px;
  padding-top: 40px;
  padding-bottom: 111px;
  line-height: normal;
  display: flex;
}

.main-slider__title1 {
  color: #ffd180;
  max-height: 102px;
  margin-bottom: 3px;
  font-size: 24px;
  font-weight: 700;
  overflow: hidden;
}

.main-slider__title2 {
  color: #fff;
  max-height: 65px;
  margin-bottom: 12px;
  font-size: 60px;
  font-weight: 900;
}

.main-slider__title3 {
  color: #f9fbe7;
  min-height: 17px;
  font-size: 36px;
  font-weight: 900;
  line-height: normal;
}

.main-slider__title1, .main-slider__title2, .main-slider__title3 {
  text-shadow: 0 4px #00000040;
  overflow: hidden;
}

.main-slider__btn-sign {
  border: 1px solid #fff;
  margin-top: 15px;
  display: none;
}

.main-slider__btn {
  box-sizing: border-box;
  margin-top: 20px;
  position: relative;
}

.main-slider .slick-dots {
  justify-content: center;
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 72px;
}

.main-slider .slick-dots li {
  cursor: pointer;
  margin: 2px 5px;
  padding: 8px 0;
  line-height: 0;
}

.main-slider .slick-dots button {
  cursor: pointer;
  opacity: .5;
  background: #3f3f3f;
  border: 2px solid #0000;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  padding: 0;
  font-size: 0;
  transition: background .2s;
}

.main-slider .slick-dots li:hover button {
  opacity: 1;
  background-color: #3f3f3f;
}

.main-slider .slick-dots .slick-active button {
  opacity: 1;
  background: #3f3f3f;
  width: 10px;
  height: 10px;
}

@media screen and (width <= 1239px) {
  .main-slider__inner-content {
    height: 430px;
    padding-bottom: 96px;
  }

  .main-slider .slick-dots {
    bottom: 55px;
  }
}

@media screen and (width <= 1023px) {
  .main-slider__title2 {
    font-size: 50px;
  }

  .main-slider__inner-content {
    height: 390px;
    padding-bottom: 56px;
  }

  .main-slider .slick-dots {
    bottom: 16px;
  }
}

@media screen and (width <= 767px) {
  .main-slider__inner:before {
    content: "";
    z-index: 0;
    background-color: #3f3f3fb3;
    position: absolute;
    inset: 0;
  }

  .main-slider__inner-content {
    z-index: 1;
    background: none;
    justify-content: center;
    width: 100%;
    height: 340px;
    padding: 15px;
    position: relative;
    overflow: hidden;
  }

  .main-slider__title2 {
    margin-bottom: 10px;
  }
}

@media only screen and (width <= 539px) {
  .main-slider__inner-content {
    justify-content: flex-end;
    padding: 15px 15px 40px;
  }

  .main-slider__inner {
    background-position-x: calc(50% + 270px);
  }

  .main-slider__title1 {
    max-height: 40px;
    margin-bottom: 10px;
    font-size: 18px;
  }

  .main-slider__title2 {
    max-height: 75px;
    margin-bottom: 10px;
    font-size: 32px;
  }

  .main-slider__title3 {
    min-height: auto;
    max-height: 55px;
    font-size: 24px;
  }

  .main-slider__btn {
    margin-top: auto;
  }

  .main-slider__btn-sign, .main-slider__btn {
    border-radius: 20px;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
  }

  .main-slider__btn-sign {
    display: block;
  }

  .main-slider__text-wrap {
    margin: auto;
  }
}

.main-slider .slick-dots {
  z-index: 1;
}

.linda-has-user .main-slider__btn-sign {
  display: none;
}

.linda-has-user .main-slider__title1, .linda-has-user .main-slider__title2 {
  margin-bottom: 10px;
}

.linda-has-user .main-slider__btn {
  margin-top: 40px;
}

@media only screen and (width <= 539px) {
  .linda-has-user .main-slider__text-wrap {
    margin: unset;
  }

  .linda-has-user .main-slider__inner-content {
    justify-content: center;
    padding: 15px;
  }
}

.home-dashboard {
  grid-gap: 16px;
  grid-template-columns: repeat(6, 1fr);
  display: grid;
}

.home-dashboard .dashboard-game-item {
  width: 100%;
  margin: 0;
}

.home-dashboard .dashboard-game-item.is-big-first {
  grid-area: 1 / 1 / 3 / 3;
}

.home-dashboard .dashboard-game-item.is-big-second {
  grid-area: 3 / 4 / 5 / 6;
}

.home-dashboard .dashboard-promo-game {
  grid-area: 1 / 1 / 3 / 3;
  width: 100%;
  margin: 0;
}

.home-dashboard .dashboard-promo-game > div {
  height: 100%;
}

.home-dashboard .dashboard-promo-game__pic {
  background-position: top;
}

.home-dashboard__tournaments {
  grid-area: 3 / 4 / 5 / 6;
  overflow: hidden;
}

.home-dashboard__events {
  grid-area: 1 / 6 / 5 / 7;
  position: relative;
}

@media screen and (width <= 1439px) {
  .home-dashboard {
    grid-gap: 12px;
  }
}

@media screen and (width <= 1239px) {
  .home-dashboard {
    grid-gap: 11px;
    grid-template-columns: repeat(5, 1fr);
  }

  .home-dashboard .dashboard-game-item:nth-of-type(n+19) {
    display: none;
  }

  .home-dashboard .dashboard-game-item.is-big-first {
    grid-area: 1 / 1 / 3 / 3;
  }

  .home-dashboard .dashboard-game-item.is-big-second, .home-dashboard__tournaments {
    grid-column: 3 / 5;
  }

  .home-dashboard__events {
    grid-column: 5 / 6;
  }
}

@media screen and (width <= 1023px) {
  .home-dashboard {
    grid-gap: 16px;
    grid-template-columns: repeat(3, 1fr);
  }

  .home-dashboard .dashboard-game-item:nth-of-type(n+19) {
    display: block;
  }

  .home-dashboard .dashboard-game-item.is-big-first {
    grid-area: 1 / 1 / 2 / -1;
  }

  .home-dashboard .dashboard-game-item.is-big-second {
    grid-area: 7 / 1 / 8 / -1;
  }

  .home-dashboard .dashboard-game-item.is-big .game-item__pic:before {
    padding-top: 34.3%;
  }

  .home-dashboard__events {
    grid-area: 4 / 1 / 5 / -1;
  }
}

@media screen and (width <= 767px) {
  .home-dashboard {
    grid-gap: 12px;
    grid-template-columns: repeat(2, 1fr);
  }

  .home-dashboard .dashboard-game-item:nth-of-type(n+13) {
    display: none;
  }

  .home-dashboard .dashboard-game-item.is-big-second {
    grid-row: 6 / 7;
  }

  .home-dashboard__events {
    grid-row: 5 / 6;
  }

  .home-dashboard .dashboard-game-item.is-big .game-item__pic:before {
    padding-top: 55%;
  }
}

.linda-widget-jackpot {
  z-index: 0;
  background-color: #ea4642;
  display: block;
  position: relative;
}

.linda-widget-jackpot__content {
  z-index: 5;
  height: 400px;
  padding: 80px 0 0 35px;
  position: relative;
}

.linda-widget-jackpot__roullette-image {
  text-align: right;
  height: 430px;
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  overflow: hidden;
}

.linda-widget-jackpot__roullette-image img {
  max-width: unset;
  animation: 60s linear infinite rouletteRotate;
  position: absolute;
  right: -111px;
}

.linda-widget-jackpot__title {
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 50%;
  margin: 0 0 30px;
  font-size: 34px;
  font-weight: 600;
  line-height: 1.2;
  overflow: hidden;
}

.linda-widget-jackpot__subtitle {
  color: #ffd180;
  text-transform: uppercase;
  max-width: 600px;
  margin: 0;
  font-size: 80px;
  font-weight: bold;
  line-height: 94px;
}

.linda-widget-jackpot__icon-cup {
  text-align: center;
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.linda-widget-jackpot__icon-cup svg {
  opacity: .3;
  fill: #f9fbe7;
  width: 210px;
  height: 240px;
}

.linda-widget-jackpot .odometer.odometer-auto-theme, .linda-widget-jackpot .odometer.odometer-theme-default, .linda-widget-jackpot .odometer.odometer-auto-theme .odometer-digit, .linda-widget-jackpot .odometer.odometer-theme-default .odometer-digit {
  vertical-align: middle;
  display: inline-block;
  position: relative;
}

.linda-widget-jackpot .odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .linda-widget-jackpot .odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer {
  vertical-align: middle;
  visibility: hidden;
  display: inline-block;
}

.linda-widget-jackpot .odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .linda-widget-jackpot .odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.linda-widget-jackpot .odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .linda-widget-jackpot .odometer.odometer-theme-default .odometer-digit .odometer-ribbon {
  display: block;
}

.linda-widget-jackpot .odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .linda-widget-jackpot .odometer.odometer-theme-default .odometer-digit .odometer-ribbon-inner {
  -webkit-backface-visibility: hidden;
  display: block;
}

.linda-widget-jackpot .odometer.odometer-auto-theme .odometer-digit .odometer-value, .linda-widget-jackpot .odometer.odometer-theme-default .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}

.linda-widget-jackpot .odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .linda-widget-jackpot .odometer.odometer-theme-default .odometer-digit .odometer-value.odometer-last-value {
  position: absolute;
}

.linda-widget-jackpot .odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .linda-widget-jackpot .odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -o-transition: -o-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  transition: transform 2s;
}

.linda-widget-jackpot .odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .linda-widget-jackpot .odometer.odometer-theme-default.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .linda-widget-jackpot .odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .linda-widget-jackpot .odometer.odometer-theme-default.odometer-animating-down .odometer-ribbon-inner {
  transform: translateY(-100%);
}

.linda-widget-jackpot .odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .linda-widget-jackpot .odometer.odometer-theme-default.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -o-transition: -o-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  transition: transform 2s;
  transform: translateY(0);
}

@media screen and (width <= 1439px) {
  .linda-widget-jackpot__content {
    padding: 70px 0 0 30px;
  }

  .linda-widget-jackpot__roullette-image {
    height: calc(100% + 30px);
  }

  .linda-widget-jackpot__roullette-image img {
    right: -310px;
  }
}

@media screen and (width <= 1239px) {
  .linda-widget-jackpot__roullette-image img {
    right: -411px;
  }
}

@media screen and (width <= 1023px) {
  .linda-widget-jackpot__content {
    height: 250px;
    padding: 35px 0 0 9px;
  }

  .linda-widget-jackpot__title {
    margin: 0 0 20px;
    font-size: 24px;
  }

  .linda-widget-jackpot__subtitle {
    max-width: 360px;
    font-size: 48px;
    font-weight: 900;
    line-height: 56px;
  }

  .linda-widget-jackpot__icon-cup svg {
    width: 149px;
    height: 170px;
  }

  .linda-widget-jackpot__roullette-image {
    height: calc(100% + 25px);
    top: -25px;
  }

  .linda-widget-jackpot__roullette-image img {
    width: 550px;
    right: -174px;
  }
}

@media screen and (width <= 767px) {
  .linda-widget-jackpot__roullette-image img {
    right: -380px;
  }
}

@media only screen and (width <= 539px) {
  .linda-widget-jackpot {
    display: none;
  }
}

@keyframes rouletteRotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.home-promo {
  box-sizing: border-box;
  border: 1px solid #00be6e;
  border-radius: 10px;
  width: 585px;
  min-height: 350px;
  padding: 30px 10px 30px 35px;
}

.home-promo + .home-promo {
  margin-left: 30px;
}

.home-promo__content {
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: 300px 300px;
  width: 100%;
  height: 100%;
}

.home-promo__info {
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding: 35px 295px 25px 0;
  display: flex;
}

.home-promo__title, .home-promo__text {
  color: #3f3f3f;
  margin: 0 0 15px;
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
}

.home-promo__sum {
  color: #00be6e;
  margin-bottom: 15px;
  font-size: 32px;
  font-weight: 900;
  line-height: normal;
  overflow: hidden;
}

.home-promo__link {
  margin-top: auto;
  transition: all .2s;
}

.home-promo_user-bonus-1 {
  border: 1px solid #aa69ac;
}

.home-promo_user-bonus-1 .home-promo__sum {
  color: #aa69ac;
}

.home-promo_user-bonus-1 .home-promo__link {
  background-color: #aa69ac;
}

.home-promo_user-bonus-1 .home-promo__link:hover {
  color: #aa69ac;
  background-color: #0000;
  box-shadow: inset 0 0 0 1px #aa69ac;
}

.home-promo_user-bonus-2 {
  border: 1px solid #ffa800;
  display: none;
}

.home-promo_user-bonus-2 .home-promo__sum {
  color: #ffa800;
}

.home-promo_user-bonus-2 .home-promo__link {
  background-color: #ffa800;
}

.home-promo_user-bonus-2 .home-promo__link:hover {
  color: #ffa800;
  background-color: #0000;
  box-shadow: inset 0 0 0 1px #ffa800;
}

.linda-has-user .home-promo_welcome-bonus + .home-promo_user-bonus-1 {
  margin-left: 0;
}

@media screen and (width <= 1439px) {
  .home-promo + .home-promo {
    margin-left: 20px;
  }
}

@media screen and (width <= 1239px) {
  .home-promo {
    width: calc(50% - 7px);
    min-height: 260px;
    padding: 23px 5px 23px 30px;
  }

  .home-promo + .home-promo {
    margin-left: 14px;
  }

  .home-promo__content {
    background-size: 220px 220px;
  }

  .home-promo__info {
    padding: 17px 220px 17px 0;
  }

  .home-promo__title {
    margin-bottom: 10px;
    font-size: 18px;
  }

  .home-promo__sum {
    margin-bottom: 10px;
    font-size: 24px;
  }

  .home-promo__text {
    margin-bottom: 20px;
    font-size: 18px;
  }
}

@media screen and (width <= 1023px) {
  .home-promo {
    min-height: unset;
    padding: 20px 10px 20px 20px;
  }

  .home-promo__content {
    background-size: 150px 150px;
  }

  .home-promo__info {
    padding: 8px 150px 8px 0;
  }
}

@media screen and (width <= 767px) {
  .home-promo {
    width: 100%;
    min-height: 250px;
    padding: 15px 13px 15px 30px;
  }

  .home-promo__content {
    background-size: 220px 220px;
  }

  .home-promo__info {
    padding: 12px 220px 12px 0;
  }
}

@media only screen and (width <= 539px) {
  .home-promo {
    padding: 25px 0 15px 25px;
  }

  .home-promo__content {
    background-position: right top -15px;
    padding: 0;
  }

  .home-promo__info {
    background: #f9fbe7cc;
    padding: 0 25px 20px 0;
  }
}

.welcom-bonus-home {
  text-align: center;
  background-image: url("/joxi/sweden-yoyo/welcome_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 520px;
  display: flex;
  position: relative;
}

.welcom-bonus-home__content {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 600px;
  margin-top: auto;
  overflow: hidden;
}

.welcom-bonus-home__title {
  color: #ffd180;
  text-shadow: 0 4px #00000040;
  margin-bottom: 16px;
  font-size: 36px;
  font-weight: 900;
  line-height: 42px;
}

.welcom-bonus-home__desc {
  color: #f9fbe7;
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 14px;
}

.welcom-bonus-home__text {
  color: #fff;
  text-shadow: 0 6px #00000040;
  overflow-wrap: break-word;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  font-weight: 900;
  display: -webkit-box;
  overflow: hidden;
}

.welcom-bonus-home__text_big {
  margin-bottom: 16px;
  font-size: 60px;
  line-height: 70px;
}

.welcom-bonus-home__text_small {
  margin-bottom: 20px;
  font-size: 50px;
  line-height: 59px;
}

.welcom-bonus-home__no-registr {
  color: #f9fbe7;
  justify-content: center;
  margin-top: 21px;
  font-size: 14px;
  font-weight: bold;
  line-height: 25px;
  display: flex;
}

.welcom-bonus-home .icon-icon-reg-arrow__before:before {
  content: "";
  filter: invert(94%) sepia(15%) saturate(213%) hue-rotate(27deg) brightness(103%) contrast(97%);
  width: 30px;
  height: 30px;
  margin-top: -9px;
  margin-right: 6px;
  display: block;
}

.welcom-bonus-home__text-bottom {
  color: #fff;
  max-width: 700px;
  margin-top: auto;
  margin-bottom: 15px;
  font-size: 12px;
  line-height: 14px;
}

.welcom-bonus-home__text-bottom a {
  color: #fff;
  text-decoration: underline;
}

@media screen and (width <= 1023px) {
  .welcom-bonus-home {
    height: 466px;
  }

  .welcom-bonus-home__content, .welcom-bonus-home__text-bottom {
    max-width: 500px;
  }

  .welcom-bonus-home__title {
    font-size: 32px;
    line-height: 37px;
  }

  .welcom-bonus-home__text_big {
    font-size: 50px;
    line-height: 59px;
  }

  .welcom-bonus-home__text_small {
    font-size: 36px;
    line-height: 42px;
  }
}

@media screen and (width <= 767px) {
  .welcom-bonus-home {
    background-image: url("/joxi/yoyo/bonus-bg-320-767.jpg");
    height: 470px;
  }

  .welcom-bonus-home__content, .welcom-bonus-home__text-bottom {
    max-width: 290px;
    margin-left: auto;
    margin-right: 60px;
  }

  .welcom-bonus-home__title {
    font-size: 24px;
    line-height: 28px;
  }

  .welcom-bonus-home__text_big {
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 28px;
  }

  .welcom-bonus-home__text_small {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 21px;
  }

  .welcom-bonus-home__btn {
    font-size: 18px;
  }

  .welcom-bonus-home__text-bottom {
    margin-bottom: 30px;
  }
}

@media only screen and (width <= 539px) {
  .welcom-bonus-home__content, .welcom-bonus-home__text-bottom {
    margin-right: auto;
  }
}

@media (width >= 1920px) {
  .widget-bigwins__bg {
    background-size: cover;
  }

  .widget-bigwins__img.game-item {
    margin-left: 8%;
  }
}

@media screen and (width <= 1439px) {
  .widget-bigwins {
    width: calc(50% - 20px);
  }

  .widget-bigwins__price {
    margin-bottom: 18px;
    font-size: 24px;
    line-height: 28px;
  }
}

@media screen and (width <= 1023px) {
  .widget-bigwins__price {
    margin-bottom: 19px;
    font-size: 18px;
    line-height: 21px;
  }

  .widget-bigwins__img.game-item {
    background-size: 140px 210px;
    width: 140px;
    height: 210px;
    top: -18px;
  }

  .widget-bigwins__img.game-item .game-item__hoverplay {
    width: 140px;
    height: 210px;
  }

  .widget-bigwins__bg {
    height: 210px;
  }
}

@media screen and (width <= 767px) {
  .widget-bigwins {
    width: 29%;
  }

  .widget-bigwins__bg {
    width: 70%;
  }
}

@media only screen and (width <= 539px) {
  .widget-bigwins {
    width: calc(100% - 150px);
  }

  .widget-bigwins__price {
    margin-bottom: 12px;
    font-size: 14px;
  }

  .widget-bigwins__bg {
    display: none;
  }

  .widget-bigwins__img.game-item {
    width: 140px;
    margin-left: 0;
    top: -5px;
  }
}

.widget-rewards-content__reward-prize {
  color: #ea4642;
  margin-bottom: 5px;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}

.widget-rewards-content__reward-name {
  color: #3f3f3f;
  font-size: 12px;
  line-height: normal;
}

.timer {
  display: flex;
}

.timer__label {
  color: #3f3f3f;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  display: none;
}

.timer__num {
  color: #fff;
  background: #3f3f3f80;
  border-radius: 3px;
  width: 50px;
  height: 35px;
  margin-right: 14px;
  padding: 3px 11px;
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  position: relative;
}

.timer__num:nth-child(4):after, .timer__num:nth-child(5):after {
  content: ":";
  color: #3f3f3f80;
  display: block;
  position: absolute;
  top: 2px;
  right: -10px;
}

.timer__num:nth-child(2) {
  background: #3f3f3fb3;
}

.timer__num:last-child {
  margin-right: 0;
}

.timer_mini .timer__label {
  display: block;
}

.timer_mini .timer__num {
  border-radius: none;
  color: #3f3f3f;
  background: none;
  width: auto;
  height: auto;
  margin-right: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.timer_mini .timer__num:nth-child(4):after, .timer_mini .timer__num:nth-child(5):after {
  content: ":";
  color: #3f3f3f;
  display: block;
  position: absolute;
  top: 0;
  right: 2px;
}

@media screen and (width <= 767px) {
  .timer_mini .timer__label, .timer_mini .timer__num, .timer_mini .timer__num:nth-child(4):after, .timer_mini .timer__num:nth-child(5):after {
    color: #f9fbe7;
  }
}

.events {
  display: block;
  position: relative;
}

.events__title {
  position: absolute;
  top: -26px;
  left: 0;
}

.events__in {
  width: 100%;
  height: 0;
  padding-top: 286.631%;
  position: relative;
  overflow: hidden;
}

.events__list {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden auto;
}

.events__list::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.events__item {
  flex-shrink: 0;
  width: 100%;
}

.events__item:not(:last-child) {
  margin-bottom: 10px;
}

.events-item {
  background: #f9fbe7;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  align-items: center;
  width: 100%;
  height: 70px;
  padding: 10px 8px;
  display: flex;
}

.events-item__text {
  color: #3f3f3f;
  overflow-wrap: break-word;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  display: -webkit-box;
  overflow: hidden;
}

.events-item__text a {
  color: currentColor;
  text-decoration: underline;
}

.events-item__text a:hover {
  color: #81348e;
}

.events-item__img {
  border-radius: 6px;
  flex-shrink: 0;
  width: 60px;
  height: 50px;
  margin-left: 8px;
}

.footer__content-wrap {
  background-color: #f2ece0;
  padding-bottom: 35px;
}

.footer__top {
  background-color: #9746a5;
  padding: 13px 0;
}

.footer__top-wrap {
  box-sizing: border-box;
  justify-content: space-between;
  display: flex;
  overflow: hidden;
}

.footer__lang {
  color: #f9fbe7;
  text-transform: capitalize;
  height: 30px;
  padding-left: 30px;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  position: relative;
}

.footer__lang:hover .footer__icon-wrap {
  background: #f9fbe7;
}

.footer__lang:hover .footer__icon-wrap svg {
  fill: #aa69ac;
}

.footer__icon-wrap {
  text-align: center;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  padding-top: 2px;
  transition: all .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.footer__icon-wrap svg {
  fill: #f9fbe7;
  width: 20px;
  height: 20px;
  padding-top: 5px;
}

.footer__payments-list {
  border-bottom: 1px solid #e0e0e0;
  padding: 20px 0;
}

.footer .footerLicense {
  text-align: center;
  color: #3f3f3f;
  padding-top: 24px;
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
}

.footer .footerLicense-icons {
  flex-wrap: wrap;
  gap: 10px 24px;
  margin-bottom: 0;
}

.footer .footerLicense-icons li {
  height: 32px;
}

.footer .footerLicense-icons a:not([href]) {
  pointer-events: none;
  cursor: initial;
}

.footer .footerLicense-text a, .footer .footerLicense-text button {
  color: #aa69ac;
  text-decoration: underline;
  transition: color, -webkit-text-decoration .2s ease-in-out, text-decoration .2s ease-in-out;
}

.footer .footerLicense-text a:hover, .footer .footerLicense-text button:hover {
  color: #ab69ad;
  text-decoration: none;
}

.footer .footerLicense-text button {
  background: none;
  border: 0;
  padding: 0;
  font-size: 10px;
}

.footer .footerLicense-text {
  padding-top: 24px;
}

.footer .footerLicense-text p {
  margin: 10px 0 0;
}

.footer .footerLicense-text p:first-child {
  margin-top: 0;
}

@media screen and (width <= 1439px) {
  .footer__content-wrap {
    padding-bottom: 30px;
  }
}

@media screen and (width <= 1023px) {
  .footer__content-wrap {
    padding-bottom: 25px;
  }

  .footer__payments-list {
    padding: 20px 0;
  }
}

.footer-nav {
  flex-grow: 1;
}

.footer-nav__list {
  flex-wrap: wrap;
  display: flex;
}

.footer-nav__item {
  color: #fff;
  margin: 5px 60px 5px 0;
  font-size: 14px;
  line-height: 1.29;
}

.footer-nav__item:hover {
  opacity: .7;
}

.footer-nav__link {
  color: #fff;
}

.footer-nav__link.active {
  font-weight: bold;
}

@media screen and (width <= 1239px) {
  .footer-nav__item {
    margin: 5px 30px 5px 0;
    font-size: 12px;
  }
}

.providers {
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-top: 24px;
  margin-bottom: 7px;
  display: flex;
  position: relative;
}

.providers .selected-filter {
  letter-spacing: normal;
  color: #413034cc;
  margin: 0;
  padding-left: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}

.providers__wrap {
  background-color: #dcdbd3;
  border-radius: 25px;
  width: calc(16.6667% - 15px);
  margin-right: 8px;
  transition: all .2s;
}

.providers__wrap:hover {
  background-color: #dcdbd380;
  box-shadow: 0 6px 20px #00000014;
}

.providers__wrap.active {
  background-color: #f9fbe7;
  box-shadow: 0 6px 20px #00000014;
}

.providers__wrap.providers-open {
  z-index: 150;
  background-color: #f9fbe7;
  border-radius: 10px;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: 0 6px 20px #00000014;
}

.providers__wrap.providers-open:hover {
  box-shadow: 0 6px 20px #00000014;
}

.providers__select {
  cursor: pointer;
  height: 41px;
  line-height: 41px;
  position: relative;
}

.providers__select-text {
  letter-spacing: normal;
  color: #413034cc;
  -webkit-text-stroke: 0 #413034cc;
  padding: 0 40px 0 30px;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
}

.providers__select-arr {
  width: 11px;
  height: 7px;
  line-height: 7px;
  transition: all .2s;
  position: absolute;
  top: 15px;
  right: 20px;
}

.providers-open .providers__select-arr {
  transform: rotate(180deg);
}

.providers__select-arr svg {
  fill: #413034cc;
  width: 11px;
  height: 7px;
}

.providers__list {
  max-height: 430px;
  overflow-y: auto;
}

.providers__over {
  display: none;
}

.providers-open .providers__over {
  display: block;
}

.providers__link {
  color: #413034cc;
  justify-content: space-between;
  height: 36px;
  padding: 3px 10px 3px 15px;
  font-size: 14px;
  font-weight: 500;
  transition: all .2ms;
  display: flex;
}

.providers__link.active {
  color: #413034cc;
  background-color: #dcdbd3;
}

.providers__link:hover {
  color: #413034cc;
  background-color: #dcdbd380;
}

.providers__img {
  width: 30px;
  height: 30px;
  margin: auto 0;
}

.providers__img img {
  height: 100%;
}

.providers__name {
  margin: auto 0;
}

@media screen and (width <= 1439px) {
  .providers {
    margin-bottom: 4px;
  }

  .providers__wrap {
    width: calc(20% - 11px);
    margin-right: 6px;
  }
}

@media screen and (width <= 1023px) {
  .providers {
    margin-bottom: 0;
  }

  .providers__wrap {
    width: calc(33.3333% - 16px);
    margin-right: 14px;
  }
}

@media screen and (width <= 767px) {
  .providers {
    flex-direction: column;
    align-items: flex-start;
    height: 75px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .providers__wrap {
    width: calc(100% - 9px);
    margin-left: 5px;
  }

  .providers__wrap.providers-open {
    top: 35px;
    right: -10px;
  }
}

linda-game-categories {
  z-index: 2;
  display: block;
  position: relative;
}

@media screen and (width <= 1023px) {
  linda-game-categories {
    transform: translateY(0);
  }
}

.game-category {
  margin-top: 20px;
}

.game-category::-webkit-scrollbar-track {
  background: none;
}

.game-category::-webkit-scrollbar-thumb {
  background: none;
}

.linda-has-user .game-category {
  margin-top: 53px;
}

.game-category__list {
  z-index: 2;
  background: #f9fbe7;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  height: 110px;
  margin: auto;
  padding: 0 40px;
  display: flex;
  position: relative;
  box-shadow: 0 6px 20px #00000014;
}

.game-category__item {
  justify-content: center;
  align-items: center;
  max-width: 10%;
  display: flex;
}

@media screen and (width <= 1239px) {
  .game-category__list {
    height: 90px;
    padding: 0 25px;
  }
}

@media screen and (width <= 1023px) {
  .game-category {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: auto hidden;
    box-shadow: 0 6px 20px #00000014;
  }

  .game-category::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }

  .game-category.game-category_scroll {
    box-shadow: none;
    overflow-x: scroll;
  }

  .game-category.game-category_scroll::-webkit-scrollbar-track {
    background: linear-gradient(#00000014 0%, #0000 100%);
    border-radius: 5px;
  }

  .game-category.game-category_scroll::-webkit-scrollbar-thumb {
    background-color: #fbce7e;
    border-radius: 5px;
  }

  .game-category.game-category_scroll::-webkit-scrollbar-thumb:hover {
    background-color: #fbce7e;
  }

  .game-category.game-category_scroll::-webkit-resizer {
    background-image: none;
    background-repeat: no-repeat;
    width: 4px;
    height: 4px;
  }

  .game-category.game-category_scroll::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  .game-category__list {
    box-shadow: none;
    border-radius: 0;
    padding: 0;
  }

  .game-category__item {
    width: 90px;
    min-width: 90px;
  }
}

.category-item {
  color: #aa69ac;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  height: 80px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
}

.category-item:hover, .category-item.active {
  color: #ea4642;
}

.category-item:hover .category-item__wrap, .category-item.active .category-item__wrap {
  filter: invert(38%) sepia(16%) saturate(5979%) hue-rotate(335deg) brightness(98%) contrast(87%);
  border-color: #0000;
  margin-top: 3px;
  transform: scale(1.7);
}

.category-item__name {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}

@media (any-hover: none) {
  .category-item:hover {
    color: #aa69ac;
  }

  .category-item:hover .category-item__wrap {
    border-color: #aa69ac;
    margin-top: 0;
  }

  .category-item.active {
    color: #ea4642;
  }

  .category-item.active .category-item__wrap {
    filter: invert(38%) sepia(16%) saturate(5979%) hue-rotate(335deg) brightness(98%) contrast(87%);
    border-color: #0000;
    margin-top: 3px;
    transform: scale(1.7);
  }
}

.category-item__wrap {
  filter: invert(52%) sepia(13%) saturate(1593%) hue-rotate(250deg) brightness(91%) contrast(87%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 32px;
  border: 1px solid #aa69ac;
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
  margin-bottom: 5px;
  transition: all .2s;
  display: flex;
}

.category-item__wrap_profile svg {
  width: 28px;
  height: 28px;
}

.category-item__wrap_cashbox svg {
  width: 26px;
  height: 27px;
}

.category-item__wrap_history svg {
  width: 30px;
  height: 30px;
}

@media screen and (width <= 1239px) {
  .category-item {
    height: 70px;
  }

  .category-item__wrap {
    width: 46px;
    height: 46px;
  }
}

.no-result-block {
  z-index: 1;
  text-align: center;
  color: #fff;
  border: 1px solid #fff9;
  border-radius: 10px;
  width: 100%;
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  position: relative;
  box-shadow: 0 4px 4px #2020201a;
}

.pregame-page {
  z-index: 997;
  flex-direction: column;
  display: flex;
  position: fixed;
  inset: 0;
}

.pregame-page .rg-bar {
  z-index: 2;
  flex-shrink: 0;
  position: relative;
}

.pregame-page .game-page-header {
  flex-shrink: 0;
}

.pregame-page__seo {
  z-index: 1;
  position: relative;
}

.pregame-page__in {
  flex-direction: column;
  flex-grow: 1;
  padding: 40px 0 0;
  display: flex;
  overflow: hidden auto;
}

.pregame-page__in:before {
  content: "";
  z-index: 0;
  background: linear-gradient(0deg, #000c 0% 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.pregame-page__content {
  z-index: 1;
  flex-grow: 1;
  width: 100%;
  max-width: 428px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 32px;
  position: relative;
}

.pregame-page__name {
  color: #fff;
  text-transform: capitalize;
  margin: 0 0 4px;
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
}

.pregame-page__provider {
  color: #dcdbca;
  text-transform: capitalize;
  margin: 0 0 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}

.pregame-page__img {
  width: 100%;
  margin-bottom: 20px;
  display: block;
}

.pregame-page__img img {
  border-radius: 10px;
}

.pregame-page__btn {
  z-index: 1;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

.game-frame {
  flex-grow: 1;
  position: relative;
}

.game-frame iframe {
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.game-hall-container {
  margin-bottom: 60px;
}

@media screen and (width <= 1439px) {
  .game-hall-container {
    margin-bottom: 50px;
  }
}

@media screen and (width <= 1239px) {
  .game-hall-container {
    margin-bottom: 40px;
  }
}

@media screen and (width <= 1023px) {
  .game-hall-container {
    margin-bottom: 30px;
  }
}

.no-favourite-text {
  letter-spacing: normal;
  color: #3f3f3f;
  text-align: justify;
  padding-left: 10px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.76;
}

.linda-has-games .no-favourite-text {
  display: none;
}

.game-box__wrap {
  flex-wrap: wrap;
  display: flex;
}

.game-box__show-more-wrap {
  text-align: center;
}

.game-box__show-more-btn {
  max-width: 100%;
  margin: 20px auto 0;
}

.game-box__nogame {
  margin: 0 auto;
}

.game-box__nogame-h2 {
  color: #413034cc;
  margin: 20px 0;
}

@media screen and (width <= 1439px) {
  .game-box__show-more-btn {
    margin: 25px auto 0;
  }
}

@media screen and (width <= 1023px) {
  .game-box__show-more-btn {
    margin: 20px auto 0;
  }
}

@media screen and (width <= 767px) {
  .game-box__show-more-btn {
    margin: 15px auto 0;
  }
}

@media screen and (width <= 1439px) {
  .game-box-tournament {
    margin: 0 -8px;
  }
}

@media screen and (width <= 767px) {
  .game-box-tournament {
    margin: 0 -5px;
  }
}

.game-item {
  width: calc(16.6667% - 16px);
  margin: 8px;
  position: relative;
  overflow: hidden;
}

@media screen and (width <= 1439px) {
  .game-item {
    width: calc(20% - 12px);
    margin: 6px;
  }
}

@media screen and (width <= 1023px) {
  .game-item {
    width: calc(33% - 16px);
    margin: 8px;
  }
}

@media screen and (width <= 767px) {
  .game-item {
    width: calc(50% - 10px);
    margin: 5px;
  }
}

.game-item__pic {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.is-big .game-item__pic {
  height: 100%;
}

.game-item__pic.has-jackpot {
  border: 2px solid #700e66;
}

.game-item__pic:before {
  content: "";
  padding-top: 66.6%;
  display: block;
}

.game-item__label {
  color: #fff;
  text-align: center;
  z-index: 101;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
  background-color: #35d290;
  width: 100px;
  height: 20px;
  padding: 0 25px;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  position: absolute;
  top: 13px;
  left: -26px;
  overflow: hidden;
  transform: rotate(-45deg);
}

.game-item__label.is-exclusive {
  background: #ea4642;
  font-size: 11px;
}

.game-item__label.is-live {
  display: none !important;
}

.game-item__label.is-dropAndWins {
  z-index: 10;
  background-color: #0000;
  background-image: url("/joxi/betinia/ribbons/drops-wins.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border-top-left-radius: 10px;
  width: 36%;
  height: 32%;
  font-size: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(0);
}

.is-big .game-item__label.is-dropAndWins {
  width: 40%;
  height: 29%;
}

.game-item__hoverplay, .game-item__touchplay {
  z-index: 100;
  background-color: #f9fbe7cc;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% + 4px);
  padding: 0 24px;
  display: flex;
  position: absolute;
  inset: -2px;
  overflow: hidden;
}

.game-item__hoverplay {
  opacity: 0;
  height: calc(100% + 4px);
  transition: all .2s;
}

.game-item__touchplay {
  height: 0;
}

.active-touch-game-item .game-item__touchplay {
  height: 100%;
}

.game-item__play {
  max-width: 100%;
}

.game-item__demo {
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.76;
  overflow: hidden;
}

.game-item:hover .game-item__hoverplay {
  opacity: 1;
}

.game-box-tournament .game-item {
  width: calc(25% - 10px);
  margin: 5px;
}

@media screen and (width <= 1439px) {
  .game-box-tournament .game-item {
    width: calc(33% - 16px);
    margin: 8px;
  }
}

@media screen and (width <= 767px) {
  .game-box-tournament .game-item {
    width: calc(50% - 10px);
    margin: 5px;
  }
}

@media screen and (width <= 1439px) {
  .game-demo-footer .game-item:nth-child(6) {
    display: none;
  }
}

.game-item.widget-game-item {
  width: 100%;
  height: 100%;
  margin: 0;
  display: block;
}

.game-item.widget-game-item > div {
  height: 100%;
}

.game-item.widget-game-item .game-item__label, .game-item.widget-game-item .game-item__demo {
  display: block;
}

.game-item-new {
  box-sizing: border-box;
  display: block;
  position: relative;
}

.game-item-new__in {
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  height: 0;
  padding-top: 150%;
  transition: transition .2s;
  position: relative;
  overflow: hidden;
}

.game-item-new.is-stub .game-item-new__in {
  cursor: default;
  background-color: #fff5da;
  background-image: url("/joxi/yoyo/game-card-stub.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 10px;
}

.game-item-new__in.has-hover:hover {
  box-shadow: 0 4px 10px #76676133;
}

.game-item-new__in.is-active .game-item-new__overlay {
  opacity: 1;
  pointer-events: auto;
}

.game-item-new__in.is-active .game-item-new__img picture {
  transform: scale(1.08);
}

.game-item-new__in.is-active .game-item-new__badge, .game-item-new__in.is-active .game-item-new__number {
  opacity: 0;
}

.game-item-new__name {
  color: #3f3f3f;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  margin: 4px 0 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 140%;
  overflow: hidden;
}

.game-item-new__img picture {
  transition: transform .3s ease-out;
}

.game-item-new__img, .game-item-new__img picture, .game-item-new__img img {
  object-fit: cover;
  z-index: 2;
  background-image: url("/joxi/yoyo/game-card-stub.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.game-item-new__click-zone {
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.game-item-new__overlay {
  z-index: 10;
  background-color: #fff5da;
  background-image: url("/joxi/yoyo/game-card-add-fav.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.game-item-new__overlay-content {
  box-sizing: border-box;
  text-align: center;
  color: #413034cc;
  width: 100%;
  height: 100%;
  white-space: initial;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 140%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.game-item-new__overlay-text {
  text-align: center;
  overflow-wrap: break-word;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.game-item-new__overlay-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 22px;
  height: 22px;
  margin-top: 10px;
  margin-bottom: -10px;
}

.game-item-new__overlay-icon.is-fav {
  background-image: var(--icon-icon-add-fav);
}

.game-item-new:hover .game-item-new__overlay-icon.is-fav {
  filter: invert(33%) sepia(24%) saturate(1811%) hue-rotate(248deg) brightness(99%) contrast(89%);
}

.game-item-new__overlay-icon.is-recently {
  background-image: var(--icon-btn-play);
}

.game-item-new:hover .game-item-new__overlay-icon.is-recently {
  background-image: var(--icon-btn-play-hover);
}

.game-item-new__hoverplay {
  z-index: 102;
  opacity: 0;
  background-color: #f9fbe7cc;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  padding: 0 10px;
  transition: all .2s;
  display: flex;
  position: absolute;
  inset: -2px;
  overflow: hidden;
}

.game-item-new__play {
  max-width: 100%;
  height: 36px;
  padding: 0 20px;
  font-size: 14px;
  line-height: 36px;
}

.game-item-new__demo {
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  overflow: hidden;
}

.game-item-new:hover .game-item-new__hoverplay {
  opacity: 1;
}

.game-item-new__label {
  color: #fff;
  text-align: center;
  z-index: 101;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #35d290;
  width: 100px;
  height: 20px;
  padding: 0 25px;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  position: absolute;
  top: 13px;
  left: -26px;
  overflow: hidden;
  transform: rotate(-45deg);
}

.game-item-new__label.is-exclusive {
  background: #ea4642;
  font-size: 11px;
}

.game-item-new__label.is-live {
  display: none !important;
}

.game-item-new__label.is-dropAndWins {
  z-index: 10;
  background-color: #0000;
  background-image: url("/joxi/betinia/ribbons/drops-wins.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border-top-left-radius: 10px;
  width: 36%;
  height: 32%;
  font-size: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(0);
}

.is-big .game-item-new__label.is-dropAndWins {
  width: 40%;
  height: 29%;
}

.game-item-new__number {
  pointer-events: none;
  z-index: 15;
  background-size: 100% 100%;
  width: 91.6667%;
  height: 50%;
  transition: opacity .2s ease-in-out;
  position: absolute;
  bottom: -8px;
  right: -12px;
}

@media (width >= 1440px) {
  .game-item-new.is-stub .game-item-new__in {
    border-radius: 14px;
  }

  .game-item-new__hoverplay {
    padding: 0 24px;
  }

  .game-item-new__demo {
    margin-top: 12px;
    font-size: 18px;
  }

  .game-item-new__play {
    height: 40px;
    padding: 0 24px;
    font-size: 16px;
    line-height: 40px;
  }

  .game-item-new__overlay-content {
    font-size: 16px;
  }

  .game-item-new__overlay-icon {
    width: 32px;
    height: 32px;
  }

  .game-item-new__name {
    margin-top: 10px;
    font-size: 16px;
  }
}

.game-item-simple {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.game-item-simple__pic {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.game-item-simple__pic.has-jackpot {
  border: 2px solid #700e66;
}

.game-item-simple__pic:before {
  content: "";
  padding-top: 66.6%;
  display: block;
}

.game-item-simple__label {
  color: #fff;
  text-align: center;
  z-index: 101;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
  background-color: #35d290;
  width: 100px;
  height: 20px;
  padding: 0 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  position: absolute;
  top: 13px;
  left: -25px;
  overflow: hidden;
  transform: rotate(-45deg);
}

.game-item-simple__label.is-exclusive {
  background: #ea4642;
  font-size: 11px;
}

.game-item-simple__label.is-live {
  display: none !important;
}

.game-item-simple__label.is-dropAndWins {
  z-index: 10;
  background-color: #0000;
  background-image: url("/joxi/betinia/ribbons/drops-wins.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border-top-left-radius: 10px;
  width: 36%;
  height: 32%;
  font-size: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(0);
}

.is-big .game-item-simple__label.is-dropAndWins {
  width: 40%;
  height: 29%;
}

.game-item-simple__hoverplay {
  z-index: 100;
  opacity: 0;
  background-color: #f9fbe7cc;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  padding: 0 24px;
  transition: all .2s;
  display: flex;
  position: absolute;
  inset: -2px;
  overflow: hidden;
}

.game-item-simple__play {
  max-width: 100%;
}

.game-item-simple__demo {
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.76;
  overflow: hidden;
}

.game-item-simple__bar {
  justify-content: space-between;
  margin-top: 7px;
  padding: 0 15px;
  display: flex;
}

.game-item-simple__bar_touch {
  padding-right: 35px;
}

.game-item-simple__name {
  color: #3f3f3f;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 700;
  overflow: hidden;
}

.game-item-simple:hover .game-item-simple__hoverplay {
  opacity: 1;
}

@media only screen and (width >= 1024px) {
  .game-item-simple:hover .game-item-simple__name {
    color: #ab69ad;
  }
}

.game-item-jackpot__text, .game-item-jackpot__amount {
  text-align: center;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: linear-gradient(154.66deg, #a745b0 11.99%, #700e66 83.71%);
  padding: 0 13px;
  font-weight: 900;
  position: absolute;
  overflow: hidden;
}

.game-item-jackpot__text {
  z-index: 2;
  border-radius: 0 0 10px 10px;
  min-width: 82px;
  font-size: 12px;
  line-height: 20px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.game-item-jackpot__amount {
  z-index: 2;
  border-radius: 0 0 5px 5px;
  width: 100%;
  font-size: 18px;
  line-height: 30px;
  bottom: 0;
}

.is-sm .game-item-jackpot__amount {
  font-size: 10px;
  line-height: 20px;
}

@media (width >= 1440px) {
  .is-sm .game-item-jackpot__amount {
    font-size: 14px;
    line-height: 26px;
  }

  .is-sm .game-item-jackpot__text {
    min-width: 86px;
    font-size: 14px;
    line-height: 24px;
  }
}

.linda-vip .slick-dots {
  margin-right: 10px;
  display: flex;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.linda-vip .slick-dots li {
  margin-right: 10px;
}

.linda-vip .slick-dots li:last-child {
  margin-right: 0;
}

.linda-vip .slick-dots li button {
  cursor: pointer;
  background: #3f3f3f80;
  border: none;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  padding: 0;
  font-size: 0;
}

.linda-vip .slick-dots li.slick-active button {
  background-color: #3f3f3f;
}

.linda-vip__list {
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  display: flex;
}

.linda-vip__slide {
  padding: 87px 0;
}

.linda-vip__slide_first {
  background: url("/joxi/yoyo/vip-back_1.jpg") center no-repeat;
}

.linda-vip__slide_second {
  background: url("/joxi/yoyo/vip-back_2.jpg") center no-repeat;
}

.linda-vip__slide_third {
  background: url("/joxi/yoyo/vip-back_3.jpg") center no-repeat;
}

.linda-vip__slide_fourth {
  background: url("/joxi/yoyo/vip-back_4.jpg") center no-repeat;
}

.linda-vip__slide-content {
  box-sizing: border-box;
  background: #3f3f3fb3;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  width: 580px;
  height: 276px;
  max-height: 276px;
  margin: 0 auto;
  padding: 35px;
  display: flex;
}

.linda-vip__slide-title {
  color: #fff;
  text-align: center;
  max-height: 80px;
  margin: 0 0 12px;
  font-size: 32px;
  font-weight: bold;
  line-height: normal;
  overflow: hidden;
}

.linda-vip__slide-text {
  color: #fff;
  max-height: 121px;
  font-size: 14px;
  line-height: 1.76;
  display: block;
  overflow: hidden;
}

.linda-vip__main-title {
  color: #413034cc;
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
}

.linda-vip__main-content {
  justify-content: space-between;
  margin-bottom: 42px;
  display: flex;
}

@media screen and (width <= 1239px) {
  .linda-vip .slick-dots {
    bottom: 29px;
  }

  .linda-vip__slide {
    padding: 57px 0;
  }

  .linda-vip__main-content {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (width <= 1023px) {
  .linda-vip__main-content {
    max-width: 900px;
    margin: 0 auto 12px;
  }
}

@media screen and (width <= 767px) {
  .linda-vip__main-content {
    max-width: 700px;
    margin-bottom: 15px;
  }

  .linda-vip__slide {
    padding: 0;
  }

  .linda-vip__slide-content {
    border-radius: 0;
    width: 100%;
    height: 340px;
    max-height: 340px;
    padding: 67px 20px;
  }
}

@media only screen and (width <= 539px) {
  .linda-vip__main-content {
    flex-direction: column;
    align-items: center;
  }

  .linda-vip__slide {
    background-position: calc(50% - 270px);
  }

  .linda-vip__slide-text {
    font-size: 12px;
  }

  .linda-vip__slide-content {
    padding: 57px 15px;
  }
}

.linda-vip-item {
  box-sizing: border-box;
  background: #35d2901a;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 19%;
  margin-right: 15px;
  padding: 32px 25px;
  display: flex;
}

.linda-vip-item:last-child {
  margin-right: 0;
}

.linda-vip-item_status-2 {
  background: #ffd18033;
}

.linda-vip-item_status-3 {
  background: #aa69ac1a;
}

.linda-vip-item_status-4 {
  background: #ffa80026;
}

.linda-vip-item_status-5 {
  background: #ea46421a;
}

.linda-vip-item__title-wrap {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
  display: flex;
}

.linda-vip-item__title {
  color: #3f3f3f;
  text-align: center;
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
}

.linda-vip-item__icon {
  line-height: 0;
}

.linda-vip-item__icon svg {
  width: 100%;
  max-width: 160px;
  height: 100%;
  max-height: 126px;
}

.linda-vip-item__info {
  border-bottom: 1px solid #c5d3bf;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 50px;
  padding: 8px 0;
  display: flex;
}

.linda-vip-item__info:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.linda-vip-item__key {
  text-align: left;
  color: #3e3e3e;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.2;
}

.linda-vip-item__value {
  color: #3e3e3e;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  overflow: hidden;
}

@media screen and (width <= 1439px) {
  .linda-vip-item {
    padding: 32px 15px;
  }

  .linda-vip-item__icon svg {
    max-width: 128px;
    max-height: 101px;
  }
}

@media screen and (width <= 1239px) {
  .linda-vip-item {
    flex: 1 1 0;
    min-width: 300px;
    max-width: 316px;
    margin: 0 18px 18px 0;
    padding: 32px 25px;
  }

  .linda-vip-item__title {
    margin-bottom: 22px;
  }

  .linda-vip-item__title-wrap {
    margin-bottom: 4px;
  }

  .linda-vip-item_status-3 {
    margin-right: 0;
  }
}

@media screen and (width <= 1023px) {
  .linda-vip-item {
    min-width: 220px;
    max-width: 232px;
    margin: 0 16px 16px 0;
    padding: 30px 15px;
  }

  .linda-vip-item_status-3 {
    margin-right: 0;
  }
}

@media screen and (width <= 767px) {
  .linda-vip-item {
    min-width: 233px;
    max-width: 242px;
    padding: 20px 20px 25px;
  }

  .linda-vip-item_status-2 {
    margin-right: 0;
  }

  .linda-vip-item_status-3 {
    margin-right: 16px;
  }

  .linda-vip-item_status-4 {
    margin-right: 0;
  }

  .linda-vip-item__icon svg {
    max-width: 84px;
    max-height: 67px;
  }

  .linda-vip-item__title {
    margin-bottom: 0;
  }

  .linda-vip-item__title-wrap {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5px;
  }
}

@media only screen and (width <= 539px) {
  .linda-vip-item {
    width: 100%;
    max-width: 290px;
    margin-right: 0;
    padding-top: 20px;
    display: block;
  }

  .linda-vip-item__title {
    text-align: left;
    width: 130px;
    margin-bottom: 0;
  }

  .linda-vip-item__icon svg {
    max-width: 84px;
    max-height: 67px;
  }

  .linda-vip-item__key {
    font-size: 12px;
  }

  .linda-vip-item__value {
    font-size: 14px;
  }
}

.info-page {
  color: #3f3f3f;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.76em;
}

.info-page__container {
  max-width: 730px;
  margin: 0 auto;
}

.info-page__wrap {
  padding: 32px 0 40px;
}

.info-page ul:not(.accordion) {
  margin-bottom: 32px;
  padding-left: 35px;
  list-style-type: disc;
}

.info-page li:not(.accordion__item) {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 1.76;
}

.info-page li:not(.accordion__item):last-child {
  margin-bottom: 0;
}

.info-page h1 {
  color: #9746a5;
  margin: 0 0 20px;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
}

.info-page h2 {
  color: #9746a5;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}

.info-page a {
  color: #3f3f3f;
}

.info-page a:hover {
  color: #ab69ad;
}

.info-page p > span {
  margin-right: 5px;
  font-weight: 700;
}

.info-page table {
  width: 100%;
}

.info-page table td, .info-page table th {
  border: 1px solid #3f3f3f;
}

.info-page table tr > td:nth-child(3) {
  word-break: break-word;
}

.info-page table td {
  vertical-align: top;
  padding: 5px;
}

.info-page__table-wrap {
  overflow: auto;
}

.info-page ol {
  counter-reset: item;
  padding: 0;
  list-style-type: none;
}

.info-page ol > li {
  text-indent: -18px;
  counter-increment: item;
  margin: 0;
  padding: 0 0 0 18px;
}

.info-page ol > li:before {
  content: counter(item) ".";
  width: 20px;
  padding-right: 5px;
  font-weight: bold;
}

@media screen and (width <= 1239px) {
  .info-page__container {
    max-width: 100%;
    padding: 0 20px;
  }
}

@media screen and (width <= 767px) {
  .info-page__container {
    padding: 0 15px;
  }

  .info-page h1 {
    line-height: 1.52;
  }
}

.info-page .text-just {
  text-align: justify;
}

.accordion__item {
  margin-bottom: 10px;
}

.accordion__item.open .faq__q {
  background-color: #ffd18080;
  font-size: 18px;
  font-weight: 700;
}

.accordion__item.open .faq__q:before {
  margin-top: -15px;
  right: 18px;
  transform: rotate(-180deg)translateY(-50%);
}

.accordion__item.open .faq__a {
  background: none;
  padding: 5px 32px;
  display: block;
}

.faq__q {
  cursor: pointer;
  box-sizing: border-box;
  background-color: #ffd18033;
  border-radius: 25px;
  align-items: center;
  min-height: 50px;
  margin: 0;
  padding: 10px 40px 10px 30px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.41;
  display: flex;
  position: relative;
}

.faq__q:hover {
  background-color: #ffd18080;
}

.faq__q:before {
  content: "";
  background: url("/img/arr_b-dark.svg") 100% / 11px 7px no-repeat;
  width: 15px;
  height: 15px;
  transition: all .2s;
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: rotate(0)translateY(-50%);
}

.faq__a {
  font-size: 14px;
  line-height: 1.76;
  display: none;
}

.sitemap {
  padding: 35px 0;
}

.sitemap__wrap {
  box-sizing: border-box;
  padding: 0 120px;
}

.sitemap__section {
  box-sizing: border-box;
  margin-bottom: 8px;
}

.sitemap__section:last-child {
  margin-bottom: 0;
}

.sitemap__title, .sitemap__subtitle {
  color: #9746a5;
  margin-bottom: 22px;
  font-weight: bold;
}

.sitemap__title {
  margin-top: 0;
  font-size: 32px;
}

.sitemap__subtitle {
  font-size: 24px;
}

.sitemap__list {
  flex-wrap: wrap;
  display: flex;
}

.sitemap__item {
  align-items: center;
  width: 25%;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.76;
  display: flex;
}

.sitemap__item-ref {
  color: #3f3f3f;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 250px;
  transition: color .2s ease-in;
  overflow: hidden;
}

.sitemap__item-ref:hover {
  color: #ab69ad;
}

.sitemap__label {
  text-align: center;
  color: #fff;
  background-color: #35d290;
  border-radius: 15px;
  width: 30px;
  height: 15px;
  margin-left: 5px;
  font-size: 10px;
  line-height: 14px;
  display: inline-block;
}

@media screen and (width <= 1439px) {
  .sitemap__item-ref {
    max-width: 200px;
  }
}

@media screen and (width <= 1239px) {
  .sitemap {
    padding: 22px 0 14px;
  }

  .sitemap__wrap {
    padding: 0 20px;
  }

  .sitemap__item {
    width: 33.3333%;
    margin-bottom: 10px;
  }

  .sitemap__item-ref {
    max-width: 200px;
  }
}

@media screen and (width <= 767px) {
  .sitemap {
    padding: 22px 0 9px;
  }

  .sitemap__wrap {
    padding: 0 15px;
  }

  .sitemap__item {
    width: 100%;
  }
}

.paymentcb {
  min-height: 100vh;
  position: relative;
}

.paymentcb__content {
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  min-height: 100vh;
  display: flex;
}

.paymentcb__content.background-status-success {
  background-image: url("/joxi/yoyo/paymсb_done.jpg");
}

.paymentcb__content.background-status-fail {
  background-image: url("/joxi/yoyo/paymсb_error.jpg");
}

.paymentcb__content-text-wrap {
  background: url("/img/blotter.svg") top / 1119px 968px no-repeat;
  margin-top: auto;
  padding-top: 101px;
  padding-bottom: 227px;
}

.paymentcb__title {
  color: #3e3e3e;
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.52;
}

.paymentcb__subtitle {
  color: #3e3e3e;
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 1.52;
}

.paymentcb__text-or {
  color: #3e3e3e;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.52;
}

.paymentcb__btn {
  min-width: 179px;
  height: 40px;
}

.paymentcb .header-logo {
  display: block;
}

.paymentcb .header-logo svg {
  width: 188px;
  height: 76px;
}

.paymentcb__success .header-logo {
  margin-bottom: 50px;
}

.paymentcb__fail .header-logo {
  margin-bottom: 60px;
}

@media screen and (width <= 1239px) {
  .paymentcb__success .header-logo {
    margin-bottom: 40px;
  }

  .paymentcb__title {
    line-height: 1;
  }

  .paymentcb__subtitle, .paymentcb .paymentcb-timer {
    margin-bottom: 30px;
  }
}

@media screen and (width <= 1023px) {
  .paymentcb__content-text-wrap {
    background-size: cover;
    width: 100%;
    margin: auto;
  }
}

@media screen and (width <= 767px) {
  .paymentcb__content.background-status-success, .paymentcb__content.background-status-fail {
    background: none;
  }

  .paymentcb__content-text-wrap {
    background: none;
    padding: 30px;
  }

  .paymentcb__subtitle, .paymentcb__header-wrap .header-logo, .paymentcb .paymentcb-timer {
    margin-bottom: 30px;
  }
}

.paymentcb-timer {
  margin-bottom: 40px;
  position: relative;
}

.paymentcb-timer svg {
  width: 262px;
  height: 262px;
  transform: rotate(-90deg);
}

.paymentcb-timer__timer-grad-circle {
  animation-name: PaymentcbTimer;
  animation-duration: 20s;
  animation-timing-function: linear;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.paymentcb-timer__seconds {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}

.paymentcb-timer__time-wrap {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.paymentcb-timer__text {
  color: #3f3f3f80;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
}

.paymentcb-timer__seconds {
  background-color: #3f3f3f80;
  border-radius: 3px;
  height: 35px;
  padding: 0 12px;
  line-height: 35px;
  display: inline-block;
}

@keyframes PaymentcbTimer {
  0% {
    stroke-dashoffset: 0;
  }

  99% {
    opacity: 1;
  }

  100% {
    stroke-dashoffset: 728px;
    opacity: 0;
  }
}

.not-found {
  text-align: center;
  background: url("/joxi/yoyo/404_bg.jpg") center / cover no-repeat;
  position: relative;
}

.not-found__wrap {
  background: url("/img/blotter.svg") 50% -90px / 890px 842px no-repeat;
  padding: 165px 0 270px;
  position: relative;
}

.not-found__title {
  color: #ea4642;
  margin-bottom: 5px;
  font-size: 150px;
  font-weight: 700;
  line-height: 1;
}

.not-found__forbidden {
  color: #3e3e3e;
  margin-bottom: 15px;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.52;
}

.not-found__text {
  color: #3e3e3e;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.52;
}

@media screen and (width <= 1023px) {
  .not-found__wrap {
    background-position: 50% 5px;
    background-size: 696px 661px;
    padding: 175px 0 188px;
  }

  .not-found__text {
    margin-bottom: 20px;
  }
}

@media screen and (width <= 767px) {
  .not-found {
    background: none;
  }

  .not-found__wrap {
    background: none;
    padding: 104px 0 109px;
  }

  .not-found:before {
    background: none;
  }

  .not-found__forbidden {
    margin-bottom: 20px;
  }

  .not-found__text {
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 400;
  }
}

.article-box, .seo {
  text-align: justify;
  margin: auto auto 40px;
}

.article-box h1, .article-box h2, .article-box h3, .article-box h4, .article-box h5, .article-box h6, .seo h1, .seo h2, .seo h3, .seo h4, .seo h5, .seo h6 {
  color: #9746a5;
  margin: 0 0 12px;
  font-weight: bold;
}

.article-box h2, .article-box h3, .article-box h4, .article-box h5, .article-box h6, .seo h2, .seo h3, .seo h4, .seo h5, .seo h6 {
  margin-top: 32px;
}

.article-box p, .article-box li, .seo p, .seo li {
  color: #3f3f3f;
  font-size: 14px;
  line-height: 1.75;
}

.article-box h1, .seo h1 {
  font-size: 32px;
  line-height: 1.25;
}

.article-box h2, .seo h2 {
  font-size: 24px;
}

.article-box h3, .seo h3 {
  font-size: 18px;
}

.article-box h4, .seo h4 {
  font-size: 16px;
}

.article-box p, .seo p {
  margin: 0 0 12px;
}

.article-box p span, .seo p span {
  font-weight: 900;
}

.article-box ul, .seo ul {
  margin: 0 0 12px;
  padding: 0;
  list-style: none;
}

.article-box ul:last-child, .seo ul:last-child {
  margin: 0;
}

.article-box ul li, .seo ul li {
  padding-left: 8px;
  position: relative;
}

.article-box ul li:before, .seo ul li:before {
  content: "";
  background: #3f3f3f;
  border-radius: 50%;
  width: 3px;
  height: 3px;
  position: absolute;
  top: 10px;
  left: 1px;
}

.article-box ol, .seo ol {
  counter-reset: item;
  margin: 0;
  padding: 0;
}

.article-box li, .seo li {
  display: block;
}

.article-box li ol, .seo li ol {
  padding-left: 15px;
}

.article-box li:before, .seo li:before {
  content: counters(item, ".") ".";
  counter-increment: item;
  font-weight: bold;
}

.article-box a, .seo a {
  color: #3e3e3e;
  text-decoration: underline;
}

.article-box a:hover, .seo a:hover {
  color: #ab69ad;
}

.article-box.is-promo, .seo.is-promo {
  margin-top: 30px;
}

.article-box.is-promo .seo__title, .seo.is-promo .seo__title {
  text-align: center;
}

.article-box__content, .seo__content {
  margin: 0;
  display: block;
}

.article-box__content.has-hide, .seo__content.has-hide {
  height: 214px;
  position: relative;
  overflow: hidden;
}

.article-box__content.has-hide:before, .seo__content.has-hide:before {
  content: "";
  pointer-events: none;
  z-index: 10;
  background: linear-gradient(#fff0 0%, #f9fbe7 100%);
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.article-box__content.has-hide.is-active, .seo__content.has-hide.is-active {
  height: auto;
  overflow: auto;
}

.article-box__content.has-hide.is-active:before, .seo__content.has-hide.is-active:before {
  opacity: 0;
}

@media screen and (width <= 767px) {
  .article-box h1, .seo h1 {
    font-size: 24px;
  }

  .article-box h2, .seo h2 {
    font-size: 21px;
  }

  .article-box h3, .seo h3 {
    font-size: 18px;
  }

  .article-box.is-promo, .seo.is-promo {
    margin-top: 20px;
  }
}

.restoration {
  padding-top: 20px;
  padding-bottom: 57px;
}

.restoration__text {
  color: #3e3e3e;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
}

.restoration__btns {
  margin-top: 20px;
}

.restoration__btn {
  width: 100%;
}

.restoration__bottom {
  background: #ffc051;
  border-radius: 0 0 10px 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 57px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.restoration__link {
  color: #66554f;
  background-color: #0000;
  border: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-decoration: underline;
}

.restoration__link:hover {
  color: #ab69ad;
}

.lang-popup__list {
  margin-bottom: -20px;
}

.lang-popup__item {
  border-radius: 100%;
  margin: 0 19px 20px 0;
  display: inline-block;
  position: relative;
}

.lang-popup__item:nth-child(5n) {
  margin-right: 0;
}

.lang-popup__icon {
  border: 3px solid #0000;
  width: 40px;
  height: 40px;
}

.lang-popup__icon img {
  word-break: break-all;
  border: 3px solid #0000;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  font-size: 10px;
  transition: all .2s;
  display: block;
}

.lang-popup__lang {
  display: none;
}

.lang-popup__item.active img, .lang-popup__item:hover img {
  border: 3px solid #f9fbe7;
}

.new-pass__body-text {
  color: #3e3e3e;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.76;
}

.new-pass__btns {
  margin-top: 15px;
}

.new-pass__btns button {
  width: 100%;
  max-width: 100%;
}

.new-pass__formbox input, .change-pass__formbox input {
  padding-right: 40px;
}

.change-pass__btn {
  width: 100%;
  max-width: 100%;
  margin-top: 15px;
}

.notifications {
  z-index: 9999;
  flex-direction: column;
  align-items: center;
  width: 350px;
  font-family: Roboto, sans-serif;
  display: flex;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

.notifications__item {
  color: #fff;
  width: 100%;
  animation: .3s linear infinite showNotification;
  animation-iteration-count: initial;
  background: #ffd180;
  border-radius: 10px;
  margin-top: 10px;
  padding: 24px 30px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.79;
  transition: all .3s;
  position: relative;
  overflow: hidden;
}

.notifications__item.ng-move, .notifications__item.ng-enter {
  transition: all .5s;
}

.notifications__item.ng-leave.ng-leave-active, .notifications__item.ng-move, .notifications__item.ng-enter {
  margin-top: -70px;
}

.notifications__item.ng-leave, .notifications__item.ng-move.ng-move-active, .notifications__item.ng-enter.ng-enter-active {
  margin-top: 20px;
}

@media screen and (width <= 767px) {
  .notifications__item.ng-leave, .notifications__item.ng-move.ng-move-active, .notifications__item.ng-enter.ng-enter-active {
    margin-top: 10px;
  }
}

.notifications__item_error {
  background: #ea4642;
}

.notifications__item_warning {
  background: #ffd180;
}

.notifications__item_info {
  background: #ab69ad;
}

.notifications__item_success {
  background: #00be6e;
}

.notifications__message {
  padding-right: 10px;
}

.notifications__close {
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 7px;
  position: absolute;
  top: 5px;
  right: 5px;
}

.notifications__close svg {
  fill: #fff;
  width: 15px;
  height: 15px;
}

@media screen and (width <= 767px) {
  .notifications {
    width: calc(100% - 30px);
    max-width: 500px;
  }
}

@keyframes showNotification {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.autobonus-popup__bonus-list {
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -14px;
  display: flex;
}

.autobonus-popup__bonus-item {
  margin: 30px 14px 0;
}

.autobonus-popup__btn {
  text-align: center;
  margin-top: 10px;
}

@media only screen and (width <= 539px) {
  .autobonus-popup {
    padding: 30px 15px 35px;
  }

  .autobonus-popup__bonus-item {
    margin: 15px 0 0;
  }
}

.widget-bonus-item {
  background-color: #f9fbe7;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 290px;
  min-height: 295px;
  padding: 30px 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.widget-bonus-item_active .widget-bonus-item__label {
  background: #ea4642;
}

.widget-bonus-item_new .widget-bonus-item__label {
  background: #35d290;
}

.widget-bonus-item_new .widget-bonus-item__btn {
  display: block;
}

.widget-bonus-item__label {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px;
  height: 22px;
  padding: 0 22px;
  font-size: 12px;
  font-weight: bold;
  line-height: 21px;
  position: absolute;
  top: 14px;
  left: -24px;
  overflow: hidden;
  transform: rotate(-45deg);
}

.widget-bonus-item__text {
  color: #3f3f3f;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
}

.widget-bonus-item__sum {
  color: #aa69ac;
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: 900;
  line-height: normal;
}

.widget-bonus-item__btn {
  display: none;
}

@media only screen and (width <= 539px) {
  .widget-bonus-item {
    min-height: auto;
  }
}

.widget-freespin-item {
  background-color: #f9fbe7;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 290px;
  height: 295px;
  padding: 30px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.widget-freespin-item_active .widget-freespin-item__label {
  background: #ea4642;
}

.widget-freespin-item_new .widget-freespin-item__label {
  background: #35d290;
}

.widget-freespin-item_new .widget-bonus-item__btn {
  display: block;
}

.widget-freespin-item__label {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px;
  height: 22px;
  padding: 0 22px;
  font-size: 12px;
  font-weight: bold;
  line-height: 21px;
  position: absolute;
  top: 14px;
  left: -24px;
  overflow: hidden;
  transform: rotate(-45deg);
}

.widget-freespin-item__sum {
  color: #aa69ac;
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 900;
  line-height: normal;
}

.widget-freespin-item__text {
  color: #3f3f3f;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
}

.widget-freespin-item__game {
  background-position: 50%;
  background-size: cover;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.popup-get-bonus {
  background-image: url("/img/get-bonus-popup_top-bg.svg"), url("/img/get-bonus-popup_bottom-bg.svg");
  background-position: 100% 0, 0 100%;
  background-repeat: no-repeat, no-repeat;
}

.popup-get-bonus__list {
  color: #3e3e3e;
  margin: 0;
  padding: 0 0 0 17px;
  font-size: 14px;
  line-height: 1.76;
}

.popup-get-bonus__list-item {
  padding-left: 5px;
}

.popup-get-bonus__btn {
  width: 100%;
  min-width: 100%;
  margin: 15px 0;
}

.popup-get-bonus__link {
  text-align: center;
  display: block;
}

.is-main-page .user-page__main-background {
  background-image: url("/joxi/yoyo/conffeti.png"), url("/joxi/yoyo/conffeti.png");
  background-position: 130px 0, right 130px top;
  background-repeat: no-repeat;
  background-size: 41% 214%, 41% 214%;
  padding-top: 60px;
}

@media screen and (width >= 1920px) {
  .is-main-page .user-page__main-background {
    background-position: 211px 0, right 211px top;
    background-size: 33% 291%, 33% 291%;
  }
}

@media screen and (width >= 1440px) {
  .is-main-page .user-page__main-background {
    background-position: 158px 0, right 158px top;
  }
}

@media screen and (width <= 1239px) {
  .is-main-page .user-page__main-background {
    background-position: 20px 0, right 20px top;
    padding-top: 57px;
  }
}

.is-main-page .user-page__mask {
  z-index: -1;
  background-image: linear-gradient(#ffeaa1 0%, #ffc600 100%);
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 360px;
  position: absolute;
  mask-image: url("/img/mask.svg");
  mask-position: top;
  mask-size: 100% 158px;
  -webkit-mask-repeat: no-repeat;
}

.is-main-page .user-page__mask:after {
  content: "";
  background-image: url("/joxi/yoyo/blob.svg");
  background-position: 50% -62px;
  background-repeat: no-repeat;
  background-size: 100% 122%;
  width: 59%;
  height: 140%;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (width <= 1439px) {
  .is-main-page .user-page__mask:after {
    background-position: 50% -73px;
  }
}

@media screen and (width <= 1239px) {
  .is-main-page .user-page__mask:after {
    background-position: 50% -68px;
    width: 73%;
  }
}

.user-page__container {
  max-width: 1270px;
  position: relative;
}

.user-page__container-inner {
  max-width: 984px;
  margin: auto;
}

.is-main-page .user-page__container-inner {
  background: linear-gradient(149.69deg, #a745b0 9.65%, #700e66 92%);
  border-radius: 14px;
  width: 762px;
  overflow: hidden;
  box-shadow: 0 3px 8px #290b2863;
}

.is-main-page .user-page__container-inner:after {
  content: "";
  z-index: 0;
  background-image: url("/joxi/yoyo/candy_menu.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 1300px;
  height: 1300px;
  animation: 50s linear infinite rotateCandy;
  display: block;
  position: absolute;
  top: -543px;
  left: 64%;
}

.user-page__menu {
  background: url("/joxi/yoyo/profile-cashbox_top-bg.jpg") center;
  height: 110px;
  margin-bottom: 76px;
  padding-top: 46px;
}

.user-page__content {
  margin-bottom: 40px;
}

.user-page__title {
  color: #66554f;
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

@media screen and (width <= 1439px) {
  .user-page__content {
    margin-bottom: 45px;
  }
}

@media screen and (width <= 1239px) {
  .user-page__menu {
    padding-top: 65px;
  }
}

@media screen and (width <= 1023px) {
  .user-page__content {
    margin-bottom: 35px;
  }
}

.user-page-menu {
  background-color: #f9fbe7;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 560px;
  height: 110px;
  margin: 0 auto;
  display: flex;
  box-shadow: 0 6px 20px #00000014;
}

.user-page-menu__item {
  width: calc(25% - 20px);
  margin: 0 10px;
}

@media screen and (width <= 1239px) {
  .user-page-menu {
    width: 540px;
    height: 90px;
  }
}

@media screen and (width <= 767px) {
  .user-page-menu {
    border-radius: 0;
    width: 100%;
  }
}

.user-submenu {
  justify-content: center;
  margin-bottom: 30px;
  display: flex;
  overflow-x: auto;
}

@media screen and (width <= 1439px) {
  .user-submenu {
    margin-bottom: 25px;
  }
}

@media screen and (width <= 1023px) {
  .user-submenu {
    margin-bottom: 20px;
  }
}

.user-submenu_scroll::-webkit-scrollbar-track {
  background: linear-gradient(#00000014 0%, #0000 100%);
  border-radius: 5px;
}

.user-submenu_scroll::-webkit-scrollbar-thumb {
  background-color: #fbce7e;
  border-radius: 5px;
}

.user-submenu_scroll::-webkit-scrollbar-thumb:hover {
  background-color: #fbce7e;
}

.user-submenu_scroll::-webkit-resizer {
  background-image: none;
  background-repeat: no-repeat;
  width: 4px;
  height: 4px;
}

.user-submenu_scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.user-submenu__item {
  text-align: center;
  white-space: nowrap;
  border-radius: 20px;
  width: 290px;
  height: 40px;
  padding: 0 30px;
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
}

.user-submenu__item + .user-submenu__item {
  margin-left: 20px;
}

@media screen and (width <= 767px) {
  .user-submenu {
    justify-content: flex-start;
    padding-bottom: 5px;
  }

  .user-submenu__item + .user-submenu__item {
    margin-left: 10px;
  }
}

.is-entrance .entrance-cancel {
  display: none;
}

.contact-bar {
  margin-bottom: 25px;
  display: block;
}

.contact-bar__list {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.contact-bar__item {
  color: #413034cc;
  margin-right: 33px;
  font-size: 14px;
  display: flex;
}

.contact-bar__item:last-child {
  margin: 0;
}

.contact-bar__key {
  margin: auto 10px auto 0;
}

.contact-bar__value {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.79;
}

.contact-bar__value a {
  color: #413034cc;
}

@media screen and (width <= 767px) {
  .contact-bar {
    margin-bottom: 15px;
  }

  .contact-bar__list {
    justify-content: space-between;
  }

  .contact-bar__key.phone, .contact-bar__item.email {
    display: none;
  }

  .contact-bar__item.phone {
    margin: 0;
  }

  .contact-bar__item.phone .contact-bar__key {
    display: none;
  }
}

.profile-details {
  flex-direction: column;
  margin-top: 30px;
  display: flex;
}

.profile-details__user-block {
  border-radius: 10px;
}

.profile-details__user {
  flex-flow: column wrap;
  width: 100%;
  max-height: 402px;
  display: flex;
}

.profile-details__vip {
  text-align: center;
  border: 1px solid #ffa800;
  justify-content: center;
  align-items: center;
  width: 350px;
  min-height: 390px;
  margin-right: 20px;
  padding: 20px;
  display: flex;
  overflow: hidden;
}

.profile-details__balance {
  align-items: center;
  width: calc(100% - 370px);
  min-height: 80px;
  max-height: 80px;
  display: flex;
}

.profile-details__bonus {
  width: 100%;
  margin: 25px auto 0;
}

.profile-details__bonus-title {
  letter-spacing: normal;
  color: #413034cc;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
}

.profile-details__bonus-btn {
  max-width: 290px;
  margin: 20px auto 0;
  display: block;
}

.profile-details__no-bonus {
  background: url("/img/no-bonus_bg.svg") center / 100% 100% no-repeat;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  max-width: 753px;
  min-height: 160px;
  margin: 20px auto 0;
  padding: 20px 230px;
  display: none;
  position: relative;
}

.profile-details__no-bonus svg {
  fill: #e3d3d6;
  width: 123px;
  height: 113px;
  position: absolute;
  top: 23px;
  left: 98px;
}

.profile-details__no-bonus-text {
  color: #3f3f3f;
  text-align: center;
  width: 273px;
  font-size: 24px;
  font-weight: bold;
}

.profile-details__no-bonus-link {
  text-align: center;
  margin-top: 12px;
  display: block;
}

.profile-details .profile-details__bonus_0 .profile-details__no-bonus {
  display: flex;
}

.profile-details .profile-details__bonus_0 .profile-details__bonus-btn {
  display: none;
}

@media screen and (width <= 1023px) {
  .profile-details__user {
    max-height: unset;
    flex-direction: row;
    justify-content: center;
  }

  .profile-details__vip {
    flex-grow: 1;
    max-width: 392px;
    min-height: 188px;
    max-height: 188px;
    margin-right: 15px;
  }

  .profile-details__balance {
    flex-grow: 1;
    max-width: 320px;
    min-height: 188px;
    max-height: 188px;
  }

  .profile-details__bonus {
    flex-grow: 1;
    max-width: 728px;
  }

  .profile-details__bonus-title {
    margin-bottom: 7px;
  }

  .profile-details__no-bonus {
    margin-left: -15px;
  }
}

@media screen and (width <= 767px) {
  .profile-details__user-block {
    max-width: 100%;
    max-height: unset;
    width: 100%;
    margin-right: 0;
  }

  .profile-details__balance {
    min-height: auto;
    margin-top: 20px;
  }

  .profile-details__bonus {
    width: 100%;
    max-width: 100%;
  }

  .profile-details__bonus-title {
    margin-bottom: 0;
  }

  .profile-details__no-bonus {
    background: #f2ece1;
    width: calc(100% + 40px);
    margin-left: -20px;
    padding: 20px;
  }

  .profile-details__no-bonus svg {
    display: none;
  }
}

@media only screen and (width <= 539px) {
  .profile-details__no-bonus {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
}

.profile-vip__wrap {
  flex-flow: column wrap;
  display: flex;
}

.profile-vip__percent {
  color: #3f3f3f;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 1px;
  left: -30px;
}

.profile-vip__profile-icon {
  margin-bottom: 30px;
}

.profile-vip__profile-icon svg {
  height: 108px;
}

.profile-vip__level {
  color: #3f3f3f;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}

.profile-vip__progress-bar {
  background-color: #ffd18033;
  background-repeat: no-repeat;
  border-radius: 2px;
  width: 250px;
  height: 15px;
  margin: auto auto 25px;
  position: relative;
}

.profile-vip__progress-bar-status {
  background-color: #ffa800;
  border-radius: 2px;
  width: 18%;
  position: absolute;
  inset: 0;
}

.profile-vip__registration {
  margin-bottom: 13px;
}

.profile-vip__registration-title {
  color: #3f3f3f;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: normal;
}

.profile-vip__created {
  color: #3f3f3f;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
}

.profile-vip__profile-logout {
  color: #413034cc;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
}

.profile-vip__login {
  color: #413034cc;
  margin-bottom: 22px;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
}

.profile-info__form-title {
  color: #413034cc;
  text-align: center;
  margin-bottom: 7px;
  font-size: 16px;
  font-weight: bold;
}

.profile-info__form-wrap {
  background-color: #ffd1804d;
  border-radius: 10px;
  max-width: 1066px;
  margin: 0 auto;
  padding: 30px 25px 40px;
}

.profile-info__form {
  flex-wrap: wrap;
  margin: 0 -10px;
  display: flex;
}

.profile-info__form.ng-pristine .profile-info__change-btn-wrap:before, .profile-info__form.ng-invalid .profile-info__change-btn-wrap:before {
  display: block;
}

.profile-info__field-block {
  width: calc(33.3333% - 20px);
  margin: 0 10px;
  overflow: hidden;
}

.profile-info__field-block-nav {
  margin-top: 24px;
}

.profile-info__field-label {
  color: #3f3f3f;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 12px;
  padding-left: 27px;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
}

.profile-info__radio {
  justify-content: center;
  align-items: center;
  height: 40px;
  display: flex;
}

.profile-info__rules-wrap {
  height: 40px;
  margin-top: 18px;
  display: flex;
}

.profile-info__rules {
  color: #3e3e3e;
  width: 325px;
  margin: auto;
  padding-left: 27px;
  font-size: 12px;
}

.profile-info__change-btn-wrap {
  width: 100%;
  margin-top: 45px;
  position: relative;
}

.profile-info__change-btn-wrap:before {
  content: "";
  z-index: 10;
  background: #ffffff4d;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.profile-info__change-btn {
  width: 100%;
}

.profile-info__change-pass-btn {
  width: 290px;
  margin: 30px auto 20px;
  display: block;
}

@media screen and (width <= 1439px) {
  .profile-info__form-wrap {
    padding: 30px 20px;
  }
}

@media screen and (width <= 1023px) {
  .profile-info__form-title {
    margin-bottom: 2px;
  }

  .profile-info__form-wrap {
    padding: 30px 28px 40px;
  }

  .profile-info__field-block {
    width: calc(50% - 20px);
  }

  .profile-info__field-block-address {
    order: 1;
  }

  .profile-info__field-block-personalinfo {
    order: 2;
  }

  .profile-info__field-block-contacts {
    order: 3;
  }

  .profile-info__field-block-place {
    order: 4;
  }

  .profile-info__field-block-nav {
    order: 5;
  }

  .profile-info__change-pass-btn {
    margin: 20px auto 0;
  }
}

@media screen and (width <= 767px) {
  .profile-info__form-wrap {
    max-width: 375px;
    padding: 30px 20px;
  }

  .profile-info__field-block-nav {
    margin-top: 0;
  }

  .profile-info__field-block {
    width: 100%;
  }

  .profile-info__field-block-personalinfo {
    order: 1;
  }

  .profile-info__field-block-place {
    order: 2;
  }

  .profile-info__field-block-address {
    order: 3;
  }

  .profile-info__field-block-contacts {
    order: 4;
  }

  .profile-info__rules {
    align-items: flex-start;
    height: auto;
  }

  .profile-info__change-btn-wrap {
    margin-top: 15px;
  }
}

.profile-bonus-list {
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -10px;
  display: flex;
}

.profile-bonus-list__item {
  flex-grow: 1;
  min-width: 315px;
  max-width: 315px;
  margin: 20px 10px 0;
}

@media screen and (width <= 1439px) {
  .profile-bonus-list__item {
    min-width: auto;
  }
}

@media screen and (width <= 1023px) {
  .profile-bonus-list {
    margin: -7px;
  }

  .profile-bonus-list__item {
    flex-grow: 1;
    max-width: calc(33.3333% - 14px);
    margin: 15px 7px 0;
  }
}

@media screen and (width <= 767px) {
  .profile-bonus-list {
    margin: 0 3px;
  }

  .profile-bonus-list__item {
    flex-grow: 1;
    max-width: calc(50% - 6px);
    margin: 15px 3px 0;
  }
}

@media only screen and (width <= 539px) {
  .profile-bonus-list {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .profile-bonus-list__item {
    min-width: 100%;
    max-width: 100%;
  }

  .profile-bonus-list__item + .profile-bonus-list__item {
    margin-top: 16px;
  }
}

.unsubscribe__title {
  color: #413034cc;
  text-align: center;
  margin: 30px 0 20px;
  font-size: 16px;
  font-weight: bold;
}

.unsubscribe__form {
  border: 1px solid #ffd180;
  border-radius: 10px;
}

.unsubscribe__separate {
  border-bottom: 1px solid #ffd180;
  justify-content: space-between;
  padding: 30px 100px 20px;
  display: flex;
}

.unsubscribe__all {
  padding: 30px 100px;
}

.unsubscribe__block {
  width: 330px;
}

.unsubscribe__block-title {
  text-align: center;
  color: #413034cc;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
}

.unsubscribe__item {
  margin-bottom: 15px;
  display: flex;
}

.unsubscribe__item.unsubscribe__item_checked .unsubscribe__item-label {
  border: 1px solid #00be6e;
}

.unsubscribe__item.unsubscribe__item_checked .unsubscribe__item-label:before {
  background-color: #00be6e;
  margin-left: 0;
  transform: translateX(101%);
}

.unsubscribe__item.unsubscribe__item_checked .unsubscribe__item-info {
  opacity: 1;
}

.unsubscribe__item-switch {
  margin-right: 10px;
}

.unsubscribe__item-label {
  border: 1px solid #ea4642;
  border-radius: 10px;
  width: 40px;
  height: 20px;
  display: block;
}

.unsubscribe__item-label:before {
  content: "";
  background-color: #ea4642;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  margin-left: -1px;
  transition: all .2s;
  display: block;
}

.unsubscribe__item-label:hover {
  cursor: pointer;
}

.unsubscribe__item-input {
  opacity: 0;
  position: absolute;
}

.unsubscribe__item-info {
  opacity: .7;
  color: #3f3f3f;
  line-height: 1.76;
  transition: opacity .2s;
}

.unsubscribe__item-title {
  font-size: 16px;
  font-weight: bold;
}

.unsubscribe__item-desc {
  font-size: 12px;
}

.unsubscribe__btn {
  opacity: .7;
  width: 100%;
  max-width: 290px;
  margin: auto;
  display: block;
}

@media screen and (width <= 1439px) {
  .unsubscribe__title {
    margin: 25px 0 15px;
  }
}

@media screen and (width <= 1023px) {
  .unsubscribe__title {
    margin: 20px 0 15px;
  }

  .unsubscribe__block {
    width: 300px;
  }

  .unsubscribe__separate {
    padding: 30px 40px 20px;
  }

  .unsubscribe__all {
    padding: 30px 40px;
  }
}

@media screen and (width <= 767px) {
  .unsubscribe__block {
    width: 100%;
  }

  .unsubscribe__block:first-child {
    margin-bottom: 10px;
  }

  .unsubscribe__block-title {
    margin-bottom: 15px;
  }

  .unsubscribe__separate {
    flex-direction: column;
    align-items: center;
    padding: 30px 20px 20px;
  }

  .unsubscribe__all {
    padding: 30px 20px;
  }
}

.profile-bonus-item {
  border-radius: 10px;
  padding-bottom: 35px;
  position: relative;
}

.profile-bonus-item_active {
  background-color: #faf3d0;
}

.profile-bonus-item_active .profile-bonus-item__header-wrap:before {
  content: "Active";
  background-color: #ffa800;
}

.profile-bonus-item_active .profile-bonus-item__field:first-child {
  border-top: 2px solid #ffa800;
}

.profile-bonus-item_new {
  background-color: #e6f7de;
}

.profile-bonus-item_new .profile-bonus-item__header-wrap:before {
  content: "New";
  background-color: #35d290;
}

.profile-bonus-item_new .profile-bonus-item__field:first-child {
  border-top: 2px solid #00be6e;
}

.profile-bonus-item_new .profile-bonus-item__btn {
  display: block;
}

.profile-bonus-item__header-wrap {
  position: relative;
  overflow: hidden;
}

.profile-bonus-item__header-wrap:before {
  color: #fff;
  text-align: center;
  width: 100px;
  height: 19px;
  font-size: 12px;
  font-weight: 700;
  line-height: 19px;
  position: absolute;
  top: 15px;
  left: -25px;
  transform: rotate(-45deg);
}

.profile-bonus-item__header {
  letter-spacing: normal;
  color: #3f3f3f;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  min-height: 120px;
  padding: 30px 60px 15px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  display: flex;
}

.profile-bonus-item__title {
  font-size: 18px;
  line-height: 1.76;
}

.profile-bonus-item__game {
  letter-spacing: normal;
  color: #3f3f3f;
  margin-top: 10px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
}

.profile-bonus-item__game-link {
  text-decoration: underline;
}

.profile-bonus-item__amount {
  font-size: 24px;
}

.profile-bonus-item__content {
  padding: 0 25px;
}

.profile-bonus-item__field {
  letter-spacing: normal;
  color: #3f3f3f;
  justify-content: space-between;
  align-items: flex-end;
  min-height: 50px;
  padding: 10px 0;
  line-height: normal;
  display: flex;
  position: relative;
}

.profile-bonus-item__field:first-child:hover .profile-bonus-item__tooltip-wrap {
  display: block;
}

.profile-bonus-item__field + .profile-bonus-item__field {
  border-top: 1px solid #d5cfb3;
}

.profile-bonus-item__field-name {
  max-width: 40%;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
}

.profile-bonus-item__field-value {
  text-align: right;
  padding-left: 10px;
  font-size: 16px;
  font-weight: bold;
}

.profile-bonus-item__tooltip-wrap {
  display: none;
  position: absolute;
  bottom: -22px;
  left: -170px;
}

.profile-bonus-item__tooltip {
  letter-spacing: normal;
  color: #3e3e3e;
  word-break: break-word;
  background-color: #dcdbca;
  border-radius: 3px;
  width: 156px;
  min-height: 78px;
  padding: 6px 14px;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.76;
  position: relative;
}

.profile-bonus-item__tooltip:before {
  content: "";
  background-color: #dcdbca;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  top: calc(50% - 5px);
  right: -5px;
  transform: rotate(-45deg);
}

.profile-bonus-item__tooltip-value {
  font-weight: bold;
}

.profile-bonus-item__link {
  text-align: center;
  color: #3f3f3f;
  margin-top: 20px;
  font-weight: bold;
  text-decoration: underline;
  display: block;
}

.profile-bonus-item__btn {
  text-align: center;
  margin: 10px auto 0;
  font-size: 16px;
  display: none;
}

@media screen and (width <= 1439px) {
  .profile-bonus-item__tooltip-wrap {
    z-index: 1;
    left: auto;
    right: -170px;
  }

  .profile-bonus-item__tooltip:before {
    left: -5px;
  }
}

@media screen and (width <= 1023px) {
  .profile-bonus-item__header {
    padding: 30px 30px 15px;
  }

  .profile-bonus-item__title {
    font-size: 16px;
  }

  .profile-bonus-item__amount {
    font-size: 18px;
  }
}

@media screen and (width <= 767px) {
  .profile-bonus-item__tooltip-wrap {
    top: 55px;
    left: 0;
    right: 0;
  }

  .profile-bonus-item__tooltip {
    width: 100%;
  }

  .profile-bonus-item__tooltip:before {
    top: -5px;
    left: calc(50% - 5px);
  }

  .profile-bonus-item__tooltip-item {
    width: 65%;
  }
}

.profile-balance {
  box-sizing: border-box;
  border: 1px solid #00be6e;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 10px 30px 30px;
  display: flex;
  overflow: hidden;
}

.profile-balance__field {
  color: #3f3f3f;
  justify-content: center;
  min-width: 132px;
  margin-top: 20px;
  margin-right: 40px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
}

.profile-balance__field:last-child {
  flex-shrink: 2;
  margin-right: 0;
}

.profile-balance__key {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 80%;
  overflow: hidden;
}

.profile-balance__value {
  color: #00be6e;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 10px;
  overflow: hidden;
}

.profile-balance__update-btn {
  cursor: pointer;
  flex-shrink: 0;
  width: 13px;
  height: 14px;
  margin-left: 5px;
}

.profile-balance__update-btn svg {
  fill: #35d290;
  width: 100%;
  height: 100%;
}

.profile-balance__update-btn.linda-has-preloader {
  animation: .8s linear infinite spin;
}

.profile-balance.cahbox-withdraw__balance {
  margin-bottom: 20px;
}

@media screen and (width <= 1023px) {
  .profile-balance {
    flex-wrap: wrap;
  }

  .profile-details__balance .profile-balance {
    flex-direction: column;
    justify-content: center;
  }

  .profile-details__balance .profile-balance__field {
    justify-content: flex-start;
    margin-right: 0;
  }
}

@media screen and (width <= 767px) {
  .profile-balance.cahbox-withdraw__balance {
    margin: 20px 0;
  }

  .profile-details__balance .profile-balance {
    flex-direction: row;
    justify-content: flex-start;
  }

  .profile-details__balance .profile-balance__field, .profile-balance__field {
    margin-right: 30px;
  }

  .profile-details__balance .profile-balance__field:last-child, .profile-balance__field:last-child {
    margin-right: 0;
  }
}

.se-cachbox-balance {
  text-align: center;
}

.se-cachbox-balance__refresh-wrap {
  justify-content: center;
  align-items: center;
  display: flex;
}

.se-cachbox-balance__wrap {
  margin-top: 10px;
}

.se-cachbox-balance__refresh {
  cursor: pointer;
  flex-shrink: 0;
  width: 13px;
  height: 14px;
  margin-right: 5px;
}

.se-cachbox-balance__refresh svg {
  fill: #35d290;
  width: 100%;
  height: 100%;
}

.se-cachbox-balance__refresh.animate {
  animation: .8s linear infinite spin;
}

.se-cachbox-balance__refresh-text {
  color: #3f3f3f;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.79;
}

.se-cachbox-balance__key {
  color: #3f3f3f;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}

.se-cachbox-balance__value {
  color: #00be6e;
  font-size: 24px;
  font-weight: bold;
}

.se-cachbox-balance__wrap-balance {
  margin-top: 25px;
}

@media screen and (width <= 1023px) {
  .se-cachbox-balance__wrap-value {
    justify-content: center;
    display: flex;
  }

  .se-cachbox-balance__refresh-text {
    display: none;
  }

  .se-cachbox-balance__wrap-balance {
    margin-top: 15px;
  }

  .se-cachbox-balance__refresh-wrap {
    margin-left: 10px;
  }

  .se-cachbox-balance__vip {
    width: 100%;
  }
}

@media screen and (width <= 767px) {
  .se-cachbox-balance__wrap {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .se-cachbox-balance__key {
    margin: 0;
  }

  .se-cachbox-balance__value {
    margin-left: 18px;
  }
}

@media only screen and (width <= 539px) {
  .se-cachbox-balance__value {
    margin-left: 5px;
    font-size: 16px;
  }
}

.se-profile-balance {
  justify-content: center;
}

.cash-title {
  text-align: center;
  color: #3e3e3e;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.cash-wrap {
  grid-column-gap: 18px;
  grid-template-areas: "title title"
                       "left right";
  justify-content: center;
  align-items: center;
  width: 598px;
  margin: 0 auto;
  display: grid;
}

.cash-wrap.is-dep {
  grid-column-gap: initial;
  margin-top: 11px;
}

.cash-wrap.is-dep .cash-bonus, .cash-wrap.is-dep .self-title {
  display: none;
}

.cash-wrap.is-dep .cash-balans {
  justify-content: center;
  min-width: 595px;
  display: flex;
}

.cash-wrap.is-dep .cash-balans__image {
  margin-left: auto;
}

.cash-wrap.is-dep .cash-balans__total {
  margin: 0 auto;
}

.cash-wrap.is-dep .cash-balans__total p, .cash-wrap.is-dep .cash-balans__total-bonus p {
  padding-left: 8px;
}

.cash-bonus {
  grid-gap: 7px;
  background: #00be6e;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 290px;
  padding: 3px 4px;
  display: flex;
}

.cash-bonus__title {
  text-align: center;
  color: #fff;
  max-width: 162px;
  font-size: 13px;
  font-weight: 900;
  line-height: 15px;
}

.cash-bonus__title-sub {
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 900;
  line-height: 19px;
}

.cash-balans {
  background: #edeedb;
  border-radius: 10px;
  grid-template: "area_1 area_3" .8fr
                 "area_2 area_3" .2fr
                 / .8fr .2fr;
  grid-area: right;
  align-items: center;
  gap: 0;
  width: 100%;
  min-width: 290px;
  padding: 12px;
  display: grid;
}

.cash-balans__total {
  color: #3e3e3e;
  grid-area: area_1;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  display: flex;
}

.cash-balans__total-bonus {
  color: #3e3e3e;
  grid-area: area_2;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  display: flex;
}

.cash-balans__image {
  cursor: pointer;
  grid-area: area_3;
  margin-left: 16px;
}

.cash-balans__image.linda-has-preloader {
  transition: all .5s;
  transform: rotate(380deg);
}

.cash-balans p {
  margin: 0;
}

.self-title {
  text-align: center;
  color: #3e3e3e;
  grid-area: title;
  margin: 0;
  padding: 16px 0 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.w-cancel__title {
  letter-spacing: normal;
  color: #413034cc;
  text-align: center;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
}

.w-cancel-table__thead, .w-cancel-table__tr {
  padding-left: 50px;
}

.w-cancel-table__th, .w-cancel-table__tr-subrow {
  flex-shrink: 0;
  width: 20%;
}

.w-info {
  box-sizing: border-box;
  background: #fff;
  border: 2px solid #ffa800;
  border-radius: 10px;
  width: fit-content;
  margin: 16px auto 0;
  padding: 16px 24px;
}

.w-info__text {
  text-align: center;
  color: #3e3e3e;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.w-bonus {
  box-sizing: border-box;
  text-align: center;
  background: #fff;
  border: 2px solid #ffa800;
  border-radius: 10px;
  width: fit-content;
  margin: 16px auto 0;
  padding: 16px 24px;
}

.w-bonus__text {
  text-align: center;
  color: #3e3e3e;
  margin: 0 2px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.w-bonus__text.is-red {
  color: #ff5300;
}

.w-bonus__text:last-child {
  display: block;
}

.w-cont {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background: #00be6e;
  border-radius: 20px;
  width: fit-content;
  margin: 16px auto 0;
  padding: 10px 64px;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
}

.w-cancel-bonus {
  text-align: center;
  color: #aa69ac;
  cursor: pointer;
  background-color: #0000;
  border: none;
  margin: 20px auto 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-decoration-line: underline;
  display: block;
}

.w-atten {
  box-sizing: border-box;
  text-align: center;
  background: #fff;
  border: 2px solid #ffa800;
  border-radius: 10px;
  width: fit-content;
  margin: 16px auto 0;
  padding: 16px 24px;
}

.w-atten__text {
  text-align: center;
  color: #3e3e3e;
  margin: 0 2px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.w-dep {
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-width: 250px;
  margin: 16px auto 0;
  display: flex;
}

.birth-date {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

.birth-date__item {
  width: calc(33.3333% - 5px);
}

.birth-date__item select {
  background-color: #fff;
}

linda-form-date-selector.disabled .birth-date:before {
  content: "";
  z-index: 10;
  width: 100%;
  height: 40px;
  display: block;
  position: absolute;
}

linda-form-date-selector.disabled .birth-date__item select {
  background-color: #dcdbca;
  border-color: #0000;
}

.christmas-page {
  background-color: #fafdff;
  background-image: url("/joxi/yoyo/christmas/bg_img.jpg");
  background-position: 50% -359px;
  background-repeat: no-repeat;
  padding: 20px 30px 0;
}

.christmas-page__content-wrap {
  flex-flow: column wrap;
  align-items: flex-end;
  max-width: 1365px;
  height: 1594px;
  margin: 0 auto;
  display: flex;
}

.christmas-page__days {
  align-self: flex-start;
  width: 650px;
  height: 1500px;
  margin-left: auto;
  display: block;
  position: relative;
}

.christmas-page__promo {
  color: #fff;
  border-radius: 10px;
  align-items: center;
  width: 400px;
  min-height: 80px;
  margin-top: 10px;
  padding: 13px 30px;
  font-size: 16px;
  line-height: 1.41;
  display: flex;
  overflow: hidden;
}

.christmas-page__promo-bonus {
  background-color: #2d9cdb;
}

.christmas-page__promo-img {
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  margin-right: 30px;
}

.christmas-page__promo-img svg {
  fill: #fff;
  width: 100%;
  height: 100%;
}

.christmas-page__promo-tournament {
  background-color: #aa69ac;
}

@media screen and (width <= 1239px) {
  .christmas-page {
    background-position-x: calc(50% + 40px);
    padding: 20px;
  }

  .christmas-page__bonus {
    margin-left: 20px;
  }

  .christmas-page__promo {
    width: 300px;
  }
}

@media screen and (width <= 1023px) {
  .christmas-page {
    background-position: calc(50% + 197px) -80px;
  }

  .christmas-page__content-wrap {
    align-items: center;
    height: 1961px;
  }

  .christmas-page__bonus {
    order: 0;
    max-width: 100%;
    margin-left: 0;
  }

  .christmas-page__days {
    order: 1;
    height: 700px;
    margin: auto auto 0;
  }

  .christmas-page__promo-bonus {
    order: 2;
    height: 70px;
  }

  .christmas-page__promo-tournament {
    order: 3;
    height: 70px;
  }
}

@media screen and (width <= 767px) {
  .christmas-page {
    background: #fafdff url("/joxi/yoyo/christmas/christmas_bg_mob.jpg") 50% -65px no-repeat;
    padding: 20px 10px;
  }

  .christmas-page__bonus {
    order: 0;
  }

  .christmas-page__content-wrap {
    height: 1180px;
  }

  .christmas-page__days {
    order: 1;
    width: 300px;
    height: 500px;
    margin-top: auto;
    margin-bottom: 0;
  }
}

.christmas-day {
  text-align: center;
  color: #9846a5;
  background: url("/img/christmas2019/christmas-day_open.svg") center / cover no-repeat;
  width: 80px;
  height: 75px;
  padding-top: 14px;
  font-weight: 900;
  position: absolute;
}

.christmas-day_desctop:hover .christmas-day__tooltip {
  display: block;
}

.christmas-day_status-2, .christmas-day_status-3 {
  color: #828282;
  background: url("/img/christmas2019/christmas-day_closed.svg") center no-repeat;
}

.christmas-day_11, .christmas-day_12, .christmas-day_17, .christmas-day_24, .christmas-day_31 {
  color: #d78e00;
  background: url("/img/christmas2019/christmas-day_special_open.svg") center / cover no-repeat;
}

.christmas-day_11.christmas-day_status-3, .christmas-day_12.christmas-day_status-3, .christmas-day_17.christmas-day_status-3, .christmas-day_24.christmas-day_status-3, .christmas-day_31.christmas-day_status-3 {
  color: #d78e00;
  background: url("/img/christmas2019/christmas-day_special_closed.svg") center / cover no-repeat;
}

.christmas-day_1 {
  bottom: 23px;
  left: 240px;
}

.christmas-day_2 {
  bottom: 23px;
  left: 350px;
}

.christmas-day_3 {
  bottom: 22px;
  left: 460px;
}

.christmas-day_4 {
  bottom: 72px;
  left: 555px;
}

.christmas-day_5 {
  bottom: 161px;
  left: 558px;
}

.christmas-day_6 {
  bottom: 210px;
  left: 469px;
}

.christmas-day_7 {
  bottom: 210px;
  left: 357px;
}

.christmas-day_8 {
  bottom: 210px;
  left: 237px;
}

.christmas-day_9 {
  bottom: 210px;
  left: 121px;
}

.christmas-day_10 {
  bottom: 248px;
  left: 20px;
}

.christmas-day_11 {
  bottom: 344px;
  left: 10px;
}

.christmas-day_12 {
  bottom: 393px;
  left: 105px;
}

.christmas-day_13 {
  bottom: 394px;
  left: 226px;
}

.christmas-day_14 {
  bottom: 394px;
  left: 339px;
}

.christmas-day_15 {
  bottom: 394px;
  left: 458px;
}

.christmas-day_16 {
  bottom: 438px;
  left: 552px;
}

.christmas-day_17 {
  bottom: 524px;
  left: 561px;
}

.christmas-day_18 {
  bottom: 579px;
  left: 467px;
}

.christmas-day_19 {
  bottom: 579px;
  left: 353px;
}

.christmas-day_20 {
  bottom: 579px;
  left: 238px;
}

.christmas-day_21 {
  bottom: 579px;
  left: 121px;
}

.christmas-day_22 {
  bottom: 619px;
  left: 21px;
}

.christmas-day_23 {
  bottom: 713px;
  left: 6px;
}

.christmas-day_24 {
  bottom: 765px;
  left: 95px;
}

.christmas-day_25 {
  bottom: 765px;
  left: 217px;
}

.christmas-day_26 {
  bottom: 765px;
  left: 335px;
}

.christmas-day_27 {
  bottom: 765px;
  left: 455px;
}

.christmas-day_28 {
  bottom: 812px;
  left: 550px;
}

.christmas-day_29 {
  bottom: 899px;
  left: 561px;
}

.christmas-day_30 {
  bottom: 951px;
  left: 461px;
}

.christmas-day_31 {
  bottom: 952px;
  left: 342px;
}

.christmas-day_32 {
  bottom: 991px;
  left: 239px;
}

.christmas-day_status-0 .christmas-day__tooltip-content_main, .christmas-day_status-1 .christmas-day__tooltip-content_main, .christmas-day_status-2 .christmas-day__tooltip-content_main {
  display: block;
}

.christmas-day_status-0 .christmas-day__tooltip-content_future, .christmas-day_status-1 .christmas-day__tooltip-content_future, .christmas-day_status-2 .christmas-day__tooltip-content_future, .christmas-day_status-3 .christmas-day__tooltip-content_main {
  display: none;
}

.christmas-day_status-3 .christmas-day__tooltip-content_future {
  display: flex;
}

.christmas-day__date {
  font-size: 20px;
}

.christmas-day__month {
  margin-top: -3px;
  font-size: 12px;
}

.christmas-day__tooltip {
  z-index: 10;
  color: #fff;
  font-size: 14px;
  display: none;
  position: absolute;
  top: 0;
  transform: translate(-29%, calc(-100% - 12px));
}

.christmas-day__tooltip-content {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  min-height: 80px;
  position: relative;
}

.christmas-day__tooltip-content:after {
  content: "";
  width: 18px;
  height: 18px;
  display: block;
  position: absolute;
  bottom: -9px;
  left: 50%;
  transform: rotate(45deg)translateX(-33%)translateY(6px);
}

.christmas-day__tooltip-content_main {
  background-color: #ab69ad;
  width: 182px;
  padding: 19px 20px;
}

.christmas-day__tooltip-content_main:after {
  background-color: #ab69ad;
}

.christmas-day__tooltip-content_main .christmas-day__tooltip-text {
  font-weight: bold;
  overflow: hidden;
}

.christmas-day__tooltip-content_future {
  text-align: left;
  background-color: #828282;
  width: 190px;
  padding: 19px 20px;
}

.christmas-day__tooltip-content_future:after {
  background-color: #828282;
}

.christmas-day__tooltip-content_future .christmas-day__tooltip-text {
  font-weight: normal;
  overflow: hidden;
}

.christmas-day__tooltip-close {
  text-align: center;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0;
  right: 0;
}

.christmas-day__tooltip-close svg {
  fill: #fff;
  width: 8px;
  height: 8px;
  padding: 8px;
}

.christmas-day__tooltip-bonus-name {
  margin-top: 10px;
  font-weight: normal;
}

.christmas-day__tooltip-lock {
  width: 40px;
  height: 40px;
  margin-right: 18px;
}

.christmas-day__tooltip-lock svg {
  fill: #fff;
  width: 40px;
  height: 40px;
}

.christmas-day__tooltip-terms {
  margin-top: 6px;
}

.christmas-day__tooltip-desc {
  max-width: 97px;
}

@media screen and (width <= 1239px) {
  .christmas-day_4 .christmas-day__tooltip, .christmas-day_5 .christmas-day__tooltip, .christmas-day_16 .christmas-day__tooltip, .christmas-day_17 .christmas-day__tooltip, .christmas-day_28 .christmas-day__tooltip, .christmas-day_29 .christmas-day__tooltip {
    transform: translate(-58%, calc(-100% - 12px));
  }

  .christmas-day_4 .christmas-day__tooltip-content:after, .christmas-day_5 .christmas-day__tooltip-content:after, .christmas-day_16 .christmas-day__tooltip-content:after, .christmas-day_17 .christmas-day__tooltip-content:after, .christmas-day_28 .christmas-day__tooltip-content:after, .christmas-day_29 .christmas-day__tooltip-content:after {
    left: auto;
    right: 20px;
  }

  .christmas-day_10 .christmas-day__tooltip, .christmas-day_11 .christmas-day__tooltip, .christmas-day_22 .christmas-day__tooltip, .christmas-day_23 .christmas-day__tooltip {
    transform: translate(0%, calc(-100% - 12px));
  }

  .christmas-day_10 .christmas-day__tooltip-content:after, .christmas-day_11 .christmas-day__tooltip-content:after, .christmas-day_22 .christmas-day__tooltip-content:after, .christmas-day_23 .christmas-day__tooltip-content:after {
    left: 40px;
  }
}

@media screen and (width <= 767px) {
  .christmas-day {
    background-size: cover;
    width: 40px;
    height: 38px;
    padding: 7px;
  }

  .christmas-day_1 {
    bottom: 23px;
    left: 103px;
  }

  .christmas-day_2 {
    bottom: 23px;
    left: 154px;
  }

  .christmas-day_3 {
    bottom: 23px;
    left: 203px;
  }

  .christmas-day_4 {
    bottom: 46px;
    left: 247px;
  }

  .christmas-day_5 {
    bottom: 85px;
    left: 247px;
  }

  .christmas-day_6 {
    bottom: 106px;
    left: 206px;
  }

  .christmas-day_7 {
    bottom: 106px;
    left: 153px;
  }

  .christmas-day_8 {
    bottom: 106px;
    left: 100px;
  }

  .christmas-day_9 {
    bottom: 106px;
    left: 48px;
  }

  .christmas-day_10 {
    bottom: 124px;
    left: 4px;
  }

  .christmas-day_11 {
    bottom: 166px;
    left: 0;
  }

  .christmas-day_12 {
    bottom: 190px;
    left: 43px;
  }

  .christmas-day_13 {
    bottom: 190px;
    left: 96px;
  }

  .christmas-day_14 {
    bottom: 190px;
    left: 149px;
  }

  .christmas-day_15 {
    bottom: 190px;
    left: 202px;
  }

  .christmas-day_16 {
    bottom: 209px;
    left: 245px;
  }

  .christmas-day_17 {
    bottom: 249px;
    left: 248px;
  }

  .christmas-day_18 {
    bottom: 274px;
    left: 205px;
  }

  .christmas-day_19 {
    bottom: 274px;
    left: 153px;
  }

  .christmas-day_20 {
    bottom: 274px;
    left: 100px;
  }

  .christmas-day_21 {
    bottom: 274px;
    left: 48px;
  }

  .christmas-day_22 {
    bottom: 294px;
    left: 4px;
  }

  .christmas-day_23 {
    bottom: 334px;
    left: 0;
  }

  .christmas-day_24 {
    bottom: 359px;
    left: 40px;
  }

  .christmas-day_25 {
    bottom: 359px;
    left: 95px;
  }

  .christmas-day_26 {
    bottom: 358px;
    left: 149px;
  }

  .christmas-day_27 {
    bottom: 360px;
    left: 203px;
  }

  .christmas-day_28 {
    bottom: 380px;
    left: 244px;
  }

  .christmas-day_29 {
    bottom: 420px;
    left: 247px;
  }

  .christmas-day_30 {
    bottom: 441px;
    left: 202px;
  }

  .christmas-day_31 {
    bottom: 442px;
    left: 148px;
  }

  .christmas-day_32 {
    bottom: 459px;
    left: 103px;
  }

  .christmas-day__wrapper.active-touch-christmas-day + .christmas-day__tooltip {
    display: block;
  }

  .christmas-day__tooltip {
    transform: translate(-44%, calc(-100% - 20px));
  }

  .christmas-day__tooltip-content_main {
    min-height: auto;
  }

  .christmas-day__date {
    font-size: 11px;
  }

  .christmas-day__month {
    margin-top: -2px;
    font-size: 7px;
  }

  .christmas-day_3 .christmas-day__tooltip, .christmas-day_4 .christmas-day__tooltip, .christmas-day_5 .christmas-day__tooltip, .christmas-day_6 .christmas-day__tooltip, .christmas-day_15 .christmas-day__tooltip, .christmas-day_16 .christmas-day__tooltip, .christmas-day_17 .christmas-day__tooltip, .christmas-day_17 .christmas-day__tooltip, .christmas-day_27 .christmas-day__tooltip, .christmas-day_28 .christmas-day__tooltip, .christmas-day_29 .christmas-day__tooltip, .christmas-day_30 .christmas-day__tooltip {
    transform: translate(-75%, calc(-100% - 20px));
  }

  .christmas-day_3 .christmas-day__tooltip-content:after, .christmas-day_4 .christmas-day__tooltip-content:after, .christmas-day_5 .christmas-day__tooltip-content:after, .christmas-day_6 .christmas-day__tooltip-content:after, .christmas-day_15 .christmas-day__tooltip-content:after, .christmas-day_16 .christmas-day__tooltip-content:after, .christmas-day_17 .christmas-day__tooltip-content:after, .christmas-day_17 .christmas-day__tooltip-content:after, .christmas-day_27 .christmas-day__tooltip-content:after, .christmas-day_28 .christmas-day__tooltip-content:after, .christmas-day_29 .christmas-day__tooltip-content:after, .christmas-day_30 .christmas-day__tooltip-content:after {
    left: auto;
    right: 14px;
  }

  .christmas-day_9 .christmas-day__tooltip, .christmas-day_10 .christmas-day__tooltip, .christmas-day_11 .christmas-day__tooltip, .christmas-day_21 .christmas-day__tooltip, .christmas-day_22 .christmas-day__tooltip, .christmas-day_23 .christmas-day__tooltip, .christmas-day_24 .christmas-day__tooltip {
    transform: translate(-4%, calc(-100% - 20px));
  }

  .christmas-day_9 .christmas-day__tooltip-content:after, .christmas-day_10 .christmas-day__tooltip-content:after, .christmas-day_11 .christmas-day__tooltip-content:after, .christmas-day_21 .christmas-day__tooltip-content:after, .christmas-day_22 .christmas-day__tooltip-content:after, .christmas-day_23 .christmas-day__tooltip-content:after, .christmas-day_24 .christmas-day__tooltip-content:after {
    left: 22px;
  }
}

.christmas-today-bonus {
  box-sizing: border-box;
  text-align: center;
  color: #3f3f3f;
  background: #ffd180 url("/joxi/yoyo/christmas/christmas_bg_snowflakes.png") top no-repeat;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  padding: 30px 25px;
  font-size: 16px;
}

.christmas-today-bonus__date-text {
  margin-bottom: 5px;
  font-weight: bold;
  display: none;
}

.christmas-today-bonus__name {
  margin: 26px 0 23px;
  font-size: 30px;
  font-weight: bold;
}

.christmas-today-bonus__pic {
  color: #9846a5;
  background: url("/img/christmas2019/christmas-day_open.svg") center / cover no-repeat;
  width: 140px;
  height: 140px;
  margin: 13px auto 17px;
  padding-top: 23px;
  font-weight: 700;
}

.christmas-today-bonus__pic_25-12, .christmas-today-bonus__pic_26-12, .christmas-today-bonus__pic_31-12, .christmas-today-bonus__pic_7-1, .christmas-today-bonus__pic_14-1 {
  color: #d78e00;
  background: url("/img/christmas2019/christmas-day_special_open.svg") center / cover no-repeat;
}

.christmas-today-bonus__date {
  font-size: 40px;
  font-weight: 900;
}

.christmas-today-bonus__month {
  margin-top: -4px;
  font-size: 22px;
  font-weight: 900;
}

.christmas-today-bonus__desc {
  margin-bottom: 14px;
  line-height: 1.41;
}

.christmas-today-bonus__timer {
  margin: 12px 0 30px;
}

.christmas-today-bonus__timer-label {
  font-weight: bold;
}

.christmas-today-bonus__btn-label {
  font-size: 24px;
  font-weight: bold;
}

.christmas-today-bonus__btn {
  border-radius: 80px;
  width: 100%;
  max-width: 300px;
  height: 60px;
  margin: 22px auto 21px;
  padding: 0 10px;
  font-size: 24px;
  line-height: 60px;
}

.christmas-today-bonus__rules {
  font-weight: bold;
  text-decoration: underline;
}

@media screen and (width <= 1239px) {
  .christmas-today-bonus {
    max-width: 300px;
  }
}

@media screen and (width <= 1023px) {
  .christmas-today-bonus {
    max-width: 400px;
    padding: 30px;
  }

  .christmas-today-bonus__pic, .christmas-today-bonus__desc, .christmas-today-bonus__btn-label, .christmas-today-bonus__rules {
    display: none;
  }

  .christmas-today-bonus__date-text {
    display: block;
  }

  .christmas-today-bonus__name {
    margin: 18px 0 11px;
    font-size: 24px;
  }

  .christmas-today-bonus__timer {
    margin: 3px 0 14px;
  }

  .christmas-today-bonus__timer-label {
    font-weight: normal;
  }

  .christmas-today-bonus__btn {
    margin: 0 auto;
  }
}

@media screen and (width <= 767px) {
  .christmas-today-bonus {
    max-width: 100%;
  }
}

.christmas-timer {
  color: #fff;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  display: flex;
}

.christmas-timer__num {
  background: #3f3f3f80;
  border-radius: 3px;
  width: 50px;
  height: 35px;
  line-height: 35px;
}

.christmas-timer__dots {
  color: #3f3f3f80;
  margin: 0 4px;
}

@media screen and (width <= 1023px) {
  .christmas-timer {
    color: #ed612b;
    font-size: 16px;
  }

  .christmas-timer__num {
    background: none;
    width: auto;
    height: auto;
    line-height: normal;
  }

  .christmas-timer__dots {
    color: #ed612b;
    margin: 0;
  }
}

.christmas-popup__content {
  color: #3f3f3f;
  max-width: 700px;
  padding: 0;
  position: relative;
}

.christmas-popup__title {
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: normal;
}

.christmas-popup__wrap {
  text-align: center;
  background: #ffd180 url("/joxi/yoyo/christmas/christmas_bg_snowflakes-garland.png") top no-repeat;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  width: 700px;
  padding: 30px 150px;
  font-size: 16px;
  line-height: 1.41;
  display: flex;
  overflow: hidden;
}

.christmas-popup__bonus-name {
  color: #3f3f3f;
  font-size: 30px;
  font-weight: bold;
  line-height: normal;
}

.christmas-popup__bonus-terms {
  margin: 20px 0 25px;
}

.christmas-popup__instructions {
  counter-reset: item;
  margin: 20px auto;
  padding: 0;
  overflow: hidden;
}

.christmas-popup__instruction {
  text-indent: -21px;
  counter-increment: item;
  margin: 0;
  padding: 0 0 0 21px;
  list-style-type: none;
}

.christmas-popup__instruction:before {
  text-align: right;
  content: counter(item) ".";
  width: 14px;
  padding-right: 7px;
  display: inline-block;
}

.christmas-popup__btn {
  border-radius: 80px;
  width: 100%;
  max-width: 300px;
  height: 60px;
  margin: 0 auto;
  padding: 0 10px;
  font-size: 24px;
  line-height: 60px;
}

.christmas-popup__btn-deposit {
  display: none;
}

.linda-has-user .christmas-popup .christmas-popup__btn-deposit {
  display: block;
}

.linda-has-user .christmas-popup .christmas-popup__btn-login {
  display: none;
}

.christmas-popup__chat-link {
  text-decoration: underline;
}

@media screen and (width <= 1023px) {
  .christmas-popup__close {
    top: -40px;
    right: 0;
  }
}

@media screen and (width <= 767px) {
  .christmas-popup {
    margin-top: 100px;
  }

  .christmas-popup__content {
    border-radius: 10px;
    max-width: 300px;
  }

  .christmas-popup__wrap {
    background: #ffd180 url("/joxi/yoyo/christmas/christmas_bg_snowflakes.png") top no-repeat;
    width: 300px;
    padding: 30px;
    font-size: 14px;
  }

  .christmas-popup__title {
    order: 0;
  }

  .christmas-popup__bonus-name {
    order: 1;
    font-size: 24px;
  }

  .christmas-popup__btn {
    order: 2;
    margin-top: 18px;
    font-size: 22px;
  }

  .christmas-popup__bonus-terms {
    order: 3;
  }

  .christmas-popup__instructions {
    order: 5;
    max-width: 100%;
    margin: 20px auto 0;
  }

  .christmas-popup__bonus-info {
    order: 4;
  }

  .christmas-terms-popup {
    background-color: #3e3e3ecc;
  }
}

linda-registration-bank-page {
  min-height: 100%;
  display: flex;
}

.reg-bank {
  background-image: url("/joxi/yoyo/entercash/ec-reg-bg.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 750px;
  display: flex;
}

@media (width >= 1921px) {
  .reg-bank {
    background-size: cover;
  }
}

.reg-bank__iframe {
  z-index: 2;
  width: 100%;
  min-height: 480px;
  box-shadow: 0 6px 15px #0000004d;
}

.reg-bank__container {
  flex-direction: column;
  width: 100%;
  max-width: 910px;
  margin: 0 auto;
  padding-bottom: 5px;
  display: flex;
  position: relative;
}

.reg-bank__top-promo {
  color: #f9fbe7;
  text-align: center;
  width: 485px;
  min-height: 83px;
  margin: 0 auto;
  padding-top: 15px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
  position: relative;
  overflow: hidden;
}

.reg-bank__top-promo:before {
  content: "";
  background-color: #35d290;
  background-position: bottom;
  border-radius: 100%;
  width: 485px;
  height: 485px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.reg-bank__top-promo span {
  color: #f9fbe7;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  display: block;
  position: relative;
}

.reg-bank__title {
  color: #ffd180;
  text-shadow: 0 4px #00000040;
  text-align: center;
  margin-bottom: 15px;
  font-size: 36px;
  font-weight: 900;
  line-height: 1.2;
}

.reg-bank__amount-btns {
  margin: 0 -7px 20px;
}

.reg-bank__iframe-header {
  margin-top: auto;
}

.reg-bank__content-form {
  width: 404px;
  margin: 0 auto auto;
}

.reg-bank__content-iframe {
  margin-bottom: 20px;
  display: flex;
  position: relative;
}

.reg-bank__form {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.reg-bank__form-btn {
  margin-bottom: 20px;
  padding: 0 30px;
}

.reg-bank__btn-label {
  text-align: center;
  align-items: flex-end;
  margin-left: -20px;
  display: flex;
}

.reg-bank__btn-label svg {
  fill: #f9fbe7;
  width: 28px;
  height: 29px;
  margin-bottom: 4px;
  margin-right: 3px;
}

.reg-bank__btn-label span {
  color: #f9fbe7;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.79;
}

.reg-bank__input-wrap {
  min-height: 82px;
}

.reg-bank__content-wrap {
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  margin: auto;
  display: flex;
}

@media screen and (width <= 1023px) {
  .reg-bank {
    padding-bottom: 10px;
  }

  .reg-bank__container {
    padding: 0 44px;
  }
}

@media screen and (width <= 767px) {
  .reg-bank {
    min-height: 670px;
  }

  .reg-bank .reg-bank__iframe {
    min-height: 450px;
  }

  .reg-bank__container {
    padding: 0;
  }
}

@media only screen and (width <= 539px) {
  .reg-bank {
    min-height: 720px;
  }

  .reg-bank__top-promo {
    width: 100%;
    overflow: hidden;
  }

  .reg-bank__top-promo:before {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }

  .reg-bank__form-btn {
    width: 200px;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
  }

  .reg-bank__content-form {
    width: 100%;
  }

  .reg-bank .reg-bank__iframe {
    min-height: 492px;
  }

  .reg-bank__btn-label {
    margin-top: 10px;
    margin-bottom: 45px;
  }
}

.ec-btns-choice-amount {
  justify-content: space-between;
  display: flex;
}

.ec-btns-choice-amount__btn {
  color: #66554f;
  cursor: pointer;
  background-color: #f9fbe7;
  border: none;
  border-radius: 10px;
  min-width: 124px;
  height: 45px;
  margin-right: 15px;
  padding: 0 7px;
  font-size: 18px;
  font-weight: 600;
  line-height: 45px;
  transition: background-color .2s;
}

.ec-btns-choice-amount__btn:last-child {
  margin: 0;
}

.ec-btns-choice-amount__btn:hover {
  background-color: #ffd180;
}

.ec-btns-choice-amount__btn.active {
  color: #fff;
  cursor: default;
  background-color: #ffa800;
}

@media screen and (width <= 767px) {
  .ec-btns-choice-amount {
    margin: 0 -7px 15px;
  }
}

@media only screen and (width <= 539px) {
  .ec-btns-choice-amount__btn {
    min-width: 94px;
    height: 37px;
    margin-right: 5px;
    font-size: 16px;
    line-height: 37px;
  }
}

.ec-enter-amount-field {
  width: 304px;
  height: 60px;
  margin: 0 auto;
  display: block;
  position: relative;
}

.ec-enter-amount-field__cur {
  text-shadow: 0 4px #00000040;
  color: #fff;
  z-index: 3;
  font-size: 18px;
  font-weight: 900;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
}

.ec-enter-amount-error {
  text-align: center;
  width: 304px;
  margin: 0 auto;
  padding: 3px 20px 0;
}

.ec-enter-amount-error__text {
  color: #f9fbe7;
  width: 100%;
  max-width: 100%;
  margin-bottom: 4px;
  font-size: 12px;
  line-height: normal;
}

.g-input-entercash {
  text-shadow: 0 4px #00000040;
  color: #fff;
  text-align: center;
  background-color: #5a4a41;
  border: none;
  border-radius: 30px;
  height: 60px;
  padding: 0 50px;
  font-size: 36px;
  font-weight: 900;
  line-height: 60px;
}

.g-input-entercash:hover {
  background-color: #5a4a41b3;
}

.g-input-entercash:focus {
  background-color: #f9fbe7;
}

.g-input-entercash:focus + span {
  text-shadow: 0 2px #0000001a;
  color: #66554f;
}

.g-input-entercash.ng-invalid.ng-touched {
  color: #3f3f3f;
  text-shadow: 0 2px #0000001a;
  background-color: #f9fbe7;
  border: 2px solid #ea4642;
}

.g-input-entercash.ng-invalid.ng-touched + span {
  text-shadow: 0 2px #0000001a;
  color: #66554f;
}

.g-input-entercash.ng-valid {
  border: none;
}

.g-input-entercash.ng-valid.ng-touched {
  color: #3f3f3f;
  text-shadow: 0 2px #0000001a;
  background-color: #f9fbe7;
  border: 2px solid #35d290;
}

.g-input-entercash.ng-valid.ng-touched + span {
  text-shadow: 0 2px #0000001a;
  color: #66554f;
}

@media screen and (width <= 767px) {
  .g-input-entercash {
    width: 290px;
  }
}

.reg-bank-bot-links {
  text-align: center;
}

.reg-bank-bot-links a {
  color: inherit;
  font-weight: bold;
  text-decoration: underline;
}

.reg-bank-bot-links__1 {
  color: #66554f;
  margin-bottom: 23px;
  font-size: 14px;
  font-weight: 600;
}

.reg-bank-bot-links__2 {
  color: #3f3f3f;
  margin-bottom: 46px;
  font-size: 12px;
  font-weight: 600;
}

@media screen and (width <= 1239px) {
  .reg-bank-bot-links__1 {
    display: none;
  }
}

.ec-fast-reg-icons {
  max-width: 710px;
  margin: 0 auto;
}

.ec-fast-reg-icons .icons-list__list {
  justify-content: center;
}

.ec-fast-reg-icons .icons-list__list li {
  margin: 0 8px;
}

@media screen and (width <= 767px) {
  .ec-fast-reg-icons .icons-list__list li:nth-child(4), .ec-fast-reg-icons .icons-list__list li:nth-child(5), .ec-fast-reg-icons .icons-list__list li:nth-child(6), .ec-fast-reg-icons .icons-list__list li:nth-child(7) {
    display: none;
  }
}

@media (width >= 1920px) {
  .ec-fast-reg-icons {
    margin-bottom: 10px;
  }
}

.reg-bank-bot-icons {
  margin: 20px auto 0;
}

.linda-step-2 .reg-bank__iframe-header {
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 30px 16px 0;
  font-weight: 600;
  display: flex;
}

.linda-step-2 .reg-bank__title {
  flex-grow: 1;
  margin-top: 0;
}

.reg-bank__user-amount {
  font-size: 18px;
}

.reg-bank__step-back-link {
  align-items: center;
  display: flex;
}

.reg-bank__step-back-link span {
  margin-left: 10px;
  font-size: 16px;
}

@media screen and (width <= 767px) {
  .reg-bank__iframe-header {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .reg-bank__title {
    order: 2;
    width: 100%;
    margin-top: 10px;
  }
}

.linda-step-2 .reg-bank-hide-on-2step, .show-on-2step-reg-with-dep {
  display: none;
}

.linda-step-2.linda-signup-dep .show-on-2step-reg-with-dep {
  display: flex;
}

.linda-step-2 .reg-bank .progress-bar__step.pr-2 {
  opacity: 1;
}

.linda-step-2 .reg-bank .progress-bar__step.pr-1 .progress-bar__step-number:after {
  content: "";
  background-image: url("/img/icon-tooltip-check.svg");
  width: 20px;
  height: 15px;
  position: absolute;
  top: -3px;
  right: -5px;
}

.progress-bar__step-number2 {
  color: #66554f;
  text-align: center;
  background-color: #f9fbe7;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 900;
  line-height: 40px;
}

.progress-bar {
  justify-content: center;
  margin-bottom: auto;
  display: flex;
}

.progress-bar__step {
  opacity: .6;
  flex-direction: column;
  align-items: center;
  width: 140px;
  display: flex;
  position: relative;
}

.progress-bar__step.pr-1 {
  opacity: 1;
}

.progress-bar__step:before {
  content: "";
  background-color: #f9fbe7;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 15px;
  right: 0;
}

.progress-bar__step:last-child:before {
  display: none;
}

.progress-bar__step-number {
  color: #66554f;
  text-align: center;
  background-color: #f9fbe7;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 900;
  line-height: 40px;
  position: relative;
}

.progress-bar__step-text {
  color: #f9fbe7;
  text-align: center;
  width: 130px;
  font-size: 14px;
  line-height: 1.2;
}

@media only screen and (width <= 539px) {
  .progress-bar__step {
    width: 90px;
  }

  .progress-bar__step-text {
    width: 100%;
    font-size: 12px;
  }
}

@media (width >= 1920px) {
  .progress-bar {
    margin-bottom: 30px;
  }
}

.homepage-reg-bank {
  min-height: 750px;
  margin-bottom: 60px;
}

@media screen and (width <= 1439px) {
  .homepage-reg-bank {
    margin-bottom: 50px;
  }
}

@media screen and (width <= 1239px) {
  .homepage-reg-bank {
    margin-bottom: 40px;
  }
}

@media screen and (width <= 1023px) {
  .homepage-reg-bank {
    margin-bottom: 30px;
  }
}

@media screen and (width <= 767px) {
  .homepage-reg-bank {
    min-height: 670px;
  }
}

@media only screen and (width <= 539px) {
  .homepage-reg-bank {
    min-height: 720px;
  }
}

.homepage-reg-bank .reg-bank {
  background-size: 1920px 1000px;
}

@media (width >= 1920px) {
  .homepage-reg-bank .reg-bank {
    background-size: cover;
  }
}

.ec-baner {
  background-color: #ffd07e;
  background-image: url("/joxi/yoyo/entercash/ec-banner-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  height: 450px;
  padding: 10px 0 75px;
  position: relative;
}

.ec-baner h1 {
  text-shadow: 0 4px #00000040;
  color: #ffd180;
  text-align: center;
  width: 550px;
  max-height: 45px;
  margin: 30px 0 15px;
  font-size: 36px;
  font-weight: 900;
  overflow: hidden;
}

.ec-baner__content {
  text-align: center;
  background: url("/img/slider-text-background.svg") top no-repeat content-box border-box;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 20px;
  line-height: normal;
  display: flex;
}

.ec-baner__btn {
  background-color: #35d290;
}

.ec-baner__btn:hover {
  color: #fff;
  background-color: #00be6e;
}

.ec-baner__text-or {
  text-align: center;
  color: #66554f;
  font-size: 14px;
  line-height: 1.79;
}

.ec-baner__text-or a {
  font-weight: 600;
  text-decoration: underline;
}

.ec-baner__payments-wrap, .ec-baner__list {
  justify-content: center;
  margin-bottom: 13px;
  display: flex;
}

.ec-baner__list li {
  margin: 0 10px;
}

@media screen and (width <= 1023px) {
  .ec-baner {
    height: 390px;
  }

  .ec-baner h1 {
    margin-bottom: 10px;
  }
}

@media screen and (width <= 767px) {
  .ec-baner {
    background: linear-gradient(#3f3f3fb3, #3f3f3fb3), url("/joxi/yoyo/entercash/ec-banner-bg.jpg") center;
    height: 340px;
    padding-bottom: 0;
  }

  .ec-baner__content {
    background-image: none;
  }

  .ec-baner__text-or {
    color: #ffd180;
  }

  .ec-baner__content {
    padding-bottom: 10px;
  }

  .ec-baner h1 {
    width: 100%;
    max-height: 40px;
    margin-top: 0;
    font-size: 32px;
  }

  .ec-baner__list li {
    width: 80px;
    height: 20px;
    margin: 0 7px;
  }
}

@media only screen and (width <= 539px) {
  .ec-baner__btn {
    height: 40px;
    font-size: 16px;
    line-height: 40px;
  }

  .ec-baner li.icons-list__item {
    width: 60px;
    height: 14px;
  }

  .ec-baner li.icons-list__item:nth-child(5), .ec-baner li.icons-list__item:nth-child(6), .ec-baner li.icons-list__item:nth-child(7) {
    display: none;
  }

  .ec-baner h1 {
    min-height: 55px;
    max-height: 71px;
  }
}

.mail-change {
  text-align: left;
}

.mail-change__formbox-item {
  margin-top: 0;
  margin-bottom: 20px;
}

.mail-change__btn {
  width: 100%;
}

.home-tournaments {
  height: 100%;
  display: block;
}

.home-tournaments__list {
  background-color: #ffc051;
  background-image: url("/img/widg-tournaments-text-back.svg"), url("/joxi/yoyo/widget-tournaments-back.jpg");
  background-position: right -100px center, center;
  background-repeat: no-repeat, no-repeat;
  background-size: auto 100%, cover;
  border-radius: 10px;
  height: 100%;
}

.home-tournaments__item {
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.home-tournaments__item .slick-list, .home-tournaments__item .slick-track, .home-tournaments__item .slick-slide > div {
  height: 100%;
}

.home-tournaments__item .slick-dots {
  margin-right: 10px;
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.home-tournaments__item .slick-dots li {
  margin-right: 10px;
}

.home-tournaments__item .slick-dots li button {
  cursor: pointer;
  background: #f9fbe780;
  border: none;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  padding: 0;
  font-size: 0;
}

.home-tournaments__item .slick-dots li.slick-active button {
  background-color: #fff;
}

.home-tournaments__item-content {
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  padding: 30px 20px 20px;
  position: relative;
  display: flex !important;
}

.home-tournaments__status {
  color: #fff;
  border-bottom: 20px solid #35d290;
  border-left: 20px solid #0000;
  border-right: 20px solid #0000;
  width: 100px;
  height: 0;
  position: absolute;
  top: 19px;
  right: -21px;
  transform: rotate(45deg);
}

.home-tournaments__status-text {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: bold;
  line-height: 21px;
  display: block;
  overflow: hidden;
}

.home-tournaments__title {
  color: #3f3f3f;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  margin: 0 12px 25px 0;
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  overflow: hidden;
}

.home-tournaments__timer {
  margin-bottom: 18px;
}

.home-tournaments__prize-wrap {
  align-items: center;
  margin-bottom: 7px;
  margin-right: 12px;
  display: flex;
}

.home-tournaments__prize-wrap svg {
  width: 26px;
  height: 30px;
  margin-right: 8px;
}

.home-tournaments__prize-title {
  color: #3e3e3e;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
  max-width: 193px;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  overflow: hidden;
}

.home-tournaments__prize {
  color: #3e3e3e;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
  width: 228px;
  margin: 0 12px 30px 0;
  font-size: 32px;
  font-weight: bold;
  line-height: normal;
  overflow: hidden;
}

.home-tournaments__link {
  min-width: 150px;
  max-width: 230px;
}

@media screen and (width <= 1439px) {
  .home-tournaments__item-content {
    padding: 20px;
  }

  .home-tournaments__status {
    top: 12px;
    right: -26px;
  }

  .home-tournaments__title {
    margin-bottom: 14px;
  }

  .home-tournaments__timer {
    margin-bottom: 15px;
  }

  .home-tournaments__prize {
    margin-bottom: 12px;
  }
}

@media screen and (width <= 1023px) {
  .home-tournaments {
    max-width: 100%;
  }

  .home-tournaments__item-content {
    padding: 20px 30px;
  }

  .home-tournaments__item {
    max-width: 100%;
  }

  .home-tournaments__list {
    background-position: 100%, center;
  }
}

@media screen and (width <= 767px) {
  .home-tournaments__item-content {
    padding: 20px;
  }
}

@media only screen and (width <= 539px) {
  .home-tournaments__list {
    text-align: center;
    background-image: none;
  }

  .home-tournaments__status {
    top: 14px;
    right: -25px;
  }

  .home-tournaments__item-content {
    background-color: #ffc051;
    align-items: center;
    padding: 20px 30px;
  }

  .home-tournaments__title, .home-tournaments__prize-wrap {
    margin-right: 0;
  }

  .home-tournaments__prize {
    text-align: center;
    margin-right: 0;
  }

  .home-tournaments__link {
    margin-right: 0;
  }
}

.t-page {
  margin-top: 30px;
  margin-bottom: 60px;
}

@media screen and (width <= 1439px) {
  .t-page {
    margin-bottom: 50px;
  }
}

@media screen and (width <= 1239px) {
  .t-page {
    margin-bottom: 40px;
  }
}

@media screen and (width <= 1023px) {
  .t-page {
    margin-bottom: 30px;
  }
}

.t-page:after {
  content: "";
  clear: both;
  display: table;
}

.t-page_content {
  padding: 0 65px;
}

.t-page__key {
  letter-spacing: normal;
  color: #413034cc;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  overflow: hidden;
}

.t-page__value {
  letter-spacing: normal;
  color: #3f3f3f;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  overflow: hidden;
}

.t-page__ico {
  width: 25px;
  height: 30px;
  margin-right: 10px;
}

.t-page__ico svg {
  width: 100%;
  height: 100%;
  display: none;
}

.t-page__main-info-container {
  order: 1;
}

.t-page__main-info {
  flex-direction: column;
  width: calc(100% - 415px);
  padding-left: 65px;
  display: flex;
}

.t-page__banner {
  background-position: 0;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  height: 315px;
  position: relative;
  overflow: hidden;
}

.t-page__banner-label {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px;
  height: 20px;
  padding: 0 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  position: absolute;
  top: 15px;
  left: -27px;
  overflow: hidden;
  transform: rotate(-45deg);
}

.t-page__banner_t-status-0 .t-page__banner-label {
  background-color: #ffc051;
}

.t-page__banner_t-status-1 .t-page__banner-label {
  background-color: #35d290;
}

.t-page__banner_t-status-2 .t-page__banner-label {
  background-color: #ea4642;
}

.t-page__title-block {
  align-items: center;
  margin: 30px 0 14px;
  display: flex;
}

.t-page__tournament-title {
  letter-spacing: normal;
  color: #3f3f3f;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
}

.t-page__tournament-prize {
  letter-spacing: normal;
  color: #00be6e;
  margin-left: 25px;
  font-size: 36px;
  font-weight: 900;
  line-height: normal;
}

.t-page__text {
  letter-spacing: normal;
  color: #3f3f3f;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.76;
}

.t-page__rules-link {
  letter-spacing: normal;
  color: #3f3f3f;
  margin: 20px 0 50px;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  text-decoration: underline;
}

.t-page__info-container {
  order: 3;
}

.t-page__info {
  float: right;
  flex-direction: column;
  width: 385px;
  padding-right: 65px;
  display: flex;
}

.t-page__info-block {
  border-radius: 10px;
  width: 320px;
  min-height: 490px;
  margin-bottom: 30px;
}

.t-page__info-block .t-page__key {
  width: 50%;
}

.t-page__info-block .t-page__value {
  margin-left: auto;
  margin-right: 10px;
}

.t-page__info-block_prizes {
  background: url("/img/one-tournament_prizes-bg.svg") bottom no-repeat;
  border: 1px solid #ffc200;
  padding: 30px 68px 30px 53px;
}

.t-page__info-block_prizes .t-page__key {
  width: 15%;
}

.t-page__info-block_score {
  background: url("/img/one-tournament_score-bg.svg") bottom no-repeat;
  border: 1px solid #00be6e;
  height: 490px;
  padding: 30px 38px 30px 48px;
  position: relative;
}

.t-page__info-block_score .t-page__info-block-list {
  height: 399px;
  overflow-y: auto;
}

.t-page__info-block_score .t-page__info-block-list::-webkit-scrollbar-track {
  background-color: #0000;
}

.t-page__info-block_score .t-page__list-gradient {
  background-image: linear-gradient(#e6f7de00, #e6f7de);
  width: 70%;
  height: 60px;
  position: absolute;
  bottom: 30px;
}

.t-page__info-block_score-0 {
  padding: 30px 15px;
}

.t-page__info-block_score-0 .t-page__info-block-noscore-text, .t-page__info-block_score-0 .t-page__info-block-noscore-ico {
  display: block;
}

.t-page__info-block-title {
  letter-spacing: normal;
  color: #3f3f3f;
  text-align: center;
  margin-bottom: 7px;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
}

.t-page__info-block-noscore-text {
  letter-spacing: normal;
  color: #413034cc;
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.79;
  display: none;
}

.t-page__info-block-noscore-ico {
  width: 150px;
  height: 170px;
  display: none;
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
}

.t-page__info-block-noscore-ico svg {
  fill: #00be6e4d;
  width: 100%;
  height: 100%;
}

.t-page__info-block-list {
  flex-direction: column;
  min-height: 399px;
  display: flex;
}

.t-page__info-block-item {
  align-items: center;
  width: 100%;
  min-height: 25px;
  margin-top: 10px;
  display: flex;
}

.t-page__info-block-item_1 .t-page__ico svg, .t-page__info-block-item_2 .t-page__ico svg, .t-page__info-block-item_3 .t-page__ico svg {
  display: block;
}

.t-page__info-block-item_1 .t-page__ico svg {
  fill: #00be6e;
}

.t-page__info-block-item_2 .t-page__ico svg {
  fill: #ffa800;
}

.t-page__info-block-item_3 .t-page__ico svg {
  fill: #ea4642;
}

.t-page__details-wrapper {
  background: linear-gradient(to right, #fcdf74 0% 29%, #0000 29% 100%);
  order: 2;
  min-height: 140px;
}

.t-page__details {
  align-items: center;
  width: calc(100% - 415px);
  min-height: 140px;
  padding-left: 65px;
  display: flex;
  position: relative;
}

.t-page__details-text {
  letter-spacing: normal;
  color: #3f3f3f;
  z-index: 10;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: normal;
}

.t-page__details-block {
  z-index: 10;
  width: 50%;
}

.t-page__details-block .timer {
  z-index: 10;
}

.t-page__details-title {
  text-align: center;
  letter-spacing: normal;
  color: #3f3f3f;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
}

.t-page__details-list {
  display: flex;
}

.t-page__details-list-item {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.t-page__details-list-half {
  max-width: 50%;
}

.t-page__details-list-half:first-child {
  min-width: 135px;
}

.t-page__details-list-half:last-child {
  min-width: 200px;
  margin-left: auto;
}

.t-page__details-list-half:last-child .t-page__details-list-item:last-child {
  margin-bottom: 0;
}

.t-page__details-key {
  padding-right: 10px;
}

.t-page__details-value {
  white-space: nowrap;
}

.t-page__timer {
  background: #fcdf74;
  flex-direction: column;
  justify-content: center;
  width: 320px;
  min-height: 140px;
  display: flex;
  position: relative;
}

.t-page__timer:after {
  content: "";
  background: url("/img/tournament_timer_bg.svg") 0 / contain no-repeat;
  width: 130px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: -128px;
}

.t-page__games-container {
  order: 4;
}

.t-page__games {
  width: calc(100% - 415px);
  padding-left: 65px;
  line-height: 0;
}

.t-page__games-title {
  letter-spacing: normal;
  color: #413034cc;
  margin: 30px 0 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}

@media screen and (width <= 1439px) {
  .t-page__banner {
    height: 256px;
  }

  .t-page__main-info, .t-page__details, .t-page__games {
    width: calc(100% - 370px);
    padding-left: 20px;
  }

  .t-page__info {
    width: 340px;
    padding-right: 20px;
  }

  .t-page__details-wrapper {
    background: linear-gradient(to right, #fcdf74 0% 20%, #0000 20% 100%);
  }

  .t-page__details {
    justify-content: space-between;
    height: 160px;
  }

  .t-page__details-block {
    width: 290px;
  }

  .t-page__details-list {
    flex-direction: column;
    align-items: center;
  }

  .t-page__details-list-half:first-child, .t-page__details-list-half:last-child {
    width: 100%;
    min-width: 100%;
  }

  .t-page__details-list-item {
    justify-content: space-between;
  }

  .t-page__timer {
    width: 250px;
    min-height: 160px;
  }
}

@media screen and (width <= 1023px) {
  .t-page {
    flex-direction: column;
    display: flex;
  }

  .t-page__games-container {
    margin: 0;
  }

  .t-page__main-info, .t-page__games, .t-page__info {
    width: 100%;
    padding: 0;
  }

  .t-page__timer {
    width: 280px;
  }

  .t-page__details {
    width: 728px;
    margin: auto;
    padding-left: 0;
  }

  .t-page__rules-link {
    margin: 20px 0 30px;
  }

  .t-page__info {
    float: none;
    flex-direction: row;
    order: 3;
    margin-top: 30px;
  }

  .t-page__info-block_score {
    width: 380px;
    margin-left: 28px;
    padding: 30px 60px;
  }

  .t-page__games-title {
    margin: 0 0 6px;
  }

  .t-page__banner {
    background-size: cover;
    height: auto;
    padding-top: 35%;
  }
}

@media screen and (width <= 767px) {
  .t-page__banner {
    height: 230px;
  }

  .t-page__title-block {
    flex-direction: column;
    align-items: flex-start;
    margin: 25px 0 15px;
  }

  .t-page__tournament-title {
    margin-bottom: 5px;
    font-size: 18px;
  }

  .t-page__tournament-prize {
    margin-left: 0;
    font-size: 32px;
  }

  .t-page__details-wrapper {
    background: linear-gradient(to right, #fcdf74 0% 40%, #0000 40% 100%) 0 0 / 100% 120px no-repeat;
    overflow: hidden;
  }

  .t-page__timer {
    background: linear-gradient(to right, #fcdf74 0% 80%, #0000 80% 100%);
    align-items: end;
    width: auto;
    min-height: 120px;
  }

  .t-page__timer:after {
    background-size: cover;
    width: 65px;
    right: -10px;
  }

  .t-page__details {
    flex-direction: column;
    width: auto;
    height: auto;
  }

  .t-page__details-block:first-child {
    flex-direction: column;
    height: 120px;
    padding-left: 18px;
    display: flex;
  }

  .t-page__details-block:last-child {
    margin-top: 30px;
  }

  .t-page__info {
    flex-direction: column;
  }

  .t-page__info-block {
    width: 290px;
    margin-left: 0;
  }

  .t-page__info-block_prizes {
    padding: 30px 35px;
  }

  .t-page__info-block_score {
    padding: 30px 25px 30px 20px;
  }

  .t-page__details-text {
    width: 290px;
  }

  .t-page__timer-wrap {
    width: 240px;
  }
}

.welcom-bonus {
  background-position: right 85px center, top;
  background-repeat: no-repeat;
  background-size: 340px, cover;
  border: 1px solid #00be6e;
  border-radius: 10px;
  height: 360px;
  padding-left: 60px;
  display: flex;
  position: relative;
}

.welcom-bonus__left-wrap {
  width: 100%;
  max-width: 485px;
  margin: auto 0;
  position: relative;
}

.welcom-bonus__title-small {
  color: #3f3f3f;
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: 900;
}

.welcom-bonus__title-big {
  color: #00be6e;
  max-height: 86px;
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: 900;
  overflow: hidden;
}

@media screen and (width <= 1239px) {
  .welcom-bonus {
    background-position: right 45px center, 100%;
    background-size: 255px, cover;
    height: 264px;
    padding-left: 30px;
  }

  .welcom-bonus__title-big {
    margin-bottom: 10px;
    font-size: 24px;
  }

  .welcom-bonus__title-small {
    margin-bottom: 10px;
    font-size: 18px;
  }

  .welcom-bonus__btn {
    height: 40px;
    margin-top: 10px;
    padding: 0 30px;
    font-size: 16px;
    line-height: 40px;
  }
}

@media screen and (width <= 1023px) {
  .welcom-bonus {
    background-position: right 50px center, 100%;
    height: 250px;
    padding-left: 20px;
  }

  .welcom-bonus__left-wrap {
    width: 240px;
  }
}

@media screen and (width <= 767px) {
  .welcom-bonus {
    background-position: right 10px center, 50%;
    background-size: 203px 194px, cover;
    padding-left: 30px;
  }
}

@media only screen and (width <= 539px) {
  .welcom-bonus {
    background-image: none;
    background-size: 203px 194px, 0 0;
  }

  .welcom-bonus:before {
    content: "";
    opacity: .7;
    background-color: #f9fbe7;
    border-radius: 10px;
    position: absolute;
    inset: 0;
  }
}

.rg-bar-timer-desktop {
  color: #66554f;
  font-size: 14px;
  font-weight: 500;
}

.rg-bar-timer-desktop__counter {
  width: 80px;
}

.se-header-reg-btn {
  display: block;
}

.se-header-reg-btn svg {
  width: 29px;
  height: 24px;
  display: none;
}

@media screen and (width <= 1023px) {
  .se-header-reg-btn {
    box-sizing: border-box;
    border-radius: 50%;
    width: 40px;
    min-width: 40px;
    height: 40px;
    padding: 8px 7px 8px 4px;
  }

  .se-header-reg-btn svg {
    display: block;
  }

  .se-header-reg-btn span {
    display: none;
  }
}

@media only screen and (width <= 539px) {
  .se-header-reg-btn {
    display: none;
  }
}

.reality-check__item-box {
  border-bottom: 1px solid #d9b472;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 10px;
  line-height: 1.2;
  display: flex;
}

.reality-check__value {
  color: #3e3e3e;
  font-size: 16px;
  font-weight: 600;
}

.reality-check__key {
  color: #66554f;
  text-align: left;
  font-size: 14px;
}

.reality-check__exit {
  text-align: center;
  margin-top: 20px;
}

.reality-check__btn-label {
  color: #3e3e3e;
  margin: 20px 0;
  font-size: 14px;
  line-height: 1.2;
}

.se-deposit-limit-modal {
  line-height: 1.2;
}

.se-deposit-limit-modal__title {
  color: #3f3f3f;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 500;
}

.se-deposit-limit-modal__wrap {
  background-color: #f9fbe780;
  border-radius: 10px;
  width: 290px;
  margin-bottom: 20px;
  padding: 15px;
}

.se-deposit-limit-modal__form {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.se-deposit-limit-modal__amount-btns {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  display: flex;
}

.se-deposit-limit-modal__btn {
  color: #66554f;
  cursor: pointer;
  background-color: #f9fbe7;
  border: none;
  border-radius: 10px;
  height: 45px;
  font-size: 18px;
  font-weight: 900;
  line-height: 45px;
  overflow: hidden;
}

.se-deposit-limit-modal__btn.active {
  color: #fff;
  background-color: #ffa800;
}

.se-deposit-limit-modal__btn:first-child {
  margin-right: 12px;
}

.se-deposit-limit-modal__input-wrap input, .se-deposit-limit-modal__input-wrap a.show-input {
  display: none;
}

.se-deposit-limit-modal__input-wrap a.show-input + input {
  display: block;
}

.session-limit-end-modal__sub-title {
  color: #3e3e3e;
  text-align: left;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.76;
}

.session-limit-end-modal__sub-title span.bold {
  font-weight: 600;
}

.session-limit-end-modal__text, .session-limit-end-modal__btn {
  margin-bottom: 20px;
}

@media screen and (width <= 767px) {
  .session-limit-end-modal__modal-box {
    width: 320px;
    padding: 30px 15px 40px;
  }
}

.se-self-exclusion__title {
  margin-bottom: 20px;
}

.se-self-exclusion__sub-title {
  text-align: left;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 500;
}

.se-self-exclusion__text {
  margin-bottom: 20px;
}

@media screen and (width <= 767px) {
  .se-self-exclusion__modal-box {
    width: 320px;
    padding: 30px 15px 40px;
  }
}

.se-widget-balance__wrap {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.se-widget-balance__btn {
  margin-left: 5px;
  position: relative;
  top: 0;
  right: auto;
}

.profile-deposit-limit__interval-wrap {
  align-items: flex-start;
  min-height: 320px;
  margin-top: 20px;
  display: flex;
}

.profile-deposit-limit__interval-item {
  text-align: center;
  background-color: #faeab9;
  border-radius: 10px;
  flex-grow: 1;
  max-width: 314px;
  min-height: 248px;
  margin-right: 20px;
  padding: 20px 20px 30px;
  overflow: hidden;
}

.profile-deposit-limit__interval-item:last-child {
  margin-right: 0;
}

.profile-deposit-limit__interval-title {
  color: #3f3f3f;
  margin-bottom: 5px;
  font-size: 14px;
}

.profile-deposit-limit__interval-big-title {
  color: #66554f;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 900;
}

.profile-deposit-limit__amount-text {
  color: #3f3f3f;
  text-align: left;
  margin-bottom: 10px;
  padding-left: 25px;
  font-size: 14px;
  font-weight: 500;
}

.profile-deposit-limit__input-wrap {
  text-align: left;
  margin-bottom: 20px;
}

.profile-deposit-limit__next-limit {
  color: #3f3f3f;
  text-align: center;
  margin-top: 5px;
  margin-bottom: -10px;
  font-size: 12px;
  line-height: 1.2;
}

.profile-deposit-limit__text-b {
  font-weight: 600;
}

.profile-deposit-limit__btn-s {
  margin-bottom: 15px;
}

@media screen and (width <= 767px) {
  .profile-deposit-limit__interval-wrap {
    flex-wrap: wrap;
    justify-content: center;
  }

  .profile-deposit-limit__interval-item {
    max-width: 247px;
    margin-bottom: 15px;
    margin-right: 15px;
  }

  .profile-deposit-limit__interval-item:nth-child(2n) {
    margin-right: 0;
  }
}

@media only screen and (width <= 539px) {
  .profile-deposit-limit__interval-item {
    max-width: 290px;
    margin-right: 0;
  }
}

.show-on-editMode {
  display: none;
}

.set-editmode .show-on-editMode {
  display: block;
}

.set-editmode .show-on-editMode.is-flex {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.set-editmode .hide-on-editMode {
  display: none;
}

.input-block {
  background-color: #dcdbca;
  border-color: #0000;
  line-height: 38px;
}

.profile-session-limit__title {
  color: #3f3f3f;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
}

.profile-session-limit__form-box {
  max-width: 395px;
  margin: 0 auto 20px;
  display: flex;
}

.profile-session-limit__input-wrap {
  flex-direction: column;
  margin-right: 15px;
  display: flex;
}

.profile-session-limit__input {
  text-align: left;
  width: 190px;
}

.profile-session-limit__btns-wrap {
  width: 290px;
  margin: auto;
}

.profile-session-limit__save {
  display: none;
}

.profile-session-limit__infoline {
  color: #3f3f3f;
  margin-top: -10px;
  margin-bottom: 10px;
  font-size: 12px;
}

.profile-session-limit__infoline-text {
  font-weight: 600;
}

.profile-session-limit__link {
  margin-top: 15px;
}

.profile-session-limit__select {
  background-color: #fff;
}

@media only screen and (width <= 539px) {
  .profile-session-limit__input-wrap {
    width: 100%;
    margin: 0 0 20px;
  }

  .profile-session-limit__input {
    width: 100%;
  }

  .profile-session-limit__form-box {
    flex-direction: column;
    max-width: 250px;
  }

  .profile-session-limit__btns-wrap {
    width: 250px;
  }

  .profile-session-limit__btns-wrap button {
    width: 100%;
    min-width: auto;
  }
}

.set-editmode .profile-session-limit__change {
  display: none;
}

.set-editmode .profile-session-limit__save {
  display: block;
}

.set-editmode.ng-invalid .self-control__btn-save {
  opacity: .6;
}

.se-profile-details__user {
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  max-height: 100%;
}

.se-profile-details__balance {
  border: 1px solid #00be6e;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 263px;
  display: flex;
}

.se-profile-details__bonus-wrap {
  margin-bottom: 20px;
  margin-left: 20px;
}

.se-profile-details__info-wrap {
  margin-bottom: 20px;
  display: flex;
}

@media screen and (width <= 1023px) {
  .se-profile-details__balance {
    width: 320px;
  }

  .se-profile-details__bonus-wrap {
    margin-top: 15px;
    margin-left: 15px;
  }

  .se-profile-details__vip {
    width: 392px;
  }

  .se-profile-details__info-wrap {
    justify-content: center;
    width: 100%;
  }
}

@media screen and (width <= 767px) {
  .se-profile-details__vip {
    width: 100%;
  }

  .se-profile-details__balance {
    width: 100%;
    margin-top: 20px;
  }

  .se-profile-details__info-wrap {
    flex-direction: column;
  }

  .se-profile-details__balance {
    padding: 32px 0 30px;
  }

  .se-profile-details__bonus-wrap {
    width: 290px;
    margin-top: 20px;
  }
}

.deposit-page {
  color: #3e3e3e;
  background-image: url("/joxi/sweden-yoyo/img-bg-dep_lim_page.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.deposit-page__logo {
  width: 188px;
  height: 76px;
  margin: auto auto 60px;
  display: block;
}

.deposit-page__logo svg {
  width: 100%;
  height: 100%;
}

.deposit-page__blot {
  background-image: url("/img/img-deposit-blot.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  min-height: 100vh;
}

.deposit-page__title {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
}

.deposit-page__sub-title {
  margin-bottom: 20px;
  font-size: 14px;
}

.deposit-page__wrap {
  text-align: center;
  width: 100%;
  max-width: 698px;
  margin: auto;
  padding: 60px 0 40px;
}

.deposit-page__item {
  background-color: #f9fbe780;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  padding: 15px;
  display: flex;
}

.deposit-page__item_small {
  width: 85%;
}

.deposit-page__btns-wrap {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  overflow-x: auto;
}

.deposit-page__method-name {
  flex: 0 0 62px;
  margin-right: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.deposit-page__item-title {
  color: #3f3f3f;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 500;
}

.deposit-page__btn {
  color: #66554f;
  text-align: center;
  cursor: pointer;
  background-color: #f9fbe7;
  border: none;
  border-radius: 10px;
  flex-shrink: 0;
  width: 124px;
  height: 45px;
  margin-right: 12px;
  font-size: 18px;
  font-weight: 600;
  line-height: 45px;
  overflow: hidden;
}

.deposit-page__btn.active {
  color: #fff;
  background-color: #ffa800;
}

.deposit-page__input-wrap {
  flex: 0 0 142px;
  margin-top: 5px;
}

.deposit-page__link {
  margin-top: 5px;
}

.deposit-page__link.clicked {
  display: none;
}

.deposit-page__link.clicked + .deposit-page__input-wrap {
  display: block;
}

.deposit-page__form {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media screen and (width >= 1440px) {
  .deposit-page__blot {
    background-size: contain;
  }
}

@media screen and (width <= 767px) {
  .deposit-page__blot {
    background: #ffd180;
  }

  .deposit-page__wrap {
    background: #ffd180;
    max-width: 100%;
    padding: 30px 0 40px;
  }

  .deposit-page__logo {
    width: 135px;
    height: 54px;
    margin: auto auto 30px;
  }

  .deposit-page__item {
    border-radius: 0;
    padding: 15px 0;
  }

  .deposit-page__btns-wrap {
    justify-content: flex-start;
  }
}

.h-hide-input {
  display: none;
}

.h-for-z-index:first-child {
  z-index: 4;
}

.h-for-z-index:nth-child(2) {
  z-index: 3;
}

.h-for-z-index:nth-child(3) {
  z-index: 2;
}

.balance-history {
  border: 1px solid #ffa800;
  border-radius: 10px;
  padding-bottom: 29px;
  display: block;
  position: relative;
}

.balance-history__filters {
  background-color: #ffd180;
  border-radius: 10px 10px 0 0;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  padding: 20px;
  display: flex;
}

.balance-history__filter-item {
  width: 168px;
  height: 40px;
  margin-right: 10px;
  position: relative;
}

.balance-history__filter-item:last-child {
  margin-right: 0;
}

.balance-history__filter-item.is-range {
  width: auto;
  min-width: 220px;
}

.balance-history__status-filter:before {
  content: "";
  z-index: 10;
  background: #fff9;
  border-radius: 25px;
  width: 100%;
  height: 40px;
  display: block;
  position: absolute;
}

.balance-history__status-filter.is-active-status-filter:before {
  display: none;
}

.balance-history__date-filter {
  display: flex;
  position: relative;
}

.balance-history__date {
  text-align: center;
  z-index: 1;
  color: #3e3e3e;
  white-space: nowrap;
  background-color: #fff;
  border: none;
  border-radius: 25px;
  width: 100%;
  padding: 13px 25px;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  overflow: hidden;
}

.balance-history__date_from span {
  display: none;
}

.linda-has-range .balance-history__date_from span {
  display: inline;
}

.balance-history__date_to {
  opacity: 0;
}

.linda-has-range .balance-history__date_to {
  opacity: 1;
}

.balance-history__table-wrap {
  min-height: 100px;
  position: relative;
}

.balance-history__table {
  height: 467px;
  overflow: auto;
}

.balance-history__table.is-table-with-scroll {
  margin-right: 10px;
  padding-right: 10px;
}

.balance-history__table:before {
  content: "";
  background: #f7fce5;
  width: 100%;
  height: 43px;
  position: absolute;
}

.balance-history__head {
  z-index: 1;
  text-align: center;
  color: #66554f;
  background-color: #f9fbe7;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 43px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.2;
  display: flex;
  position: sticky;
  top: 0;
}

.balance-history__body {
  z-index: 0;
  position: relative;
}

.balance-history__empty {
  text-align: center;
  color: #dcdbca;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
}

.balance-history__row {
  text-align: center;
  color: #66554f;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.balance-history__table-item {
  word-break: break-all;
  width: 170px;
  padding: 0 6px;
  overflow: hidden;
}

.balance-history__table-item_bonus {
  color: #00be6e;
}

.balance-history__table-item_real {
  color: #aa69ac;
}

.balance-history__short-preview {
  background-color: #f1f2dd;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-bottom: 1px;
  transition: background-color .2s;
  display: flex;
  position: relative;
}

.balance-history__short-preview:hover {
  cursor: pointer;
  background-color: #faf3d2;
}

.balance-history__short-preview:after {
  content: "";
  z-index: 5;
  background-image: url("/img/arr-select_black.svg");
  background-repeat: no-repeat;
  background-size: 11px 7px;
  width: 11px;
  height: 7px;
  transition: transform .2s;
  position: absolute;
  top: 22px;
  right: 21px;
}

.balance-history__full-descr {
  background-image: linear-gradient(to right, #fce6b3 0% 50%, #fce6b3 calc(50% + 1px), #f9fbe7 calc(50% + 1px) calc(50% + 2px), #fce6b3 calc(50% + 2px) 100%);
  align-items: center;
  min-height: 50px;
  display: none;
}

.balance-history__descr-item {
  justify-content: center;
  align-items: center;
  width: 50%;
  min-height: 49px;
  padding: 5px 20px;
  display: flex;
  overflow: hidden;
}

.balance-history__descr-title {
  padding-right: 10px;
  font-size: 12px;
  font-weight: 700;
}

@media screen and (width <= 1023px) {
  .balance-history__filters {
    flex-wrap: wrap;
  }

  .balance-history__filter-item {
    width: 252px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .balance-history__filters-btn-block {
    text-align: center;
    width: 100%;
  }

  .balance-history__filters-btn {
    margin-bottom: 0;
  }

  .balance-history__table-item {
    width: 160px;
  }
}

@media screen and (width <= 767px) {
  .balance-history {
    border: none;
    border-bottom: 1px solid #ffa800;
    border-radius: 0;
    padding-bottom: 0;
  }

  .balance-history__filters {
    border-radius: 0;
  }

  .balance-history__filter-item {
    flex-grow: 1;
    width: 204px;
    max-width: 252px;
  }

  .balance-history__date-filter {
    justify-content: center;
    width: 100%;
  }

  .balance-history__filters-btn {
    width: 252px;
  }

  .balance-history__table-item {
    padding: 0 1px;
  }

  .balance-history__table-item:last-child {
    padding-right: 20px;
  }

  .balance-history__table-item_amount {
    font-size: 14px;
  }

  .balance-history__short-preview {
    background-color: #f1f2dd;
    padding-left: 15px;
    padding-right: 15px;
  }

  .balance-history__short-preview:after {
    right: 15px;
  }

  .balance-history__row {
    font-size: 12px;
  }

  .balance-history__head {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 11px;
    line-height: 13px;
  }

  .balance-history__full-descr {
    background-color: #fce6b3;
    background-image: none;
    flex-direction: column;
    height: auto;
  }

  .balance-history__descr-item {
    border-bottom: 1px solid #f9fbe7;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    display: flex;
  }

  .balance-history__descr-data {
    font-size: 14px;
    line-height: 16px;
  }
}

@media only screen and (width <= 767px) and (width <= 539px) {
  .balance-history__filter-item {
    width: 252px;
  }

  .balance-history__date-filter {
    flex-direction: column;
    align-items: center;
    height: 108px;
  }
}

.balance-history__short-preview.is-opened-row {
  background: #ffd180;
}

.balance-history__short-preview.is-opened-row:after {
  transform: rotate(180deg);
}

.balance-history__short-preview.is-opened-row + .balance-history__full-descr {
  display: flex;
}

.balance-history-select {
  color: #656565;
  z-index: 4;
  border-radius: 25px;
  width: 100%;
  height: 40px;
  font-size: 14px;
  line-height: 30px;
  transition: box-shadow .1s, border-radius .1s;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.balance-history-select:hover {
  cursor: pointer;
}

.balance-history-select:after {
  content: "";
  z-index: 5;
  background-image: url("/img/arr-select.svg");
  background-repeat: no-repeat;
  background-size: 11px 7px;
  width: 11px;
  height: 7px;
  transition: transform .2s;
  position: absolute;
  top: 17px;
  right: 19px;
}

.balance-history-select__top {
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 1;
  background-color: #fff;
  padding: 8px 45px 7px 25px;
  line-height: 25px;
  position: relative;
  overflow: hidden;
}

.balance-history-select__bottom {
  z-index: 0;
  opacity: 0;
  background-color: #fff;
  width: 100%;
  height: 0;
  padding-bottom: 15px;
  transition: opacity .1s, transform .1s;
  position: relative;
  overflow: hidden;
  transform: translateY(-100px);
}

.balance-history-select__checkbox-block {
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #fff;
  padding: 3px 45px 3px 25px;
  line-height: 30px;
  display: block;
  overflow: hidden;
}

input:checked + .balance-history-select__checkbox-block {
  background-image: url("/img/check.svg");
  background-position: right 18px top 14px;
  background-repeat: no-repeat;
  background-size: 13px 9px;
}

.balance-history-select__divider {
  color: #ffc051;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  padding: 10px 18px 5px 25px;
  font-size: 12px;
  line-height: 21px;
  display: none;
  overflow: hidden;
}

.balance-history-select__divider:after {
  content: "";
  background-color: #ffd180;
  flex-grow: 1;
  height: 1px;
  margin-left: 5px;
}

.linda-first-in-group .balance-history-select__divider {
  display: flex;
}

.balance-history-select.is-open-select {
  border-radius: 10px;
  height: auto;
  box-shadow: 0 6px 20px #00000014;
}

.balance-history-select.is-open-select:after {
  transform: rotate(180deg);
}

.balance-history-select.is-open-select .balance-history-select__bottom {
  opacity: 1;
  height: auto;
  transform: translateY(0);
}

.balance-history-bottom-descr {
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  display: flex;
}

.balance-history-bottom-descr__item {
  color: #66554f;
  padding: 0 15px;
}

.balance-history-bottom-descr__real {
  color: #aa69ac;
}

.balance-history-bottom-descr__bonus {
  color: #00be6e;
}

@media only screen and (width <= 539px) {
  .balance-history-bottom-descr {
    margin-left: -15px;
    margin-right: -15px;
  }

  .balance-history-bottom-descr__item {
    padding: 0 8px;
  }
}

.is-status {
  display: none;
}

.is-status.linda-is-active {
  display: block;
}

.table-filter input {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #3e3e3e;
  box-sizing: border-box;
  background-color: #fff;
  background-image: url("/img/arr-select.svg");
  background-position: calc(100% - 20px);
  background-repeat: no-repeat;
  border: none;
  border-radius: 20px;
  width: 100%;
  max-width: 100%;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  display: block;
}

.table-filter__date {
  width: 168px;
  display: inline-block;
}

.table-filter__date:last-child {
  opacity: 0;
  height: 0;
}

.linda-has-range .table-filter__date:last-child {
  opacity: 1;
  height: auto;
}

@media screen and (width <= 1023px) {
  .table-filter__date {
    width: 252px;
    margin-bottom: 12px;
  }
}

@media screen and (width <= 767px) {
  .table-filter__date {
    flex-grow: 1;
    width: 204px;
    max-width: 252px;
  }

  .table-filter__date + .table-filter__date {
    margin-left: 10px;
  }
}

@media only screen and (width <= 539px) {
  .table-filter__date {
    width: 252px;
  }

  .table-filter__date + .table-filter__date {
    margin-left: 0;
  }
}

.is-touch-templ .is-table-with-scroll {
  margin: 0;
  padding: 0;
}

.is-touch-templ .balance-history__table {
  max-height: 467px;
}

.is-touch-templ .balance-history__date-filter-touch {
  width: 346px;
  margin-right: 10px;
}

.is-touch-templ .balance-history__date-filter-touch > div {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

@media screen and (width <= 1023px) {
  .is-touch-templ .balance-history__table {
    height: auto;
  }

  .is-touch-templ .balance-history__date-filter-touch {
    width: 516px;
    margin-right: 0;
  }
}

@media screen and (width <= 767px) {
  .is-touch-templ .balance-history__date-filter-touch {
    width: calc(100% - 10px);
    max-width: 512px;
  }
}

@media only screen and (width <= 539px) {
  .is-touch-templ .balance-history__date-filter-touch {
    height: 108px;
  }

  .is-touch-templ .balance-history__date-filter-touch > div {
    text-align: center;
    display: block;
  }
}

.reg-modal {
  color: #66554f;
  text-align: left;
  background: #3e3e3ecc;
  font-size: 14px;
}

.reg-modal__box {
  width: 100%;
  max-width: 450px;
}

.reg-modal__box.is-step-2 {
  padding-top: 56px;
}

.reg-modal__subtitle {
  border-bottom: 1px solid #3e3e3e33;
  margin-bottom: 0;
  padding-bottom: 10px;
}

.reg-modal__subtitle.no-border {
  border-bottom: none;
}

.reg-modal__btn {
  background: #35d290;
  margin: 0 auto;
  display: block;
}

.reg-modal__btn:hover {
  background: #00be6e;
  box-shadow: 0 4px 15px #0003;
}

.reg-modal__btn:disabled {
  pointer-events: none;
  background: #00be6e66;
}

.reg-modal__link {
  cursor: pointer;
  color: #66554f;
  background: none;
  border: none;
  border-bottom: 1px dashed #66554f;
  margin: 16px auto 26px;
  font-size: 14px;
  font-weight: bold;
  display: block;
}

.reg-modal__formbox {
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  column-gap: 10px;
  display: grid;
}

.reg-modal__formbox .is-full-width {
  grid-column: span 2;
}

.reg-modal__formbox .is-centered {
  margin: 0 auto;
}

.reg-modal__promotions {
  row-gap: 8px;
  margin-top: 6px;
  display: grid;
}

.reg-modal__promotions-text {
  color: #66554f;
  margin-top: 24px;
  font-size: 12px;
  line-height: 120%;
}

.reg-modal__promo-link {
  color: #66554f;
  justify-content: center;
  margin: 24px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: underline;
  display: flex;
}

.reg-modal__promo-link:hover {
  opacity: .8;
}

.reg-modal__back-link {
  color: #66554f;
  background-image: var(--icon-back);
  text-transform: capitalize;
  cursor: pointer;
  background-color: #0000;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  padding-left: 20px;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  top: 17px;
  left: 20px;
}

.reg-modal__back-link:hover {
  opacity: .8;
}

.footer-icons__list {
  justify-content: center;
  display: flex;
}

.footer-icons__item {
  width: 88px;
  height: 24px;
}

.footer-icons__img {
  word-break: break-all;
  width: 100%;
  height: 100%;
  margin-top: 2px;
  font-size: 10px;
}

@media screen and (width <= 1023px) {
  .footer-icons__item {
    width: 80px;
    height: 22px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media screen and (width <= 767px) {
  .footer-icons__list {
    flex-wrap: wrap;
  }

  .footer-icons__item {
    margin-top: 10px;
  }
}

.bankid-pay-icons__list {
  justify-content: space-between;
  display: flex;
}

.bankid-pay-icons__item {
  width: 86px;
  height: 23px;
  margin: 0 8px;
}

.bankid-pay-icons__img {
  word-break: break-all;
  width: 100%;
  height: 100%;
  font-size: 10px;
}

@media (width >= 1920px) {
  .bankid-pay-icons {
    margin-bottom: 10px;
  }
}

@media screen and (width <= 1023px) {
  .bankid-pay-icons__item {
    width: 65px;
    height: 23px;
  }
}

@media screen and (width <= 767px) {
  .bankid-pay-icons {
    flex-wrap: wrap;
  }

  .bankid-pay-icons__list {
    flex-wrap: wrap;
    justify-content: center;
  }

  .bankid-pay-icons__item {
    width: 60px;
    height: 16px;
    margin-top: 10px;
  }

  .bankid-pay-icons__item:nth-child(n+5) {
    display: none;
  }
}

.bankid-banner-icons__list {
  justify-content: space-between;
  display: flex;
}

.bankid-banner-icons__item {
  width: 86px;
  height: 23px;
  margin: 0 10px;
}

.bankid-banner-icons__img {
  word-break: break-all;
  width: 100%;
  height: 100%;
  font-size: 10px;
}

@media screen and (width <= 767px) {
  .bankid-banner-icons__list {
    justify-content: center;
  }

  .bankid-banner-icons__item {
    width: 60px;
    height: 18px;
    margin: 0 7px;
  }
}

@media only screen and (width <= 539px) {
  .bankid-banner-icons__item:nth-child(n+5) {
    display: none;
  }
}

.login-popup {
  max-width: 450px;
  padding-top: 20px;
  padding-bottom: 32px;
}

.login-popup.is-frame {
  padding-top: 6px;
}

.login-popup__btn-box {
  margin-top: 20px;
}

.login-popup__btn {
  width: fit-content;
  max-width: 100%;
  margin: 0 auto 52px;
  display: block;
}

.login-popup__logo {
  width: 135px;
  height: 54px;
  margin: 0 auto 32px;
}

.is-frame .login-popup__logo {
  display: none;
}

.login-popup__logo svg {
  width: 100%;
  height: 100%;
}

.login-popup__nav {
  grid-column-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.is-frame .login-popup__nav {
  display: none;
}

.login-popup__nav-btn {
  color: #aa69ac;
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 30px;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  transition: background-color .2s;
  display: flex;
  box-shadow: inset 0 0 0 1px #aa69ac;
}

.login-popup__nav-btn:hover, .login-popup__nav-btn.is-active {
  color: #fff;
  background-color: #aa69ac;
}

.login-popup__nav-icon {
  background-image: var(--icon-bank-id);
  filter: invert(53%) sepia(8%) saturate(2428%) hue-rotate(250deg) brightness(91%) contrast(92%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 21px;
  height: 20px;
  margin-right: 8px;
}

.login-popup__title {
  color: #66554f;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px solid #3f3f3f33;
  margin-top: 20px;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  overflow: hidden;
}

.login-popup__link {
  color: #3e3e3e;
  text-align: center;
  background-color: #0000;
  border: none;
  padding: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-decoration: underline;
}

.login-popup__link.has-inherit {
  line-height: inherit;
}

.login-popup__link:hover {
  color: #ab69ad;
}

.login-popup__question {
  text-align: center;
  color: #3e3e3e;
  margin-top: 20px;
  font-size: 12px;
  line-height: normal;
}

.login-popup__reg {
  text-align: center;
  margin-top: 9px;
}

.login-popup__reg:first-letter {
  text-transform: capitalize;
}

.login-popup__ec-promo {
  background-color: #ffc051;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin: 20px -30px -40px;
  padding: 20px 30px 40px;
}

.login-popup__ec-promo-text {
  color: #3e3e3e;
  text-align: center;
  margin-bottom: 15px;
  font-size: 12px;
  line-height: 1.2;
}

.login-popup__secure-block {
  margin-top: auto;
}

@media only screen and (width <= 539px) {
  .login-popup__ec-promo {
    border-radius: 0;
    margin: 20px -15px -40px;
    padding: 20px 15px 40px;
  }
}

.popup-acc-stat {
  position: relative;
}

.popup-acc-stat__title {
  max-width: 200px;
}

.popup-acc-stat__logo {
  width: 68px;
  height: 28px;
  margin: 0 auto 20px;
}

.popup-acc-stat__logo svg {
  width: 100%;
  height: 100%;
}

.popup-acc-stat__item {
  color: #3e3e3e;
  border-bottom: 1px solid #3f3f3f33;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 10px;
  display: flex;
}

.popup-acc-stat__item:last-child {
  border-bottom: none;
  margin-bottom: 10px;
}

.popup-acc-stat__item-name {
  margin: 0;
}

.popup-acc-stat__value {
  font-weight: bold;
}

.popup-acc-stat__button {
  grid-gap: 12px;
  text-align: center;
  display: grid;
}

.self-control__section {
  border: 1px solid #ffa800;
  border-radius: 10px;
  padding: 24px 28px;
}

.self-control p, .self-control h2 {
  margin: 0;
}

.self-control__title {
  color: #3f3f3f;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.self-control__text {
  color: #3f3f3f;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.self-control__list {
  counter-reset: item;
  text-align: left;
  margin: auto;
  padding: 0;
  list-style-type: none;
}

.self-control__list-item {
  counter-increment: item;
  color: #3f3f3f;
  margin: 0;
  padding-bottom: 12px;
  padding-left: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  position: relative;
}

.self-control__list-item:last-of-type {
  padding-bottom: 0;
}

.self-control__list-item:before {
  content: counter(item) ".";
  width: 20px;
  padding-right: 4px;
  font-weight: bold;
  position: absolute;
  left: 0;
}

.self-control__wrap {
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
  column-gap: 20px;
  margin-top: 20px;
  display: grid;
}

.slider-games {
  margin-bottom: 80px;
  padding-top: 29px;
  padding-left: 40px;
  display: block;
  position: relative;
}

.slider-games:before, .slider-games:after {
  content: "";
  width: 20px;
  height: 100%;
  position: absolute;
  top: 0;
}

.slider-games:before {
  background: linear-gradient(90deg, #ea4642, #0000);
  left: 20px;
}

.slider-games:after {
  background: linear-gradient(-90deg, #ea4642, #0000);
  right: 0;
}

.slider-games__block {
  position: relative;
}

.slider-games__title {
  align-items: baseline;
  max-width: calc(100% - 550px);
  padding-bottom: 25px;
  padding-left: 10px;
  display: flex;
}

.slider-games__span {
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 10px;
  font-size: 34px;
  font-weight: 500;
  line-height: 40px;
  overflow: hidden;
}

.slider-games__link {
  color: #f9fbe7;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 25px;
  text-decoration-line: underline;
  overflow: hidden;
}

.slider-games__img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  width: 188px;
  height: 282px;
  box-shadow: 0 4px 5px #00000026;
}

.slider-games__btn {
  z-index: 3;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: absolute;
  top: -65px;
}

.slider-games__btn:hover, .slider-games__btn:active {
  background: #ea4642;
  border: 1px solid #fff;
}

.slider-games__btn_prev {
  right: 95px;
}

.slider-games__btn_next {
  right: 40px;
  transform: rotate(180deg);
}

.slider-games .icon-icon-arr-right__before:before {
  content: "";
  filter: invert(54%) sepia(84%) saturate(4607%) hue-rotate(335deg) brightness(95%) contrast(93%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 14px;
  width: 20px;
  height: 40px;
  display: block;
  position: absolute;
  top: 0;
  right: 10px;
}

.slider-games .icon-icon-arr-right__before:hover:before, .slider-games .icon-icon-arr-right__before:active:before {
  filter: none;
}

.slider-games .slick-slide {
  margin: 0 5px;
}

.slider-games .slick-list {
  margin-right: -5px;
}

.slider-games__roullette-image {
  text-align: right;
  z-index: 2;
  max-width: 901px;
  height: 430px;
  margin-left: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: -175px;
  overflow: hidden;
}

.slider-games__roullette-image img {
  max-width: unset;
  animation: 60s linear infinite rouletteRotate;
  position: absolute;
  right: 0;
}

.slider-games__item {
  margin: 0;
  padding-bottom: 24px;
}

@media (width >= 1920px) {
  .slider-games__roullette-image {
    right: 0;
  }

  .slider-games__title {
    max-width: calc(100% - 750px);
    padding-bottom: 25px;
  }
}

@media screen and (width <= 1439px) {
  .slider-games {
    margin-bottom: 70px;
    padding-left: 20px;
  }

  .slider-games:before {
    left: 0;
  }

  .slider-games__roullette-image {
    right: -390px;
  }

  .slider-games__title {
    max-width: calc(100% - 360px);
    padding-left: 12px;
  }
}

@media screen and (width <= 1239px) {
  .slider-games {
    margin-bottom: 60px;
  }

  .slider-games__roullette-image {
    right: -50%;
  }

  .slider-games__title {
    max-width: calc(100% - 255px);
  }
}

@media screen and (width <= 1023px) {
  .slider-games {
    margin-bottom: 38px;
    padding-top: 16px;
    padding-left: 15px;
    overflow: hidden;
  }

  .slider-games:before, .slider-games:after {
    width: 15px;
  }

  .slider-games__item {
    padding-bottom: 15px;
  }

  .slider-games__img {
    width: 140px;
    height: 210px;
  }

  .slider-games__title {
    padding-bottom: 12px;
    padding-left: 5px;
  }

  .slider-games__span {
    font-size: 24px;
    line-height: 28px;
  }

  .slider-games .slick-list {
    min-height: 210px;
  }

  .slider-games__roullette-image {
    max-width: 550px;
    height: 550px;
    bottom: -289px;
    right: -156px;
  }

  .slider-games__roullette-image img {
    height: 100%;
  }
}

@media screen and (width <= 767px) {
  .slider-games__roullette-image {
    right: -380px;
  }

  .slider-games__title {
    max-width: calc(100% - 35px);
  }
}

@media only screen and (width <= 539px) {
  .slider-games {
    margin-bottom: 25px;
  }

  .slider-games__roullette-image {
    display: none;
  }

  .slider-games__title {
    max-width: 100%;
    padding-left: 0;
    padding-right: 15px;
  }
}

.widget-bigwins {
  z-index: 1;
  width: 50%;
  overflow: hidden;
}

.widget-bigwins__price {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 14px;
  font-size: 32px;
  font-weight: bold;
  line-height: 37px;
  overflow: hidden;
}

.widget-bigwins_month .widget-bigwins__price {
  color: #9846a5;
}

.widget-bigwins_day .widget-bigwins__price {
  color: #00be6e;
}

.widget-bigwins__text {
  font-size: 12px;
  line-height: 14px;
}

.widget-bigwins__game-link {
  font-weight: bold;
}

.widget-bigwins__bg {
  z-index: -1;
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 483px 260px;
  border-radius: 10px;
  width: calc(50% + 20px);
  height: 260px;
  position: absolute;
  top: 0;
  left: 0;
}

.widget-bigwins[type="'day'"] .widget-bigwins__bg {
  background-image: url("/joxi/yoyo/winners/win-green-bg.png");
}

.widget-bigwins[type="'month'"] .widget-bigwins__bg {
  background-image: url("/joxi/yoyo/winners/win-violet-bg.png");
}

.widget-bigwins__img.game-item {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 188px 282px;
  border-radius: 10px;
  width: 188px;
  height: 282px;
  margin: 0 0 0 5%;
  position: absolute;
  top: -40px;
  left: 0;
}

.widget-bigwins__img.game-item .game-item__hoverplay {
  border-radius: 10px;
  width: 188px;
  height: 282px;
}

@media (width >= 1920px) {
  .widget-bigwins__bg {
    background-size: cover;
  }

  .widget-bigwins__img.game-item {
    margin-left: 8%;
  }
}

@media screen and (width <= 1439px) {
  .widget-bigwins {
    width: calc(50% - 20px);
  }

  .widget-bigwins__price {
    margin-bottom: 18px;
    font-size: 24px;
    line-height: 28px;
  }
}

@media screen and (width <= 1023px) {
  .widget-bigwins__price {
    margin-bottom: 19px;
    font-size: 18px;
    line-height: 21px;
  }

  .widget-bigwins__img.game-item {
    background-size: 140px 210px;
    width: 140px;
    height: 210px;
    top: -18px;
  }

  .widget-bigwins__img.game-item .game-item__hoverplay {
    width: 140px;
    height: 210px;
  }

  .widget-bigwins__bg {
    height: 210px;
  }
}

@media screen and (width <= 767px) {
  .widget-bigwins {
    width: 29%;
  }

  .widget-bigwins__bg {
    width: 70%;
  }
}

@media only screen and (width <= 539px) {
  .widget-bigwins {
    width: calc(100% - 150px);
  }

  .widget-bigwins__price {
    margin-bottom: 12px;
    font-size: 14px;
  }

  .widget-bigwins__bg {
    display: none;
  }

  .widget-bigwins__img.game-item {
    width: 140px;
    margin-left: 0;
    top: -5px;
  }
}

.missed-data {
  background-image: url("/joxi/yoyo/bg1920.jpg");
  background-size: cover;
  min-height: 100%;
  padding-bottom: 30px;
  display: block;
}

.missed-data .header__logo {
  width: 120px;
  height: 49px;
  display: block;
}

.missed-data__header {
  margin-bottom: 72px;
  padding-top: 30px;
}

.missed-data__section {
  background: #f9fbe7;
  border-radius: 12px;
  width: 640px;
  margin: 0 auto;
  padding: 30px 12px 0 34px;
}

.missed-data__content .has-terms {
  padding: 20px 0 16px;
}

.missed-data__wrapper {
  --scroll-width: 6px;
  height: 417px;
  padding-right: calc(24px - var(--scroll-width));
  overflow: hidden auto;
}

.missed-data__wrapper::-webkit-scrollbar {
  width: var(--scroll-width);
}

.missed-data__wrapper::-webkit-scrollbar-track {
  background-color: #fff;
}

.missed-data__wrapper::-webkit-scrollbar-thumb {
  background-color: #fbce7e;
}

.missed-data__wrapper h1:not(.missed-data__title) {
  display: none;
}

.missed-data__wrapper .info-page__container {
  padding: 0;
}

.missed-data__wrapper.is-small {
  height: 251px;
}

.missed-data__title {
  color: #686b6c;
  text-align: center;
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
}

.missed-data__form-wrapper {
  z-index: 100;
  margin: 0 -12px 0 -34px;
  position: relative;
}

.has-terms .missed-data__form-wrapper {
  box-shadow: 0 -2px 3px #00000026;
}

.missed-data__form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 9px 40px 24px;
  display: flex;
}

.missed-data__check {
  margin-top: 15px;
  margin-bottom: 15px;
}

.missed-data__check .g-formbox-item, .missed-data__check .g-check-label, .missed-data__check .g-check-text {
  width: auto;
}

.missed-data__check .g-check + .g-check-label {
  flex-direction: column;
  justify-content: center;
}

.missed-data__check .g-check-label__wrap {
  align-items: center;
  display: flex;
}

.missed-data__check .g-form-error_checkbox .g-form-error__text .g-required {
  text-align: center;
}

.missed-data .missed-terms .info-page__wrap {
  padding-top: 0;
  padding-bottom: 0;
}

.missed-data .missed-terms h1, .missed-data .missed-terms h2, .missed-data .missed-terms h3, .missed-data .missed-terms p {
  margin: 0 0 15px;
  font-size: 12px;
  line-height: 16px;
}

.missed-data .missed-terms h1 span, .missed-data .missed-terms h2 span, .missed-data .missed-terms h3 span, .missed-data .missed-terms p span {
  font-weight: bold;
}

.missed-data__btn {
  color: #fff;
  text-transform: capitalize;
  cursor: pointer;
  background-color: #35d290;
  border: none;
  border-radius: 80px;
  width: 151px;
  height: 40px;
  margin: 0 5px;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
}

.missed-data__btn:disabled {
  color: #fff6;
  cursor: not-allowed;
  background-color: #a4a4a4;
}

.missed-data__btn:disabled:hover {
  color: #fff6;
  background-color: #a4a4a4;
}

.missed-data__btn:hover {
  color: #fff;
  background-color: #2eb47b;
}

.missed-data__cancel {
  cursor: pointer;
  background: none;
  border: none;
  width: 151px;
  margin: 0 5px;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
}

.missed-data__cancel:hover {
  text-decoration-line: underline;
}

.missed-data .has-margin-top-16 {
  margin-top: 16px;
}

.missed-data__limit-btn {
  cursor: pointer;
  background: none;
  border: none;
  margin-top: 20px;
}

.missed-data-cancellation {
  background-color: #3e3e3ecc;
  min-height: 100%;
  display: flex;
}

.missed-data-cancellation__content {
  background-color: #f9fbe7;
  border-radius: 10px;
  max-width: 350px;
  margin: auto;
  padding: 40px;
}

.missed-data-cancellation__content-text {
  color: #686b6c;
  margin: 0 0 50px;
  font-size: 19px;
  line-height: 23px;
}

.missed-data-cancellation__btns {
  display: flex;
}

.missed-data-cancellation__accept {
  margin-right: 24px;
}

.sidemenu-promo {
  margin: 0 16px 24px;
  position: relative;
}

.sidemenu-promo__mill {
  width: 100%;
  height: 100%;
  filter: box-shadow(0px 14px 35px 0px #21014a 51%);
  z-index: 1;
  background-image: linear-gradient(150deg, #a745b0 0, #700e66 100%);
  border-radius: 15px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  box-shadow: 0 3px 9px #290b2863;
}

.sidemenu-promo__mill:before {
  content: "";
  z-index: 2;
  transform-origin: center;
  background-image: url("/joxi/sweden-yoyo/bankid-spinner.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 600px;
  height: 600px;
  animation-name: rotate;
  animation-duration: 60s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  top: calc(50% - 230px);
  left: calc(50% - 300px);
}

.sidemenu-promo__content {
  z-index: 10;
  padding: 90px 12px 40px;
  position: relative;
}

.sidemenu-promo__content:before {
  content: "";
  background-image: url("/joxi/yoyo/bird_left.png"), url("/joxi/yoyo/fish_right.png");
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat, no-repeat;
  background-size: 120px 120px, 120px 120px;
  width: 210px;
  height: 120px;
  display: block;
  position: absolute;
  top: -32px;
  left: calc(50% - 105px);
}

.sidemenu-promo__title {
  text-align: center;
  text-transform: capitalize;
  color: #fff9;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.sidemenu-promo__text {
  color: #fff;
  text-align: center;
  overflow-wrap: break-word;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  max-width: 202px;
  margin: 0 auto 8px;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  display: -webkit-box;
  overflow: hidden;
}

.sidemenu-promo__text.is-big {
  max-width: 100%;
  margin-bottom: 0;
  font-size: 32px;
  font-weight: 900;
  line-height: 36px;
}

.sidemenu-promo__btns {
  width: 100%;
  margin-top: 16px;
}

.sidemenu-promo__link {
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  text-decoration: underline;
  display: block;
}

.sidemenu-promo__link:hover {
  opacity: .8;
}

.app-page {
  background-image: url("/joxi/yoyo/fish_right.png"), url("/joxi/yoyo/mex.png"), url("/joxi/yoyo/horse_left.png"), url("/joxi/yoyo/conffeti.png"), url("/joxi/yoyo/conffeti.png");
  background-position: 28px 113px, 47px 401px, right 32px top 139px, right -169px top 63px, -220px -46px;
  background-repeat: no-repeat;
  background-size: 187px 199px, 254px 248px, 259px, 503px 386px, 503px 386px;
  position: relative;
}

.app-page:before {
  content: "";
  z-index: -1;
  background-image: url("/img/app-blob.svg"), linear-gradient(#ffeaa1 0, #ffc600 100%);
  background-position: top;
  background-repeat: no-repeat;
  background-size: auto 100%, cover;
  width: 100%;
  height: 529px;
  position: absolute;
  top: 0;
  left: 0;
  mask-image: url("/img/app-mask.svg");
  mask-position: bottom;
  mask-size: cover;
  mask-repeat: no-repeat;
}

.app-page__banner {
  text-align: center;
  background-image: url("/joxi/yoyo/application/phones_1440.png"), url("/joxi/yoyo/conffeti.png");
  background-position: bottom, center bottom 150px;
  background-repeat: no-repeat;
  background-size: auto 704px, 508px;
  width: 727px;
  margin: 0 auto;
  padding-top: 59px;
  padding-bottom: 704px;
  position: relative;
}

.app-page__content {
  position: relative;
}

.app-page__content:before {
  content: "";
  z-index: -1;
  background-image: url("/img/app-vector-green.svg"), url("/joxi/yoyo/conffeti.png");
  background-position: -64px 0, 100% 100%;
  background-repeat: no-repeat;
  background-size: contain, 484px 370px;
  width: 187px;
  height: 472px;
  display: block;
  position: absolute;
  top: 98px;
  left: 0;
}

.app-page__content:after {
  content: "";
  z-index: -1;
  background-image: url("/img/app-vector-yellow.svg");
  background-position: right -105px center;
  background-repeat: no-repeat;
  width: 300px;
  height: 300px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-300px);
}

.app-page__wrap {
  text-align: center;
  width: 696px;
  margin: 0 auto 47px;
}

.app-page__title {
  color: #9746a5;
  margin-top: 0;
  font-size: 32px;
  font-weight: 900;
  line-height: 37px;
}

.app-page__title.is-adaptive {
  margin-bottom: 30px;
}

.app-page__text {
  color: #3f3f3f;
  margin-top: 0;
  margin-bottom: 25px;
  font-size: 14px;
  line-height: 24px;
}

.app-page__qr-code {
  background-image: url("/joxi/yoyo/qr.png");
  background-size: cover;
  width: 87px;
  height: 87px;
  margin: 0 auto;
}

.app-page__promo {
  background-image: url("/joxi/yoyo/application/phones-1440.png"), url("/joxi/yoyo/conffeti.png"), url("/joxi/yoyo/candy_menu.svg"), linear-gradient(149.69deg, #a745b0 9%, #700e66 92%);
  background-position: right -10px top 25px, right top 28px, top, center;
  background-repeat: no-repeat;
  background-size: 516px, 480px, cover, cover;
  border-radius: 14px;
  width: 983px;
  min-height: 484px;
  margin: 0 auto 60px;
  padding: 50px 480px 50px 50px;
  position: relative;
  box-shadow: 0 3px 8px #290b2863;
}

.app-page__promo:before {
  content: "";
  background-image: url("/joxi/yoyo/bird_left.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 219px;
  height: 193px;
  position: absolute;
  top: 0;
  right: 27px;
  transform: translateY(-110px);
}

.app-page__promo-title {
  color: #fff;
  margin-top: 0;
  margin-bottom: 37px;
  font-size: 32px;
  font-weight: 900;
  line-height: 1.2;
}

.app-page__promo-list {
  padding-left: 27px;
  list-style: none;
  position: relative;
}

.app-page__promo-item {
  text-align: justify;
  color: #fff;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 25px;
}

.app-page__promo-item:before {
  content: "";
  background-color: #01c02a;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  display: inline-block;
  position: absolute;
  left: 0;
  transform: translateY(7px);
}

.app-page__footer {
  text-align: center;
  width: 983px;
  margin: 0 auto 49px;
}

.app-page__footer .app-page__text {
  margin-bottom: 0;
}

@media screen and (width >= 1440px) {
  .app-page {
    background-position: 75px 85px, 153px 314px, right 131px top 43px, 100% 0, -109px 0;
    background-size: 187px 199px, 254px 248px, 259px, 574px 438px, 574px 438px;
  }

  .app-page:before {
    height: 656px;
  }

  .app-page__wrap {
    width: 1129px;
    margin: 0 auto 98px;
  }

  .app-page__content:before {
    background-size: 249px 313px, 617px 472px;
    width: 395px;
    height: 472px;
    top: 150px;
  }

  .app-page__content:after {
    background-position: 100% 0;
  }

  .app-page__title.is-adaptive {
    margin-bottom: 40px;
  }

  .app-page__banner {
    padding-top: 49px;
  }

  .app-page__footer {
    margin: 0 auto 65px;
  }
}

@media screen and (width >= 1920px) {
  .app-page {
    background-position: 154px 102px, 290px 403px, right 157px top 90px, right -32px top 57px, -35px 0;
    background-size: 219px 233px, 268px 262px, 333px, 650px 509px, 650px 509px;
  }

  .app-page__content:after {
    transform: translateY(-180px);
  }

  .app-page__banner {
    background-size: auto 704px, 0;
    padding-top: 98px;
  }

  .app-page__wrap {
    margin: 0 auto 64px;
  }

  .app-page__footer {
    margin: 0 auto 26px;
  }
}

.search-grid {
  grid-gap: 12px 6px;
  grid-template-columns: repeat(4, 104px);
  display: grid;
}

@media (width >= 1440px) {
  .search-grid {
    grid-gap: 16px 8px;
    grid-template-columns: repeat(6, 170px);
    margin-top: 12px;
  }
}

.search-cols {
  grid-gap: 6px;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

@media (width >= 1440px) {
  .search-cols {
    grid-gap: 8px;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 12px;
  }
}

.search-results__text {
  color: #3f3f3f99;
  margin-top: 18px;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}

.search-results__text strong {
  color: #9746a5;
  font-weight: 500;
}

.search-results__category-block {
  margin-top: 18px;
}

@media (width >= 1440px) {
  .search-results__text {
    margin-top: 24px;
    font-size: 18px;
  }

  .search-results__category-block {
    margin-top: 24px;
  }
}

.search-categories {
  min-height: 300px;
}

.search-categories .preloader {
  background-color: #0000;
}

.search-categories__item {
  margin-top: 16px;
}

@media (width >= 1440px) {
  .search-categories__item {
    margin-top: 24px;
  }
}

.search-popup {
  --offset-x: 24px;
  padding: 0 var(--offset-x) 32px;
}

.search-popup__input {
  margin-top: -3px;
  margin-bottom: 14px;
  display: block;
}

@media (width >= 1440px) {
  .search-popup {
    --offset-x: 70px;
  }

  .search-popup__input {
    margin-bottom: 22px;
  }
}

.info-self-control p, .info-self-control h2 {
  margin: 0;
}

.info-self-control p + p, .info-self-control li + li, .info-self-control ul {
  padding-top: 10px;
}

.info-self-control ul {
  padding-bottom: 10px;
}

.info-self-control section {
  border: 1px solid #ffa800;
  border-radius: 10px;
  margin-bottom: 24px;
  padding: 24px 28px;
}

.info-self-control a {
  color: #9746a5;
  font-weight: bold;
  text-decoration: underline;
}

.info-self-control a:hover {
  text-decoration: none;
}

.info-self-control h2 {
  color: #3f3f3f;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.info-self-control p, .info-self-control li {
  color: #3f3f3f;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

@media (width <= 768px) {
  .verification-popup {
    max-width: 100%;
    height: 100%;
    min-height: 100vh;
    max-height: none;
    padding: 10px 20px 100px;
    position: fixed;
    inset: 0;
    overflow-y: auto;
    transform: translate(0);
  }
}

@media (width <= 950px) and (orientation: landscape) {
  .verification-popup {
    max-width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 10px 20px 100px;
    position: fixed;
    inset: 0;
    overflow-y: auto;
    transform: translate(0);
  }
}

@media (width <= 768px) {
  .verification-popup__close-btn {
    inset-inline-end: 15px;
    top: 10px;
  }

  .verification-popup__title {
    padding-block: 0 20px;
    padding-inline: 0 25px;
    font-size: 24px;
    line-height: 28px;
  }

  .verification-popup__description p {
    margin-bottom: 4px;
  }

  .verification-popup__description p:last-child {
    margin-bottom: 0;
  }

  .verification-popup .onfido-sdk-ui-Modal-inner {
    width: calc(100% - 40px);
    height: 37.5em;
  }
}

@media (width <= 950px) and (orientation: landscape) {
  .verification-popup .onfido-sdk-ui-Modal-inner {
    width: calc(100% - 40px);
    height: 37.5em;
  }
}

.document-item {
  border: 1px solid #00c853;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 12px;
  padding: 12px;
  display: flex;
}

.document-item.has-error {
  border-color: #e66a87;
}

.document-item__ico {
  background-image: url("/joxi/dvs/i-document.svg");
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
  width: 32px;
  height: 48px;
  margin-right: 12px;
}

.document-item__info {
  flex: auto;
  width: calc(100% - 88px);
}

.document-item__title {
  color: #524e72;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 95%;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
}

.has-error .document-item__title {
  color: #f44336;
}

.document-item__size {
  color: #8c87b3;
  font-size: 14px;
  line-height: 20px;
}

.has-error .document-item__size {
  color: #f44336;
}

.document-item__close {
  cursor: pointer;
  background-image: url("/joxi/dvs/i-close-rounded.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.document-item__errors {
  color: #f44336;
  flex: 0 100%;
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
}

.document-item .has-error {
  color: #f44336;
  border: 1px solid #e66a87;
}

.drag-and-drop {
  text-align: center;
  background-color: #8c87b314;
  border: 1px dashed #8c87b3;
  margin: 12px 0;
  padding: 32px 0;
}

body.rtl-lang .drag-and-drop * {
  text-align: center;
}

.drag-and-drop__icon {
  background-image: url("/joxi/dvs/i-upload.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 48px;
  margin-bottom: 8px;
}

.drag-and-drop__title {
  color: #524e72;
  margin-bottom: 8px;
  font-size: 28px;
  line-height: 28px;
}

.drag-and-drop__label {
  color: #8c87b3;
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 24px;
}

.drag-and-drop__label label {
  color: #3586ff;
  cursor: pointer;
  text-decoration: underline;
}

.drag-and-drop__file-types {
  color: #8c87b3;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
}

.drag-and-drop.dragging {
  background: #eee;
}

.drag-and-drop.error {
  background: #f4433614;
}

.drag-and-drop.is-disabled {
  opacity: .4;
  pointer-events: none;
}

.verification-btn_survey, .verification-btn_doc {
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  text-align: center;
  background: #3586ff;
  border: none;
  border-radius: 30px;
  min-width: 180px;
  margin-top: 12px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  transition: box-shadow .3s;
}

.verification-btn_survey:hover, .verification-btn_doc:hover {
  box-shadow: 0 6px 8px #1e88e566;
}

.verification-btn_survey:focus, .verification-btn_doc:focus {
  background: #8ec3f1;
}

.verification-btn_again {
  box-shadow: none;
  cursor: pointer;
  color: #3586ff;
  text-align: center;
  background: #fff;
  border: 2px solid #8c87b333;
  border-radius: 30px;
  min-width: 86px;
  height: 40px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 600;
  transition: background-color .3s;
}

.verification-btn_again:hover {
  background: #8c87b31a;
}

.verification-btn_again:focus {
  background: #8c87b366;
}

.verification-btn:disabled {
  pointer-events: none;
  filter: grayscale(.5);
  cursor: initial;
}

.verification-content {
  background: #fff;
  border-bottom: 1px solid #8c87b333;
  padding: 20px 20px 20px 88px;
  font-size: 16px;
  position: relative;
}

.verification-content.has-no-status:after {
  content: none;
}

body.rtl-lang .verification-content {
  direction: rtl;
  padding: 20px 88px 20px 20px;
}

body.rtl-lang .verification-content:before {
  left: 0;
  right: 22px;
}

body.rtl-lang .verification-content:after {
  left: 0;
  right: 56px;
}

.verification-content:last-child {
  border-bottom: none;
}

.verification-content:before {
  content: "";
  background: #faf9ff 50% no-repeat;
  border: 1px solid #eeecff;
  width: 48px;
  height: 48px;
  display: inline-block;
  position: absolute;
  top: 18px;
  left: 22px;
}

.verification-content:after {
  content: "";
  background: #fff 0 0 / cover no-repeat;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 56px;
}

.verification-content__title {
  color: #1f2126;
  padding-bottom: 6px;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.verification-content__text {
  color: #524e72;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.verification-content__status {
  padding: 0 0 12px;
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  line-height: 20px;
}

.verification-content__details ul {
  padding: 0 0 3px 24px;
  list-style-type: disc;
}

.verification-content__details ul li {
  padding: 0 0 8px;
}

.verification-content__details p {
  padding: 0 0 6px;
}

.verification-content__comment {
  overflow-wrap: anywhere;
  background: #fbc02d14;
  border: 1px solid #fbc02d80;
  flex-direction: column;
  margin: 0 0 12px;
  padding: 10px 16px;
  font-style: italic;
  display: flex;
}

.verification-content__comment strong {
  padding-right: 6px;
  font-weight: 600;
}

.verification-content__comment ol {
  padding-left: 15px;
}

.verification-content__comment ul {
  padding-left: 15px;
  list-style: disc;
}

.verification-content_proof_of_identity:before {
  background-image: url("/joxi/dvs/i-identity-document.svg");
}

.verification-content_proof_of_address:before {
  background-image: url("/joxi/dvs/i-proof-address.svg");
}

.verification-content_card_photo:before {
  background-image: url("/joxi/dvs/i-card-photo.svg");
}

.verification-content_proof_of_bank_account_owner:before {
  background-image: url("/joxi/dvs/i-proof-bank.svg");
}

.verification-content_e_wallet_ownership:before {
  background-image: url("/joxi/dvs/i-proof-wallet.svg");
}

.verification-content_transaction_history:before {
  background-image: url("/joxi/dvs/i-transaction-history.svg");
}

.verification-content_selfie_photo:before, .verification-content_additional:before {
  background-image: url("/joxi/dvs/i-selfie-photo.svg");
}

.verification-content_proof_of_wealth:before {
  background-image: url("/joxi/dvs/i-proof-wealth.svg");
}

.verification-content_proof_wallet:before {
  background-image: url("/joxi/dvs/i-proof-wallet.svg");
}

.verification-content_not_requested:after, .verification-content_not-uploaded:after {
  background-image: url("/joxi/dvs/i-status-not-verified.svg");
}

.verification-content_not_requested .verification-content__status, .verification-content_not-uploaded .verification-content__status {
  color: #55c9ff;
}

.verification-content_requested:after {
  background-image: url("/joxi/dvs/i-status-requires.svg");
}

.verification-content_requested .verification-content__status {
  color: #fbc02d;
}

.verification-content_declined:after {
  background-image: url("/joxi/dvs/i-status-reload.svg");
  background-position: -3px -3px;
  background-size: 28px;
}

.verification-content_declined .verification-content__status {
  color: #fbc02d;
}

.verification-content_pending:after {
  background-image: url("/joxi/dvs/i-status-pending.svg");
}

.verification-content_pending .verification-content__status {
  color: #ffa000;
}

.verification-content_approved:after {
  background-image: url("/joxi/dvs/i-status-verified.svg");
}

.verification-content_approved .verification-content__status {
  color: #00c853;
}

.verification-content_survey:before, .verification-content_survey.is-responsible_gambling:before {
  background-image: url("/joxi/dvs/i-survey-rg.svg");
}

.verification-content_survey.is-anti_money_laundering:before {
  background-image: url("/joxi/dvs/i-survey-aml.svg");
}

.verification-content__pending-description {
  margin-bottom: 12px;
}

dvs-page, survey-list {
  color: #000;
  font-family: Roboto Condensed, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.verification-content-wrap {
  background-color: #fff;
  padding: 20px;
}

.verification-content-wrap .verification-content-status:first-child .verification-status {
  margin-top: 0;
}

dvs-popup {
  color: #000;
  font-family: Roboto Condensed, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.verification-popup {
  background: #fff;
  width: 100%;
  max-width: 800px;
  height: auto;
  max-height: 90%;
  padding: 30px 40px 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  box-shadow: 0 6px 16px #250e3d1a;
}

@media (width <= 768px) {
  .verification-popup {
    max-width: 100%;
    height: 100%;
    min-height: 100vh;
    max-height: none;
    padding: 10px 20px 100px;
    position: fixed;
    inset: 0;
    overflow-y: auto;
    transform: translate(0);
  }
}

@media (width <= 950px) and (orientation: landscape) {
  .verification-popup {
    max-width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 10px 20px 100px;
    position: fixed;
    inset: 0;
    overflow-y: auto;
    transform: translate(0);
  }
}

.verification-popup__close-btn {
  cursor: pointer;
  inset-inline-end: 40px;
  background-color: #0000;
  background-image: url("/joxi/dvs/i-close-rounded.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  border: none;
  width: 28px;
  height: 28px;
  transition: opacity .3s;
  position: absolute;
  top: 30px;
}

.verification-popup__close-btn:hover {
  opacity: .6;
}

@media (width <= 768px) {
  .verification-popup__close-btn {
    inset-inline-end: 15px;
    top: 10px;
  }
}

.verification-popup__title {
  text-align: center;
  padding: 0 40px 20px;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
}

@media (width <= 768px) {
  .verification-popup__title {
    padding-block: 0 20px;
    padding-inline: 0 25px;
    font-size: 24px;
    line-height: 28px;
  }
}

body.rtl-lang .verification-popup__title {
  text-align: center;
}

.verification-popup__frame {
  margin: 0 auto;
}

.verification-popup__frame.is-kreditz {
  padding: 30px;
}

.verification-popup__description {
  color: #524e72;
}

.verification-popup__description ul {
  margin-bottom: 10px;
  padding-left: 20px;
  list-style-type: disc;
}

.verification-popup__description p {
  margin-bottom: 12px;
  line-height: 20px;
}

@media (width <= 768px) {
  .verification-popup__description p {
    margin-bottom: 4px;
  }

  .verification-popup__description p:last-child {
    margin-bottom: 0;
  }
}

.verification-popup__description li {
  margin-bottom: 4px;
  line-height: 20px;
}

.verification-popup__btn-wrap {
  text-align: center;
}

.verification-popup__error {
  color: #524e72;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #f4433614;
  border: 1px solid #f4433680;
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  padding: 0 16px;
  font-size: 16px;
  font-style: italic;
  line-height: 40px;
  overflow: hidden;
}

@media (width <= 768px) {
  .verification-popup .onfido-sdk-ui-Modal-inner {
    width: calc(100% - 40px);
    height: 37.5em;
  }
}

@media (width <= 950px) and (orientation: landscape) {
  .verification-popup .onfido-sdk-ui-Modal-inner {
    width: calc(100% - 40px);
    height: 37.5em;
  }
}

.verification-popup-wrap.dvs-preloader {
  z-index: 900;
  position: relative;
}

.verification-popup-wrap.dvs-preloader .verification-popup__form {
  pointer-events: none;
}

.verification-popup-wrap.dvs-preloader:after {
  content: "";
  z-index: 900;
  pointer-events: none;
  background: #fff9;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  inset: 0;
}

.verification-status {
  background: #fff 14px no-repeat;
  border: 10px solid #0000;
  align-items: center;
  min-height: 88px;
  margin-top: 16px;
  padding-left: 80px;
  font-size: 22px;
  font-weight: bold;
  line-height: 26px;
  display: flex;
}

body.rtl-lang .verification-status {
  direction: rtl;
  background-position: calc(100% - 14px);
  padding-right: 80px;
}

.verification-status_not_verified {
  background-image: url("/joxi/dvs/i-status-not-verified.svg");
  border-color: #4fc3f733;
}

.rtl-lang .verification-status_not_verified {
  background-image: url("/joxi/dvs/i-status-not-verified-rtl.svg");
}

.verification-status_failed_verification, .verification-status_requires_verification {
  background-image: url("/joxi/dvs/i-status-requires.svg");
  border-color: #fbc02d33;
}

.verification-status_pending_verification {
  background-image: url("/joxi/dvs/i-status-pending.svg");
  border-color: #ffa00033;
}

.verification-status_verified, .verification-status_none {
  background-image: url("/joxi/dvs/i-status-verified.svg");
  border-color: #00c85333;
}

.verification-title {
  color: #000;
  text-align: center;
  background: #fff;
  padding: 20px 20px 16px;
}

.verification-title__title {
  padding-bottom: 12px;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}

.verification-title__description {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.dvs-alert {
  color: #fff;
  background-color: #da2424;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: none;
  transform: translateZ(0);
}

.dvs-alert:before {
  content: "";
  background: url("/joxi/dvs/verification-status/non-verified.svg") 0 0 / contain no-repeat;
  flex-shrink: 0;
  width: 26px;
  height: 26px;
  margin-right: 12px;
  display: block;
}

.dvs-alert.is-verified {
  background-color: #45b832;
}

.dvs-alert.is-verified:before {
  background: url("/joxi/dvs/verification-status/verified.svg") no-repeat;
}

.dvs-alert.is-verification-changed {
  display: flex;
}

.dvs-alert__text {
  overflow-wrap: break-word;
  line-clamp: 7;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  overflow: hidden;
}

.dvs-alert__link {
  color: #fff;
  padding: 0 4px;
  font-weight: 600;
  text-decoration: underline;
}

.dvs-alert__icon {
  aspect-ratio: 1;
  filter: brightness(0) invert();
  width: 26px;
  margin-right: 12px;
}

.dvs-alert__icon-verified {
  aspect-ratio: 1;
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 26px;
  margin-right: 12px;
}

.dvs-alert__button {
  appearance: none;
  cursor: pointer;
  filter: invert();
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14px;
  border: none;
  border-radius: 0;
  flex: none;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-left: 12px;
  display: flex;
}

.dvs-alert__button.is-arrow {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTYgMTYiICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgPHBhdGggZD0iTTQuNzgzNzggMTUuMzU3QzUuNjY1MzggMTYuMiA2Ljk5NjkzIDE2LjIxNTkgNy44OTYwMSAxNS4zOTQxTDE0LjY4OSA5LjE4NDk0QzE1LjM3MDYgOC41NjE5MiAxNS4zNzA2IDcuNDM4MDggMTQuNjg5IDYuODE1MDZMNy44OTYwMSAwLjYwNTkyQzYuOTk2OTMgLTAuMjE1ODg3IDUuNjY1MzggLTAuMjAwMDEgNC43ODM3OCAwLjY0MzAzMUMzLjcxMzE3IDEuNjY2ODIgMy43NDQwOSAzLjQ2NDQgNC44NDkyIDQuNDQ1NjlMOC43NjE4NiA3LjkxOTk4QzguNzg0MTUgNy45Mzk3NyA4Ljc5NzAzIDcuOTY5MDkgOC43OTcwMyA4QzguNzk3MDMgOC4wMzA5MSA4Ljc4NDE1IDguMDYwMjMgOC43NjE4NiA4LjA4MDAyTDQuODQ5MiAxMS41NTQzQzMuNzQ0MDkgMTIuNTM1NiAzLjcxMzE3IDE0LjMzMzIgNC43ODM3OCAxNS4zNTdaIiAvPgo8L3N2Zz4K");
}

.dvs-alert__button.is-close {
  filter: brightness(0) invert();
  background-image: url("/joxi/dvs/i-close-rounded.svg");
  background-position: center;
  background-repeat: no-repeat;
}

@media (width <= 540px) {
  .dvs-alert__wrap {
    justify-content: space-between;
  }
}

.easter-wrapper {
  box-sizing: border-box;
  background: url("/joxi/easter/2023/elements/stars-bg.svg"), linear-gradient(#2a3d75 0%, #11194a 52.08%, #351d57 100%);
  justify-content: center;
  align-items: center;
  font-family: Jost, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Helvetica Neue, Helvetica, Arial, cursive;
  display: flex;
  overflow: hidden;
}

body.is-anim .easter-slider__item.is-today .easter-box {
  animation: 1.5s linear forwards scaleInOut;
}

body.is-anim .easter-slider__item.is-today .easter-box__not-selected {
  pointer-events: none;
  animation-name: hideBlock;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

body.is-anim .easter-slider__item.is-today .easter-box__selected, body.is-anim .easter-slider__item.is-today .easter-box__requirements {
  pointer-events: all;
  animation-name: showBlock;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

body:not(.is-anim) .easter-slider__item.is-selected-bonus .easter-box__not-selected {
  display: none;
}

body:not(.is-anim) .easter-slider__item.is-selected-bonus .easter-box__selected, body:not(.is-anim) .easter-slider__item.is-selected-bonus .easter-box__requirements {
  opacity: 1;
  pointer-events: all;
  position: relative;
  transform: scale(1);
}

@keyframes scaleInOut {
  from, to {
    opacity: 1;
    transform: scale(1);
  }

  25%, 50%, 75% {
    opacity: 0;
    transform: scale(1.4);
  }
}

@keyframes hideBlock {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    position: absolute;
    transform: scale(0);
  }
}

@keyframes showBlock {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    position: relative;
    transform: scale(1);
  }
}

.easter-box {
  width: 100%;
  max-width: 490px;
  position: relative;
}

.easter-info .easter-box {
  margin: 0 auto;
}

.easter-box .easter-btn {
  white-space: nowrap;
  margin: 16px auto -58px;
}

.easter-box__content {
  width: 100%;
}

.easter-box__inner {
  opacity: .3;
  width: 100%;
  transition: opacity .2s;
}

.easter-box__not-selected, .easter-box__selected {
  width: 100%;
}

.easter-box__selected {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transform: scale(0);
}

.easter-box__num {
  text-align: center;
  color: #fff;
  -webkit-text-stroke: 1px #8d752d;
  text-shadow: 1px 2px #8d752d, -1px 2px #8d752d, 1px 2px #8d752d;
  background-image: url("/joxi/easter/2023/elements/level-number.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: center;
  align-items: flex-start;
  width: 74px;
  height: 77px;
  margin: 10px auto 25px;
  padding-top: 7px;
  font-family: Ubuntu, -apple-system, Segoe UI, Roboto, Oxygen, Cantarell, Fira Sans, Helvetica Neue, Helvetica, Arial, cursive;
  font-size: 44px;
  font-weight: 700;
  line-height: 51px;
  display: flex;
  position: relative;
}

.easter-box__status {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 32px;
  height: 34px;
  position: absolute;
  top: -8px;
  right: -7px;
}

.easter-slider__item.is-yesterday .easter-box__status, .easter-slider__item.is-previous .easter-box__status {
  background-image: url("/joxi/easter/2023/elements/status-success.png");
}

.easter-slider__item.is-next .easter-box__status, .easter-slider__item.is-future .easter-box__status {
  background-image: url("/joxi/easter/2023/elements/status-locked.png");
}

.easter-box__gift {
  width: 224px;
  height: 217px;
  margin: 0 auto;
  position: relative;
}

.easter-box__gift.is-cursor {
  cursor: pointer;
}

.easter-box__gift-img {
  z-index: 1;
  filter: grayscale();
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  position: relative;
}

.easter-info .easter-box__gift-img {
  filter: none;
}

.easter-box__gift-shine {
  pointer-events: none;
  width: 310px;
  max-width: none;
  height: 295px;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.easter-info .easter-box__gift-shine {
  display: block;
}

.easter-box__panel {
  z-index: 2;
  background-color: #bc9c3b;
  border-radius: 22px;
  width: 100%;
  margin-top: -15px;
  padding: 2px 2px 7px;
  position: relative;
}

.easter-box__panel-label {
  text-align: center;
  color: #f8edd9;
  z-index: 2;
  background-image: url("/joxi/easter/2023/elements/label.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 64px;
  padding-top: 11px;
  font-family: Josefin Sans, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Helvetica Neue, Helvetica, Arial, cursive;
  font-size: 24px;
  font-weight: 700;
  line-height: 1em;
  display: flex;
  position: absolute;
  top: -36px;
  left: 50%;
  transform: translateX(-50%);
}

.easter-box__panel-inner {
  background: linear-gradient(#f4d165 0% 100%);
  border-radius: 19px;
  width: 100%;
  padding: 32px 22px 24px;
  position: relative;
}

.easter-box__panel-inner:after {
  content: "";
  pointer-events: none;
  background-image: url("/joxi/easter/2023/elements/shine-panel.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 41px;
  height: 18px;
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
}

.easter-info .easter-box__panel-inner {
  padding-bottom: 45px;
}

.easter-box__panel-countdown {
  margin-top: 16px;
}

.easter-box__panel-title {
  white-space: break-spaces;
  color: #5c225d;
  width: 100%;
  font-size: 24px;
  font-weight: 800;
  line-height: 1.25em;
  display: block;
  text-align: center !important;
}

.easter-box__panel-title + .easter-box__panel-text {
  margin-top: 8px;
}

.easter-box__panel-text {
  color: #5c225d;
  width: 100%;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4em;
  display: block;
  text-align: center !important;
}

.easter-box__panel-info {
  text-align: center;
  color: #5c225d;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.easter-box__row {
  justify-content: space-between;
  width: 100%;
  display: flex;
  position: relative;
}

.easter-box__row-side {
  flex-direction: column;
  justify-content: flex-start;
  width: calc(50% - 12px);
  padding-right: 10px;
  display: flex;
}

.easter-box__row-side:first-child {
  order: 0;
}

.easter-box__row-side:nth-child(2) {
  order: 2;
}

.easter-box__row-side:only-child {
  width: 100%;
}

.easter-box__row-title {
  text-align: center;
  color: #5c225d;
  text-transform: capitalize;
  width: 100%;
  min-height: 20px;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.42em;
}

.easter-box__row-side:only-child .easter-box__row-title {
  min-height: 26px;
  font-size: 24px;
}

.easter-box__row-bonus {
  word-break: break-word;
  text-align: center;
  color: #5c225d;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.42em;
}

.easter-box__row-side:only-child .easter-box__row-bonus {
  font-size: 24px;
  font-weight: 800;
  line-height: 1.2em;
}

.easter-box__row-trigger {
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  display: flex;
}

.easter-box__row-trigger-btn {
  cursor: pointer;
  background-color: #c3c3c3;
  border: none;
  border-radius: 50%;
  outline: none;
  justify-content: center;
  width: 26px;
  height: 26px;
  display: flex;
  position: relative;
}

.easter-box__row-trigger-btn.is-active:after {
  opacity: 1;
  transform: scale(1);
}

.easter-box__row-trigger-btn:before, .easter-box__row-trigger-btn:after {
  content: "";
  display: block;
  position: absolute;
}

.easter-box__row-trigger-btn:before {
  background-color: #fff;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  top: -2px;
  left: -1px;
}

.easter-box__row-trigger-btn:after {
  opacity: 0;
  background-image: url("/joxi/easter/2023/icons/check-circle.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 19px;
  height: 21px;
  transition-property: transform, opacity;
  transition-duration: .2s;
  top: 1px;
  left: 3px;
  transform: scale(0);
}

.easter-box__row-divider {
  text-align: center;
  color: #5c225d66;
  order: 1;
  justify-content: center;
  align-items: center;
  width: 13px;
  height: 41px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.1;
  display: flex;
  position: absolute;
  top: 28px;
  left: 50%;
  transform: translateX(-50%);
}

.easter-box__row-divider span {
  align-items: center;
  height: 14px;
  display: flex;
}

.easter-box__row-divider:before, .easter-box__row-divider:after {
  content: "";
  background-color: #5c225d66;
  width: 1px;
  height: 12px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.easter-box__row-divider:before {
  top: 0;
}

.easter-box__row-divider:after {
  bottom: 0;
}

.easter-box__info {
  background-color: #5a215b;
  border-radius: 0 0 22px 22px;
  width: 397px;
  margin: 0 auto;
  padding: 0 2px 7px;
}

.easter-box__info-inner {
  background-color: #844275;
  border-radius: 0 0 19px 19px;
  width: 100%;
  padding: 37px 11px 17px 10px;
}

.easter-box__requirements {
  opacity: 0;
  pointer-events: none;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  position: absolute;
  transform: scale(0);
}

.easter-box__requirements-item {
  background-color: #5a215b;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 4px 8px;
  display: flex;
}

.easter-box__requirements-item:not(:last-child) {
  margin-bottom: 8px;
}

.easter-box__requirements-item-descr {
  color: #fff9;
  text-align: left;
  margin-right: 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

body.rtl-lang .easter-box__requirements-item-descr {
  margin-right: 0;
}

body.rtl-lang .easter-box__requirements {
  direction: rtl;
}

.easter-box__requirements-item-val {
  color: #fff;
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.easter-box__terms-wrap {
  justify-content: center;
  align-items: center;
  margin-top: auto;
  padding-top: 12px;
  display: flex;
}

.easter-box__terms-trigger {
  cursor: pointer;
  text-align: center;
  color: #fff;
  background-color: #0000;
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  margin: 12px auto 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4em;
  text-decoration-line: underline;
  display: flex;
}

.easter-box__terms-wrap .easter-box__terms-trigger {
  color: #5c225d;
  margin: 0;
}

.easter-slider {
  align-items: center;
  min-width: 1024px;
  display: flex;
  overflow: hidden;
}

.easter-slider__inner {
  box-sizing: border-box;
}

.easter-slider__circle {
  background-color: #f8eeda;
  background-image: url("/joxi/easter/2023/icons/slider-arrow.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14px;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  display: block;
  position: relative;
  top: 0;
  transform: translateY(-2px);
}

.easter-slider__circle:hover {
  background-color: #efdfbf;
  box-shadow: inset 1px 3px 4px #fff, inset 0 -2px 4px #94763a;
}

.easter-slider__circle:active {
  background-color: #efdfbf;
  box-shadow: inset 0 -2px 13px #94763a;
}

.easter-slider__btn {
  cursor: pointer;
  background-color: #c8b389;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 53px;
  padding: 0;
  display: flex;
}

.easter-slider__btn.prev {
  z-index: 100;
  position: absolute;
  left: calc(50% - 315px);
  transform: translateX(-50%)scale(-1, 1);
}

.easter-slider__btn.next {
  z-index: 100;
  position: absolute;
  right: calc(50% - 315px);
  transform: translateX(50%);
}

.easter-slider__btn img {
  background-color: red;
  height: 24px;
}

.easter-slider__item {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 0;
  transition: opacity .2s;
  display: flex;
  position: relative;
}

.easter-slider__item.swiper-slide-active .easter-box__inner {
  opacity: 1;
}

.easter-slider__item.is-today {
  min-height: 630px;
}

.easter-slider__item.is-today .easter-box__gift-img, .easter-slider__item.is-yesterday .easter-box__gift-img, .easter-slider__item.is-previous .easter-box__gift-img {
  filter: none;
}

.easter-slider__item.is-today .easter-box__gift-shine, .easter-slider__item.is-yesterday .easter-box__gift-shine, .easter-slider__item.is-previous .easter-box__gift-shine {
  display: block;
}

.easter-btn {
  cursor: pointer;
  background: #237a2b;
  border: none;
  border-radius: 66px;
  outline: none;
  align-items: flex-start;
  height: 88px;
  padding: 0;
  text-decoration: none;
  display: flex;
  position: relative;
  box-shadow: 0 5px 8px #3232324d;
}

.easter-btn.is-height {
  height: 64px;
}

.easter-btn.is-height .easter-btn__inner {
  height: 60px;
}

.easter-btn.is-height .easter-btn__text {
  font-size: 28px;
}

.easter-btn:hover {
  text-decoration: none;
}

.easter-btn:hover .easter-btn__inner:after {
  background: linear-gradient(150.67deg, #77eb41 17.99%, #4ea226 51.48%), linear-gradient(#d9d9d9 0%, #d9d9d900 100%);
  box-shadow: inset 1px 1px #f6f3bb66, inset -1px -1px 1px #319641, inset 0 -2px 8px #acc83c;
}

.easter-btn:after {
  content: "";
  pointer-events: none;
  z-index: 4;
  background-image: url("/joxi/easter/2023/elements/shine-btn.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 14px;
  position: absolute;
  top: 8px;
  left: 14px;
}

.easter-info .easter-btn {
  margin: 0;
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
}

.easter-btn__inner {
  background-image: linear-gradient(149.95deg, #77eb41 18.32%, #4ea226 84.78%), linear-gradient(#d9d9d9 0%, #d9d9d900 100%);
  border-radius: 66px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 84px;
  padding: 0 43px;
  display: flex;
  position: relative;
  box-shadow: inset 1px 1px #f6f3bb66, inset -1px -1px 1px #6ad23666;
}

.easter-btn__inner:after {
  content: "";
  background-image: linear-gradient(149.95deg, #77eb41 18.32%, #4ea226 84.78%), linear-gradient(#d9d9d9 0%, #d9d9d900 100%);
  border-radius: 66px;
  transition-property: background-image, box-shadow;
  transition-duration: .2s;
  display: block;
  position: absolute;
  inset: 4px 6px 4px 4px;
  box-shadow: inset 1px 1px #f6f3bb66, inset -1px -1px 1px #76d846, inset 0 -10px 8px #69c83c;
}

.easter-btn__inner:before {
  content: "";
  opacity: .3;
  z-index: 2;
  background-image: linear-gradient(#f0f3b100 0%, #cae480 100%);
  border-radius: 50px;
  height: 9px;
  display: block;
  position: absolute;
  top: 12px;
  left: 17px;
  right: 17px;
}

.easter-btn__text {
  text-align: center;
  z-index: 5;
  color: #fff;
  -webkit-text-stroke: 1px #377320;
  text-shadow: 1px 2px #377320, -1px 2px #377320, 1px 2px #377320;
  width: 100%;
  font-family: Ubuntu, -apple-system, Segoe UI, Roboto, Oxygen, Cantarell, Fira Sans, Helvetica Neue, Helvetica, Arial, cursive;
  font-size: 40px;
  font-weight: 700;
  line-height: 1em;
  display: block;
  position: relative;
}

.easter-info .easter-btn__text {
  font-size: 24px;
}

.swiper-button-disabled {
  display: none;
}

.easter-unset {
  all: unset;
}

.easter-countdown {
  text-align: center;
  width: 100%;
}

.easter-countdown__title {
  color: #fff9;
  margin-right: 3px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1em;
}

.easter-box__panel-countdown .easter-countdown__title {
  display: none;
}

body.rtl-lang .easter-countdown__time {
  margin-right: 8px;
  display: block;
}

body.rtl-lang .easter-countdown__title {
  direction: rtl;
}

body.rtl-lang .easter-countdown {
  flex-direction: row-reverse;
  justify-content: center;
  display: flex;
}

.easter-countdown__time {
  text-align: center;
  color: #f8eeda;
  font-size: 20px;
  font-weight: 700;
  line-height: 1em;
}

.easter-box__panel-countdown .easter-countdown__time {
  color: #5c225d;
  font-size: 24px;
  font-weight: 800;
  line-height: 1.15em;
}

.easter-popup {
  background: #bc9c3b;
  border-radius: 22px;
  width: 100%;
  max-width: 512px;
  margin: 0 auto;
  padding: 2px 2px 7px;
  font-family: Jost, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Helvetica Neue, Helvetica, Arial, cursive;
  position: relative;
}

.easter-popup__close {
  cursor: pointer;
  z-index: 2;
  background-color: #5a215b;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 41px;
  padding: 0;
  display: flex;
  position: absolute;
  top: -17px;
  right: -16px;
}

.easter-popup__span {
  background-color: #844275;
  background-image: url("/joxi/easter/2023/icons/close.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: block;
  position: relative;
  top: 0;
  transform: translateY(-2px);
}

.easter-popup__span:hover {
  background-color: #6f2f60;
  box-shadow: inset 1px 1px 2px #ebaedd66, inset 0 -2px 6px #450b38;
}

.easter-popup__span:active {
  background-color: #621f52;
  box-shadow: inset 1px 1px 6px #ebaedd66, inset 0 -4px 24px #310024;
}

.easter-popup__inner {
  background-image: linear-gradient(#f4d165 0% 100%);
  border-radius: 19px;
  width: 100%;
  padding: 22px 14px 17px;
  position: relative;
}

.easter-popup__inner:after {
  content: "";
  z-index: 2;
  pointer-events: none;
  background-image: url("/joxi/easter/2023/elements/shine-panel.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 41px;
  height: 18px;
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
}

body.rtl-lang .easter-popup__inner {
  direction: rtl;
}

.easter-popup__title {
  text-align: center;
  color: #5c225d;
  text-transform: capitalize;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.45em;
}

.easter-popup__bonus {
  text-align: center;
  color: #5c225d;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
}

.easter-popup__text {
  text-align: center;
  margin-bottom: 16px;
}

.easter-popup__text p {
  color: #5c225d;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.easter-popup__list {
  text-align: center;
  margin: 0 0 23px;
  padding: 0;
  list-style: none;
}

.easter-popup__list li {
  color: #5c225d;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.easter-popup__list li:not(:last-child) {
  margin-bottom: 1px;
}

.easter-popup__btn {
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  display: flex;
}

.easter-popup__terms {
  width: 100%;
}

body.rtl-lang .easter-popup__terms {
  direction: rtl;
}

.easter-popup__terms-trigger {
  cursor: pointer;
  color: #5c225d;
  background-color: #e6c354;
  border: none;
  border-radius: 12px;
  outline: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 67px;
  padding: 20px 40px 20px 30px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.45em;
  display: flex;
  position: relative;
}

.easter-popup__terms.is-opened .easter-popup__terms-trigger {
  border-radius: 12px 12px 0 0;
}

.easter-popup__terms.is-opened .easter-popup__terms-trigger:after {
  transform: translateY(-50%)rotate(270deg);
}

.easter-popup__terms-trigger:after {
  content: "";
  background-image: url("/joxi/easter/2023/icons/slider-arrow.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  transition: transform .2s;
  display: block;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%)rotate(90deg);
}

.easter-popup__terms-inner {
  background: #e6c354;
  border-radius: 0 0 12px 12px;
  width: 100%;
  padding: 0 16px 12px;
  display: none;
}

.easter-popup__terms.is-opened .easter-popup__terms-inner {
  display: block;
}

.easter-popup__terms-list {
  width: 100%;
  margin: 0;
  padding: 0;
}

.easter-popup__terms-list thead th {
  text-align: left;
  width: 50%;
  padding-right: 10px;
}

.easter-popup__terms-list p {
  color: #5c225d;
  margin: 0 0 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.15em;
}

.easter-popup__terms-list p a {
  color: #5c225d;
  font-size: 14px;
  font-weight: 600;
  text-decoration-line: underline;
}

.easter-popup__terms-list ul, .easter-popup__terms-list ol {
  width: 100%;
  margin-bottom: 12px;
  list-style-position: inside;
}

.easter-popup__terms-list li {
  color: #5c225d;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.15em;
  position: relative;
}

.easter-popup__terms-list li:not(:last-child) {
  margin-bottom: 12px;
}

.easter-popup__terms-list table {
  color: #5c225d;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.15em;
}

.easter-popup__terms-list table td {
  padding: 0 3px;
}

.easter-info {
  width: 100%;
}
/*# sourceMappingURL=index.7fb52d23.css.map */
