$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;

/*
XL - browser viewport 1440 and more
L -  browser viewport 1439-and les
M - browser viewport 1239-and les
S - browser viewport 1023-and les
XS - browser viewport 767-and les
XXS - browser viewport 539 and les
*/


/* break points*/
$xxxl: 1920px;
$xxl: 1730px;
$xl: 1440px;
$l: 1240px;
$m: 1024px;
$s: 768px;
$xs: 540px;
$xxs: 320px;


@mixin xxxl {
  @media screen and (min-width: #{$xxxl}) {
    @content;
  }
}
@mixin xxl {
  @media screen and (max-width: #{$xxl}) {
    @content;
  }
}
@mixin xl {
  @media screen and (min-width: #{$xl}) {
    @content;
  }
}
@mixin l {
  @media screen and (max-width: #{$xl - 1px}) {
    @content;
  }
}
@mixin m {
  @media screen and (max-width: #{$l - 1px}) {
    @content;
  }
}

//min
@mixin min-xxxl {
  @media screen and (min-width: #{$xxxl}) {
    @content;
  }
}
@mixin min-xxl {
  @media screen and (min-width: #{$xxl}) {
    @content;
  }
}
@mixin min-xl {
  @media screen and (min-width: #{$xl}) {
    @content;
  }
}
@mixin min-l {
  @media screen and (min-width: #{$xl - 1px}) {
    @content;
  }
}
@mixin min-m {
  @media screen and (min-width: #{$l - 1px}) {
    @content;
  }
}
//
@mixin s {
  @media  screen  and (max-width: #{$m - 1px}) {
    @content;
  }
}
@mixin xs {
  @media screen  and (max-width: #{$s - 1px}) {
    @content;
  }
}
@mixin xxs {
  @media only screen  and (max-width: #{$xs - 1px}) {
    @content;
  }
}


@mixin hover {
  @media only screen and (min-width: #{$m}) {
    @content;
  }
}

@mixin hover-v2 {
  @media only screen and (min-width: #{$m}) {
    &:hover {
      @content;
    }
  }
}

