$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.home-promo {
  width: 585px;
  min-height: 350px;
  border-radius: 10px;
  border: solid 1px #00be6e;
  padding: 30px 10px 30px 35px;
  box-sizing: border-box;

  & + & {
    margin-left: 30px;
  }

  &__content {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 300px 300px;

  }

  &__info {
    height: 100%;
    padding: 35px 295px 25px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__title, &__text {
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    color: #3f3f3f;
    margin: 0 0 15px 0;
  }
  &__sum {
    font-size: 32px;
    font-weight: 900;
    line-height: normal;
    color: #00be6e;
    margin-bottom: 15px;
    overflow: hidden;
  }
  &__link {
    margin-top: auto;
    transition: .2s;
  }
  &_user-bonus-1 {

    border: 1px solid #aa69ac;
    & .home-promo__sum {
      color: #aa69ac;
    }
    & .home-promo__link {
      background-color: #aa69ac;
      &:hover {
        background-color: transparent;
        color: #aa69ac;
        box-shadow: inset 0 0 0 1px #aa69ac;
      }
    }
  }
  &_user-bonus-2 {
    display: none;
    border: 1px solid #ffa800;
    & .home-promo__sum {
      color: #ffa800;
    }
    & .home-promo__link {
      background-color: #ffa800;
      &:hover {
        background-color: transparent;
        color: #ffa800;
        box-shadow: inset 0 0 0 1px #ffa800;
      }
    }
  }

  .linda-has-user  &_welcome-bonus + &_user-bonus-1 {
      margin-left: 0;
    }

  @include l {

    & + & {
      margin-left: 20px;
    }
  }
  @include m() {

    min-height: 260px;

    width: calc(50% - 7px);

    padding: 23px 5px 23px 30px;

    & + & {
      margin-left: 14px;
    }

    &__content {
      background-size: 220px 220px;
    }

    &__info {
      padding: 17px 220px 17px 0;
    }

    &__title {
      font-size: 18px;
      margin-bottom: 10px;
    }
    &__sum {
      font-size: 24px;
      margin-bottom: 10px;
    }
    &__text {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
  @include s() {

    min-height: unset;

    padding: 20px 10px 20px 20px;

    &__content {
      background-size: 150px 150px;
    }

    &__info {
      padding: 8px 150px 8px 0;
    }
  }

  @include xs() {

    min-height: 250px;
    width: 100%;

    padding: 15px 13px 15px 30px;

    &__content {
      background-size: 220px 220px;
    }

    &__info {
      padding: 12px 220px 12px 0;
    }
  }
  @include xxs() {

    padding: 25px 0 15px 25px;

    &__content {
      background-position: right top -15px;
      padding: 0;
    }

     &__info {
       padding: 0 25px 20px 0;
       background: rgba(249, 251, 231, 0.8);
    }
  }
}




//******** WELCOME BONUS HOME PAGE ***************//

.welcom-bonus-home {
  display: flex;
  height: 520px;
  position: relative;
  background-image: url($cdn + '/sweden-yoyo/welcome_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;

  &__content {
    margin-top: auto;
    max-width: 600px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__title {
    font-size: 36px;
    line-height: 42px;
    font-weight: 900;
    margin-bottom: 16px;

    color: #ffd180;
    text-shadow: 0 4px 0 rgba(0, 0, 0, 0.25);
  }
  &__desc {
    font-size: 12px;
    line-height: 14px;
    color: #F9FBE7;
    margin-bottom: 20px;
  }
  &__text {
    font-weight: 900;
    color: #fff;
    text-shadow: 0px 6px 0px rgba(0, 0, 0, 0.25);
    @include text-lines-clip(2);

    &_big {
      font-size: 60px;
      line-height: 70px;
      margin-bottom: 16px;
    }
    &_small {
      font-size: 50px;
      line-height: 59px;
      margin-bottom: 20px;
    }

  }
  &__no-registr {
    color: #F9FBE7;
    font-weight: bold;
    font-size: 14px;
    line-height: 25px;
    display: flex;
    justify-content: center;
    margin-top: 21px;


  }
  .icon-icon-reg-arrow__before {
    &:before {
      content: "";
      width: 30px;
      height: 30px;
      filter: invert(94%) sepia(15%) saturate(213%) hue-rotate(27deg) brightness(103%) contrast(97%);
      display: block;
      margin-right: 6px;
      margin-top: -9px;
    }
  }

  &__text-bottom {
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    max-width: 700px;
    margin-top: auto;
    margin-bottom: 15px;

    a {
      color: $white;
      text-decoration: underline;
    }
  }


  @include s {
    height: 466px;

    &__content,
    &__text-bottom {
      max-width: 500px;
    }
    &__title {
      font-size: 32px;
      line-height: 37px;
    }
    &__text {
      &_big {
        font-size: 50px;
        line-height: 59px;
      }
      &_small {
        font-size: 36px;
        line-height: 42px;
      }
    }

  }
  @include xs {
    height: 470px;
    background-image: url($cdn + '/yoyo/bonus-bg-320-767.jpg');

    &__content,
    &__text-bottom {
      max-width: 290px;
      margin-right: 60px;
      margin-left: auto;
    }
    &__title {
      font-size: 24px;
      line-height: 28px;
    }

    &__text {
      &_big {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 12px;
      }
      &_small {
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 30px;
      }
    }
    &__btn {
      font-size: 18px;
    }
    &__text-bottom {
      margin-bottom: 30px;
    }

  }
  @include xxs {
    &__content,
    &__text-bottom {
      margin-right: auto;
    }
  }
}