$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
// --- CUSTOM BREAKPOINT FUNCTION
@mixin breakpoint($width) {
  @media ( min-width: #{$width} ) {
    @content;
  }
}

// --- BREAKPOINT HELPER FUNCTIONS
@mixin bp-1440 {
  @media ( min-width: #{$bp-1440} ) {
    @content;
  }
}

@mixin bp-1920 {
  @media ( min-width: #{$bp-1920} ) {
    @content;
  }
}

@mixin bp-2560 {
  @media ( min-width: #{$bp-2560} ) {
    @content;
  }
}