$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.switcher {
  position: relative;

  &__label {
    display: block;
    position: relative;
    z-index: 0;
    box-sizing: border-box;
    width: 50px;
    height: 26px;
    border: 1px solid $white;
    background: $white;
    border-radius: 24px;
    transition: border-color .2s ease-in-out, background-color .2s ease-in-out;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: $green;
      transition: transform .2s ease-in-out, background-color .2s ease-in-out;
    }
  }

  input {
    z-index: 1;
    @include absolute-cover;
    opacity: 0;
    cursor: pointer;

    &:checked + {
      .switcher__label {
        background-color: $green;
        border-color: $green;

        &::before {
          background-color: $white;
          transform: translateX(100%);
        }
      }
    }
  }
}
