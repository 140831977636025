$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
$margin-list: 0 2 4 8 10 12 14 15 16 18 19 20 22 24 25 26 28 30 32 36 40 48 60 92;

@for $i from 1 through length( $margin-list ) {
  .has-padding-bottom-#{nth( $margin-list, $i )} {
    padding-bottom: #{nth( $margin-list, $i )}px !important;
  }
  .has-padding-top-#{nth( $margin-list, $i )} {
    padding-top: #{nth( $margin-list, $i )}px !important;
  }
  .has-padding-right-#{nth( $margin-list, $i )} {
    padding-right: #{nth( $margin-list, $i )}px !important;
  }
  .has-padding-left-#{nth( $margin-list, $i )} {
    padding-left: #{nth( $margin-list, $i )}px !important;
  }
}

.has-mb-25 {
  margin-bottom: 25px;
}
.has-mb-40 {
  margin-bottom: 40px;
}
.is-notification:after {
    content: "";
    width: 7px;
    height: 7px;
    box-sizing: content-box;
    background-color: #ff5300;
    border: 1px solid #282828;
    border-radius: 50%;
    position: absolute;
    right: 5px;
    bottom: 5px;
}

.is-notification-small:after {
    content: "";
    width: 7px;
    height: 7px;
    box-sizing: content-box;
    background-color: #ff5300;
    border: 1px solid #282828;
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 3px;
}

.is-notification-item:after {
    content: "";
    width: 7px;
    height: 7px;
    box-sizing: content-box;
    background-color: #ff5300;
    border: 1px solid #282828;
    border-radius: 50%;
    position: absolute;
    left: 8px;
    top: 20px;
}

.notification-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    & svg{
      width: 25px;
      height: 25px;
      margin-right: 5px;
      flex-shrink:0;
    }
}
