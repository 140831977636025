$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.sweden-deposit-limit-form {
    color: #3E3E3E;

    &__title {
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        margin: 0;

        .has-terms & {
            padding-top: 11px;
        }
    }
    &__text{
        margin-top: 16px;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        margin-bottom: 0;
    }
    &__limits {
        margin: 12px 0 20px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 12px;

        .has-terms & {
            margin-bottom: 0;
        }
    }

    &__limit {
        padding: 12px;
        border-radius: 12px;
        margin-top: 0;

        & input {
            border: 1px solid #686B6C;
            &:hover {
                border: 1px solid #19191D;
            }

            &.ng-invalid.ng-touched {
                border: 1px solid #E12D2D;
            }
            &.ng-not-empty.ng-valid  {
                border: 1px solid #01B93C;
            }
        }
    }

    &__limit-label {
        margin-bottom: 12px;
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        margin-top: 0;
    }
    &__limit-span {
        text-transform: uppercase;
    }
    &__info {
        width: 100%;
        margin-bottom: 20px;
        padding: 10px 10px 10px 50px;
        background: #FCE6B3;
        border-radius: 10px;
        background-position: 10px center;
        background-size: 30px;
        background-repeat: no-repeat;
        background-image: var(--icon-warning);
    }
    &__info-text{
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        &:first-child {
            margin-bottom: 10px;
        }
    }
}