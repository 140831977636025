$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.rg-bar {
  background-color: #EBEBEB;
  box-shadow: inset 0px -2px 4px rgba(0, 0, 0, 0.13);
  padding: 0 12px;

  &__wrap {
    height: var(--rg-bar-height);
    display: flex;
    align-items: center;
  }
  
  &__item {
    background-color: #fff;
    box-shadow: 0 0 0 0.025em inset #020203;
    display: flex;
    align-items: center;
    padding: 0 4px 0 21px;
    height: 17px;
    @include ellipsis;
    background-repeat: no-repeat;
    background-position: left 4px center;
    background-size: 14px;

    &.is-spelpaus {
      background-image: url($cdn + '/campobet/elements/icons/rg-bar/control-spelpaus.svg');
    }

    &.is-spelgranser {
      background-image: url($cdn + '/campobet/elements/icons/rg-bar/control-spelgranser.svg');
    }

    &.is-sjalvtest {
      background-image: url($cdn + '/campobet/elements/icons/rg-bar/control-sjalvtest.svg');
    }
  }

  &__text {
    color: #020203;
    font-size: 8px;
  }

  &__btns {
    display: grid;
    grid-template-columns: repeat(3, minmax(min-content, min-content));
    grid-gap: 4px;
    flex-shrink: 0;
    max-width: 100%;
    margin-left: auto;
  }

  &__timer {
    margin-right: 8px;
  }
}

