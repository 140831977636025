$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.se-deposit-limit-modal {
  line-height: 1.2;
  &__title {
    font-size: 14px;
    font-weight: 500;
    color: #3f3f3f;
    margin-bottom: 15px;
  }
  &__wrap {
    padding: 15px;
    border-radius: 10px;
    background-color: rgba(249, 251, 231, .5);
    width: 290px;
    margin-bottom: 20px;
  }
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__amount-btns {
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
    align-items: center;
  }
  &__btn {
    height: 45px;
    border-radius: 10px;
    background-color: #f9fbe7;
    color: #66554f;
    font-weight: 900;
    font-size: 18px;
    line-height: 45px;
    overflow: hidden;
    cursor: pointer;
    border: none;
    &.active {
      background-color: #ffa800;
      color: #ffffff;
    }
    &:first-child {
      margin-right: 12px;
    }
  }
}




.se-deposit-limit-modal__input-wrap {
  input {
    display: none;
  }
  a.show-input {
    display: none;
    + input {
      display: block;
    }
  }
}