$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.cookie {
  bottom: 20px;
  position: fixed;
  z-index: 999;
  width: 100%;
  margin: 0 auto;

  color: #3E3E3E;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  &__wrap {
    padding: 8px 12px;
    background: #FBCE7E;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    align-items: center;
    max-width: 600px;
    min-height: 56px;
    margin: auto;
  }
  &__left-wrap {
    margin: 0 12px 0 0;
    flex-grow: 1;
  }
  &__link {
    @include reset-btn();
    font-size: 12px;
    color: #3E3E3E;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }
  &__btn {
    @include reset-btn();
    @include text-dots();
    min-width: 140px;
    max-width: 220px;
    width: fit-content;
    height: 40px;
    padding: 0 10px;

    background: #9746A5;
    border-radius: 20px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;

    text-align: center;

    color: #FBFBFB;
    &:hover {
      cursor: pointer;
      background-color: #81348e;
    }
  }
}