$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.footer-icons {
  &__list {
    display: flex;
    justify-content: center;
  }
  &__item {
    width: 88px;
    height: 24px;
  }
  &__img {
    margin-top: 2px;
    width: 100%;
    height: 100%;
    font-size: 10px;
    word-break: break-all;
  }

  @include s {
    &__item {
      width: 80px;
      height: 22px;
      margin-right: 5px;
      margin-left: 5px;
    }
  }

  @include xs {
    &__list {
      flex-wrap: wrap;
    }
    &__item {
      margin-top: 10px;
    }
  }
}