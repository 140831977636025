$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;

@mixin text-dots {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin position ($left, $bottom) {
  left:$left;
  bottom: $bottom;
}


/* --------- centered --------- */

@mixin absolute-center {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
}



/* --------- vertical indents --------- */

@mixin margin-rhythm-60 {
  margin-bottom: 60px;

  @include l {
    margin-bottom: 50px;
  }

  @include m {
    margin-bottom: 40px;
  }

  @include s {
    margin-bottom: 30px;
  }
}


@mixin margin-rhythm-40 {
  margin-bottom: 40px;

  @include l {
    margin-bottom: 30px;
  }

  @include m {
    margin-bottom: 20px;
  }
}

@mixin margin-rhythm-30 {
  margin-bottom: 30px;

  @include l {
    margin-bottom: 25px;
  }

  @include s {
    margin-bottom: 20px;
  }
}

@mixin margin-rhythm-20 {
  margin-bottom: 20px;

  @include l {
    margin-bottom: 16px;
  }

  @include xxs {
    margin-bottom: 15px;
  }
}

@mixin without-scrollbar(){
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar  {
    display: none;
    height: 0;
    width: 0;
  }
}

@for $i from 0 through 64 {
  .bottom-#{$i} {
    margin-bottom: #{$i}px !important;
  }
  .top-#{$i} {
    margin-top: #{$i}px !important;
  }
}

@mixin reset-btn {
  appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
}
