$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
html {
  --header-height: 90px;
  --rg-bar-height: 28px;
}

.g-sprite {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

article, section, div, p {
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', Arial, sans-serif;
  background-color: $cream;
  min-width: 1024px;
  height: 100%;
  box-sizing: border-box;
}

a,
button {
  cursor: pointer;
}

.page {
  --fixed-offset: calc( var(--header-height) + var(--rg-bar-height));
  
  height: 100%;
  box-sizing: border-box;

  display: block;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch !important;

  body.is-install-bar-shown & {
    --install-bar-height: 70px;
    .install-bar {
      position: relative;
    }
  }

  @include m {
    --header-height: 70px;
  }
  @include xs {
    --header-height:104px;
  }
}

.g-link {
  color: $darkDarken;
  &:not(.no-hover):hover{
    color: $violetOld;
  }
  &-bold {
    font-weight: 700;
  }
  &-s {
    font-size: 12px;
  }
  &-m {
    font-size: 14px;
  }
  &-l {
    font-size: 16px;
  }
  &-decor {
    text-decoration: underline;
  }
  &.is-violet {
    color: #9746A5;
  }
  &:hover {
    text-decoration: none;
  }
}

.g-link-reverse {
  color: #6E0E57;
  &:hover{
    color: $darkDarken;
  }
}

/* ---------------------
 ========  FORM =========
 -----------------------*/
.g-formbox-item {
  margin-top: 12px;
  position: relative;
  width: 100%;

  &.has-checkbox {
    margin-top: 16px;
  }

  &.is-flex {
    display: flex;
    justify-content: center;
  }
}

input:disabled,
input:disabled + label {
  pointer-events: none;
}

.g-input {
  border-radius: 20px;
  border: 1px solid transparent;
  height: 40px;
  line-height: 1.2;
  padding: 0 25px 0 25px;
  font-size: 14px;
  color: $darkDarken;
  font-weight: 400;
  box-sizing: border-box;
  background-color: white;
  position: relative;
  z-index: 2;
  width: 100%;

  &::-webkit-input-placeholder {
    color: rgba(62, 62, 62, 0.8);
    font-size: 14px;
  }
  &::-moz-placeholder {
    color: rgba(62, 62, 62, 0.8);
    font-size: 14px;
  }
  &:-ms-input-placeholder {
    color: rgba(62, 62, 62, 0.8);
    font-size: 14px;
  }
  &:-moz-placeholder {
    color: rgba(62, 62, 62, 0.8);
    font-size: 14px;
  }

  &:hover {
    border-color: #dcdbd3;
  }

  &:focus,
  &:active {
    border: 1px solid $violetLight;
    color: $dark;
  }

  // search
  &.is-search {
    padding: 0 72px 0 44px;

    &.ng-not-empty + .form-clear-btn {
      display: block;
    }

    &.novalidate:focus,
    &.novalidate:active {
      border: 1px solid $violetLight;
      color: $dark;
    }
  }

}
.g-select:disabled,
.g-input:disabled,
.g-input.ng-valid:disabled,
.g-select.ng-valid:disabled
{
  background-color: #dcdbca;
  border-color: transparent;
  pointer-events: none;
  opacity: 1;
  &:hover {
    cursor: default;
  }
}

.g-input.ng-valid.ng-dirty:not(.novalidate), .g-select.ng-valid  {
  border: 1px solid $greenLight;
}

.is-placeholder-hidden {
  &::placeholder {
    color: transparent !important;
  }
}

.form-input-icon {
  width: 16px;
  height: 16px;
  @include absolute-center-y;
  left: 18px;
  fill: #656565;
  z-index: 2;
}

.form-clear-btn {
  @include text(12px, 140%, 400, #A8A8A8);
  @include ellipsis;
  @include absolute-center-y;
  right: 15px;
  max-width: 48px;
  display: none;
  z-index: 2;
  background-color: transparent;
  border: none;
  padding: 0;

  &:hover {
    color: $darkGrey;
  }
}

.g-select {
  width: 100%;
  max-width: 100%;
  border-radius: 20px;
  border: 1px solid transparent;
  height: 40px;
  color: $darkDarken;
  position: relative;
  z-index: 2;
  padding: 0 25px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  background-color: transparent;
  background-image: url('/img/arr-select.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) 50%;
  -webkit-appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
  line-height: 40px;

  &.is-white {
    background-color: white;
  }

  &::-ms-expand {
    display: none;
  }
  &:hover {
    border-color: #dcdbd3;
  }

  &:focus,
  &:active {
    border: 1px solid $violetLight !important;
    color: $dark;
  }
}

option {
  background: #fff;
  line-height: 30px;
  font-size: 16px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="date"] {
  padding: 0 25px;
  overflow: hidden;
}

input[type="date"].ng-valid.ng-dirty {
  padding: 0 25px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  opacity: 0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  width: 20px;
  height: 25px;
  opacity: 0;
  cursor: pointer;

}

input[type="date"]::-webkit-inner-spin-button {
  display: none;
  padding: 0;
}

input[type="date"]::-webkit-clear-button {
  display: none;
  padding: 0;
}

input[type="date"]::-webkit-datetime-edit {
  padding: 0;
  margin: 0;
}

input[type="date"]::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
  margin: 0;
}


.g-input.novalidate {

  border: 1px solid transparent;

  &:focus, &:active {
    border: 1px solid transparent;
  }
}

.g-select.novalidate {
  border: 1px solid transparent;

  &:focus, &:active {
    border: 1px solid transparent !important;
  }

}

.g-form-disabled-date {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 60px);
  height: 100%;
}

.g-check {
  display: none;
}

.g-check-label {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.g-check-text {
  cursor: pointer;
  padding-left: 10px;
  line-height: normal;
  font-size: 12px;
  width: calc(100% - 35px);

  & a {
    font-weight: bold;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.g-radio {
  display: none;
}

.g-radio:disabled + .g-radio-label .g-radio-block,
.g-radio:disabled + .g-radio-label .g-check-text {
  cursor: default;
}

.g-radio-label {
  display: flex;
  align-items: center;
  &_2nd {
    margin-left: 20px;
  }
}

.g-check-block,
.g-radio-block {
  position: relative;
  background: #fff;
  cursor: pointer;

  &:after {
    content: '';
    @include absolute-center;
    display: block;
  }
}

.g-check-wrap {
  display: flex;
  align-items: center;
}

.g-check-block {
  width: 25px;
  min-width: 25px;
  height: 25px;
  border: 1px solid #a2a2a2;
  border-radius: 10px;
  cursor: pointer;
}

.g-radio-block {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
}

.g-radio:checked + label .g-radio-block{
  &:after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $greenLight;
  }
}
.g-check:checked + label .g-check-block {
  &:after {
    background: #fff url('/img/check.svg') no-repeat;
    width: 13px;
    height: 9px;
  }
}

.g-radio.ng-valid:disabled + label .g-radio-block {
  background-color: #DCDBCA;
}

.g-radio-text {
  cursor: pointer;
  padding-left: 10px;
  font-size: 12px;
  line-height: 12px;
}

.g-form-error {
  padding: 8px 11px 0 26px;

  &.g-form-error_checkbox  {
    padding-left: 20px;
  }

  &__text {
    position: relative;
    color: #FA635C;
    font-size: 12px;
    line-height: normal;
    font-style: italic;
    width: 100%;
    max-width: 100%;
    text-align: left;

    &::before {
      content: '';
      background-image: var(--icon-attention);
      background-size: contain;
      background-repeat: no-repeat;
      width: 13px;
      height: 15px;
      position: absolute;
      top: 0;
      left: -17px;
    }

    & p {
      margin: 0;
    }

    .is-main-page & {
      color: $white;
    }
  }

  .is-main-page & {
    position: absolute;
    bottom: -14px;
  }
}

.g-form-error {
  display: none;
}

.ng-invalid.ng-touched + .g-form-error {
  display: block;
}

.ng-untouched + .g-form-error {
  display: none;
}

.ng-valid + .g-form-error {
  display: none;
}


/* ----------------- Invalid message ----------------*/

.g-minlength {
  display: none;
}

.g-maxlength {
  display: none;
}

.g-pattern {
  display: none;
}

.g-email {
  display: none;
}

.g-required {
  display: none;
}

.g-passwordVer {
  display: none;
}

.g-min,
.g-max {
  display: none;
}

.g-mask-phone {
  display: none;
}

.g-amount {
  display: none;
}

.ng-invalid-minlength + .g-form-error .g-minlength {
  display: block;
}

.ng-invalid-maxlength + .g-form-error .g-maxlength {
  display: block;
}

.ng-invalid-pattern + .g-form-error .g-pattern,
.ng-invalid-number + .g-form-error .g-pattern {
  display: block;
}
.ng-invalid-phone + .g-form-error .g-pattern {
  display: block;
}

.ng-invalid-email + .g-form-error .g-email {
  display: block;
}

.ng-invalid-required + .g-form-error .g-required {
  display: block;
}

.ng-invalid-password-verify + .g-form-error .g-passwordVer {
  display: block;
}

.ng-invalid-min + .g-form-error .g-min { display: block; }

.ng-invalid-max + .g-form-error .g-max { display: block; }

.ng-invalid-required + .g-form-error .g-required { display: block; }

.ng-invalid-amount + .g-form-error .g-amount  { display:block; }

.ng-invalid-phone + .g-form-error .g-mask-phone { display: block;}

.ng-invalid-phone + .g-form-error .g-required { display: block;}

.g-check.ng-invalid-required.ng-touched + label + .g-form-error_inline .g-required {
  display: block;
}

.g-check.ng-touched.ng-invalid + label .g-form-error .g-required {
  display: block;
}

.g-check.ng-dirty.ng-invalid + label .g-form-error .g-required {
  display: block;
}

.g-check.ng-invalid + label .g-required {
  min-width: 100%;
  display: block !important;
  text-align: left;
}
//.g-check.ng-touched + label .g-form-error{
//  display:block;
//}
.g-check.ng-invalid.ng-touched + label .g-form-error {
  display: block;
}

.g-check.ng-invalid.ng-dirty + label .g-form-error {
  display: block;
}

.ng-invalid.datepicker-open + .g-form-error {
  display: none;
}

/* ----------------- Invalid form ----------------*/

.g-input.ng-valid.ng-dirty + .g-form-error + .g-form-ico,
.g-input.ng-valid.ng-dirty.ng-not-empty + .g-form-ico,
.g-select.ng-valid.ng-dirty + .g-form-ico {
  fill: $greenLight;
}

input.ng-invalid.ng-touched, select.ng-invalid-required.ng-touched{
  border: 1px solid $red;
  color: $dark;
}

.g-input.ng-invalid.ng-touched + .g-form-error + .g-form-ico,
.g-select.ng-invalid.ng-dirty + .g-form-ico {
  fill: $red;
}

input.ng-invalid.ng-touched.datepicker-open {
  border: 1px solid $red !important;
}

.g-check.ng-invalid.ng-touched + label .g-check-block,
.g-check.ng-invalid.ng-dirty + label .g-check-block {
  border: solid 1px $red;
}



/* ----------------- SHOW PASS ----------------*/
.g-showPass {
  position: absolute;
  top: 11px;
  right: 15px;
  cursor: pointer;
  z-index: 3;
  svg {
    width: 20px;
    height: 20px;
  }
  &__true {
    display: none;
    line-height: 0;
    svg {
      fill: $greenLight;
    }
  }
  &__false {
    line-height: 0;
    svg {
      fill: $red;
    }
  }
  &__check {
    display: none;
  }
  & svg {
    cursor: pointer;
  }
}

.g-showPass__check:checked + label .g-showPass__true {
  display: block;
}

.g-showPass__check:checked + label .g-showPass__false {
  display: none;
}



/*---------------------------
======= Datepicker ===========
---------------------------*/

.datepicker-body {
  position: relative;
}

.ui-datepicker {
  position: absolute !important;
  top: inherit !important;
  left: 0 !important;
  width: 290px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.08);
  background: $cream;
  //padding: 24px 0 14px;

  &-header {
    border-radius: 8px 8px 0 0;
    padding: 0 15px;
    position: relative;
    color: #fff;
    padding: 25px 15px 0;
  }
  &-title {
    margin: auto;
  }
  &-year,
  &-month {
    @extend .g-select;
    background-color: $white;
    margin-bottom: 15px;
    border-color: transparent;
  }
  &-month {
    font-size: 12px;
  }
  &-year{
    font-size: 14px;
  }

  &-prev,
  &-next {
    display: none;

    /*position: absolute;
    top: 10px;
    color: #fff;
    font-size: 0px;
    width: 20px;
    height: 30px;*/
  }
  /*&-prev {
    left: 20px;
    background: url('img/arr-left_w.svg') 50% 50% no-repeat;
  }
  &-next {
    right: 20px;
    background: url('img/arr-right_w.svg') 50% 50% no-repeat;
  }*/

  &-calendar {
    width: 100%;
    font-size: 12px;
    font-size: 12px;
    margin: 0 15px 15px;
    width: 100%;
    max-width: 260px;
  }
  & th,
  & td {
    padding: 8px 3px;
  }
  & th {
    font-weight: bold;
    color: rgba(63, 63, 63, 0.8);
  }

  & td {
    text-align: center;
    box-sizing: border-box;

    & a {
      color: $dark;
      display: block;
      position: relative;
      z-index: 1;

      &:after {
        content:'';
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
      &:hover {
          &:after{
            background: $yellowLight;
          }

      }
    }
  }
  option {
    color: #3e3e3e;
    background: #fff;
  }
 /* &-unselectable:hover {
    background-color: #fff !important;
  }*/
  @include xxs() {
    left: -21px !important;
  }
}

.date-history-t .ui-datepicker {
  right: 0 !important;
  left: inherit !important;
}


.trans-history-f .ui-datepicker {
  top: 0 !important;
  bottom: inherit !important;
  right: inherit !important;
  left: 0 !important;
}

.trans-history-t .ui-datepicker {
  top: 0 !important;
  bottom: inherit !important;
  right: 0 !important;
  left: inherit !important;
}

.ui-datepicker-right .ui-datepicker {
  right: 0 !important;
  left: inherit !important;
  @include xs {
    left: 0 !important;
    right: inherit !important;
  }
}


/*======================
----- SCROLL ------------
======================*/

.g-scroll_touch {
  -webkit-overflow-scrolling: touch;
}

.g-scroll {
  &::-webkit-scrollbar-track {
    background-color: rgb(229, 229, 229);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-color: rgb(251, 206, 126);
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgb(251, 206, 126);
  }
  &::-webkit-resizer {
    background-image: none;
    background-repeat: no-repeat;
    width: 4px;
    height: 4px
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
}
.page-contet-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__page-content {
    flex: 1 0 auto;
  }
  &__page-footer-wrap {
    flex: 0 0 auto;
  }
}

.g-go-top {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $violetDark;
  position: fixed;
  bottom: 35px;
  right: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 120;

  & svg {
    width: 20px;
    height: 13.1px;
    fill: $white;
    transform: rotate(180deg);
  }
  &:active,
  &:hover {
    background-color: #81348E;
  }
}

.is-full-width {
  width: 100%;
}
