$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.homepage {
  margin-top: 47px;

  &__dashboard {
    padding: 20px 0 52px;
  }

  &__dashboard-title {
    max-width: 80%;
    margin-bottom: 16px;
  }

  &__banner {
    .game-banner {
      margin-bottom: 32px;
    }
  }
  
  &__dashboard-btn-wrap {
    text-align: center;
    margin-top: 30px;
    line-height: 0;
  }

  &__dashboard-btn {
    min-width: 200px;
    transition: .2s;
  }

  &__slider-games {
    background-size: 100%;
    background-position: top 54% left 10%;
    background-repeat: no-repeat;
    background-color: #EA4642;
    border-radius: 10px;

    position: relative;
    overflow: hidden;

    margin-bottom: 16px;

    .slider-games {
      margin-bottom: 0;
    }

    &:before {
      position: absolute;
      content: '';
      background-image: url($cdn + '/yoyo/candy_menu.svg');
      width: 1930px;
      height: 1930px;
      top: -200%;
      left: -17%;
      transform: translate(100%, 100%);
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;

      animation-name: rotate;
      transform-origin: center;
      animation-duration: 60s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    .slider-games::before {
      display: none;
    }

    .slider-games {
      padding: 30px 20px 0;

      &:after {
        content: none;
      }

      &__img {
        width: 100%;
      }

      &__btn_next {
        right: 0;
      }

      &__btn_prev {
        right: 54px;
      }

      .slick-list {
        margin: 0 -7px;
      }

      .slick-slide {
        margin: 0;
      }
    }
  }
  //&__jackpot {
  //  @include margin-rhythm-60;
  //}
  &__bonuses{
    @include margin-rhythm-60;
  }
  &__bonuses-content{
    max-width: 1200px;
    margin: auto;
    display:flex;
    justify-content: center;
    @include l(){
      padding:0;
    }
  }
  //&__event-widgets {
  //  @include margin-rhythm-60;
  //}
  &__widget-bigwins {
    @include margin-rhythm-60;
  }
  //&__vips {
  //  @include margin-rhythm-60;
  //  @include xxs {
  //    display: none;
  //  }
  //}

  &__reg {
    filter: contrast(2);
    position: relative;
    overflow: hidden;
    background: #be9f68 url($cdn + "/yoyo/registration_1920_50contrast.jpg") top center no-repeat;
    @include margin-rhythm-60;

  }

  &__reg-form {
    filter: contrast(0.5);
    padding: 27px 0 35px;
    min-height: 565px;
  }

  @include bp-1440 {
    &__dashboard {
      padding-bottom: 55px;
    }

    &__banner {
      .game-banner {
        margin-bottom: 114px;
      }
    }

    &__slider-games {
      margin-bottom: 28px;
    }
  }

  @include xs {
    &__reg {
      background: url($cdn + "/yoyo/registration_768_50contrast.jpg") top center no-repeat;
    }

    &__user-bonus-1, &__user-bonus-2 {
      display: none;
    }
  }

  @include xxs {
    &__reg {
      background: #FCBE50;
      filter: contrast(1);
    }

    &__reg-form {
      filter: contrast(1);
    }
  }
}

.linda-has-user{

  & .homepage__welcome-bonus{
    display:none;
  }
  & .homepage__user-bonus-2{
    display:block;
  }
  @include xs(){

    & .homepage__user-bonus-1{
      display:block;
    }
    & .homepage__user-bonus-2{
      display:none;
    }
  }
}


//------------------------------------------------
//------------------- VIP SECTION ------------------
//------------------------------------------------
/*.home-vips-section {
  text-align: center;
  &__wrap {
    display: flex;
    justify-content: space-between;
    margin: 0 -8px;
  }
  &__title {
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    color: rgba(65, 48, 52, 0.8);
    margin-bottom: 20px;
  }
  &__item {
    border-radius: 10px;
    margin: 0 8px;
    padding: 18px;
    flex: 1 1 0;
    display: flex;
    word-break: break-word;
    box-sizing: border-box;
    &:nth-child(1) {
      background-color: rgba(53, 210, 144, 0.1);
    }
    &:nth-child(2) {
      background-color: rgba(255, 209, 128, 0.2);
    }
    &:nth-child(3) {
      background-color: rgba(170, 105, 172, 0.1);
    }
    &:nth-child(4) {
      background-color: rgba(255, 192, 81, 0.15);
    }
    &:nth-child(5) {
      background-color: rgba(234, 70, 66, 0.1);
    }
  }
  &__item-wrap {
    margin: auto;
  }
  &__icon {
    margin-right: 10px;
  }
  svg {
    width: 110px;
    height: 90px;
  }
  &__level {
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    color: rgb(63, 63, 63);
    margin-bottom: 8px;
  }
  &__limits {
    font-size: 12px;
    font-style: normal;
    color: rgb(62, 62, 62);
    margin-bottom: 8px;
  }
  &__price {
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    color: rgb(62, 62, 62);
    text-transform: uppercase;
  }
  @include l() {
    &__item {
      display: block;
    }
    &__icon {
      margin-right: 0;
      margin-bottom: 6px;
    }
  }
  @include s() {
    max-width: 728px;
    margin-left: auto;
    margin-right: auto;
    &__wrap {
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: -16px;
    }
    &__item {
      flex: unset;
      margin-bottom: 16px;
      max-width: 232px;
      flex: 1 1 0;
      min-width: 200px;
    }
  }
  @include xs() {
    max-width: 500px;
    &__wrap {
      margin: 0 -5px -10px;
    }
    &__item {
      max-width: 160px;
      margin: 0 5px 10px;
      min-width: 150px;
    }
  }
}
*/

//------------------------------------------------
//-------------- WIN,REWARD WIDGETS --------------
//------------------------------------------------
.home-event-widgets-section {
  background-color: rgb(62, 62, 62);
  padding: 30px 0;
  &__wrap {
    display: flex;
    justify-content: space-between;
    margin: 0 -7px;
  }
  &__item {
    flex: 1 1 0;
    border-radius: 10px;
    background-color: rgb(249, 251, 231);
    padding: 15px 10px 10px;
    text-align: center;
    margin: 0 7px;
    &.app-for-gadget {
      background-image: url($cdn + "/yoyo/banner_devices.jpg");
      background-size: cover;
      background-position: center right;
      padding-bottom: 15px;
      position: relative;
      word-break: break-word;
      min-height: 100px;
      display: flex;
      flex-direction: column;
    }
  }
  &__title {
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    color: rgb(63, 63, 63);
    margin-bottom: 5px;
  }
  .app-for-gadget {
    background-position-x: -80px;
    &__title {
      width: 50%;
      position: relative;
      margin: 0px 0px 0px auto;
      font-size: 14px;
      font-weight: 700;
      line-height: normal;
      color: rgb(62, 62, 62);
      text-align: left;
      margin: auto auto auto 50%;
    }
  }
  .widget_month {
    color: rgb(170, 105, 172);
  }
  .widget_day {
    color: rgb(53, 210, 144);
  }
  @include s() {
    .home-widget-rewards {
      display: none;
    }
  }
  @include xs() {
    .home-widget-bigwins_day {
      display: none;
    }
  }
  @include xxs() {
    .home-widget-bigwins_month {
      display: none;
    }
  }
}



//--------------WIDGET-BIGWINS------------------


.widget-bigwins-home {
  padding-top: 40px;

  &__wrap {
    display: flex;
    margin: 0 auto;
    //padding: 0 35px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    border-radius: 10px;
    width: 50%;
    height: 260px;
    text-align: right;
    padding-right: 60px;
    background-repeat: no-repeat;
    background-position: 0 0;
    position: relative;

    &_month {
      margin-right: 10px;
      background-color: #F1ECE1;
    }
    &_day {
      margin-left: 10px;
      background-color: #E5F7DE;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 9px;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    z-index: 1;
    width: 50%;
  }

  //@media (min-width: 1920px) {
  //  &__wrap {
  //    padding: 0 40px;
  //  }
  //
  //}

  @include l {
    //&__wrap {
    //  padding: 0 20px;
    //}
    &__item {
      &_month {
        margin-right: 6px;
      }
      &_day {
        margin-left: 6px;
      }
    }
    &__title {
      margin-bottom: 14px;
      max-width: calc(50% - 20px);
    }
  }

  @include m {
    &__item {
      padding-right: 24px;
    }
  }

  @include s {
    &__item {
      height: 210px;
      padding-right: 20px;
    }
    &__title {
      font-size: 14px;
      line-height: 25px;
      margin-bottom: 17px;
    }
  }

  @include xs {
    &__wrap {
      flex-wrap: wrap;
    }
    &__item {
      width: 100%;

      &_month {
        margin-right: 0px;
        margin-bottom: 38px;
      }
      &_day {
        margin-left: 0px;
      }
    }
    &__title {
      max-width: 29%;
    }

  }

  @include xxs {
    //&__wrap {
    //  padding: 0 15px;
    //}
    &__item {
      height: 200px;
      padding-right: 12px;

      &_month {
        margin-bottom: 25px;
      }
    }
    &__title {
      margin-bottom: 12px;
      max-width: calc(100% - 150px);
    }
  }
}



