$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.missed-data-cancellation {
    display: flex;
    background-color: rgba(62,62,62,.8);
    min-height: 100%;
    &__content {
        padding: 40px;
        margin: auto;
        border-radius: 10px;
        background-color:#F9FBE7;
        max-width: 350px;
        &-text {
            margin: 0 0 50px;
            font-size: 19px;
            line-height: 23px;
            color: #686B6C;
        }
    }
    &__btns {
        display: flex;
    }
    &__accept {
        margin-right: 24px;
    }
}
  