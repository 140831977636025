$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.widget-bonus-item{
  padding: 30px 20px;
  position: relative;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 290px;
  min-height:295px;
  border-radius: 10px;
  background-color: #f9fbe7;
  overflow:hidden;

  &_active {
    .widget-bonus-item__label {
      background: #ea4642;
    }
  }

  &_new {
    .widget-bonus-item__label {
      background: #35d290;
    }

    .widget-bonus-item__btn {
     display: block;
    }
  }

  &__label{
    padding: 0 22px;
    width: 100px;
    height: 22px;
    position: absolute;
    top: 14px;
    left: -24px;
    color: #fff;
    transform: rotate(-45deg);
    font-size: 12px;
    font-weight: bold;
    line-height: 21px;
    text-align: center;
    @include text-dots
  }

  &__text{
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    color: #3f3f3f;
    margin-bottom:15px;
  }
  &__sum{
    font-size: 24px;
    font-weight: 900;
    line-height: normal;
    color: #aa69ac;
    margin-bottom:25px;
  }

  &__btn {
    display: none;
  }

  @include xxs {
    min-height: auto;
  }
}
