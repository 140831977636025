$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.widget-bigwins {
  width: 50%;
  z-index: 1;
  overflow: hidden;

  &__price {
    font-weight: bold;
    font-size: 32px;
    line-height: 37px;
    margin-bottom: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .widget-bigwins_month & {
      color: #9846A5;
    }
    .widget-bigwins_day & {
      color: #00BE6E;
    }
  }
  &__text {
    font-size: 12px;
    line-height: 14px;
  }
  &__game-link {
    font-weight: bold;
  }
  &__bg {
    position: absolute;
    width: calc(50% + 20px);
    height: 260px;
    left: 0;
    top: 0;
    border-radius: 10px;
    background-size: 483px 260px;
    background-repeat: no-repeat;
    background-position: 100% center;
    z-index: -1;

    .widget-bigwins[type="'day'"] & {
      background-image: url($cdn + '/yoyo/winners/win-green-bg.png');
    }
    .widget-bigwins[type="'month'"] & {
      background-image: url($cdn + '/yoyo/winners/win-violet-bg.png');
    }

  }
  &__img.game-item {
    height: 282px;
    width: 188px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 188px 282px;
    background-position: 0 0;
    top: -40px;
    left: 0;
    margin: 0;
    margin-left: 5%;
    border-radius: 10px;

    & .game-item__hoverplay {
      width: 188px;
      height: 282px;
      border-radius: 10px;
    }
  }

  @media (min-width: 1920px) {
    &__bg {
      background-size: cover;
    }
    &__img.game-item {
      margin-left: 8%;
    }
  }

  @include l {
    width: calc(50% - 20px);

    &__price {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 18px;
    }
  }

  @include s {
    &__price {
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 19px;
    }
    &__img.game-item {
      background-size: 140px 210px;
      top: -18px;
      height: 210px;
      width: 140px;

      & .game-item__hoverplay {
        width: 140px;
        height: 210px;
      }
    }

    &__bg {
      height: 210px;
    }
  }

  @include xs {
    width: 29%;

    &__bg {
      width: 70%;
    }
  }

  @include xxs {
    width: calc(100% - 150px);

    &__price {
      font-size: 14px;
      margin-bottom: 12px;
    }
    &__bg {
      display: none;
    }
    &__img.game-item {
      width: 140px;
      top: -5px;
      margin-left: 0;
    }
  }
}

