$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.article-box,
.seo {
  text-align: justify;
  margin: auto auto 40px;

  & h1, h2, h3, h4, h5, h6 {
    color: $violetDark;
    font-weight: bold;
    margin: 0 0 12px;
  }
  & h2, h3, h4, h5, h6 {
    margin-top: 32px;
  }
  & p, li{
    font-size: 14px;
    line-height: 1.75;
    color: #3f3f3f;
  }
  & h1 {
    font-size: 32px;
    line-height: 1.25;
  }
  & h2 {
    font-size: 24px;
  }
  & h3 {
    font-size: 18px;
  }
  & h4 {
    font-size: 16px;
  }
  & p{
    margin: 0 0 12px;

    & span {
      font-weight: 900;
    }
  }
  & ul {
    list-style: none;
    margin: 0 0 12px;
    padding: 0;
    &:last-child{
      margin: 0;
    }
  }
  & ul {
    & li {
      position: relative;
      padding-left: 8px;

      &::before {
        height: 3px;
        width: 3px;
        border-radius: 50%;
        background: #3f3f3f;
        content: "";
        position: absolute;
        left: 1px;
        top: 10px;
      }
    }
  }

  //& ol {
  //  margin: 0 0 1.5em;
  //  padding: 0;
  //  counter-reset: item;
  //}
  //
  //& ol > li {
  //  margin: 0;
  //  padding: 0 0 0 2em;
  //  text-indent: -2em;
  //  list-style-type: none;
  //  counter-increment: item;
  //}
  //
  //& ol > li:before {
  //  display: inline-block;
  //  width: 1em;
  //  padding-right: 0.5em;
  //  font-weight: bold;
  //  text-align: right;
  //  content: counter(item) ".";
  //}

  & ol {
    padding: 0;
    margin:0;
    counter-reset: item;
  }
  & li{
    display: block;
  }

  & li ol {
    padding-left:15px;
  }
  & li:before {
    content: counters(item, ".") ".";
    counter-increment: item ;
    font-weight: bold;
  }
  & a {
    color: $darkDarken;
    text-decoration: underline;
    &:hover{
      color: $violetOld;
    }
  }

  &.is-promo {
    margin-top: 30px;

    .seo__title {
      text-align: center;
    }
  }

  &__content {
    margin: 0;
    display: block;

    &.has-hide {
      position: relative;
      height: 214px;
      overflow: hidden;

      &::before {
        content: '';
        display: block;
        @include absolute-cover;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #F9FBE7 100%);
        pointer-events: none;
        z-index: 10;
      }

      &.is-active {
        height: auto;
        overflow: auto;

        &::before {
          opacity: 0;
        }
      }

    }
  }


  @include xs{
    & h1{ font-size:24px;}
    & h2{ font-size:21px;}
    & h3{ font-size:18px;}

    &.is-promo {
      margin-top: 20px;
    }
  }
}
