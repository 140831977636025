$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-info {

  &__form-title {
    margin-bottom: 7px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(65, 48, 52, 0.8);
    text-align:center;
  }

  &__form-wrap {
    max-width: 1066px;
    margin: 0 auto;
    padding: 30px 25px 40px;
    background-color: rgba(255,209,128, 0.3);
    border-radius: 10px;
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    &.ng-pristine, &.ng-invalid {
      .profile-info__change-btn-wrap::before {
        display: block;
      }
    }
  }

  &__field-block {
    width: calc(100%/3 - 20px);
    margin: 0 10px;
    overflow:hidden;
  }

  &__field-block-nav {
    margin-top: 24px;
  }

  &__field-label {
    font-size: 14px;
    font-weight: 500;
    color: #3f3f3f;
    margin-bottom: 12px;
    padding-left: 27px;
    @include text-dots
  }

  &__radio {
    display: flex;
    justify-content:center;
    align-items: center;
    height: 40px;
  }

  &__rules-wrap {
    height: 40px;
    display: flex;
    margin-top: 18px;
  }

  &__rules {
    width: 325px;
    margin: auto;
    font-size: 12px;
    color: #3e3e3e;
    padding-left: 27px;
  }
  &__change-btn-wrap {

    position: relative;
    width: 100%;
    margin-top: 45px;

    &::before {
      display: none;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      content: '';
      background: rgba(255, 255, 255, 0.3);
      position: absolute;
      top:0;
      left:0;
      z-index: 10;
    }
  }

  &__change-btn  {
    width: 100%;
  }

  &__change-pass-btn {
    margin: 30px auto 20px;
    width: 290px;
    display: block;
  }

  @include l {

    &__form-wrap {
      padding: 30px 20px;
    }
  }

  @include s {

    &__form-title {
      margin-bottom: 2px;
    }

    &__form-wrap {
      padding: 30px 28px 40px;
    }

    &__field-block {
      width: calc(50% - 20px);
    }

    &__field-block-address {
      order: 1;
    }

    &__field-block-personalinfo {
      order: 2;
    }

    &__field-block-contacts {
      order: 3;
    }

    &__field-block-place {
      order: 4;
    }

    &__field-block-nav {
      order: 5;
    }

    &__change-pass-btn {
      margin: 20px auto 0;
    }
  }

  @include xs {

    &__form-wrap {
      padding: 30px 20px;
      max-width: 375px;
    }

    &__field-block-nav {
      margin-top: 0;
    }

     &__field-block {
      width: 100%;
    }

    &__field-block-personalinfo {
      order: 1;
    }

    &__field-block-place {
      order: 2;
    }

    &__field-block-address {
      order: 3;
    }

    &__field-block-contacts {
      order: 4;
    }

    &__rules {
      height: auto;
      align-items:flex-start;
    }

    &__change-btn-wrap {
      margin-top: 15px;
    }

    &__{

    }

  }

}