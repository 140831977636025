$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.profile {

  //todo delete after global refactoring
  p {
    margin: 0;
  }

  &__top-wrap {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
  }
  &__submenu {
    margin: 0 -118px 0 -118px;
    flex-wrap: wrap;
    justify-content: center;
  }
  &__item {
    box-sizing: border-box;
    margin: 0 0 20px 20px;
    &:nth-child(5n) {
      margin-left: 0;
    }
    &:first-child {
      margin-left: 0;
    }
  }
  &__title {
    width: calc(100% - 175px);
    padding-left: 175px;
    margin: 0;
  }
  &__time {
    width: 175px;
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    color: #66554f;

    overflow: hidden;
  }
  @include l {
    &__submenu {
      margin: 0 -8px;
    }
    &__item {
      width: calc(25% - 16px);
      margin: 0 8px 16px;
      &:first-child {
        margin-left: 8px;
      }
    }
    &__item + .user-submenu__item {
      margin: 0 8px 16px;
    }
  }
  @include s {
    &__submenu {
      margin: 0 -5px;
    }
    &__item + .user-submenu__item {
      margin: 0 5px 10px;
    }
    &__item {
      width: auto;
      min-width: 151px;
      &:first-child {
        margin-left: 5px;
        margin-bottom: 10px;
      }
    }
    &__title {
      width: calc(100% - 130px);
      padding-left: 130px;
    }
    &__time {
      width: 130px;
      padding: 0;
    }
  }


}
