$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.widget-rewards-content {
  &__reward-prize {
    margin-bottom: 5px;
    color: rgb(234, 70, 66);
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
  }
  &__reward-name {
    font-size: 12px;
    line-height: normal;
    color: rgb(63, 63, 63);
  }
}