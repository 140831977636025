$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.contact-bar {
  display: block;
  margin-bottom: 25px;
  &__list {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
  }
  &__item {
    display: flex;
    margin-right: 33px;
    font-size: 14px;
    color: #413034cc;
    &:last-child {
      margin: 0px;
    }
  }
  &__key {
    margin: auto 10px auto 0;
  }
  &__value {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.79;
    a {
      color: #413034cc;
    }
  }
  @include xs () {
    margin-bottom: 15px;
    &__list {
      justify-content: space-between;
    }
    &__key {
      &.phone {
        display: none;
      }
    }
    &__item {
      &.email {
        display: none;
      }
      &.phone {
        margin: 0px;
        .contact-bar__key  {
          display: none;
        }
      }
    }
  }
}