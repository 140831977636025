$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
linda-game-categories {
  display: block;
  position: relative;
  z-index: 2;
  @include s {
    transform: translateY(0);
  }
}

.game-category {
  margin-top: 20px;
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  .linda-has-user & {
    margin-top: 53px;
  }

  &__list {
    background: $cream;
    border-radius: 10px;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.08);
    width: 100%;
    max-width: 1200px;
    margin: auto;
    height: 110px;
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
  }

  &__item {
    // min-width: 10%;
    max-width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include m {
    &__list {
      height: 90px;
      padding: 0 25px;
    }
  }

  @include s {
    overflow-x: auto;
    overflow-y: hidden;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.08);
    @include without-scrollbar;

    &.game-category_scroll {
      overflow-x: scroll;
      box-shadow: none;

      &::-webkit-scrollbar-track {
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.08) 0%,
          transparent 100%
        );
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background-color: rgb(251, 206, 126);
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: rgb(251, 206, 126);
      }
      &::-webkit-resizer {
        background-image: none;
        background-repeat: no-repeat;
        width: 4px;
        height: 4px;
      }
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
    }

    &__list {
      padding: 0;
      border-radius: 0;
      box-shadow: none;
    }
    &__item {
      width: 90px;
      min-width: 90px;
    }
  }
}

.category-item {
  max-width: 100%;
  font-size: 12px;
  font-weight: 700;
  color: $violetLight;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80px;
  justify-content: space-between;

  &:hover,
  &.active {
    color: $red;
    .category-item__wrap {
      border-color: transparent;
      margin-top: 3px;
      filter: $filterRed;
      transform: scale(1.7);
    }
  }

  &__name {
    max-width: 100%;
    @include text-dots;
  }

  @media (any-hover: none) {
    &:hover {
      color: $violetLight;

      .category-item__wrap {
        border-color: $violetLight;
        margin-top: 0;
      }
    }

    &.active {
      color: $red;
      .category-item__wrap {
        border-color: transparent;
        margin-top: 3px;
        filter: $filterRed;
        transform: scale(1.7);
      }
    }
  }

  &__wrap {
    flex-shrink: 0;
    width: 54px;
    height: 54px;
    border: 1px solid $violetLight;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    transition: all 0.2s;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 32px;
    filter: $filterViolet;
  }
  &__wrap_profile {
    svg {
      width: 28px;
      height: 28px;
    }
  }

  &__wrap_cashbox {
    svg {
      width: 26px;
      height: 27px;
    }
  }

  &__wrap_history {
    svg {
      width: 30px;
      height: 30px;
    }
  }

  @include m {
    height: 70px;
    &__wrap {
      width: 46px;
      height: 46px;
    }
  }
}
