$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
// --- THIS IS NEW COMPONENT FOR GLOBAL POPUPS AND MODALS

.modal-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translate3d(0,0,0);

  &.is-popup {
    z-index: 999;
  }

  &.is-modal {
    z-index: 2000;
  }
}

.modal-box-inner {
    width: 100%;
    max-width: 480px;
    min-height: auto;
    margin: 60px auto;
    display: flex;
    flex-direction: column;
    background-color: $orange;
    height: auto;

    @include bp-1440 {
      max-width: 1200px;
    }
}

.modal-box-header {
  position: relative;
  width: 100%;
  min-height: 68px;
  padding: 0 24px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &__title {
    color: $white;
    @include ellipsis;
    max-width: calc(100% - 24px * 2 - 12px * 2);
    @include text(24px, 100%, 700, $darkDarken);
    padding: 0;
    margin: 0;
  }

  &__title-span {
    opacity: .6;
  }

  &__logo {
    width: 144px;
    height: 30px;
    background-image: url('/joxi/campobet/elements/logo/logo-lg.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% 100%;
  }

  @include bp-1440 {
    padding: 0 70px;
    margin-bottom: 8px;
  }
}

.modal-box-btn-icon {
  width: 20px;
  height: 20px;
  @include absolute-center-y;
  z-index: 5;
  right: 20px;
  background-color: transparent;
  border: none;
  padding: 0;
  transition: 0.2s transform;

  &.is-close {
    filter: invert(64%) sepia(15%) saturate(1946%) hue-rotate(360deg) brightness(101%) contrast(76%);

    &:hover {
      -ms-transform: translateY(-50%) rotate(-360deg);
      transform: translateY(-50%) rotate(-360deg);
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }

  @include bp-1440 {
    &.is-close {
      width: 24px;
      height: 24px;
      right: 28px;
    }
  }

}

.modal-box-body {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translate3d(0,0,0);
}
