$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.linda-vip{
  & .slick-dots{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom:50px;
    display:flex;
    margin-right:10px;
    li{
      margin-right:10px;
      &:last-child{
        margin-right:0;
      }
      button{
        width:10px;
        height:10px;
        background:rgba(63, 63, 63, 0.5);
        border-radius:50%;
        font-size:0;
        border:none;
        cursor: pointer;
        padding: 0px;
      }
      &.slick-active{
        button{
          background-color:#3f3f3f;
        }
      }
    }
  }
  &__list{
    display:flex;
    justify-content: center;
    align-items: center;
    /*height:450px;*/
    margin-bottom:30px;
  }
  &__slide{
    padding:87px 0;
    &_first{
      background:url($cdn + "/yoyo/vip-back_1.jpg") center no-repeat;
    }
    &_second{
      background:url($cdn + "/yoyo/vip-back_2.jpg") center no-repeat;
    }
    &_third{
      background:url($cdn + "/yoyo/vip-back_3.jpg") center no-repeat;
    }
    &_fourth{
      background:url($cdn + "/yoyo/vip-back_4.jpg") center no-repeat;
    }
  }
  &__slide-content{
    display:flex;
    flex-direction: column;
    justify-content: center;
    width: 580px;
    height:276px;
    max-height: 276px;
    border-radius: 10px;
    background: rgba(63,63,63,0.7);
    padding:35px;
    box-sizing: border-box;
    margin:0 auto;
  }
  &__slide-title{
    font-size: 32px;
    font-weight: bold;
    line-height: normal;
    color: #ffffff;
    margin:0 0 12px 0;
    text-align: center;
    max-height: 80px;
    overflow: hidden;
  }
  &__slide-text{
    display:block;
    max-height:121px;
    overflow:hidden;
    font-size: 14px;
    line-height: 1.76;
    color: #ffffff;
  }
  &__main-title{
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    color: rgba(65, 48, 52, 0.8);
    text-align: center;
    margin-bottom:20px;
  }
  &__main-content{
    display:flex;
    justify-content: space-between;
    margin-bottom: 42px;
  }
  @include m(){
    & .slick-dots{
      bottom:29px;
    }
    &__slide{
      padding:57px 0;
    }
    &__main-content{
      flex-wrap: wrap;
      justify-content: center;
    }
    /*&__slide{
      padding:50px 0 66px 0;
    }*/
  }
  @include s(){
    &__main-content {
      margin: 0 auto 12px;
      max-width: 900px;
    }
  }
  @include xs(){
    &__main-content{
      margin-bottom:15px;
      max-width: 700px;
    }
    &__slide{
      padding:0;
    }
    &__slide-content{
      width:100%;
      border-radius:0;
      height:340px;
      max-height:340px;
      padding:67px 20px;
    }
  }
  @include xxs(){
    &__main-content{
      flex-direction: column;
      align-items: center;
    }
    &__slide{
      background-position: calc(50% - 270px) 50%;
    }
    &__slide-text{
      font-size:12px;
    }
    &__slide-content{
      padding:57px 15px;
    }
  }
}
