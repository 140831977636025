$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
@import 'styles/_game-page-smart-bnr';

.game-page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background: rgba(#111, .85);
  // todo: remove this after search popup implementation
  z-index: 1;

  .rg-bar {
    flex-shrink: 0;
  }

  &__seo {
    background: #111;
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
  }

  &__in {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__frame {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__btn {
    line-height: 20px;
    bottom: 0;
    left: 0;
    &.has-arrow {
      position: absolute;
    }
    &.is-opened {
      position: relative;
      svg {
        display: none;
      }
    }
  }
}
