$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.footer-nav {

  flex-grow:1;

  &__list {
    display: flex;
    flex-wrap:wrap;

  }

  &__item {
    font-size: 14px;
    line-height: 1.29;
    color: #ffffff;
    margin: 5px 60px 5px 0;

    &:hover {
      opacity:0.7;
    }
   }

  &__link {
    color: #ffffff;

    &.active {
      font-weight: bold;
    }
  }

    @include m {
      &__item {
        font-size: 12px;
        margin: 5px 30px 5px 0;
    }
  }
}