$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-filter {

  min-height: 80px;
  padding: 20px;
  display: flex;
  justify-content:center;
  align-items: center;
  border-radius: 10px 10px 0 0;
  background-color: #ffd180;

  &__item {
    width: 190px;
  }

  &__item-deposit {
    display:none;
  }

  &__item + &__item {
    margin-left: 20px;
  }

  &__field {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.76;
    letter-spacing: normal;
    color: #3f3f3f;
    background-color: #ffffff;
  }

  @include s {

    &__item + &__item {
      margin-left: 16px;
    }
  }

  @include xs {

     flex-direction: column;

    &__item {
      width: 100%;
    }
    &__item + &__item {
      margin-left: 0;
      margin-top: 15px;
    }
  }
}

.profile-table {

  border: solid 1px #ffa800;
  border-radius: 10px;
  padding-bottom: 6px;

  &__thead {
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(65, 48, 52, 0.8);
    display:flex;
    justify-content: space-between;
  }

  .scroll &__thead {
    margin-right:22px;
  }

  &__th {
    min-height: 40px;
    display: flex;
    align-items: center;
    overflow:hidden;
  }

  &__th + &__th {
    padding-left: 10px;
  }

  &__tbody {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: normal;
    color: #3f3f3f;
    max-height: 400px;
    overflow-y: auto;
  }

  .scroll &__tbody {
    padding-right: 8px;
    margin-right: 8px;
  }

  &__tr {
    display:flex;
    height: 40px;
    align-items: center;
  }

  &__tr:nth-child(odd) {
    background:  #F2F3E0;
  }

  &__tr-subrow + &__tr-subrow {
    padding-left: 10px;
  }

  &__td-key {
    width: 45%;
    padding-right: 10px;
    flex-shrink:0;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(65, 48, 52, 0.8);
    display:none;
  }

  &__td-key, &__td-value {
    @include text-dots
  }

  @include s {

    .profile-table &__thead,  .profile-table &__tr {
      padding-left: 20px;
    }
  }
  @include xs {

    max-width: 480px;
    margin: 20px auto 0;
    padding: 0;

   &__thead {
      display: none;
    }

    .profile-table &__tbody {
      max-height: unset;
      padding: 0;
      margin-right: 0;
    }

    .profile-table &__tr {
      flex-direction: column;
      height: auto;
      padding: 20px;
    }

    .profile-table &__tr-subrow {
      height: 29px;
      display:flex;
      justify-content: space-between;
      align-items:center;
      width: 100%;
    }

    &__tr-subrow + &__tr-subrow {
      padding-left: 0;
    }

    &__td-key {
      display: block;
    }
  }
}




.payment-box {
  border-radius: 10px;
  border: 1px solid $orange;
  display: flex;
  margin-top: 20px;

  &.is-depos {
    margin-bottom: 30px;

  }

  .is-main-page & {
    border: none;
  }
  &__trustly {
    display: none;
    
    .is-main-page & {
      display: block;
      width: 71px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 28px;
      border: none;
      z-index: 2;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  &_withdraw {
    @include margin-rhythm-30;

    &.is-hide {
      display: none;
    }
  }
  &__paysystems {
    padding: 40px 30px 40px 50px;
    width: 50%;
    flex-shrink: 0;
  }
  &__data {
    padding: 40px 40px 40px 62px;
    width: 50%;
    flex-shrink: 0;
    border-radius: 0 10px 10px 0;

    background-image: url(/img/cashbox_bg-vertical.svg), linear-gradient(#faebb9, #faebb9);
    background-repeat: repeat-y, no-repeat;
    background-position: left top, left 35px top;
    background-size: 35px 70px, 100%;

    .is-main-page & {
      width: 100%;
      padding: 16px 0 10px;
    }
  }
  &__data-inner-cont {
    width: 100%;
    max-width: 390px;
    margin: auto;
    min-height: 100px;
  }


  .is-main-page & {
    &__paysystems {
      display: none;
    }
    &__data {
      background: none;
    }

    &::after {
      display: block;
      content: '';
      width: 71px;
      height: 20px;
    }
  }

  @include s {
    display: block;
    &__paysystems {
      width: 100%;
      padding: 40px 80px 20px;
    }
    &__data {
      border-radius: 0 0 10px 10px;
      padding: 60px 40px 40px;
      width: 100%;
      background-image: url(/img/cashbox_bg-horizontal.svg), linear-gradient( to bottom,  #faebb9, #faebb9);
      background-repeat: repeat-x, no-repeat;
      background-position: left top, left top 35px;
      background-size: 70px 35px, 100%;
    }
  }

  @include xs {
    &__paysystems {
      padding: 18px;
    }
    &__data {
      padding: 60px 20px 40px;
    }
  }
}


.payments {
  display: flex;
  flex-wrap: wrap;
  margin: -7px -7px -8px -8px;
  &__item {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 124px;
    height: 55px;
    flex-shrink: 0;
    margin: 7px 7px 8px 8px;
    border-radius: 10px;
    background-color: #edefdc;
    box-sizing: border-box;
    padding: 12px 6px;
    &:hover {
      cursor: pointer;
      background-color: #fce6b4;
    }
    &.active {
      background-color: #fbda8b;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }

  @include s {
    justify-content: center;
  }

  @include xs {
    margin: -5px;
    &__item {
      width: 120px;
      height: 53px;
      margin: 5px;
    }
  }
}


.payment-descr {
  margin-bottom: 20px;
  .is-main-page & {
    margin-bottom: 16px;
  }
  &__system {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    color: #3f3f3f;
    margin-bottom: 10px;

    .is-main-page & {
      display: none;
    }
  }
  &__title {
    display: none;

    .is-main-page & {
      display: block;
      font-weight: 900;
      font-size: 24px;
      line-height: 28px;
      color: $white;
      width: 100%;
      text-align: center;
      position: relative;
      z-index: 2;
    }
  }
  &__amounts {
    display: flex;
    justify-content: center;

    .is-main-page & {
      display: none;
    }
  }
  &__amount-item + &__amount-item {
    margin-left: 20px;
  }
  &__amount-item {
    display: flex;
    font-size: 14px;
    line-height: 1.2;
    color: #413034cc;
    align-items: center;

    .is-main-page & {
      display: none;
    }
  }
  &__value {
    font-weight: bold;
  }
}

.payment-notification {
  font-size: 14px;
  line-height: 1.2;
  color: #3f3f3f;
  font-weight: 400;
  margin-bottom: 20px;

  ol {
    margin: 0;
    li {
      margin-left: 24px;
      &::marker {
        font-weight: 600;
      }
    }
    li:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.payment-form {
  &__amount {
    width: 100%;
    max-width: 290px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    .hide-amount & {
      display: none;
    }

    .is-main-page & {
      width: 227px;
      margin-right: unset;
      margin-left: 20px;

      &::before {
        display: block;
        content: '';
        width: 30px;
        height: 30px;
        background: url(/joxi/yoyo/coin.png);
        background-repeat: no-repeat;
        background-size: 100%;
        position: absolute;
        z-index: 20;
        top: 50%;
        left: 10px;
        margin-top: -15px;
      }

      input {
        height: 50px;
        text-align: center;
        font-size: 20px;
        line-height: 50px;
        font-weight: bold;
        color: $darkGrey;
        border-radius: 60px;

        &::placeholder {
          font-size: 20px;
          line-height: 50px;
          color: transparent;
        }
      }
    }
  }
  &__amount-input-label {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    color: #3f3f3f;
    margin-bottom: 15px;
    text-align: center;

    .is-main-page & {
      display: none;
    }
  }
  &__amount-cur {
    display: none;

    .is-main-page & {
      display: block;    
      font-weight: 900;
      font-size: 15px;
      line-height: 50px;
      position: absolute;  
      top: 0;
      right: 51px;
      z-index: 4;
      opacity: .4;
    }
  }
  &__btn-wrap {
    margin-top: 20px;
    text-align: center;
    max-width: 290px;
    margin-left: auto;
    margin-right: auto;

    .is-main-page & {
      margin-top: 0;
    }
  }
  &__btn {
    width: 100%;
    max-width: 290px;
    &.is-dark {
      display: none;
    }

    .is-main-page & {
      display: none;
    }
    .is-main-page &.is-dark {
      display: block;
      width: 169px;
      height: 50px;
      font-size: 18px;
      line-height: 21px;
      font-weight: 900;
      text-align: left;
      border-radius: 54px;
      position: absolute;
      bottom: 16px;
      right: 204px;
      z-index: 25;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
      padding: 0 48px 0 30px;
      @include text-dots;

      &::after {
        display: block;
        content: '';
        width: 21px;
        height: 12px;
        background-image: url(/img/arrow-white.svg);
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        right: 20px;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
  }

  &__fields-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -5px 20px;
  }
  &__fields-title {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: #3f3f3f;
  }
  &__field {
    margin-left: 5px;
    margin-right: 5px;
    width: calc(50% - 10px);
    max-width: 290px;

  }
  &__fields-wrap {
    .payment-form__field:last-child:nth-child(even) {
      width: 100%;
    }
  }
  &__description {
    color: #EA4642;
    //text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding-top: 16px;
  }

  @include xxs {
    &__field {
      width: 100%;
    }
  }
}

.linda-has-popup, .linda-has-popup body {
  height: 100%;
  position: relative;
  overflow: hidden;
}

@include xs {

  .open-left-menu, .open-left-menu body {
    height: 100%;
    position: relative;
    overflow: hidden;
  }
}

.page-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: rgba(65, 48, 52, 0.8);
  margin-top: 30px;
  margin-bottom: 20px;

  @include l {
    margin-top: 25px;
    margin-bottom: 15px;
  }

  @include l {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  @include xxs {
    font-size: 18px;
  }
}




