$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.bankid-banner {
  min-height: 370px;
  position: relative;
  padding:48px 0;
  background-color: #f9fbe7;
  overflow: hidden;
  z-index: 1;
  margin-bottom: 25px;

  &::before {
    content: '';
    mask-image: url(/img/mask.svg);
    mask-position: center bottom;
    mask-size: cover;
    mask-repeat: no-repeat;
    background-image: url($cdn + '/yoyo/blob.svg'), linear-gradient(#ffeaa1 0, #ffc600 100%);
    background-repeat: no-repeat;
    background-position: center top -100px, center;
    background-size: 1100px auto, 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

}
