$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.rules-l{
  $root: &;
  display: block;
  position: relative;
  margin-bottom:28px;
  flex:1 0 auto;
  padding: 0 40px;
  .land-page__container{
    background: rgba(255,209,128,0.3);
    border-radius: 10px;
  }
  &__wrap{
    position: relative;
    padding:40px 40px 60px 40px;
    max-width: 1294px;
    margin: 0 auto;
  }
  &__text{
    font-size: 24px;
    font-weight: 500;
    line-height: 20px;
    color: #646464;
    &.is-bold{
      font-weight:bold;
      line-height:34px;
      letter-spacing:0.08em;
      text-transform: uppercase;
      color:#3f3f3f;
      font-size:24px;
      margin:0;
      text-align: left;
      padding-left: 7px;
    }
  }
  &__content{
    height: 230px;
    overflow:hidden;
    margin-bottom:10px;
    position: relative;
    &:after{
      background: linear-gradient(180deg, rgba(251, 238, 200, 0) 0%, #FBEEC8 100%);
      background-size: 100%;
      background-repeat: no-repeat;
      width: 100%;
      height: 26px;
      content: '';      
      position: absolute;
      left: 0;
      bottom: 0;
    }
    strong{
      font-size:14px;
      font-weight:500;
      line-height:25px;
      color:#0c1533;
    }
    p{
      font-size:14px;
      font-weight:400;
      line-height:20px;
      color:#0c1533;
      &:last-child{
        display:none;
      }
      & a{
        font-size:14px;
        font-weight:500;
        line-height:25px;
        color:#0c1533;
        text-decoration: underline;
      }
    }
    table{
      max-width: 100%;
    }
  }
  &__input{
    display:none;
    &:checked + #{$root}__content{
      height:auto;
      &:after{
        display: none;
      }
    }
  }
  &__label{
    background-color: transparent;
    --color: #9746a5;
    color: var(--color);
    fill: var(--color);
    box-shadow: inset 0 0 0 1px var(--color);
    position: absolute;
    bottom: 28px;
    left: 50%;
    margin-left: -72px;
    text-align: center;

    &:hover{
      background-color: var(--color);
      color: #fff;
    }
  }
  @include l(){
    &__content{
      height: 275px;
    }    
  } 
  @include s(){
    &__content{
      height: 170px;
    }    
  }
  @include xs(){
    padding: 0 20px;
    &__content{
      height: 442px;
    }
    &__wrap{
      padding:24px 0;
    }
    &__content{
      margin-bottom:60px;
      table{
        font-size: 12px;
      }
    }
    &__text{
      &.is-bold{
        font-size:16px;
      }
    }
  }
  @include xxs(){
    &__content{
      & p{
        font-size:12px;
        line-height:17px;
      }
    }
  }


  table {
    width: 100%;
    & td:first-child {
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
      text-align: left !important;
      padding-left: 20px;
      height: 36px;
    }
    & td:last-child {
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
      text-align: right !important;
      padding-right: 20px;
    }
    & tr:nth-child( 2n - 1 ) td  {
      background-color: rgba(220, 219, 202, .5);
    }
  }
  ol {
    margin-bottom: 10px;
    padding-left: 22px;
    @include xs(){
      padding-left: 22px;
    }
  }
  ol li {
    font-size: 14px;
    line-height: 25px;
    color: #646464;
  }
}
