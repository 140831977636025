$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.bankid-banner-amount {
  height: 50px;
  margin-bottom: 20px;
  text-align: center;

  &__label {
    position: relative;
    display: block;
    width: 247px;
    height: 50px;
    margin: 0 auto;

    &::before {
      display: block;
      content: '';
      width: 30px;
      height: 30px;
      background: url($cdn + '/yoyo/coin.png');
      background-repeat: no-repeat;
      background-size: 100%;
      position: absolute;
      z-index: 20;
      top: 50%;
      left: 15px;
      margin-top: -15px;
    }
  }

  &__cur {
    position: absolute;
    font-size: 15px;
    line-height: 18px;
    font-weight: 900;
    color: rgba(64, 64, 64, 0.4);
    z-index: 3;
    top: 50%;
    right: 25px;
    margin-top: -9px;
  }

  &__error {
    width: 304px;
    margin: 0 auto;
    padding: 3px 20px 0;
    text-align: center;
  }

  &__error-text {
    color: #f9fbe7;
    font-size: 12px;
    line-height: normal;
    width: 100%;
    max-width: 100%;
  }

  &__input {
    max-width: 247px;
    height: 50px;
    border-radius: 60px;
    background-color: $white;
    box-shadow: inset 0px 0.770316px 3.08126px rgba(0, 0, 0, 0.43);
    font-size: 27px;
    line-height: 50px;
    color: $darkGrey;
    text-shadow: none;
    padding: 0 53px;
    &:hover {
      background-color: $white;
    }
  }
}
