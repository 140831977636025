$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
// --- VISIBILITY
.show-guest {
  body.linda-has-user & {
    display: none !important;
  }
}
.show-user {
  body:not(.linda-has-user) & {
    display: none !important;
  }
}

.is-hidden {
  display: none !important;
}

.is-block {
  display: block !important;
}

// --- DIMENSIONS
.has-full-width {
  width: 100% !important;
}

.has-auto-width {
  width: fit-content !important;
}

// --- POSITIONING
.has-text-center {
  text-align: center !important;
}

.has-text-right {
  text-align: right !important;
}

.has-text-left {
  text-align: left !important;
}

.is-center {
  margin: 0 auto !important;
}

.has-no-padding {
  padding: 0 !important;
}

.is-relative {
  position: relative;
}

@for $i from 0 through 60 {
  .top-#{$i} {
    margin-top: #{$i}px !important;
  }
  .bottom-#{$i} {
    margin-bottom: #{$i}px !important;
  }
}

// --- TEXT FORMATTING
.is-capitalize {
  text-transform: capitalize;
}

.is-uppercase {
  text-transform: uppercase;
}

.is-lowercase {
  text-transform: lowercase;
}

.is-underlined {
  text-decoration: underline;
}

.has-ellipsis {
  @include ellipsis();
}

// --- SCROLLBAR
.has-no-scrollbar {
  @include without-scrollbar();
}

body.no-min-width { min-width: unset !important;}
