$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.linda-has-user {

  & .left-menu__user, & .left-menu__btn-logout, & .left-menu__username {
    display: block;
  }
  & .left-menu__login {
    display: none;
  }
}

.left-menu-overlay {
  display:none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  .open-left-menu  & {
    display:block;
    background: rgba(0, 0, 0, 0.15);
  }
}


.left-menu{
  overflow:hidden;
  position:fixed;
  top:0;
  width:330px;
  min-height:100%;
  height:100%;
  z-index:1011;
  background-color: #FEE486;
  box-shadow: 0 1px 22px 0 rgba(0, 0, 0, 0.4);
  transform: translate(-350px, 0);
  will-change: transform;
  transition: 0.2s ease-out;
  @include without-scrollbar;

  .open-left-menu  & {
    transform: translate(0, 0);
    overflow:auto;
    max-height: 100vh;
  }
  &__item {
    position: relative;
  }
  &__scroll {
    min-height: 100%;
    padding-top: 24px;
    background-image: url($cdn + '/yoyo/half_candy.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;
  }

  &__logo {
    display: block;
    width: 94px;
    height: 40px;
    margin: 0 auto 54px;

    svg {
      width: 100%;
      height: 100%;
    }

    .linda-has-user & {
      margin-bottom: 38px;
    }
  }
  &__border-btn{
    margin: 0 auto 24px;
    width: 100%;
    height: 49px;
    border: none;
  }
  &__link {
    display: flex;
    align-items: center;
    width: 300px;
    height: 48px;
    line-height: 8px;
    font-size: 20px;
    color: #3F3F3F;
    text-align: left;
    position: relative;
    padding: 0 10px 0 20px;
    transition: .2s;

    &:after {
      display: block;
      content: '';
      width: 15px;
      height: 9px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      background-image: url(/img/arrow-right.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }

    &::before {
      display: block;
      content: '';
      width: 265px;
      height: 3px;
      background-color: $violetOld;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      transition: .2s;
    }

    &:hover {
      color: $violetOld;
      &::after {
        filter: invert(33%) sepia(42%) saturate(1685%) hue-rotate(256deg) brightness(89%) contrast(83%);
      }

      &::before {
        opacity: 1;
      }
    }

    &.active {
      background: linear-gradient(154.66deg, #A745B0 11.99%, #700E66 83.71%);
      border-radius: 50px;
      color: white;

      ::after {
        filter: brightness(1);
      }
      &:hover {
        color: $white;
      }
      &:hover::after {
        filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(351deg) brightness(104%) contrast(104%);
      }
      &:hover::before {
        opacity: 0;
      }
    }
    &.active::after {
      filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(351deg) brightness(104%) contrast(104%);
    }


    &.christmas-btn {
      border: solid 1px #2d9cdb;
      color: #2d9cdb;
      display: flex;
      align-items: center;
      justify-content: center;
      & svg {
        width: 16.5px;
        height: 18px;
        fill: #2d9cdb;
        margin-right: 5px;
      }
      &:hover {
        background-color: #2d9cdb;
        color: #fff;
        & svg {
          fill: #fff;
        }
      }
    }
  }
  &__closebox {
    height: 19px;
    display: flex;
    align-items: center;
    overflow:hidden;
    position: absolute;
    top: 35px;
  }
  &__close-btn{
    width: 13px;
    height: 14px;
    margin-left: 20px;
    flex-shrink:0;

    svg {
      width: 100%;
      height: 100%;
    }
  }
  &__close-text {
    margin-left: 9px;
    color: #aa69ac;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    letter-spacing: normal;
    line-height: normal;
    cursor:pointer;
    flex-shrink:0;
    @include text-dots
  }

  &__username {
    margin-left:auto;
    padding-left: 2px;
    font-weight: 900;
    font-size: 26px;
    line-height: 30px;
    color: $white;
    display:none;
    @include text-dots;
    margin-bottom: 13px;
  }

  &__userbox {
    flex-direction:column;
    justify-content: space-between;
    align-items:center;
    background: url($cdn + '/yoyo/shape.png'),
                linear-gradient(to bottom, #A745B0 9.65%, #700E66 92%);
                background-size: 110% auto, 100%;
    background-position: left -10px top -10px,
                         left top;
    box-shadow: 0px 4px 11px rgba(41, 11, 40, 0.39);
    filter: box-shadow(0px 14px 35px 0px #21014A 51%);
    border-radius: 18px;
    padding: 23px 16px 16px;
    margin: 0 16px 21px;
  }
  &__update-btn {
    width: 18px;
    height: 16px;
  }
  &__userbox-deposit {
    height: 48px;
    font-weight: 900;
    font-size: 22px;
    line-height: 48px;
    text-transform: capitalize;
    border-radius: 50px;
  }

  &__userbox-btn {
    margin: 0 auto 8px;
    display: block;
    width: 15px;
    height: 15px;
  }

  &__user {
    display: none;
  }

  &__list{
    display:block;
    padding: 0 20px 35px;
  }

  &__item {
    display:flex;
    justify-content:center;
  }

  &__btn-logout {
    display: none;
  }

  &__balance-wrap {
    display: grid;
    grid-template-columns: repeat(2, minmax(50%, 1fr));
    grid-template-rows: repeat(4, 1fr);
    align-items: center;
    word-break: break-word;
    grid-gap: 5px 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    min-width: 0;
    margin-bottom: 10px;
  }

  &__balance-key {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: $white;
    padding-right: 5px;
    @include text-dots;
    &:first-child {
      line-height: 30px;
      background-image: url($cdn + '/yoyo/coin.png');
      background-size: 30px 30px;
      background-position: left center;
      padding-left: 33px;
      background-repeat: no-repeat;
    }
  }

  &__balance-value {
    color: $white;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    text-align: right;
    @include text-dots;

      &.is-flex{
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

  }
  &__apps {
    padding: 0 20px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }

  &__bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    padding-bottom: 30px;
  }
  &__lang {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #AB69AD;
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;
    transition: .2s;
    .linda-has-user & {
      margin-left: 140px;
    }
    &:hover {
      cursor: pointer;
      background-size: 34px;
    }
  }
  &__logout {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #AB69AD;
    background-image: url(/img/logout.svg);
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 36px;
    transition: .2s;
    &:hover {
      cursor: pointer;
      background-size: 32px;
    }
  }

  @include xxs {
    width: 320px;
  }

  &__list{
    padding: 0 16px 10px;
  }
}
