$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.btn-app {
  display: block;
  width: 126px;
  height: 42px;
  background-size: 100% 100%;
  transition: transform 0.2s;

  .is-apple & {
    background-image: url('/img/app-store.svg');
  }

  .is-google & {
    background-image: url('/img/google-play.svg');
  }

  &:hover {
    transform: scale(1.05);
    transition: transform 0.2s;
  }
}
