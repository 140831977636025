$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;

.g-btn-icon {
  display: flex;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  line-height: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 8px;
  & svg, & path {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    fill: inherit;
  }
}

.g-btn-text {
  display: inline-block;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  max-width: 100%;
  border-radius: 20px;
  line-height: 40px;
  height: 40px;
  padding: 0 30px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  @include text-dots;

  &:disabled {
    pointer-events: none;
    cursor:default;
  }

}

.g-btn-text_high {
  padding: 0 50px;
  font-size: 24px;
  height: 60px;
  line-height: 60px;
  border-radius: 80px;
}

.g-btn-width290 {
  //min-width: 290px;
  width: 290px;
}




@mixin mixin-g-btn-with-bg($btn-color) {
  background-color: $btn-color;
  color: #fff;
  fill: #fff;
  box-shadow: inset 0 0 0 1px $btn-color;
  &:hover, &.active {
    background-color: transparent;
    color: $btn-color;
    fill: $btn-color;
  }

  @media ( any-hover: none) {
    &:hover {
      background-color: $btn-color;
      color: #fff;
      fill: #fff;
      box-shadow: none;
    }
    &.active {
      background-color: transparent;
      color: $btn-color;
      fill: $btn-color;
      box-shadow: inset 0 0 0 1px $btn-color;
    }
  }
}

@mixin mixin-g-btn-with-border($btn-color, $hover-btn-color) {
  background-color: transparent;
  color: $btn-color;
  fill: $btn-color;
  box-shadow: inset 0 0 0 1px $btn-color;
  &:hover, &.active {
    background-color: $btn-color;
    color: $hover-btn-color;
    fill: $hover-btn-color;
    box-shadow: none;
  }

  @media ( any-hover: none) {
    &:hover {
      background-color: transparent;
      color: $btn-color;
      fill: $btn-color;
      box-shadow: inset 0 0 0 1px $btn-color;
    }
    &.active {
      background-color: $btn-color;
      color: $hover-btn-color;
      fill: $hover-btn-color;
      box-shadow: none;
    }
  }
}


.g-btn-green-bg {
  @include mixin-g-btn-with-bg($greenOld)
}
.g-btn-light-green-bg {
  transition: 0.2s background-color,
              0.2s color;
  @include mixin-g-btn-with-bg($greenLight);

  &:disabled {
    background: rgba(0, 190, 110, 0.4);
    pointer-events: none;
  }
}
.g-btn-red-bg {
  @include mixin-g-btn-with-bg($red)
}
.g-btn-violet-bg {
  @include mixin-g-btn-with-bg($violetOld)
}
.g-btn-orange-bg {
  @include mixin-g-btn-with-bg($orange)
}


.g-btn-green-border {
  @include mixin-g-btn-with-border($greenOld, $white)
}
.g-btn-light-green-border {
  @include mixin-g-btn-with-border($greenLight, $white)
}
.g-btn-red-border {
  @include mixin-g-btn-with-border($red, $white)
}
.g-btn-violet-border {
  @include mixin-g-btn-with-border($violetOld, $white)
}
.g-btn-orange-border {
  @include mixin-g-btn-with-border($orange, $white)
}
.g-btn-white-border {
  @include mixin-g-btn-with-border($white, $red)
}
.g-btn-white-border-orange-bg {
  @include mixin-g-btn-with-border($white, $orange)
}
.g-btn-dark-violet-border {
  @include mixin-g-btn-with-border($violetDark, $violetDark);
  &:hover {
    color: $white;
    fill: $white;
  }
  &.active {
    color: $white;
  }
}


.g-btn-light-green-always-bg {
  background-color: $greenLight;
  color: #fff;
  fill: #fff;
  &:hover, &.active {
    background-color: $greenOld;
  }
  @media ( any-hover: none) {
    &:hover {
      background-color: $greenLight;
    }
  }
}

.g-btn-white-bg {
  background-color: $white;
  color: $red;
  &.is-violet-text {
    color: $violetDark;

    &:hover {
      color: $white;
      background-color: $violetDark;
      box-shadow: none;
    }
  }
  &:hover {
    background-color: transparent;
    color: $white;
    box-shadow: inset 0 0 0 1px $white;
  }
  @media ( any-hover: none) {
    &:hover {
      background-color: $white;
      color: $red;
      box-shadow: none;
    }
  }
}

.g-btn-dark-violet-border-wide{
  display: block;
  width: 100%;
  height: auto;
  border-radius: 50px;
  font-size: 20px;
  line-height: 49px;
  font-weight: 700;
  color: #933196;
  text-align: center;
  padding: 0 20px;

  background-color: transparent;
  box-shadow: inset 0 0 0 3px #9746a5;

  @include text-dots;
  box-sizing: border-box;

  &:hover{
    background: linear-gradient(154.66deg,#a745b0 11.99%,#700e66 83.71%);
    color: $white;
    box-shadow: none;
  }
}

.g-btn-dark-green {
  background-color: #01C02A;
  color: $white;
  &:hover {
    background: $darkGreen;
  }
  &:disabled {
    background-color: rgba(0, 190, 110, 0.4);
    color: rgba(255, 255, 255, 0.8);
  }
}
//.g-btn-light-green {
//  background-color: $greenLight;
//  color: $white;
//  &:hover {
//    color: $greenLight;
//    background: transparent;
//    border: 1px solid $greenLight;
//  }
//}

.g-btn-orange {
  background-color: #FFA800;
  color: $white;
  &:hover {
    background-color: #ED9C00;
  }
}

.g-btn-dark-violet {
  background-color: $violetDark;
  color: $white;
  fill: $white;
  &:hover {
    background-color: #81348E;
  }
}

.g-btn-minw-252 {
  min-width: 252px;
}