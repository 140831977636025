$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;


//--- class-helper ---//
.h-hide-input {
  display: none;
}

.h-for-z-index {
  $filters-num: 3;
  @for $i from 1 through $filters-num{
    &:nth-child(#{$i}){
      z-index: #{$filters-num - $i + 2};
    }
  }
}


.balance-history {
  position: relative;

  display: block;
  border: solid 1px #ffa800;
  border-radius: 10px;
  padding-bottom: 29px;

  &__filters {
    min-height: 80px;
    padding: 20px;

    display: flex;
    justify-content:center;
    align-items: center;
    background-color: #ffd180;
    border-radius: 10px 10px 0 0;
  }

  &__filter-item {
    width: 168px;
    height: 40px;
    margin-right: 10px;
    position: relative;
    &:last-child {
      margin-right: 0;
    }
    &.is-range {
      width: auto;
      min-width: 220px;
    }
  }

  &__status-filter {
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 40px;
      background: rgba( 255, 255, 255, .6);
      border-radius: 25px;
      z-index: 10;
    }
    &.is-active-status-filter {
      &::before {
        display: none;
      }
    }
  }

  &__date-filter {
    display: flex;
    position: relative;
  }

  &__date {
    width: 100%;
    padding: 13px 25px;
    line-height: 16px;
    background-color: #fff;
    border: none;
    text-align: center;
    position: relative;
    z-index: 1;
    border-radius: 25px;
    color: #3e3e3e;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;

    &_from {
      span {
        display: none;
        .linda-has-range & {
          display: inline;
        }
      }
    }
    
    &_to {
      opacity: 0;
      .linda-has-range & {
        opacity: 1;
      }
    }
  }

  &__table-wrap {
    position: relative;
    min-height: 100px;
  }

  &__table {
    height: 467px;
    overflow: auto;
    &.is-table-with-scroll {
      margin-right: 10px;
      padding-right: 10px;
    }
    &::before {
      content: '';
      width: 100%;
      height: 43px;
      background: #f7fce5;
      position: absolute;
    }
  }

  &__head {
    position: sticky;
    z-index: 1;
    top: 0;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 43px;

    line-height: 1.2;
    font-size: 12px;
    text-align: center;
    color: #66554F;
    font-weight: 700;
    background-color: #f9fbe7;
  }

  &__body {
    position: relative;
    z-index: 0;
  }

  &__empty {
    text-align: center;
    color: #DCDBCA;
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
  }

  &__row {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    font-weight: 500;
    color: #66554F;
  }

  &__table-item {
    width: 170px;
    padding: 0 6px;
    word-break: break-all;
    overflow: hidden;
    &_bonus {
      color: $greenOld;
    }
    &_real {
      color: $violetLight;
    }
  }

  &__short-preview {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin-bottom: 1px;

    background-color: #f1f2dd;
    transition: background-color .2s;

    &:hover {
      cursor: pointer;
      background-color: #FAF3D2;
    }

    &::after {
      content: '';
      width:11px;
      height: 7px;
      background-image: url('/img/arr-select_black.svg');
      background-repeat: no-repeat;
      background-size: 11px 7px;
      position: absolute;
      z-index: 5;
      right: 21px;
      top: 22px;
      transition: transform .2s;
    }
  }
  &__full-descr {
    display: none;
    align-items: center;
    min-height: 50px;
    background-image: linear-gradient(to right, #FCE6B3 0%, #FCE6B3 50%, #FCE6B3 calc(50% + 1px), #f9fbe7 calc(50% + 1px), #f9fbe7 calc(50% + 2px), #FCE6B3 calc(50% + 2px), #FCE6B3 100% );
  }
  &__descr-item {
    width: 50%;
    min-height: 49px;
    overflow: hidden;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__descr-title {
    font-size: 12px;
    font-weight: 700;
    padding-right: 10px;
  }

  @include s {
    &__filters {
      flex-wrap: wrap;
    }
    &__filter-item {
      width: 252px;
      margin-bottom: 10px;
      margin-right: 5px;
      margin-left: 5px;
    }
    &__filters-btn-block {
      width: 100%;
      text-align: center;
    }
    &__filters-btn {
      margin-bottom: 0;
    }

    &__table-item {
      width: 160px;
    }
  }

  @include xs {
    border: none;
    border-bottom: solid 1px #ffa800;
    border-radius: 0;
    padding-bottom: 0;

    &__filters {
      border-radius: 0;
    }
    &__filter-item {
      width: 204px;
      max-width: 252px;
      flex-grow: 1;
    }
    &__date-filter {
      width: 100%;
      justify-content: center;
    }
    &__filters-btn {
      width: 252px;
    }
    &__table-item {
      padding: 0 1px;
      &:last-child {
        padding-right: 20px;
      }
    }
    &__table-item_amount {
      font-size: 14px;
    }
    &__short-preview {
      padding-left: 15px;
      padding-right: 15px;
      background-color: #f1f2dd;
      &::after {
        right: 15px;
      }
    }
    &__row {
      font-size: 12px;
    }
    &__head {
      font-size: 11px;
      line-height: 13px;
      padding-left: 15px;
      padding-right: 15px;
    }
    &__full-descr {
      flex-direction: column;
      background-image: none;
      background-color: #FCE6B3;
      height: auto;
    }
    &__descr-item {
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #f9fbe7;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__descr-data {
      font-size: 14px;
      line-height: 16px;
    }

    @include xxs {
      &__filter-item {
        width: 252px;
      }
      &__date-filter {
        height: 108px;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  //--------------------------------------------//
  //--- CONDITION: ROW WITH OPEN DESCRIPTION ---//
  //--------------------------------------------//
  $root: &;
  $opened-row-class: is-opened-row;

  &__short-preview {
    &.#{$opened-row-class} {
      background: #FFD180;
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &__full-descr {
    #{$root}__short-preview.#{$opened-row-class} + & {
      display: flex;
    }
  }
  //--------------------------------------------//
  //--- END OF STYLES FOR CONDITION ---//
  //--------------------------------------------//

}




.balance-history-select {
  position: absolute;
  top: 0;
  width: 100%;
  height: 40px;

  border-radius: 25px;
  overflow: hidden;

  line-height: 30px;
  font-size: 14px;
  color: #656565;
  transition: box-shadow .1s, border-radius .1s;
  z-index: 4;
  &:hover {
    cursor: pointer;
  }

  &::after {
    content: '';
    width:11px;
    height: 7px;
    background-image: url('/img/arr-select.svg');
    background-repeat: no-repeat;
    background-size: 11px 7px;
    position: absolute;
    z-index: 5;
    right: 19px;
    top: 17px;
    transition: transform .2s;
  }

  &__top {
    padding: 8px 45px 7px 25px;
    line-height: 25px;
    background-color: #fff;
    @include text-dots;
    position: relative;
    z-index: 1;
  }

  &__bottom {
    position: relative;
    z-index: 0;
    width: 100%;
    padding-bottom: 15px;
    background-color: #fff;
    height: 0;
    overflow: hidden;
    opacity: 0;
    transform: translateY(-100px);
    transition: opacity .1s, transform .1s;
  }

  &__checkbox-block {
    display: block;
    padding: 3px 45px 3px 25px;
    line-height: 30px;
    background-color: #fff;
    cursor: pointer;
    @include text-dots;

    input:checked + & {
      background-image: url('/img/check.svg');
      background-repeat: no-repeat;
      background-size: 13px 9px;
      background-position: right 18px top 14px;
    }
  }

  &__divider {
    align-items: center;

    color: #FFC051;
    font-size: 12px;
    line-height: 21px;
    padding: 10px 18px 5px 25px;
    @include text-dots;
    display: none;
    
    &::after {
      content: '';
      flex-grow: 1;
      height: 1px;
      margin-left: 5px;
      background-color: #FFD180;
    }

    .linda-first-in-group & {
      display: flex;
    }
  }

  //--------------------------------------------//
  //--- CONDITION: OPEN SELECT ---//
  //--------------------------------------------//
  $root: &;
  $opened-select-class: is-open-select;

  &.#{$opened-select-class} {
    height: auto;
    border-radius: 10px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
    &::after {
      transform: rotate(180deg);
    }

    #{$root}__bottom {
      height: auto;
      opacity: 1;
      transform: translateY(0);
    }
  }
  //--------------------------------------------//
  //--- END OF STYLES FOR CONDITION ---//
  //--------------------------------------------//

}




.balance-history-bottom-descr {
  margin-top: 15px;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  &__item {
    color: #66554F;
    padding: 0 15px;
  }
  &__real {
    color: $violetLight;
  }
  &__bonus {
    color: $greenOld;
  }

  @include xxs {
    margin-left: -15px;
    margin-right: -15px;
    &__item {
      padding: 0 8px;
    }
  }
}





//--------------------------------------------//
//--- Показувати статуси в другому фільтрі в залежності від вібраних типів транзакцій в першому фільтрі ---//
//--------------------------------------------//
.is-status {
  display: none;
  &.linda-is-active {
    display: block;
  }
}



//--------------------------------------------//
//--- ONLY FOR TOUCH DEVICES ---//
//--------------------------------------------//
.table-filter {

  input {
    -moz-appearance: none;
    -webkit-appearance: none;
    font-size: 14px;
    line-height: 40px;

    background-color: #fff;
    background-image: url('/img/arr-select.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) center;

    max-width: 100%;
    border-radius: 20px;
    height: 40px;
    color: #3e3e3e;
    //padding: 8px 25px;
    width: 100%;
    display: block;
    box-sizing: border-box;
    border: none;
  }

  &__date {
    display: inline-block;
    width: 168px;
    &:last-child {
      opacity: 0;
      height: 0;


      .linda-has-range & {
        opacity: 1;
        height: auto;
      }
    }
    @include s {
      width: 252px;
      margin-bottom: 12px;
    }
    @include xs {
      width: 204px;
      max-width: 252px;
      flex-grow: 1;
      & + & {
        margin-left: 10px;
      }
    }
    @include xxs {
      width: 252px;
      & + & {
        margin-left: 0;
      }
    }
  }
}


.is-touch-templ {
  .is-table-with-scroll {
    padding: 0;
    margin: 0;
  }

  .balance-history {
    &__table {
      max-height: 467px;
    }

    &__date-filter-touch {
      width: 346px;
      margin-right: 10px;
      & > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }

    @include s {
      &__table {
        height: auto;
      }
      &__date-filter-touch {
        width: 516px;
        margin-right: 0;
      }
    }

    @include xs {
      &__date-filter-touch {
        width: calc( 100% - 10px);
        max-width: 512px;
      }
    }

    @include xxs {
      &__date-filter-touch {
        height: 108px;
        & > div {
          display: block;
          text-align: center;
        }
      }
    }
  }

}

//.balance-history {
//  &__date-filter {
//    @include s {
//      width: 516px;
//    }
//    @include xs {
//      width: calc( 100% - 10px);
//      max-width: 512px;
//    }
//    @include xxs {
//      height: 108px;
//    }
//  }
//}










