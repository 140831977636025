$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;

.rg-bar-timer-desktop {
  font-size: 14px;
  font-weight: 500;
  color: #66554f;
  &__counter {
    width: 80px;
  }
  @include xs {
    // display: none;
  }
}

.se-header-reg-btn {
  display: block;
  & svg {
    display: none;
    width: 29px;
    height: 24px;
  }
  @include s {
    min-width: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 8px 7px 8px 4px;
    box-sizing: border-box;
    & svg {
      display: block;
    }
    & span {
      display: none;
    }
  }
  @include xxs {
    display: none;
  }
}