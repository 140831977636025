$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.container {
  --offset-x: 20px;

  display: block;
  width: calc(100% - (var(--offset-x) * 2));
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  &.has-no-offset {
    --offset-x: 0;
  }

  @include bp-1440 {
    --offset-x: 35px;

    &.has-no-offset {
      --offset-x: 0;
    }
  }
}
