$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.linda-widget-jackpot{
  display:block;
  background-color:#ea4642;
  position: relative;
  z-index: 0;
    &__content{
    padding:80px 0 0px 35px;
    position: relative;
    z-index: 5;
    height: 400px;
  }
  &__roullette-image{
    height: 430px;
    right: 0px;
    left: 0px;
    top: -30px;
    position: absolute;
    overflow: hidden;
    text-align: right;
    & img {
      max-width: unset;
      right: -111px;
      position: absolute;
      animation: rouletteRotate 60s linear infinite;

    }
  }
  &__title{
    width:50%;
    font-size: 34px;
    font-weight: 600;
    line-height: 1.2;
    color: #ffffff;
    margin:0 0 30px 0;
    @include text-dots();
  }
  &__subtitle {
    font-size: 80px;
    font-weight: bold;
    color: #ffd180;
    line-height: 94px;
    max-width: 600px;
    text-transform: uppercase;
    margin: 0;
  }
  //&__sum{
  //  width:50%;
  //  font-size: 80px;
  //  font-weight: bold;
  //  color: #ffd180;
  //  line-height: 1.2;
  //  margin:0 0 30px 0;
  //  max-height: 96px;
  //  @include text-dots();
  //  .item {
  //    position: relative;
  //    top: 5px;
  //  }
  //}
  //&__button{
  //  background-color:#fff;
  //  color:#ea4642;
  //  &:hover{
  //    background-color:#ea4642;
  //    color:#ffffff;
  //    box-shadow: inset 0 0 0 1px #ffffff;
  //  }
  //}
  &__icon-cup {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    z-index: -1;
    & svg {
      width: 210px;
      height: 240px;
      opacity: 0.3;
      fill: rgb(249, 251, 231);
    }
  }
  //Odometr
  .odometer.odometer-auto-theme, .odometer.odometer-theme-default {
    display: inline-block;
    vertical-align: middle;
    // *vertical-align: auto;
    // *zoom: 1;
    // *display: inline;
    position: relative;
  }
  .odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-default .odometer-digit {
    display: inline-block;
    vertical-align: middle;
    // *vertical-align: auto;
    // *zoom: 1;
    // *display: inline;
    position: relative;
  }
  .odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer {
    display: inline-block;
    vertical-align: middle;
    // *vertical-align: auto;
    // *zoom: 1;
    // *display: inline;
    visibility: hidden;
  }
  .odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
    text-align: left;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }
  .odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .odometer.odometer-theme-default .odometer-digit .odometer-ribbon {
    display: block;
  }
  .odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .odometer.odometer-theme-default .odometer-digit .odometer-ribbon-inner {
    display: block;
    -webkit-backface-visibility: hidden;
  }
  .odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-default .odometer-digit .odometer-value {
    display: block;
    -webkit-transform: translateZ(0);
  }
  .odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-default .odometer-digit .odometer-value.odometer-last-value {
    position: absolute;
  }
  .odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner {
    -webkit-transition: -webkit-transform 2s;
    -moz-transition: -moz-transform 2s;
    -ms-transition: -ms-transform 2s;
    -o-transition: -o-transform 2s;
    transition: transform 2s;
  }
  .odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  .odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-down .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  .odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
    -webkit-transition: -webkit-transform 2s;
    -moz-transition: -moz-transform 2s;
    -ms-transition: -ms-transform 2s;
    -o-transition: -o-transform 2s;
    transition: transform 2s;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  @include l(){
    &__content{
      padding:70px 0 0px 30px;
    }
    &__roullette-image {
      height: calc(100% + 30px);
      & img {
        right: -310px;
      }
    }
  }
  @include m(){
    &__roullette-image{
      & img {
        right: -411px;
      }
    }
  }
  @include s(){
    &__content{
      padding:35px 0 0px 9px;
      height: 250px;
    }
    &__title{
      font-size:24px;
      margin:0 0 20px 0;
    }
    &__subtitle {
      font-weight: 900;
      font-size: 48px;
      line-height: 56px;
      max-width: 360px;
    }
    //&__sum{
    //  font-size:60px;
    //  margin:0 0 20px 0;
    //  max-height: 72px;
    //}
    &__icon-cup {
      & svg {
      width: 149px;
      height: 170px;
      }
    }
    &__roullette-image {
      height: calc(100% + 25px);
      top: -25px;
      & img {
        width: 550px;
        right: -174px;
      }
    }
  }
  @include xs(){
    &__roullette-image {

      & img {
        right: -380px;
      }
    }
  }
  @include xxs(){
    display:none;
  }
}

@keyframes rouletteRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
