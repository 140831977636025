$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.birth-date {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &__item {
    width: calc(100% / 3 - 5px);
    select {
      background-color: white;
    }
  }
}



linda-form-date-selector.disabled {
  .birth-date {
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 40px;
      position: absolute;
      z-index: 10;
    }

    &__item select {
      background-color: #dcdbca;
      border-color: transparent;
    }
  }
}
