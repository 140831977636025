$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.jackpot-main {
  padding-bottom: 28px;
}

.jp-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #66554F;
  padding-bottom: 16px;
}

.grid-wrap {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  margin-bottom: 20px;

  @include m {
    grid-template-columns: repeat(5, 1fr);
  }

  .has-jackpot {
    .game-item-jackpot__text {
      display: none;
    }
  }

  &__item-main {

    .game-item__pic {
      height: 100%;
    }

    &:first-child {
      grid-column: 1 / 3;
      grid-row: 1 / 3;
    }

    &:hover {
      .game-item__hoverplay {
        opacity: 1;
      }
    }
  }

  &__item-sub {
    display: block;
    overflow: hidden;

    &:nth-last-child(-n+2){
      display: block;
    }

    @include m {
      &:nth-last-child(-n+2){
        display: none;
      }
    }

    &:hover {
      .game-item__hoverplay {
        opacity: 1;
      }
    }
  }
}