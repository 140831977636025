$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.game-item-jackpot {
  &__text,
  &__amount {
    background: linear-gradient(154.66deg, #A745B0 11.99%, #700E66 83.71%);
    text-align: center;
    color: #fff;
    position: absolute;
    font-weight: 900;
    padding: 0 13px;
    @include text-dots;
  }

  &__text {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0 0 10px 10px;
    line-height: 20px;
    font-size: 12px;
    min-width: 82px;
    z-index: 2;
  }

  &__amount {
    bottom: 0;
    width: 100%;
    line-height: 30px;
    font-size: 18px;
    border-radius: 0 0 5px 5px;
    z-index: 2;

    .is-sm & {
      font-size: 10px;
      line-height: 20px;
    }

  }

  @include bp-1440 {

    .is-sm &__amount {
      font-size: 14px;
      line-height: 26px;
    }
    .is-sm &__text {
      min-width: 86px;
      font-size: 14px;
      line-height: 24px;
    }

  }
}