$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.header-l-lang{
    $root: &;
    display:flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;

    font-size: 14px;
    color: #3f3f3f;
    font-weight:500;
    &:hover{
        cursor: pointer;

        #{$root}__ico{
            fill:#9746a5;
            transition: 0.2s;
        }
        span{
            color:#9746a5;
            transition:0.2s;
        }
    }

    &__ico{
        width:20px;
        height:20px;
        margin-right:8px;
        & svg{
            max-width:100%;
            max-height:100%;
        }
    }

    @include s() {
        &:hover {
            #{$root}__ico  {
                fill: #35d290;
                transition: 0.2s;
            }
        }
        span {
            display: none;
        }
        &__ico {
            position: absolute;
            right: 0;
            width: 24px;
            height: 24px;
            margin-right: 0;
            fill: #35d290;
        }
    }
}