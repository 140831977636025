$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.promo-item-full {
    position: relative;
    min-height: 360px;
    padding: 58px 113px 0;
    display: flex; 
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 60% 0;
    border-radius: 10px;
    border: 1px solid #00BE6E;

    &::before {
        content:'';
        width: 340px;
        height: 340px;
        position: absolute;
        top: 50%;
        right: 70px; 
        transform: translateY(-50%);
        background: #E6E9D0;
        box-shadow: inset 0px 2.68641px 2.68641px rgba(0, 0, 0, 0.25);
        border-radius: 100%;
    }
    &__img {
        width: 347px;
        height: 347px;
        position: absolute;
        top: 50%;
        right: 70px; 
        transform: translateY(-50%) scaleX(-1);
        background-size: contain;
        background-repeat: no-repeat;
    }

    &__content {
        max-width: 490px;
    }
    &__title {
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 28px;
        line-height: 120%;
        color: #3F3F3F;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__description {
        margin: 0 0 20px;
        color: #00BE6E;
        font-weight: 900;
        font-size: 36px;
        line-height: 120%;
        @include text-lines-clip(3);
    }
    &__button {
        min-width: 138px;
        background: #35D290;
        &:hover {
            background-color: #01c02a;
        }
    }
    &__text {
        z-index: 1;
        align-self: start;
        margin-top: 33px;
        background: #F9FBE7;
        border-radius: 50px;
        font-size: 8px;
        color: #3F3F3F;
        max-width: 490px;

        a {
            color: #6E0E57;
            &:hover {
                color: $darkDarken;
            }
        }
    }
    &__back-link {
        position:absolute;
        top: 20px; 
        left: 20px;
        font-weight: bold;
        font-size: 16px;
        color: #3F3F3F;
        padding-left: 30px;
        background-image: var(--icon-back);
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: contain;
        text-transform: capitalize;
        cursor: pointer;
        &:hover{
            filter: invert(33%) sepia(42%) saturate(1685%) hue-rotate(256deg) brightness(89%) contrast(83%);
        }
    }

    @include m {
        min-height: 264px;
        padding: 26px 67px 0;
        &::before {
            width: 250px;
            height: 250px;
            right: 50px;
        }
        &__img{
            right: 50px;
            width: 255px;
            height: 255px;
        }

        &__button {
            min-width: unset;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
        }
    }
}