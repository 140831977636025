$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.lang-popup {
  &__list {
    margin-bottom: -20px;
  }
  &__item {
    border-radius: 100%;
    display: inline-block;
    margin: 0 19px 20px 0;
    position: relative;
    &:nth-child(5n) {
      margin-right: 0;
    }
  }
  &__icon {
    width: 40px;
    height: 40px;
    border: 3px solid transparent;
    & img {
      border-radius: 100%;
      border: 3px solid transparent;
      transition: all .2s;
      font-size: 10px;
      word-break: break-all;
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  &__lang {
    display: none;
  }
}

.lang-popup__item.active {
  img {
    border: solid 3px #f9fbe7;
  }
}
.lang-popup__item:hover {
  img {
    border: solid 3px #f9fbe7;
  }
}
