$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.game-item-simple {
  position: relative;
  overflow:hidden;
  cursor: pointer;

  &__pic {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
    overflow: hidden;
    &.has-jackpot {
      border: 2px solid #700E66;
    }
  }
  &__pic::before {
    content: "";
    display: block;
    padding-top: 66.6%;
  }

  &__label {
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    text-align:center;
    height: 20px;
    line-height: 20px;
    width: 100px;
    background-color: #35d290;
    position: absolute;
    top: 13px;
    left: -25px;
    transform:rotate(-45deg);
    z-index:101;
    padding: 0 20px;
    @include text-dots;
    text-transform: capitalize;

    &.is-exclusive {
      background: #EA4642;
      font-size: 11px;
    }

    &.is-live {
      display: none !important;
    }

    &.is-dropAndWins {
      display: block;
      width: 36%;
      height: 32%;
      background-image: url($cdn + '/betinia/ribbons/drops-wins.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-color: transparent;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      transform: rotate(0);
      font-size: 0;
      border-top-left-radius: 10px;

      .is-big & {
        width: 40%;
        height: 29%;
      }
    }

  }

  &__hoverplay{
    position: absolute;
    top: -2px;
    left: -2px;
    right:  -2px;
    bottom:  -2px;
    width: calc(100% + 4px);
    background-color: rgba(249,251,231, 0.8);
    padding: 0 24px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 100;
    overflow: hidden;

  }

  &__hoverplay {
    opacity: 0;
    height: calc(100% + 4px);
    transition: .2s;
  }

  &__play {
    max-width: 100%;
  }

  &__demo {
    // display: none;
    margin-top: 5px;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.76;
    font-weight: 700;
    @include text-dots;
    max-width: 100%;

    // linda-game-item-simple.linda-has-demo & {
    //   display: block;
    // }
  }

  &__bar {
    margin-top: 7px;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    &_touch {
      padding-right: 35px;
    }
  }
  &__name {
    font-size: 14px;
    font-weight: 700;
    color: #3f3f3f;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &:hover {
    & .game-item-simple__hoverplay {
      opacity: 1;
    }
  }
  @include hover-v2 {
    & .game-item-simple__name {
      color: $violetOld;
    }
  }
}