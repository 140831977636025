$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.header-balance {
  width: 48%;
  flex-grow: 1;
  text-align: right;

  &__block {
    padding-right: 30px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.41;
    letter-spacing: normal;
    color: #3f3f3f;
    display: inline-block;
    max-width: 100%;
  }

  &__field {
    display: flex;
    justify-content: space-between;
  }

  &__key {
    text-transform: capitalize;
  }

  &__value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__balance {
    position: relative;
  }

  &__balance-value {
    color: #ab69ad;
  }

  &__update-btn {
    position: absolute;
    right: -16px;
    top: 3px;
    width: 12px;
    height: 12px;
    border: none;
    background: none;
    padding: 0;
    border-radius: 50%;
    cursor: pointer;
    & svg {
      fill: #35d290;
    }
    &.linda-has-preloader {
      animation: spin 0.8s infinite linear;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}