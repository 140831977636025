$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
@use "sass:math";

.game-scrollbox {
  $this: &;
  --columnCount: 6;

  &__in {
    width: calc(100% + var(--offset-x) * 2);
    margin-left: calc(-1 * var(--offset-x));
    margin-top: 3px;
  }

  &__track {
    --item-width: calc((100vw - 28px ) / var(--columnCount));
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 calc(var(--offset-x) - 3px);
  }

  &__item {
    flex-shrink: 0;
    width: calc(100% / var(--columnCount));
    padding: 0 3px;
  }

}