$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.login-popup {
  padding-top: 20px;
  padding-bottom: 32px;
  max-width: 450px;

  &.is-frame {
    padding-top: 6px;
  }



  &__btn-box {
    margin-top: 20px;
  }
  &__btn {
    max-width: 100%;
    width: fit-content;
    margin: 0 auto 52px;
    display: block;
  }

  &__logo {
    width: 135px;
    height: 54px;
    margin: 0 auto 32px;

    .is-frame & {
      display: none;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__nav {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;

    .is-frame & {
      display: none;
    }
  }
  &__nav-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: inset 0 0 0 1px $violetLight;
    background-color: transparent;
    border-radius: 20px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    height: 40px;
    padding: 0 30px;
    width: 100%;
    color: $violetLight;
    cursor: pointer;
    transition: 0.2s background-color;

    &:hover,
    &.is-active {
      color: white;
      background-color: $violetLight;
    }

  }

  &__nav-icon {
    width: 21px;
    height: 20px;
    background-image: var(--icon-bank-id);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    filter: $filterAA69AC;
    margin-right: 8px;
  }

  &__title {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #66554F;
    @include text-dots;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(63, 63, 63, 0.2);
    margin-top: 20px;
  }

  &__link {
    text-decoration: underline;
    font-size: 14px;
    line-height: 16px;
    color: rgb(62, 62, 62);
    font-weight: 700;
    text-align: center;
    background-color: transparent;
    border: none;
    padding: 0;

    &.has-inherit {
      line-height: inherit;
    }

    &:hover {
      color: rgb(171, 105, 173);
    }
  }
  &__question {
    text-align: center;
    font-size: 12px;
    line-height: normal;
    color: rgb(62, 62, 62);
    margin-top: 20px;
  }
  &__reg {
    text-align: center;
    margin-top: 9px;
    &::first-letter {
      text-transform: capitalize;
    }
  }
  &__ec-promo {
    background-color: #ffc051;
    margin: 20px -30px -40px;
    padding: 20px 30px 40px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  &__ec-promo-text {
    font-size: 12px;
    line-height: 1.2;
    color: #3e3e3e;
    margin-bottom: 15px;
    text-align: center;
  }

  &__secure-block {
    margin-top: auto;
  }
  @include xxs {
    &__ec-promo {
      padding: 20px 15px 40px;
      margin: 20px -15px -40px;
      border-radius: 0;
    }
  }
}