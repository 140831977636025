$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.reg-modal {
    font-size: 14px;
    color: #66554f;
    text-align: left;
    background: rgba(62,62,62, .8);

    &__box {
        width: 100%;
        max-width: 450px;

        &.is-step-2 {
            padding-top: 56px;
        }
    }
    &__subtitle {
        padding-bottom: 10px;
        border-bottom: 1px solid rgba( 62, 62, 62, .2 );
        margin-bottom: 0;
        &.no-border {
            border-bottom: none;
        }
    }
    &__btn {
        display: block;
        margin: 0 auto;
        background: #35D290;
        &:hover {
            background: #00BE6E;
            box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
        }
        &:disabled {
            background: rgba(0, 190, 110, 0.4);
            pointer-events: none;
        }
    }
    &__link {
        display: block;
        margin: 16px auto 26px;
        cursor: pointer;
        font-size: 14px;
        color: #66554f;
        font-weight: bold;
        border: none;
        background: none;
        border-bottom: 1px dashed #66554f;
    }

    &__formbox {
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 10px;
        .is-full-width {
            grid-column: span 2;
        }
        .is-centered {
            margin: 0 auto;
        }
    }
    &__promotions {
        display: grid;
        row-gap: 8px;
        margin-top: 6px;
    }
    &__promotions-text {
        margin-top: 24px;
        font-size: 12px;
        line-height: 120%;
        color: #66554F;
    }
    &__promo-link {
        display: flex;
        margin: 24px 0;
        justify-content: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #66554F;
        text-decoration: underline;
        &:hover {
            opacity: .8;
        }
    }
    &__back-link {
        position:absolute;
        top: 17px;
        left: 20px;
        font-weight: bold;
        font-size: 14px;
        color: #66554F;
        padding-left: 20px;
        background-image: var(--icon-back);
        background-color:transparent;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: contain;
        border: none;
        text-transform: capitalize;
        cursor: pointer;
        &:hover{
            opacity: .8;
        }
    }
}